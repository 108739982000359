import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ContratadoService } from '../../../services/contratado.service';
import { Contratado } from 'src/app/models/contratado.model';
import { FiltroContratado } from '../../../models/filtro-contratado.model';
import { Especialidade } from '../../../models/especialidade.model';
import { ActivatedRoute, Router } from '@angular/router';
import { ProfissionalSaude } from '../../../models/profissionalSaude.model';
import { ProfissionalSaudeService } from '../../../services/profissionalSaude.service';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { TabelaConvenio } from 'src/app/models/tabela-convenio.model';
import { Porte } from '../../../models/porte-model';
import { ConvenioService } from '../../../services/convenio.service';
import { EmpresaService } from '../../../services/empresa.service';
import { ProfissionalContratado } from 'src/app/models/profissional-contratado.model';
import { ContratadoConvenio } from 'src/app/models/contratado-convenio.model';
import { ProfissionalConvenio } from '../../../models/profissional-convenio.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { SelectItem, MessageService, ConfirmationService, Dialog } from 'primeng/primeng';
import { Estado } from 'src/app/models/estado.model';
import { Cidade } from 'src/app/models/cidade.model';
import { HorariosAgenda } from 'src/app/models/horariosAgenda.model';
import { ProfissionalConselho } from 'src/app/models/profissional-conselho.model';
import { Conselho } from 'src/app/models/conselho.model';
import { Usuario } from 'src/app/models/usuario.model';
import { ValueTransformer } from '@angular/compiler/src/util';
import { ComunsService } from 'src/app/comuns/comuns.service';
import { DatePipe } from '@angular/common';
import { NullVisitor } from '@angular/compiler/src/render3/r3_ast';
import { Memed } from 'src/app/models/memed.model';

@Component({
  selector: 'app-profissional-executante',
  templateUrl: './profissional-executante.component.html',
  styleUrls: ['./profissional-executante.component.css'],
  providers: [ConfirmationService, MessageService]
})

export class ProfissionalExecutanteComponent implements OnInit {

  @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();

  display: boolean = false;
  lancamentos: any;

  contratados: Array<Contratado>;
  convenios: Array<ContratadoConvenio>;
  contratadosByProfissional: Array<Contratado>;
  contratadosSelecionados: Array<Contratado>;
  tabelasConvenio: Array<TabelaConvenio>;
  portes: Array<Porte>;
  profissionalContratados: Array<ProfissionalContratado>;
  profissionalConvenios: Array<ProfissionalConvenio>;
  conselhos: Array<Conselho>;
  estados: Array<Estado>;
  cidades: Array<Cidade>;
  listaEspecialidades: Array<Especialidade>
  profissionalConselhos: Array<ProfissionalConselho>;

  listaContratadosProfissional: Array<Contratado>;
  profissional: ProfissionalSaude;
  profissionalExecutanteForm: FormGroup;
  submitted: boolean = false;
  submittedConselho: boolean = false;
  datePipe = new DatePipe('pt-BR');
  indexConselhoEditado: number = null;
  indexContratadoEditado: number = null;
  toggleable: boolean = false;
  collapsed: boolean = true;


  constructor(private formBuilder: FormBuilder,
    private routerNavigation: Router,
    private router: ActivatedRoute,
    private contratadoService: ContratadoService,
    private route: ActivatedRoute,
    private empresaService: EmpresaService,
    private profissionalSaudeService: ProfissionalSaudeService,
    private messageService: MessageService,
    private spinner: NgxSpinnerService,
    private comunsService: ComunsService,
    private convenioService: ConvenioService) {
    this.form(null, null);
  }

  ngOnInit() {

  }

  form(valueForm: ProfissionalSaude, profissionalConvenio: ProfissionalConvenio) {
    this.profissionalExecutanteForm = this.formBuilder.group({
      id: [valueForm && valueForm.id ? valueForm.id : 0],
      profissionalSaudeNome: [valueForm && valueForm.profissionalSaudeNome, Validators.required],
      telefone: [valueForm && valueForm.telefone],
      cpf: [valueForm && valueForm.usuario.cpf, Validators.required],
      dataNascimento: [valueForm && this.datePipe.transform(valueForm.dataNascimento, "dd/MM/yyyy"), Validators.required],
      email: [valueForm && valueForm.usuario.email, Validators.required],
      estado: [valueForm && valueForm.cidade.estado, Validators.required],
      estadoId: [valueForm && valueForm.cidade && valueForm.cidade.estado.id],
      especialidade: [valueForm && valueForm.especialidade, Validators.required],
      especialidadeId: [valueForm && valueForm.especialidade.id],
      cidadeId: [valueForm && valueForm.cidade && valueForm.cidade.id],
      memedCadastrado: [valueForm && valueForm.memedCadastrado],
      cidade: [null],
      senha: [valueForm && valueForm.senha],
      contratadoProfissionalForm: this.formBuilder.group({
        id: [null],
        contratado: [null],
        ativo: [true]
      }),
      tabelaConvenioForm: this.formBuilder.group({
        id: [profissionalConvenio && profissionalConvenio.id],
        contratado: [profissionalConvenio && profissionalConvenio.contratado],
        contratadoConvenio: [profissionalConvenio && profissionalConvenio.convenio],
        tabelaConvenio: [profissionalConvenio && profissionalConvenio.tabelaConvenio],
        porte: [profissionalConvenio && profissionalConvenio.porte],
        deflator: [profissionalConvenio && profissionalConvenio.deflator]
      }),
      conselhoProfissionalForm: this.formBuilder.group({
        id: [null],
        nomeEstado: [null],
        estadoId: [null],
        nomeConselho: [null],
        conselhoReset: [null],
        estadoReset: [null],
        conselhoId: [null],
        numeroConselho: [null],
        ativo: [true]
      }),
      horariosAgenda: this.formBuilder.group({
        id: [valueForm && valueForm.horariosAgenda && valueForm.horariosAgenda.id],
        duracaoConsulta: [valueForm && valueForm.horariosAgenda && valueForm.horariosAgenda.duracaoConsulta, Validators.required],
        segunda: [valueForm && valueForm.horariosAgenda && valueForm.horariosAgenda.segunda, Validators.required],
        showSegunda: [valueForm && valueForm.horariosAgenda && valueForm.horariosAgenda.showSegunda, Validators.required],
        segundaInicioExpediente: [valueForm && valueForm.horariosAgenda && valueForm.horariosAgenda.segundaInicioExpediente, Validators.required],
        segundaFimExpediente: [valueForm && valueForm.horariosAgenda && valueForm.horariosAgenda.segundaFimExpediente, Validators.required],
        segundaInicioIntervalo: [valueForm && valueForm.horariosAgenda && valueForm.horariosAgenda.segundaInicioIntervalo, Validators.required],
        segundaFimIntervalo: [valueForm && valueForm.horariosAgenda && valueForm.horariosAgenda.segundaFimIntervalo, Validators.required],
        terca: [valueForm && valueForm.horariosAgenda && valueForm.horariosAgenda.terca, Validators.required],
        showTerca: [valueForm && valueForm.horariosAgenda && valueForm.horariosAgenda.showTerca, Validators.required],
        tercaInicioExpediente: [valueForm && valueForm.horariosAgenda && valueForm.horariosAgenda.tercaInicioExpediente, Validators.required],
        tercaFimExpediente: [valueForm && valueForm.horariosAgenda && valueForm.horariosAgenda.tercaFimExpediente, Validators.required],
        tercaInicioIntervalo: [valueForm && valueForm.horariosAgenda && valueForm.horariosAgenda.tercaInicioIntervalo, Validators.required],
        tercaFimIntervalo: [valueForm && valueForm.horariosAgenda && valueForm.horariosAgenda.tercaFimIntervalo, Validators.required],
        quarta: [valueForm && valueForm.horariosAgenda && valueForm.horariosAgenda.quarta, Validators.required],
        showQuarta: [valueForm && valueForm.horariosAgenda && valueForm.horariosAgenda.showQuarta, Validators.required],
        quartaInicioExpediente: [valueForm && valueForm.horariosAgenda && valueForm.horariosAgenda.quartaInicioExpediente, Validators.required],
        quartaFimExpediente: [valueForm && valueForm.horariosAgenda && valueForm.horariosAgenda.quartaFimExpediente, Validators.required],
        quartaInicioIntervalo: [valueForm && valueForm.horariosAgenda && valueForm.horariosAgenda.quartaInicioIntervalo, Validators.required],
        quartaFimIntervalo: [valueForm && valueForm.horariosAgenda && valueForm.horariosAgenda.quartaFimIntervalo, Validators.required],
        quinta: [valueForm && valueForm.horariosAgenda && valueForm.horariosAgenda.quinta, Validators.required],
        showQuinta: [valueForm && valueForm.horariosAgenda && valueForm.horariosAgenda.showQuinta, Validators.required],
        quintaInicioExpediente: [valueForm && valueForm.horariosAgenda && valueForm.horariosAgenda.quintaInicioExpediente, Validators.required],
        quintaFimExpediente: [valueForm && valueForm.horariosAgenda && valueForm.horariosAgenda.quintaFimExpediente, Validators.required],
        quintaInicioIntervalo: [valueForm && valueForm.horariosAgenda && valueForm.horariosAgenda.quintaInicioIntervalo, Validators.required],
        quintaFimIntervalo: [valueForm && valueForm.horariosAgenda && valueForm.horariosAgenda.quintaFimIntervalo, Validators.required],
        sexta: [valueForm && valueForm.horariosAgenda && valueForm.horariosAgenda.sexta, Validators.required],
        showSexta: [valueForm && valueForm.horariosAgenda && valueForm.horariosAgenda.showSexta, Validators.required],
        sextaInicioExpediente: [valueForm && valueForm.horariosAgenda && valueForm.horariosAgenda.sextaInicioExpediente, Validators.required],
        sextaFimExpediente: [valueForm && valueForm.horariosAgenda && valueForm.horariosAgenda.sextaFimExpediente, Validators.required],
        sextaInicioIntervalo: [valueForm && valueForm.horariosAgenda && valueForm.horariosAgenda.sextaInicioIntervalo, Validators.required],
        sextaFimIntervalo: [valueForm && valueForm.horariosAgenda && valueForm.horariosAgenda.sextaFimIntervalo, Validators.required],
        sabado: [valueForm && valueForm.horariosAgenda && valueForm.horariosAgenda.sabado, Validators.required],
        showSabado: [valueForm && valueForm.horariosAgenda && valueForm.horariosAgenda.showSabado, Validators.required],
        sabadoInicioExpediente: [valueForm && valueForm.horariosAgenda && valueForm.horariosAgenda.sabadoInicioExpediente, Validators.required],
        sabadoFimExpediente: [valueForm && valueForm.horariosAgenda && valueForm.horariosAgenda.sabadoFimExpediente, Validators.required],
        sabadoInicioIntervalo: [valueForm && valueForm.horariosAgenda && valueForm.horariosAgenda.sabadoInicioIntervalo, Validators.required],
        sabadoFimIntervalo: [valueForm && valueForm.horariosAgenda && valueForm.horariosAgenda.sabadoFimIntervalo, Validators.required],
        domingo: [valueForm && valueForm.horariosAgenda && valueForm.horariosAgenda.domingo, Validators.required],
        showDomingo: [valueForm && valueForm.horariosAgenda && valueForm.horariosAgenda.showDomingo,Validators.required],
        domingoInicioExpediente: [valueForm && valueForm.horariosAgenda && valueForm.horariosAgenda.domingoInicioExpediente, Validators.required],
        domingoFimExpediente: [valueForm && valueForm.horariosAgenda && valueForm.horariosAgenda.domingoFimExpediente, Validators.required],
        domingoInicioIntervalo: [valueForm && valueForm.horariosAgenda && valueForm.horariosAgenda.domingoInicioIntervalo, Validators.required],
        domingoFimIntervalo: [valueForm && valueForm.horariosAgenda && valueForm.horariosAgenda.domingoFimIntervalo, Validators.required],
      })
    });
    
    this.startValoresAgenda(valueForm);
  }  

  mudarTabView(tab) {
    var index = tab.index;

    // if (index == 0) //Pendentes
    //   this.lancamentosPendentes();

    // if (index == 1) //Faturados
    //   this.lancamentosFaturar();

    if (index == 3) //Tabela Convenio
      this.contratadosPorEmpresa(this.profissional.id);
  }

  incluirContratado() {
    var form = (<FormGroup>this.profissionalExecutanteForm.controls['contratadoProfissionalForm']);
    if (form.invalid)
      return false;
    var contratadoSelecionado = form.get('contratado').value;

    if(contratadoSelecionado == null){
      this.messageService.add({ severity: 'error', summary: '', detail: 'Existem campos obrigatórios não preenchidos!' });
      return false;
    }

    let contratadoSelect = {
      id: 0,
      razaoSocial: contratadoSelecionado.razaoSocial,
      cpfCnpj: contratadoSelecionado.cpfCnpj,
      cnes: contratadoSelecionado.cnes,
      telefone: contratadoSelecionado.telefone,
      celular: contratadoSelecionado.celular,
      email: contratadoSelecionado.email,
      pessoaResponsavel: contratadoSelecionado.pessoaResponsavel,
      contratadoId: contratadoSelecionado.id,
      profissionalSaudeId: this.profissionalID,
      ativo: form.value["ativo"]
    } as Contratado;

    if (this.indexContratadoEditado != null) {
      this.contratadosSelecionados[this.indexContratadoEditado] = contratadoSelect;
    } else {
      if (this.contratadosSelecionados.find(contratado => contratado.cpfCnpj == contratadoSelecionado.cpfCnpj) != null) {
        form.reset();
      }
      else {
        this.contratadosSelecionados.push(contratadoSelect);
      }
    }
    (<FormGroup>this.profissionalExecutanteForm.controls['contratadoProfissionalForm']).reset();
    this.indexContratadoEditado = null;
  }


  findAllContratados() {
    let filtros = {
      contratadoId: 0,
      cpfCnpj: null,
      cnes: null
    } as FiltroContratado;
    this.contratadoService.contratados(filtros).subscribe((response) => {
      this.contratados = response;
    }, (exception) => {
      console.log(exception);
    })
  }

  contratadosPorEmpresa(id: number) {
    this.contratadoService.getProfissionalContratadosPorEmpresa(id).subscribe((response) => {
      this.contratadosByProfissional = response;
    }, (exception) => {
      console.log(exception);
    })
  }

  findAllTabelasConvenio() {
    this.convenioService.tabelas().subscribe((response) => {
      this.tabelasConvenio = response;
    }, (exception) => {
      console.log(exception);
    })
  }

  findAllPortes() {
    this.convenioService.portes().subscribe((response) => {
      this.portes = response;
    }, (exception) => {
      console.log(exception);
    })
  }

  contratadoTabelaConvenio() {
    var form = (<FormGroup>this.profissionalExecutanteForm.controls['tabelaConvenioForm']);
    var formValue = form.value['contratado'] as Contratado;

    if (formValue)
      this.convenios = formValue.contratadoConvenio;
    else {
      form.patchValue({
        convenio: [null, Validators.required]
      });
      this.convenios = [];
    }
  }

  salvarTabelaConvenio() {
    let form = (<FormGroup>this.profissionalExecutanteForm.controls['tabelaConvenioForm']);
    let formValue = form.value as ProfissionalConvenio;

    if (form.invalid)
      return false;

    if(formValue.contratado == null || formValue.contratadoConvenio == null || formValue.tabelaConvenio == null || formValue.porte == null)
    {
      this.messageService.add({ severity: 'error', summary: '', detail: 'Existem campos obrigatórios não preenchidos!' });
      return false;
    }

    let profissionalConvenio = {
      id: formValue.id != null ? formValue.id : 0,
      profissionalsaudeId: this.profissional.id,
      contratadoId: formValue.contratado.id,
      convenioId: formValue.contratadoConvenio.convenioId,
      porteId: formValue.porte == null ? null : formValue.porte.id,
      tabelaConvenioId: formValue.tabelaConvenio == null ? null : formValue.tabelaConvenio.id,
      deflator: formValue.deflator
    } as ProfissionalConvenio;

    if (profissionalConvenio.id == 0) {
      this.convenioService.createProfissionalConvenio(profissionalConvenio).subscribe((response) => {
        this.profissionalConvenioByIdProfissional(this.profissional.id);
        form.reset();
        this.convenios = [];
      }, (exception) => {
        console.log(exception);
      })
    }
    else {
      this.updateProfissionalConvenio(profissionalConvenio);
      form.reset();
    }
  }

  profissionalConvenioByIdProfissional(id: number) {
    this.convenioService.profissionalConvenioByIdProfissional(id).subscribe((response) => {
      this.profissionalConvenios = response;
    }, (exception) => {
      console.log(exception);
    })
  }

  findProfissionalByIdentificator(id: number) {
    this.spinner.show();
    this.profissionalSaudeService.findProfissionalByIdentificator(id).subscribe((response) => {
      this.profissional = response;
      this.profissionalConselhos = response.profissionalConselho;
      this.contratadosSelecionados = response.profissionalContratado;
      this.findAllCidades(response.cidade.estado.id, response.cidade.id);
      this.form(response, null);
      var aux = this.profissionalExecutanteForm.value;
      this.contratadosPorEmpresa(response.id);
      this.profissionalConvenioByIdProfissional(response.id);
    }, (exception) => {
      console.log(exception);
    })
  }

  editar(id: number, contratadosDoProfissional: Array<Contratado>) {

    this.spinner.show();
    this.convenioService.findProfissionalConvenioById(id).subscribe((profissionalPesquisado: ProfissionalConvenio) => {

      var result = profissionalPesquisado;
      // this.form(null, result);
      console.log(contratadosDoProfissional);

      var form = (<FormGroup>this.profissionalExecutanteForm.controls['tabelaConvenioForm']);
      var contratadoSelecionado = contratadosDoProfissional.find(c => c.id == result.contratado.id);
      var convenioSelecionado = contratadoSelecionado.contratadoConvenio.find(c => c.convenioId == result.convenio.convenioId);
      this.contratadoTabelaConvenio();

      form.patchValue({
        id: result.id,
        contratado: contratadoSelecionado,
        contratadoConvenio: convenioSelecionado,
        tabelaConvenio: result.tabelaConvenio,
        porte: result.porte,
        deflator: result.deflator
      });

      this.spinner.hide();
    }, (exception) => {
      this.spinner.hide();
      console.log(exception);
    })
  }

  updateProfissionalConvenio(profissionalConvenio: ProfissionalConvenio) {
    this.convenioService.updateProfissionalConvenio(profissionalConvenio).subscribe((response) => {
      this.spinner.hide();
      this.messageService.add({ severity: 'success', summary: '', detail: 'Registro alterado com sucesso!' });
      this.profissionalConvenioByIdProfissional(this.profissional.id);
    }, (exception) => {
      this.spinner.hide();
      this.messageService.add({ severity: 'error', summary: '', detail: exception });
    })
  }

  findAllConselhos() {
    this.contratadoService.findConselhos()
      .subscribe((result) => {
        this.conselhos = result;
        this.spinner.hide();
      }, (exception) => {
        this.spinner.hide();
      })
  }

  findAllEstados() {
    this.spinner.show();
    this.empresaService.findEstados()
      .subscribe((result) => {
        this.estados = result;
        this.spinner.hide();
      }, (exception) => {
        this.spinner.hide();
      })
  }

  getEspecialidade(event) {
    let valor = event.query;

    if (valor && valor.length >= 3) {
      this.contratadoService.getEspecialidades(valor)
        .subscribe((result) => {
          this.listaEspecialidades = result;
          this.spinner.hide();
        }, (exception) => {
          this.spinner.hide();
        })
    }
  }

  createProfissionalSaude() {
    this.setValueFiltro(this.profissionalExecutanteForm.value);
    let form = this.profissionalExecutanteForm.value as ProfissionalSaude;

    if (this.profissionalExecutanteForm.invalid) {
      this.messageService.add({ severity: 'error', summary: '', detail: 'Existem campos obrigatórios não preenchidos.' });
      return;
    }

    var formHorarios = (<FormGroup>this.profissionalExecutanteForm.controls['horariosAgenda']).value as HorariosAgenda;

    let usuario = {
      email: form.email,
      password: form.senha,
      cpf: form.cpf.replace(".", "").replace(".", "").replace("-", ""),
      nomePessoa: form.profissionalSaudeNome
    } as Usuario;

    let parametros = {
      id: form.id,
      profissionalSaudeNome: form.profissionalSaudeNome,
      telefone: form.telefone,
      dataNascimento: this.comunsService.transformDate(form.dataNascimento),
      email: form.email,
      senha: form.senha,
      especialidadeId: form.especialidadeId,
      usuario: usuario,
      cidadeId: form.cidade.id,
      profissionalConselho: this.profissionalConselhos,
      profissionalContratado: this.contratadosSelecionados,
      horariosAgenda: formHorarios
    } as ProfissionalSaude;

    this.spinner.show();
    if (parametros.id > 0) {
      this.profissionalSaudeService.updateProfissionalSaudeWeb(parametros).subscribe((response) => {
        this.spinner.hide();
        this.messageService.add({ severity: 'success', summary: '', detail: 'Registro alterado com sucesso!' });

        this.voltar();
        this.modalSave.emit(null);
      }, (exception) => {
        this.spinner.hide();
        this.messageService.add({ severity: 'error', summary: '', detail: exception });
      })
    }

    else {
      this.profissionalSaudeService.createProfissionalSaude(parametros).subscribe((response) => {
        this.spinner.hide();
        this.messageService.add({ severity: 'success', summary: '', detail: 'Registro criado com sucesso!' });

        this.voltar();
        this.modalSave.emit(null);
      }, (exception) => {
        this.spinner.hide();
        this.messageService.add({ severity: 'error', summary: '', detail: exception });
      })
    }
  }

  setEspecialidade(especialidade) {
    if (especialidade) {
      this.profissionalExecutanteForm.patchValue({
        especialidadeId: especialidade.id
      });
    }
  }

  findAllCidades(id: number, cidadeId: number) {
    this.spinner.show();
    this.empresaService.findCidades(id)
      .subscribe((result) => {
        this.cidades = result;
        if (cidadeId > 0) {
          var cidadeView = this.cidades.find(c => c.id == cidadeId);
          var estadoView = this.estados.find(c => c.id == id);
          this.profissionalExecutanteForm.patchValue({ cidade: cidadeView, estado: estadoView });
        }
        this.spinner.hide();
      }, (exception) => {
        this.spinner.hide();
      })
  }

  receiveCidades(estado: any) {
    var form = (<FormGroup>this.profissionalExecutanteForm);

    if (estado) {
      this.findAllCidades(estado.id, form.get('cidadeId').value);
    }
    else {
      form.controls["estado"].reset();
      form.controls["cidadeId"].reset();
      this.cidades = [];
    }
  }

  setValueFiltro(cadastro_Form) {
    this.profissionalExecutanteForm.patchValue({
      estadoId: cadastro_Form.estado == null ? null : cadastro_Form.estado.id,
      cidadeId: cadastro_Form.cidade == null ? null : cadastro_Form.cidade.id
    });
  }

  incluirConselho() {
    this.submittedConselho = true;
    var form = (<FormGroup>this.profissionalExecutanteForm.controls['conselhoProfissionalForm']);
    if (form.invalid)
      return false;

    let formValue = form.value as ProfissionalConselho;
    if(formValue.numeroConselho == null || formValue.conselhoId == null || formValue.estadoId == null)
    {
      this.messageService.add({ severity: 'error', summary: '', detail: 'Existem campos obrigatórios não preenchidos!' });
      return false;
    }
    let estadoSelecionado = {
      nomeEstado: formValue.nomeEstado,
      id: formValue.estadoId,
    } as Estado;

    let conselhoSelecionado = {
      nomeConselho: formValue.nomeConselho,
      id: formValue.conselhoId
    } as Conselho;
    let conselhoView = {
      id: (formValue.id == null || formValue.id == 0) ? 0 : formValue.id,
      nomeConselho: formValue.nomeConselho,
      nomeEstado: formValue.nomeEstado,
      conselhoId: formValue.conselhoId,
      profissionalSaudeId: this.profissionalID,
      conselho: conselhoSelecionado,
      estadoId: formValue.estadoId,
      estado: estadoSelecionado,
      numeroConselho: formValue.numeroConselho,
      ativo: form.value["ativo"]
    } as ProfissionalConselho;

    if (this.indexConselhoEditado != null) {
      this.profissionalConselhos[this.indexConselhoEditado] = conselhoView;
      this.indexConselhoEditado = null;
    } else {
      this.profissionalConselhos.push(conselhoView);
    }

    (<FormGroup>this.profissionalExecutanteForm.controls['conselhoProfissionalForm']).reset();
    this.submittedConselho = false;
    this.indexConselhoEditado = null;

  }

  receiveConselho(conselho: Conselho) {
    var conselhoProfissionalForm = (<FormGroup>this.profissionalExecutanteForm.controls['conselhoProfissionalForm']);

    if (conselho) {
      conselhoProfissionalForm.patchValue({
        conselhoId: conselho.id,
        nomeConselho: conselho.nomeConselho
      })
    } else {
      conselhoProfissionalForm.get('conselhoId').reset();
    }
  }

  receiveEstado(estado: Estado) {
    var conselhoProfissionalForm = (<FormGroup>this.profissionalExecutanteForm.controls['conselhoProfissionalForm']);

    if (estado) {
      conselhoProfissionalForm.patchValue({
        estadoId: estado.id,
        nomeEstado: estado.nomeEstado
      })
    } else {
      conselhoProfissionalForm.get('estadoId').reset();
    }
  }

  validacoes = {
    'campoObrigatorio': [
      { type: 'required', message: 'Este campo é obrigatório.' }
    ]
  };

  showDialogMaximized(dialog: Dialog) {
    this.comunsService.showDialogMaximized(dialog);
  }

  showDialogProfissionalExecutante(profissionalId?: number) {
    this.form(null, null);
    this.findAllEstados();
    this.findAllConselhos();
    this.findAllContratados();
    this.indexContratadoEditado = null;
    this.indexConselhoEditado = null;

    if (profissionalId > 0) {
      this.findAllPortes();
      this.findAllTabelasConvenio();
      this.findProfissionalByIdentificator(profissionalId);
    }

    this.profissional = new ProfissionalSaude();
    this.profissionalConselhos = [];
    this.contratadosSelecionados = [];

    this.display = true;
    this.submitted = false;
  }

  voltar() {
    this.display = false;
  }

  editarConselho(profissionalConselho: ProfissionalConselho, index: number) {
    var conselhoEditado = this.profissionalConselhos.find(c => c.id == profissionalConselho.id);
    var form = (<FormGroup>this.profissionalExecutanteForm.controls['conselhoProfissionalForm']);
    this.indexConselhoEditado = index;

    var conselhoSelecionado = this.conselhos.find(c => c.id == conselhoEditado.conselho.id);
    var estadoSelecionado = this.estados.find(c => c.id == conselhoEditado.estado.id);
    form.patchValue(
      {
        id: profissionalConselho.id,
        nomeEstado: estadoSelecionado.nomeEstado,
        estadoId: estadoSelecionado.id,
        nomeConselho: conselhoSelecionado.nomeConselho,
        conselhoReset: conselhoSelecionado,
        conselhoId: conselhoSelecionado.id,
        numeroConselho: conselhoEditado.numeroConselho,
        estadoReset: estadoSelecionado,
        ativo: conselhoEditado.ativo
      });
  }

  editarContratado(profissionalContratado: Contratado, index: number) {
    var contratadoEditado = this.contratadosSelecionados.find(c => c.id == profissionalContratado.id);
    var form = (<FormGroup>this.profissionalExecutanteForm.controls['contratadoProfissionalForm']);

    this.indexContratadoEditado = index;

    var contratadoSelecionado = this.contratados.find(c => c.id == profissionalContratado.contratadoId);

    form.patchValue({
      id: profissionalContratado.id,
      contratado: contratadoSelecionado,
      ativo: contratadoEditado.ativo
    })
  }

  cadastrarMemed(){
    var dados = this.profissionalExecutanteForm.value;

    // var conselho = this.profissionalConselhos[0];

    // var nome = dados.profissionalSaudeNome.split(" ")[0];
    // var sobrenome = dados.profissionalSaudeNome.split(" ")[1];

    // var parametros = {
    //   nome: nome,
    //   sobrenome: sobrenome,
    //   dataNascimento: dados.dataNascimento,
    //   cpf: dados.cpf,
    //   crm: conselho.numeroConselho.toString(),
    //   email: dados.email
    // } as Memed;

    this.profissionalSaudeService.cadastroMemed(dados.id).subscribe((response) => {
      this.spinner.hide();
      this.messageService.add({ severity: 'success', summary: '', detail: 'Memed criado com sucesso!' });
    }, (exception) => {
      this.spinner.hide();
      this.messageService.add({ severity: 'error', summary: '', detail: exception });
    })
  }

  habilitaPanel(checked, dia) {
    switch (dia) {
      case 1: //Segunda-feira
        this.checarPanelDia('segunda', 'showSegunda', checked);
        if(checked){
          this.aplicarFormValidation('segundaInicioExpediente');
          this.aplicarFormValidation('segundaFimExpediente');
          this.aplicarFormValidation('segundaInicioIntervalo');
          this.aplicarFormValidation('segundaFimIntervalo');
        }
          else{
            this.removerFormValidation('segundaInicioExpediente');
            this.removerFormValidation('segundaFimExpediente');
            this.removerFormValidation('segundaInicioIntervalo');
            this.removerFormValidation('segundaFimIntervalo');
          }
        break;

      case 2: //Terça-feira
        this.checarPanelDia('terca', 'showTerca', checked);
        if(checked){
          this.aplicarFormValidation('tercaInicioExpediente');
          this.aplicarFormValidation('tercaFimExpediente');
          this.aplicarFormValidation('tercaInicioIntervalo');
          this.aplicarFormValidation('tercaFimIntervalo');
        }
          else{
            this.removerFormValidation('tercaInicioExpediente');
            this.removerFormValidation('tercaFimExpediente');
            this.removerFormValidation('tercaInicioIntervalo');
            this.removerFormValidation('tercaFimIntervalo');
          }
        break;

      case 3: //Quarta-feira
        this.checarPanelDia('quarta', 'showQuarta', checked);
        if(checked){
          this.aplicarFormValidation('quartaInicioExpediente');
          this.aplicarFormValidation('quartaFimExpediente');
          this.aplicarFormValidation('quartaInicioIntervalo');
          this.aplicarFormValidation('quartaFimIntervalo');
        }
          else{
            this.removerFormValidation('quartaInicioExpediente');
            this.removerFormValidation('quartaFimExpediente');
            this.removerFormValidation('quartaInicioIntervalo');
            this.removerFormValidation('quartaFimIntervalo');
          }
        break;

      case 4: //Quinta-feira
        this.checarPanelDia('quinta', 'showQuinta', checked);
        if(checked){
          this.aplicarFormValidation('quintaInicioExpediente');
          this.aplicarFormValidation('quintaFimExpediente');
          this.aplicarFormValidation('quintaInicioIntervalo');
          this.aplicarFormValidation('quintaFimIntervalo');
        }
          else{
            this.removerFormValidation('quintaInicioExpediente');
            this.removerFormValidation('quintaFimExpediente');
            this.removerFormValidation('quintaInicioIntervalo');
            this.removerFormValidation('quintaFimIntervalo');
          }
        break;

      case 5: //Sexta-feira
        this.checarPanelDia('sexta', 'showSexta', checked);
        if(checked){
          this.aplicarFormValidation('sextaInicioExpediente');
          this.aplicarFormValidation('sextaFimExpediente');
          this.aplicarFormValidation('sextaInicioIntervalo');
          this.aplicarFormValidation('sextaFimIntervalo');
        }
          else{
            this.removerFormValidation('sextaInicioExpediente');
            this.removerFormValidation('sextaFimExpediente');
            this.removerFormValidation('sextaInicioIntervalo');
            this.removerFormValidation('sextaFimIntervalo');
          }
        break;

      case 6: //Sabado
        this.checarPanelDia('sabado', 'showSabado', checked);
        if(checked){
          this.aplicarFormValidation('sabadoInicioExpediente');
          this.aplicarFormValidation('sabadoFimExpediente');
          this.aplicarFormValidation('sabadoInicioIntervalo');
          this.aplicarFormValidation('sabadoFimIntervalo');
        }
          else{
            this.removerFormValidation('sabadoInicioExpediente');
            this.removerFormValidation('sabadoFimExpediente');
            this.removerFormValidation('sabadoInicioIntervalo');
            this.removerFormValidation('sabadoFimIntervalo');
          }
        break;

      default: //Domingo
        this.checarPanelDia('domingo', 'showDomingo', checked);
        if(checked){
          this.aplicarFormValidation('domingoInicioExpediente');
          this.aplicarFormValidation('domingoFimExpediente');
          this.aplicarFormValidation('domingoInicioIntervalo');
          this.aplicarFormValidation('domingoFimIntervalo');
        }
          else{
            this.removerFormValidation('domingoInicioExpediente');
            this.removerFormValidation('domingoFimExpediente');
            this.removerFormValidation('domingoInicioIntervalo');
            this.removerFormValidation('domingoFimIntervalo');
          }
        break;

    }
  }

  private aplicarFormValidation(control: string) {
    this.profissionalExecutanteForm.controls['horariosAgenda'].get(control).setValidators(Validators.required);
    this.profissionalExecutanteForm.controls['horariosAgenda'].get(control).updateValueAndValidity();
  }

  private removerFormValidation(control: string) {
    this.profissionalExecutanteForm.controls['horariosAgenda'].get(control).clearValidators();
    this.profissionalExecutanteForm.controls['horariosAgenda'].get(control).updateValueAndValidity();
  }

  checarPanelDia(dia, showDia, checked) {
    let newValueDia = this.profissionalExecutanteForm.controls['horariosAgenda'].get(dia);
    let newValueShowDia = this.profissionalExecutanteForm.controls['horariosAgenda'].get(showDia);

    if (checked) {
      newValueDia.setValue(true);
      newValueShowDia.setValue(true);
    }
    else {
      newValueDia.setValue(false);
      newValueShowDia.setValue(false);
    }
  }

  get profissionalID() {
    if (this.profissional && this.profissional.id) return this.profissional.id;
    return 0;
  }

  get conselhoID() {
    return this.profissionalExecutanteForm.controls['conselhoProfissionalForm'].get('id').value != null ?
      this.profissionalExecutanteForm.controls['conselhoProfissionalForm'].get('id').value : 0;
  }

  get ContratadoID() {
    return this.profissionalExecutanteForm.controls['contratadoProfissionalForm'].get('id').value != null ?
      this.profissionalExecutanteForm.controls['contratadoProfissionalForm'].get('id').value : 0
  }

  get TabelaId() {
    return this.profissionalExecutanteForm.controls['tabelaConvenioForm'].get('id').value != null ?
      this.profissionalExecutanteForm.controls['tabelaConvenioForm'].get('id').value : 0
  }

  private startValoresAgenda(valueForm: ProfissionalSaude) {
    if (valueForm && valueForm.horariosAgenda) {
      this.habilitaPanel(valueForm.horariosAgenda.segunda, 1);
      this.habilitaPanel(valueForm.horariosAgenda.terca, 2);
      this.habilitaPanel(valueForm.horariosAgenda.quarta, 3);
      this.habilitaPanel(valueForm.horariosAgenda.quinta, 4);
      this.habilitaPanel(valueForm.horariosAgenda.sexta, 5);
      this.habilitaPanel(valueForm.horariosAgenda.sabado, 6);
      this.habilitaPanel(valueForm.horariosAgenda.domingo, 7);
    } else {
      this.habilitaPanel(false, 1);
      this.habilitaPanel(false, 2);
      this.habilitaPanel(false, 3);
      this.habilitaPanel(false, 4);
      this.habilitaPanel(false, 5);
      this.habilitaPanel(false, 6);
      this.habilitaPanel(false, 7);
    }
  }
}
