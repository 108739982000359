import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-operadora',
  templateUrl: './operadora.component.html',
  styleUrls: ['./operadora.component.css']
})
export class OperadoraComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  receiveConvenio(event){
    
  }
}
