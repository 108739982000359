import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Dropdown } from '../../../../models/dropdown.model';
import { ConvenioService } from '../../../../services/convenio.service';

@Component({
  selector: 'app-convenio',
  templateUrl: './convenio.component.html',
  styleUrls: ['./convenio.component.css']
})
export class ConvenioComponent implements OnInit {

  convenioSelecionado: Dropdown;
  convenios: Dropdown[];

  @Output() convenio = new EventEmitter();
  @Input() convenioEdit;
  @Input() todos: boolean = false;
  @Input() readonly: boolean = false;

  constructor(private convenioService: ConvenioService) { }

  ngOnInit() {
    this.getConvenios();
  }

  ngOnAfterViewInit() {
    this.convenioSelecionado.value = this.convenioEdit;
  }

  getConvenios() {
    if (this.todos)
      this.getTodosConvenios();
    else
      this.getConveniosProfissionalSaude();
  }

  getTodosConvenios() {
     this.convenioService.getTodosConvenios().subscribe((convenios) => {
      this.convenios = convenios;
    });
  }
  getConveniosProfissionalSaude() {
    this.convenioService.getConvenios().subscribe((convenios) => {
      this.convenios = convenios;
    });
  }

  emit() {
    this.convenio.emit(this.convenioSelecionado);
  }

  reset() {
    this.convenioSelecionado = null;
  }

  setConvenio(convenioId: number) {
    var convenio = this.convenios.find((c) => c.value == convenioId);
    this.convenioSelecionado = convenio;
  }

}
