import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { MEDGESTAO_API } from '../app.api';
import { ErrorHandler } from '../pages/app.error-handler';
import { Chart } from '../models/chart.model';
import { ParametrosPesquisaChart } from '../models/ParametrosPesquisaChart.model';

@Injectable()
export class ChartService {

  constructor(public httpClient: HttpClient) {
  }

  getTotalGuias(mes: number) {
    return this.httpClient.get(`${MEDGESTAO_API}/chart/GetTotalGuias/${mes}`)
      .pipe(map(response => {
        return response as Chart;
      }, catchError(ErrorHandler.handleError)))
  }

  getTotalProfissionalSaude(mes: number) {
    return this.httpClient.get(`${MEDGESTAO_API}/chart/GetTotalProfissionalSaude/${mes}`)
      .pipe(map(response => {
        return response as Chart;
      }, catchError(ErrorHandler.handleError)))
  }

}