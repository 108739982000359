import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Contratado } from '../models/contratado.model';
import { MEDGESTAO_API } from '../app.api';
import { ErrorHandler } from '../pages/app.error-handler';
import { Especialidade } from '../models/especialidade.model';
import { catchError, map } from 'rxjs/operators';
import { FiltroContratado } from '../models/filtro-contratado.model';
import { ProfissionalContratado } from '../models/profissional-contratado.model';
import { Paciente } from '../models/paciente.model';
import { FiltroPaciente } from '../models/filtro-paciente.model';

@Injectable({
    providedIn: 'root'
})
export class PacienteService {

    constructor(public httpClient: HttpClient) {

    }
    createPaciente(parametros: Paciente) {
        return this.httpClient.post(`${MEDGESTAO_API}/paciente/CreatePaciente`, parametros)
            .pipe(
                map(response => { return response as boolean },
                    catchError(ErrorHandler.handleError)))
    }

    updatePaciente(parametros: Paciente) {
        return this.httpClient.post(`${MEDGESTAO_API}/paciente/UpdatePaciente`, parametros)
            .pipe(
                map(response => { return response as boolean },
                    catchError(ErrorHandler.handleError)))
    }

    getAllPacientes(parametros: FiltroPaciente) {
        return this.httpClient.post(`${MEDGESTAO_API}/paciente/GetAllPacientes`, parametros)
            .pipe(
                map(response => response,
                    catchError(ErrorHandler.handleError)))
    }

    getPacienteById(id: number) {
        return this.httpClient.get(`${MEDGESTAO_API}/paciente/PacienteById/${id}`)
            .pipe(
                map(response => { return response as Paciente },
                    catchError(ErrorHandler.handleError)))
    }

    getPacienteByName(profissionalId: number, name: string) {
        return this.httpClient.get(`${MEDGESTAO_API}/paciente/PacienteByName/${profissionalId}/${name}`)
            .pipe(
                map(response => { return response as Array<Paciente> },
                    catchError(ErrorHandler.handleError)))
    }
}