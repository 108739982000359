import { Component, OnInit, Input } from '@angular/core';
import { LancamentoService } from '../../../../services/lancamento.service';
import { AnexoLancamento } from '../../../../models/anexoLancamento.model';
import { GuiaService } from 'src/app/services/guia.service';

@Component({
  selector: 'app-download-guia',
  templateUrl: './download-guia.component.html',
  styleUrls: ['./download-guia.component.css']
})
export class DownloadGuiaComponent implements OnInit {

  anexosLancamento: AnexoLancamento[];
  display: boolean;

  constructor(private guiaService: GuiaService) { }

  ngOnInit() {
  }

  showDialog(anexoLancamento: AnexoLancamento[]) {
    this.display = true;
    this.anexosLancamento = anexoLancamento;
  }
  
  downloadGuia(nomeArquivo: string) {
    this.guiaService.downloadGuia(nomeArquivo).subscribe((url) => {
      window.open(url);
    }, (exception) => {
      console.log(exception);
    });
  }

}
