import { LazyLoadEvent } from "primeng/primeng";

export class Paginacao {
    PageNumber: number;
    PageSize: number;

    setPaginacao(parametros: any, paginacao: LazyLoadEvent) {
        if (paginacao != null) {
            parametros.PageNumber = Math.ceil(paginacao.first / paginacao.rows) + 1 || 1;
            parametros.PageSize = paginacao.rows;
        }
    }
}