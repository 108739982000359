import { Component, ElementRef, OnInit, ViewChild, TemplateRef, EventEmitter, Output } from '@angular/core';
import { AgendaService } from '../../services/agenda.service';
import { ConvenioService } from '../../services/convenio.service';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { listLocales } from 'ngx-bootstrap/chronos';
import { NgxSpinnerService } from "ngx-spinner";
import { MessageService, FileUpload, SelectItem, Dialog, ConfirmationService } from 'primeng/primeng';
import { PacienteService } from '../../services/paciente.service';
import * as moment from 'moment';
import 'moment/locale/pt-br';
import { HorariosAgendaProfissionalSaude } from 'src/app/models/horariosAgendaProfissionalSaude.model';
import { Paciente } from 'src/app/models/paciente.model';
import { Agendamento } from 'src/app/models/agendamento.model';
import { Atendimento } from 'src/app/models/atendimento.model';
import { TipoAgendamento } from 'src/app/models/tipo-agendamento.model';
import { SituacaoAgendamento } from 'src/app/models/situacao-agendamento.model';
import { Convenio } from '../../models/convenio.model';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { Dropdown } from '../../models/dropdown.model';
import { ESituacaoAgendamento, ESituacaoLote, ETipoAtendimento } from 'src/app/enum/enums';
import { ContratadoService } from '../../services/contratado.service';
import { Contratado } from '../../models/contratado.model';
import { ProfissionalSaude } from '../../models/profissionalSaude.model';
import { ComunsService } from '../../comuns/comuns.service';
import { Beneficiario } from 'src/app/models/beneficiario.model';
import { ProntuarioService } from 'src/app/services/prontuario.service';
import { Prontuario } from 'src/app/models/prontuario.model';
import { PacienteComponent } from '../pacientes/paciente/paciente.component';
import { AuthenticationService } from '../../services/authentication.service';
import { Usuario } from '../../models/usuario.model';
import { FiltroAgenda } from '../../models/filtro-agenda.model'
import { EPermissao } from 'src/app/models/permissao.model';

moment.locale('pt-br');

@Component({
  selector: 'app-agenda',
  templateUrl: './agenda.component.html',
  styleUrls: ['./agenda.component.css'],
  providers: [ConfirmationService, MessageService]
})
export class AgendaComponent implements OnInit {
  @ViewChild('modalAgendarPaciente')
  private modalAgendarPaciente: TemplateRef<any>;
  @ViewChild('modalDetalheAgendamento')
  private modalDetalheAgendamento: TemplateRef<any>;
  @ViewChild('modalEncaixeAgendamento')
  private modalEncaixeAgendamento: TemplateRef<any>;
  @ViewChild('modalFiltrarAgendamento')
  private modalFiltrarAgendamento: TemplateRef<any>;
  @ViewChild(PacienteComponent) pacienteComponent: PacienteComponent;

  @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();

  bsInlineValue = new Date();
  horariosAgenda = Array<Agendamento>();
  bsConfig: Partial<BsDatepickerConfig>;
  modalRef: BsModalRef;
  config = {
    animated: true,
    class: "modal-lg",
    backdrop: true,
    ignoreBackdropClick: true
  };
  ultimosAtendimentos: any[];
  agendaDia: any[];
  agenda: any[];
  dpwTipoAtendimento: Dropdown[];
  situacoesAgendamento: Array<SituacaoAgendamento>;
  tiposAgendamento: Array<TipoAgendamento>;
  pacientes: Array<Paciente>;
  agendamentoForm: FormGroup;
  detalhamentoForm: FormGroup;
  filtrarForm: FormGroup;
  encaixeForm: FormGroup;
  profissionais: Array<ProfissionalSaude>;
  agendamentoUpdate: number;
  contratados: Array<Contratado>;
  public isConvenio: boolean = false;

  submitted: boolean = false;
  dataAgendamento: Date;
  mesAgenda: String;
  diaAgenda: String;
  datePipe = new DatePipe('pt-BR');

  prontuarioId: number;
  paciente: Paciente;
  pacienteProntuario: Paciente;
  atendimentosPaciente: Array<Atendimento>;
  nomePaciente: string;

  agendamento: Agendamento;
  convenios: Dropdown[];

  locales = listLocales();

  text2: string;
  menusBotaoAcoesAgenda: any;
  usuario: Usuario;
  permissao: string;

  nomeProfissional: string = "";

  constructor(private router: Router,
    private formBuilder: FormBuilder,
    private modalService: BsModalService,
    private localeService: BsLocaleService,
    private agendaService: AgendaService,
    private pacienteService: PacienteService,
    private prontuarioService: ProntuarioService,
    private spinner: NgxSpinnerService,
    private comunsService: ComunsService,
    private convenioService: ConvenioService,
    private contratadoService: ContratadoService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private auth: AuthenticationService) {
    this.usuario = this.auth.currentUserValue.user;
    this.permissao = this.usuario.permissao;
    this.form(null);
    this.carregarBotaoAcoesAgenda();
    this.localeService.use('pt-br');
    const date = new Date();

    this.contratadosByEmpresa();
    this.getTodosConvenios();
    this.findAllTipoAgendamento();
    this.findAllSituacaoAgendamento();

    this.bsConfig = Object.assign({}, { containerClass: "theme-dark-blue" });

    this.dpwTipoAtendimento = [
      { label: 'Convênio', value: 2 },
      { label: 'Particular ', value: 1 },
    ]
  }

  form(valueForm: Agendamento) {
    this.agendamentoForm = this.formBuilder.group({
      nome: [valueForm && valueForm.horariosAgendaProfissionalSaude.paciente.beneficiario.nome, Validators.required],
      celular: [valueForm && valueForm.horariosAgendaProfissionalSaude.paciente.celular, Validators.required],
      telefone: [valueForm && valueForm.horariosAgendaProfissionalSaude.paciente.telefone],
      email: [valueForm && valueForm.horariosAgendaProfissionalSaude.paciente.email],
      horario: [valueForm && valueForm.horariosAgendaProfissionalSaude.horario, Validators.required],
      pacienteId: [null],
      dia: [valueForm && this.datePipe.transform(valueForm.horariosAgendaProfissionalSaude.data, "dd/MM/yyyy"), Validators.required],
      convenioId: [null],
      tipoAgendamentoId: [null],
      tipoAgendamento: [null, Validators.required],
      situacaoAgendamentoId: [null],
      tipoAtendimentoId: [null, Validators.required],
      observacoes: [null],
      profissionalId: [null],
      recorrencia: [valueForm && valueForm.recorrencia || 0],
    });
    this.detalhamentoForm = this.formBuilder.group({
      id: [null, Validators.required],
      nome: [valueForm && valueForm.horariosAgendaProfissionalSaude.paciente.beneficiario.nome, Validators.required],
      celular: [valueForm && valueForm.horariosAgendaProfissionalSaude.paciente.celular],
      telefone: [valueForm && valueForm.horariosAgendaProfissionalSaude.paciente.telefone],
      email: [valueForm && valueForm.horariosAgendaProfissionalSaude.paciente.email],
      horario: [valueForm && valueForm.horariosAgendaProfissionalSaude.horario, Validators.required],
      pacienteId: [null, Validators.required],
      dia: [valueForm && this.datePipe.transform(valueForm.horariosAgendaProfissionalSaude.data, "dd/MM/yyyy"), Validators.required],
      convenioId: [null, Validators.required],
      tipoAgendamentoId: [null, Validators.required],
      tipoAgendamento: [null, Validators.required],
      situacaoAgendamento: [null, Validators.required],
      situacaoAgendamentoId: [null, Validators.required],
      tipoAtendimentoId: [null, Validators.required],
      observacoes: [null, Validators.required],
      recorrencia: [valueForm && valueForm.recorrencia || 0]
    });
    this.filtrarForm = this.formBuilder.group({
      contratado: [null, Validators.required],
      profissional: [null, Validators.required],
      nomeFiltro: [null],
      situacaoAgendamentoFiltroId: [null],
      tipoAtendimentoFiltroId: [null]
    });
    this.encaixeForm = this.formBuilder.group({
      nome: [valueForm && valueForm.horariosAgendaProfissionalSaude.paciente.beneficiario.nome, Validators.required],
      celular: [valueForm && valueForm.horariosAgendaProfissionalSaude.paciente.celular, Validators.required],
      telefone: [valueForm && valueForm.horariosAgendaProfissionalSaude.paciente.telefone],
      email: [valueForm && valueForm.horariosAgendaProfissionalSaude.paciente.email],
      horarioEncaixe: [null],
      pacienteId: [null],
      diaEncaixe: [valueForm && this.datePipe.transform(valueForm.horariosAgendaProfissionalSaude.data, "dd/MM/yyyy"), Validators.required],
      convenioId: [null],
      tipoAgendamentoId: [null],
      tipoAgendamento: [null, Validators.required],
      situacaoAgendamentoId: [null],
      tipoAtendimentoId: [null, Validators.required],
      observacoes: [null],
      profissionalId: [null]
    });
  }

  openEncaixe() {
    this.submitted = false;
    // this.encaixeForm.patchValue({
    //   diaEncaixe: this.diaAgenda
    // });
    this.abrirModalEncaixeAgendamento();
  }

  openModal(horario) {
    if (horario.horariosAgendaProfissionalSaude.pacienteId > 0) {
      this.abrirModalDetalheAgendamento();
      this.getAgendamentoById(horario.id);
      this.agendamentoUpdate = horario.id;
      //this.confirmaExcluirAgendamento();
    } else {
      this.submitted = false;
      this.abrirModalAgendarPaciente();

      this.diaAgenda = this.datePipe.transform(horario.horariosAgendaProfissionalSaude.data, "dd/MM/yyyy");
      this.agendamentoForm.patchValue({
        horario: this.datePipe.transform(horario.horariosAgendaProfissionalSaude.horario, "HH:mm"),
        dia: this.datePipe.transform(horario.horariosAgendaProfissionalSaude.data, "dd/MM/yyyy"),
        recorrencia: 0
      });
      this.encaixeForm.patchValue({
        diaEncaixe: this.datePipe.transform(horario.horariosAgendaProfissionalSaude.data, "dd/MM/yyyy")
      });
    }
  }

  openModalFiltro() {
    this.abrirModalFiltroAgendamento();
  }

  abrirModalDetalheAgendamento() {
    this.modalRef = this.modalService.show(this.modalDetalheAgendamento, this.config);
  }

  abrirModalFiltroAgendamento() {
    this.modalRef = this.modalService.show(this.modalFiltrarAgendamento, this.config);
  }

  abrirModalAgendarPaciente() {
    this.modalRef = this.modalService.show(this.modalAgendarPaciente, this.config);
  }

  abrirModalEncaixeAgendamento() {
    this.modalRef = this.modalService.show(this.modalEncaixeAgendamento, this.config);
  }

  ngOnInit() {


  }

  validacoes = {
    'campoObrigatorio': [
      { type: 'required', message: 'Este campo é obrigatório.' }
    ]
  };

  buscarHorarios() {
    this.spinner.show();
    var aux = this.contratados;

    var formValue = this.agendamentoForm.value;

    if (formValue.profissionalId == null)
      if (this.profissionais == null)
        return;
      else {
        var profissional = this.profissionais[0];
        this.receiveProfissional(profissional);
      }

    var date = this.datePipe.transform(this.dataAgendamento, "yyyy/MM/dd");

    this.encaixeForm.patchValue({
      diaEncaixe: this.datePipe.transform(this.dataAgendamento, "dd/MM/yyyy")
    });

    var parametros = {
      data: date,
      profissionalSaudeId: formValue.profissionalId
    } as HorariosAgendaProfissionalSaude;

    this.agendaService.buscarHorariosAgendaProfissionalSaude(parametros).subscribe((response) => {
      this.horariosAgenda = response;
      this.spinner.hide();
    }, (exception: Response) => {
      //this.spinner.hide();
      this.messageService.add({ severity: 'error', summary: '', detail: 'Este profissional não possui agenda. ' });
    })
  }
  //


  onValueChange(date: Date): void {

    this.dataAgendamento = date;
    var data = this.datePipe.transform(this.dataAgendamento, "yyyy/MM/dd");

    this.buscarHorarios();

    this.mesAgenda = moment(date).format('LL');
    this.diaAgenda = moment(date).format('dddd');

  }

  onRowSelect(event) {
    console.log(event);
  }

  save_form() {

  }

  close_form() {
    this.modalRef.hide();
  }

  delete_event() {

  }

  carregarBotaoAcoesAgenda() {
    this.menusBotaoAcoesAgenda = () => {
      return [
        {
          label: 'Atualizar', icon: 'pi pi-refresh', command: () => {
            this.buscarHorarios();
          }
        },
        // {
        //   label: ' Novo Agendamento', icon: 'fa fa-calendar-check-o', command: () => {
        //   }
        // },
        // {
        //   label: ' Encaixe', icon: 'fa fa-exchange', command: () => {
        //     this.openEncaixe();
        //   }
        // },
        // {
        //   label: ' Bloquear Dia', icon: 'fa fa-lock', command: () => {
        //   }
        // },
        // {
        //   label: ' Bloquear Período', icon: 'fa fa-lock', command: () => {
        //   }
        // },
      ];
    };
  }

  navigateToProntuario(isNewProntuario: boolean) {
    this.modalRef.hide();

    var agendamento = this.agendamentoForm.value;
    var form = this.detalhamentoForm.value;

    var dataAgenda = {
      id: form.id,
      situacaoAgendamentoId: ESituacaoAgendamento.EmAtendimento
    } as Agendamento;


    this.spinner.show();

    if (isNewProntuario) {
      this.agendaService.updateSituacaoAgendamento(dataAgenda).subscribe((response) => {
        this.spinner.hide();

        setTimeout(() => {
          this.router.navigate(['/prontuario'], { queryParams: { agendamentoId: dataAgenda.id, pacienteId: form.pacienteId, profissionalId: agendamento.profissionalId, prontuario: isNewProntuario } });
        }, 500);
      }, (ex) => {
        this.spinner.hide();

      })
    } else {
      this.spinner.hide();

      setTimeout(() => {
        this.router.navigate(['/prontuario'], { queryParams: { agendamentoId: dataAgenda.id, pacienteId: form.pacienteId, profissionalId: agendamento.profissionalId, prontuario: isNewProntuario } });
      }, 500);
    }
  }

  //AUTOCOMPLETE DO PACIENTE
  getPaciente(event) {
    let valor = event.query;
    var formValue = this.agendamentoForm.value;

    if (valor && valor.length >= 3) {
      this.pacienteService.getPacienteByName(formValue.profissionalId, valor)
        .subscribe((result) => {
          this.pacientes = result;
          this.spinner.hide();
        }, (exception) => {
          this.spinner.hide();
        })
    }
  }

  setPaciente(paciente) {
    this.nomePaciente = paciente.beneficiario.nome;
    this.atendimentosPaciente = paciente.prontuario;

    if (paciente) {
      this.agendamentoForm.patchValue({
        pacienteId: paciente.id,
        celular: paciente.celular,
        email: paciente.email,
        telefone: paciente.telefone
      });
      this.encaixeForm.patchValue({
        pacienteId: paciente.id,
        celular: paciente.celular,
        email: paciente.email,
        telefone: paciente.telefone
      });
    }
  }

  setTipoAtendimentoFiltro(tipo) {
    if (tipo) {
      this.filtrarForm.patchValue({
        tipoAtendimentoId: tipo.id
      });
      this.filtrarHorarios();
    }
  }

  getTodosConvenios() {
    this.convenioService.getTodosConvenios().subscribe((convenios) => {
      this.convenios = convenios;
    });
  }

  createAgendamento() {
    var formValue = this.agendamentoForm.value;
    this.agendamento = this.agendamentoForm.value as Agendamento;
    this.submitted = true;

    var date = this.datePipe.transform(this.dataAgendamento, "yyyy/MM/dd");

    var beneficiarioForm = {
      convenioId: formValue.convenioId,
    } as Beneficiario;

    if (this.agendamentoForm.invalid) {
      this.messageService.add({ severity: 'error', summary: '', detail: 'Existem campos obrigatórios não preenchidos.' });
      return;
    }

    if (formValue.nome.id == null) {
      beneficiarioForm.nome = formValue.nome;
    }
    else {
      beneficiarioForm.nome = this.nomePaciente;
    }
    var pacienteForm = {
      beneficiario: beneficiarioForm,
      celular: formValue.celular,
      email: formValue.email,
      telefone: formValue.telefone,
      id: formValue.nome.id ? null : null
    } as Paciente;

    var pacienteFormId = formValue.nome.id

    let horariosAgenda = {
      horario: formValue.horario,
      data: date,
      paciente: pacienteForm,
      pacienteId: pacienteFormId,
      profissionalSaudeId: formValue.profissionalId
    } as HorariosAgendaProfissionalSaude;

    let parametros = {
      id: 0,
      tipoAgendamentoId: formValue.tipoAgendamento.id,
      tipoAtendimentoId: formValue.tipoAtendimentoId,
      horariosAgendaProfissionalSaude: horariosAgenda,
      observacoes: formValue.observacoes,
      situacaoAgendamentoId: 1,
      recorrencia: formValue.recorrencia
    } as Agendamento;

    this.agendaService.createAgendamento(parametros).subscribe((response) => {
      this.spinner.hide();
      this.close_form();
      this.buscarHorarios();
      this.resetarFormulario();
      this.messageService.add({ severity: 'success', summary: '', detail: 'Registro criado com sucesso!' });

      this.paciente = {} as Paciente;
      this.paciente.id = response.pacienteId;

      this.confirmationService.confirm({
        message: `Deseja completar o cadastro do paciente agora?`,
        accept: () => {
          this.editarPaciente();
        }
      });

    }, (exception) => {
      this.spinner.hide();
      this.messageService.add({ severity: 'error', summary: '', detail: exception });
    })
  }

  createEncaixe() {
    var formValue = this.encaixeForm.value;
    this.agendamento = this.agendamentoForm.value as Agendamento;
    this.submitted = true;

    var date = this.datePipe.transform(this.dataAgendamento, "yyyy/MM/dd");

    var beneficiarioForm = {
      convenioId: formValue.convenioId,
    } as Beneficiario;

    if (this.encaixeForm.invalid) {
      this.messageService.add({ severity: 'error', summary: '', detail: 'Existem campos obrigatórios não preenchidos.' });
      return;
    }

    if (formValue.nome.id == null) {
      beneficiarioForm.nome = formValue.nome;
    }
    else {
      beneficiarioForm.nome = this.nomePaciente;
    }
    var pacienteForm = {
      beneficiario: beneficiarioForm,
      celular: formValue.celular,
      email: formValue.email,
      telefone: formValue.telefone,
      id: formValue.nome.id ? null : null
    } as Paciente;

    var pacienteFormId = formValue.nome.id

    let horariosAgenda = {
      horario: formValue.horarioEncaixe,
      data: date,
      paciente: pacienteForm,
      pacienteId: pacienteFormId,
      profissionalSaudeId: formValue.profissionalId
    } as HorariosAgendaProfissionalSaude;

    let parametros = {
      id: 0,
      tipoAgendamentoId: formValue.tipoAgendamento.id,
      tipoAtendimentoId: formValue.tipoAtendimentoId,
      horariosAgendaProfissionalSaude: horariosAgenda,
      observacoes: formValue.observacoes,
      situacaoAgendamentoId: 1
    } as Agendamento;

    this.agendaService.createAgendamento(parametros).subscribe((response) => {
      this.spinner.hide();
      this.close_form();
      this.buscarHorarios();
      this.resetarEncaixe();
      //this.messageService.add({ severity: 'success', summary: '', detail: 'Registro criado com sucesso!' });

    }, (exception) => {
      this.submitted = false;
      this.messageService.add({ severity: 'error', summary: '', detail: 'O horário não esta disponivel!' });
      this.spinner.hide();
    })
  }

  resetarEncaixe() {
    var form = (<FormGroup>this.encaixeForm);

    this.submitted = false;

    form.controls["nome"].reset();
    form.controls["celular"].reset();
    form.controls["email"].reset();
    form.controls["tipoAgendamento"].reset();
    form.controls["situacaoAgendamentoId"].reset();
    form.controls["convenioId"].reset();
    form.controls["observacoes"].reset();
    form.controls["tipoAtendimentoId"].reset();
    form.controls["telefone"].reset();
    form.controls["horarioEncaixe"].reset();

  }

  findAllTipoAgendamento() {
    this.agendaService.getAllTipoAgendamento().subscribe((tiposAgendamento) => {
      this.tiposAgendamento = tiposAgendamento;
    }, (exception) => {
      this.spinner.hide();
    })
  }

  findAllSituacaoAgendamento() {
    this.agendaService.getAllSituacaoAgendamento().subscribe((situacoesAgendamento) => {
      this.situacoesAgendamento = situacoesAgendamento;
    }, (exception) => {
      this.spinner.hide();
    })
  }

  resetarFormulario() {
    var form = (<FormGroup>this.agendamentoForm);

    this.submitted = false;

    form.controls["nome"].reset();
    form.controls["celular"].reset();
    form.controls["email"].reset();
    form.controls["tipoAgendamento"].reset();
    form.controls["situacaoAgendamentoId"].reset();
    form.controls["convenioId"].reset();
    form.controls["observacoes"].reset();
    form.controls["tipoAtendimentoId"].reset();
    form.controls["telefone"].reset();
  }

  receiveTipoAtendimento(tipoAtendimento: any) {
    if (tipoAtendimento.value == 2)
      this.isConvenio = true;
    else
      this.isConvenio = false;
  }

  contratadosByEmpresa() {
    this.spinner.show();
    this.contratadoService.contratadosByEmpresa().subscribe((response) => {

      this.contratados = response;

      if (this.sePermissaoNaoSecretaria()) {
        let profissional = this.contratados[0].profissionalSaude.filter(p => p.id == this.usuario.profissionalSaudeId);
        this.profissionais = profissional;
        this.nomeProfissional = profissional[0].profissionalSaudeNome;

        this.filtrarForm.patchValue({
          contratado: this.contratados[0],
          profissional: profissional[0]
        });
        this.agendamentoForm.patchValue({
          profissionalId: profissional[0].id
        });
        this.encaixeForm.patchValue({
          profissionalId: profissional[0].id
        });

      } else {
        this.profissionais = this.contratados[0].profissionalSaude;
        this.nomeProfissional = this.profissionais[0].profissionalSaudeNome;

        this.filtrarForm.patchValue({
          contratado: this.contratados[0],
          profissional: this.profissionais[0]
        });
        this.agendamentoForm.patchValue({
          profissionalId: this.profissionais[0].id
        });
        this.encaixeForm.patchValue({
          profissionalId: this.profissionais[0].id
        });
      }

      this.buscarHorarios();
    }, (exception) => {
      this.spinner.hide();
      console.log(exception);
    })
  }

  receiveContratado(contratado: any) {
    if (contratado) {
      this.profissionais = contratado.profissionalSaude;
    }
    else {
      this.profissionais = [];
    }
    this.filtrarHorarios();
  }

  receiveProfissional(profissional: any) {
    if (profissional) {
      this.nomeProfissional = profissional.profissionalSaudeNome;

      this.agendamentoForm.patchValue({
        profissionalId: profissional.id
      });
      this.filtrarForm.patchValue({
        profissionalId: profissional.id
      });
      this.encaixeForm.patchValue({
        profissionalId: profissional.id
      });
      this.filtrarHorarios();
    }

  }

  resetarFormularioFiltro() {
    var form = (<FormGroup>this.filtrarForm);

    form.controls["nomeFiltro"].reset();
    form.controls["situacaoAgendamentoFiltroId"].reset();
    form.controls["tipoAtendimentoFiltroId"].reset();
  }

  filtrarHorarios() {
    var filtro = this.filtrarForm.value;
    var date = this.datePipe.transform(this.dataAgendamento, "yyyy/MM/dd");

    var parametros = {
      nome: filtro.nomeFiltro == null ? null : filtro.nomeFiltro == "" ? "" : filtro.nomeFiltro.beneficiario.nome,
      situacaoAgendamentoId: filtro.situacaoAgendamentoFiltroId == null ? null : filtro.situacaoAgendamentoFiltroId.id,
      tipoAtendimentoId: filtro.tipoAtendimentoFiltroId == null ? null : filtro.tipoAtendimentoFiltroId,
      profissionalSaudeId: filtro.profissional.id,
      data: date
    } as FiltroAgenda;

    this.spinner.show();

    this.agendaService.filtrarAgenda(parametros).subscribe((response) => {
      this.horariosAgenda = response;
      this.spinner.hide();
    }, (exception: Response) => {
      this.spinner.hide();
      this.messageService.add({ severity: 'error', summary: '', detail: 'Este profissional não possui agenda. ' });
    })
  }

  getAgendamentoById(id: number) {
    this.agendaService.getAgendamentoById(id).subscribe((response) => {
      var agendamento = response;

      this.paciente = agendamento.horariosAgendaProfissionalSaude.paciente;

      this.agendamentoUpdate = agendamento.id;
      this.detalhamentoForm.patchValue({
        id: agendamento.id,
        pacienteId: agendamento.horariosAgendaProfissionalSaude.pacienteId,
        nome: agendamento.horariosAgendaProfissionalSaude.paciente.beneficiario.nome,
        celular: agendamento.horariosAgendaProfissionalSaude.paciente.celular,
        telefone: agendamento.horariosAgendaProfissionalSaude.paciente.telefone,
        email: agendamento.horariosAgendaProfissionalSaude.paciente.email,
        dia: this.datePipe.transform(agendamento.horariosAgendaProfissionalSaude.data, "dd/MM/yyyy"),
        horario: this.datePipe.transform(agendamento.horariosAgendaProfissionalSaude.horario, "HH:mm"),
        convenioId: agendamento.horariosAgendaProfissionalSaude.paciente.beneficiario.convenioId,
        tipoAgendamentoId: agendamento.tipoAgendamentoId,
        tipoAgendamento: agendamento.tipoAgendamento,
        situacaoAgendamento: agendamento.situacaoAgendamento,
        situacaoAgendamentoId: agendamento.situacaoAgendamentoId,
        tipoAtendimentoId: agendamento.tipoAtendimentoId,
        observacoes: agendamento.observacoes,
        recorrencia: agendamento.recorrencia
      });

      this.findByPacienteId(agendamento.horariosAgendaProfissionalSaude.pacienteId);

      if (agendamento.tipoAtendimentoId == 2)
        this.isConvenio = true;

    }, (exception) => {
      console.log(exception);
    })
  }

  confirmaExcluirAgendamento() {
    this.close_form();
    this.confirmationService.confirm({
      message: `Confirma a exclusão do Agendamento ?`,
      accept: () => {
        this.removeAgendamento();
      }
    });
  }

  removeAgendamento() {
    this.spinner.show();

    this.agendaService.deleteAgendamento(this.agendamentoUpdate).subscribe((response) => {
      this.close_form();
      this.buscarHorarios();
      this.spinner.hide();
      this.messageService.add({ severity: 'success', summary: '', detail: 'Registro excluído com sucesso!' });
    }, (exception) => {
      this.spinner.hide();

    })
  }

  confirmaExcluirAgendamentoRecorrente() {
    this.close_form();
    this.confirmationService.confirm({
      message: `Você está prestes a excluir todos os agendamentos recorrentes futuros. Esta ação é irreversível. Deseja prosseguir?`,
      accept: () => {
        this.removeAgendamentoRecorrente();
      }
    });
  }

  removeAgendamentoRecorrente() {
    this.spinner.show();

    this.agendaService.removeAgendamentoRecorrente(this.agendamentoUpdate).subscribe((response) => {
      this.close_form();
      this.buscarHorarios();
      this.spinner.hide();
      this.messageService.add({ severity: 'success', summary: '', detail: 'Registro excluído com sucesso!' });
    }, (exception) => {
      this.spinner.hide();

    })
  }

  updateAgendamento() {
    var formValue = this.detalhamentoForm.value;

    var date = this.datePipe.transform(this.dataAgendamento, "yyyy/MM/dd");

    let beneficiarioForm = {
      nome: formValue.nome,
      convenioId: formValue.convenioId
    } as Beneficiario;

    let pacienteForm = {
      email: formValue.email,
      celular: formValue.celular,
      beneficiario: beneficiarioForm,
      telefone: formValue.telefone
    } as Paciente;

    let horariosAgenda = {
      horario: formValue.horario,
      data: date,
      pacienteId: formValue.pacienteId,
      // profissionalSaudeId: 41,
      paciente: pacienteForm
    } as HorariosAgendaProfissionalSaude;

    let parametros = {
      id: formValue.id,
      tipoAgendamentoId: formValue.tipoAgendamento.id,
      tipoAtendimentoId: formValue.tipoAtendimentoId,
      horariosAgendaProfissionalSaude: horariosAgenda,
      observacoes: formValue.observacoes,
      situacaoAgendamentoId: formValue.situacaoAgendamento.id
    } as Agendamento;
    this.agendaService.updateAgendamento(parametros).subscribe((response) => {
      this.spinner.hide();
      this.detalhamentoForm.reset();
      this.close_form();
      this.buscarHorarios();
      //this.messageService.add({ severity: 'success', summary: '', detail: 'Registro criado com sucesso!' });

    }, (exception) => {
      this.spinner.hide();
      //this.messageService.add({ severity: 'error', summary: '', detail: exception });
    })
  }

  // editarPaciente(){
  //   var paciente = this.detalhamentoForm.value;
  //   this.pacienteComponent.showDialogPaciente(this.paciente);
  // }

  editarPaciente() {
    this.pacienteService.getPacienteById(this.paciente.id).subscribe((response: Paciente) => {
      this.spinner.hide();
      this.paciente = response;
      this.close_form();
      this.pacienteComponent.showDialogPaciente(this.paciente);
    }, (exception) => {
      this.spinner.hide();
      this.messageService.add({ severity: 'error', summary: '', detail: exception });
    })
  }

  novoPaciente() {
    this.close_form();
    this.pacienteComponent.showDialogPaciente(null);
  }

  findByPacienteId(pacienteId) {
    this.prontuarioService.getProntuarioByPacienteId(pacienteId, 0).subscribe((response) => {
      this.spinner.hide();
      this.pacienteProntuario = response;
      this.atendimentosPaciente = response.atendimentos;
    }, (exception) => {
      this.spinner.hide();
      this.messageService.add({ severity: 'error', summary: '', detail: exception });
    })
  }

  cancelarAgendamento() {
    this.resetarFormulario();
    this.resetarEncaixe();
    this.close_form();
  }

  sePermissaoNaoSecretaria() {
    return !this.auth.canViewPermissaoUsuario({ roles: [EPermissao.Sec] });
  }
}
