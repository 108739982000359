import { Component, OnInit, ViewChild } from '@angular/core';
import { LoteService } from '../../services/lote.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Lote } from 'src/app/models/lote.model';
import { ConfirmationService, MessageService, SelectItem } from 'primeng/primeng';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ContratadoConvenio } from '../../models/contratado-convenio.model';
import { ProfissionalSaude } from '../../models/profissionalSaude.model';
import { Contratado } from '../../models/contratado.model';
import { ContratadoService } from '../../services/contratado.service';
import { FiltroLote } from '../../models/filtro-lote';
import { ComunsService } from '../../comuns/comuns.service';
import { ETipoGuia, ESituacaoLote } from 'src/app/enum/enums';
import { LoteComponent } from './lote/lote.component';
import { ActivatedRoute, ParamMap } from '@angular/router';
import * as _ from 'lodash';

@Component({
  selector: 'app-lotes',
  templateUrl: './lotes.component.html',
  styleUrls: ['./lotes.component.css'],
  providers: [ConfirmationService, MessageService]
})
export class LotesComponent implements OnInit {

  profSaude: any;
  lotes: Lote[];
  menusBotaoAcoes: any;
  filtro_form: FormGroup;
  finalizarLote_form: FormGroup;
  convenios: Array<ContratadoConvenio>;
  profissionais: Array<ProfissionalSaude>;
  contratados: Array<Contratado>;
  situacoesLote: SelectItem[];
  display: boolean = false;

  numeroLote: number;
  loteId: number;
  headerFinalizarLote: string;
  submittedFinalizarLote: boolean = false;

  @ViewChild(LoteComponent) loteComponent: LoteComponent;


  constructor(
    private loteService: LoteService,
    private contratadoService: ContratadoService,
    private spinner: NgxSpinnerService,
    private messageService: MessageService,
    private formBuilder: FormBuilder,
    private router: Router,
    private comunsService: ComunsService,
    private confirmationService: ConfirmationService,
    private route: ActivatedRoute) {
    this.getSituacoesLote();
  }

  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      let codeCrypt = params.get('code');
      this.form();
      this.formFinalizarLote();

      if (!_.isNull(codeCrypt) && !_.isEmpty(codeCrypt)) {
        let code = atob(codeCrypt).split("&");
        console.log(code);
        this.receiveNumeroLote(code[0]);
        this.receiveSituacaoLote(code[1]);
        this.receiveTipoGuia(code[2]);
      }

      this.contratadosByEmpresa();
      this.getLotes();

      this.menusBotaoAcoes = (lote: Lote) => {
        return this.menusLoteEmAndamento(lote);
      };
    })


  }

  form() {
    this.filtro_form = this.formBuilder.group({
      profissionalSaudeId: [''],
      contratadoId: [''],
      convenioId: [''],
      dtPeriodoInicial: [''],
      dtPeriodoFinal: [''],
      tipoGuiaId: [ETipoGuia.consulta],
      situacaoId: [ESituacaoLote.EmAndamento],
      contratado: [null],
      convenio: [null],
      profissionalSaude: [null],
      numeroLote: [null]
    })
  }

  formFinalizarLote() {
    this.finalizarLote_form = this.formBuilder.group({
      numLoteOperadora: ['', Validators.required],
      numProtocolo: ['', Validators.required],
    })
  }

  getLotes() {
    this.spinner.show();
    this.setValueFiltro(this.filtro_form.value);
    let parametros = this.filtro_form.value as FiltroLote;
    parametros.dtPeriodoInicial = this.comunsService.transformDate(parametros.dtPeriodoInicial);
    parametros.dtPeriodoFinal = this.comunsService.transformDate(parametros.dtPeriodoFinal);

    this.loteService.lotes(parametros).subscribe((response) => {
      this.lotes = response;

      if (parametros.situacaoId == ESituacaoLote.Faturado) {
        this.menusBotaoAcoes = (lote) => {
          return this.menusLoteFaturado(lote);
        };
      } else {
        this.menusBotaoAcoes = (lote) => {
          return this.menusLoteEmAndamento(lote);
        };
      }
      this.spinner.hide();
    }, (exception) => {
      this.spinner.hide();
      this.messageService.add({ severity: 'error', summary: '', detail: exception });
    })
  }

  receiveTipoGuia(tipoGuiaId) {
    this.filtro_form.patchValue({
      tipoGuiaId: tipoGuiaId
    });
  }

  receiveNumeroLote(numeroLote) {
    this.filtro_form.patchValue({
      numeroLote: numeroLote
    });
  }

  receiveSituacaoLote(situacaoId) {
    this.filtro_form.patchValue({
      situacaoId: Number.parseInt(situacaoId)
    });
  }

  receiveContratado(contratado: any) {
    var form = (<FormGroup>this.filtro_form);

    if (contratado) {
      this.convenios = contratado.contratadoConvenio;
      this.profissionais = contratado.profissionalSaude;
    }
    else {
      form.controls["contratado"].reset();
      form.controls["convenio"].reset();
      form.controls["profissionalSaude"].reset();
      this.convenios = [];
      this.profissionais = [];
    }
  }

  showFinalizarLote() {
    this.headerFinalizarLote = `#Lote ${this.numeroLote}`;
    this.finalizarLote_form.reset();
    this.submittedFinalizarLote = false;
    this.display = true;
  }

  hideFinalizarLote() {
    this.display = false;
  }

  confirmFinalizarLote() {
    this.submittedFinalizarLote = true;

    if (this.finalizarLote_form.invalid)
      return;

    this.confirmationService.confirm({
      message: `Confirma a finalização do lote ${this.numeroLote} ?`,
      accept: () => {
        this.finalizarLote(this.loteId);
      }
    });
  }

  confirmAberturaLote(numeroLote: number, loteId: number) {
    this.confirmationService.confirm({
      message: `Confirma a abertura do lote ${numeroLote} ?`,
      accept: () => {
        this.showDialogLote(loteId);
      }
    });
  }

  // abrirLote(loteId: number) {
  //   this.router.navigate(['/lote'], { queryParams: { code: loteId } });
  // }

  finalizarLote(loteId: number) {
    this.spinner.show();

    let lote = this.finalizarLote_form.value as Lote;
    lote.id = this.loteId;
    this.loteService.finalizarLote(lote).subscribe((response) => {
      this.spinner.hide();

      this.hideFinalizarLote();
      this.submittedFinalizarLote = false;
      this.messageService.add({ severity: 'success', summary: '', detail: 'Lote finalizado com sucesso!' });
      this.getLotes();
    }, (exception) => {
      this.spinner.hide();
      this.messageService.add({ severity: 'error', summary: '', detail: exception });
    })
  }

  baixarLoteXml(loteId: number, numeroLote: number) {
    this.spinner.show();
    this.loteService.baixarLoteXml(loteId).subscribe((response) => {
      this.spinner.hide();

      let file = new Blob([response.blob()], { type: "application/octet-stream" });
      let nomeArquivo = this.extrairNomeArquivoDoHeader(response);
      this.loteService.download(file, nomeArquivo, '');
    }, (exception) => {
      this.messageService.add({ severity: 'error', summary: '', detail: exception });
      this.spinner.hide();
    })
  }

  extrairNomeArquivoDoHeader(httpResponse) {
    //var nomeArquivo = httpResponse.headers.get('Content-Disposition');
    //var result = contentDispositionHeader.split(';')[1].trim().split('=')[1];
    return httpResponse.headers.get('Content-Disposition');
  }

  contratadosByEmpresa() {
    this.spinner.show();
    this.contratadoService.contratadosByEmpresa().subscribe((response) => {

      this.contratados = response;
      this.spinner.hide();
    }, (exception) => {
      this.spinner.hide();
      console.log(exception);
    })
  }

  setValueFiltro(filtroForm) {
    this.filtro_form.patchValue({
      profissionalSaudeId: filtroForm.profissionalSaude == null ? null : filtroForm.profissionalSaude.id,
      contratadoId: filtroForm.contratado == null ? null : filtroForm.contratado.id,
      convenioId: filtroForm.convenio == null ? null : filtroForm.convenio.convenioId,
    });

  }

  confirmDeleteLote(loteId: number) {
    this.confirmationService.confirm({
      message: `Confirma a exclusão do lote ${loteId} ?`,
      accept: () => {
        this.deletarLote(loteId);
      }
    });
  }

  deletarLote(loteId: number) {
    this.spinner.show();
    this.loteService.deleteLote(loteId).subscribe(() => {
      this.spinner.hide();
      this.messageService.add({ severity: 'success', summary: '', detail: "Lote excluído com sucesso!" });
      this.getLotes();
    }, (exception) => {
      this.spinner.hide();
      this.messageService.add({ severity: 'error', summary: '', detail: "Erro ao tentar excluir lote, tente novamente ou contacte o suporte." });
    })

  }

  visualizarLote(loteId: number, numeroLote: number) {
    this.spinner.show();
    this.loteService.visualizarLote(loteId).subscribe((response) => {
      this.spinner.hide();
      this.loteService.download(response, numeroLote, ".pdf");
    }, (exception) => {
      this.spinner.hide();
      this.messageService.add({ severity: 'error', summary: '', detail: "Erro ao tentar visualizar o lote, tente novamente ou contacte o suporte." });
    })
  }

  getSituacoesLote() {
    this.loteService.getSituacoesLote().subscribe((response) => {
      this.situacoesLote = this.comunsService.toSelectItem(response);
    }, (exception) => {
      console.log(exception);

    })
  }

  menusLoteFaturado(lote: Lote) {
    return [
      {
        label: 'Visualizar Lote', command: () => {
          this.visualizarLote(lote.id, lote.numeroLote);
        }
      },
      {
        label: 'Abrir Lote', command: () => {
          this.confirmAberturaLote(lote.numeroLote, lote.id);
        }
      },
      {
        label: 'Baixar Xml', command: () => {
          this.baixarLoteXml(lote.id, lote.numeroLote);
        }
      },
      {
        label: 'Excluir', command: () => {
          this.confirmDeleteLote(lote.id);
        }
      },
    ];
  }

  menusLoteEmAndamento(lote: Lote) {
    return [
      {
        label: 'Visualizar Lote', command: () => {
          this.visualizarLote(lote.id, lote.numeroLote);
        }
      },
      {
        label: 'Editar', command: () => {
          this.showDialogLote(lote.id);
        }
      },
      {
        label: 'Baixar Xml', command: () => {
          this.baixarLoteXml(lote.id, lote.numeroLote);
        }
      },
      {
        label: 'Finalizar Lote', command: () => {
          //this.confirmFinalizarLote(lote.numeroLote, lote.id);    
          this.numeroLote = lote.numeroLote;
          this.loteId = lote.id;
          this.showFinalizarLote();
        }
      },
      {
        label: 'Excluir', command: () => {
          this.confirmDeleteLote(lote.id);
        }
      },
    ];
  }

  validacoes = {
    'campoObrigatorio': [
      { type: 'required', message: 'Este campo é obrigatório.' }
    ]
  };

  showDialogLote(loteId?: number) {
    this.loteComponent.showDialogLote(loteId);
  }

}
