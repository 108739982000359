import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tuss-via',
  templateUrl: './tuss-via.component.html',
  styleUrls: ['./tuss-via.component.css']
})
export class TussViaComponent implements OnInit {

  tussLancamentoVia: any;
  display: boolean;
  constructor() { }

  ngOnInit() {
  }

  showDialog(tussLancamentoVia: any) {
    this.display = true;
    this.tussLancamentoVia = tussLancamentoVia;
  }

}
