import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Dropdown } from '../models/dropdown.model';
import { Dialog } from 'primeng/primeng';

@Injectable()
export class ComunsService {

    constructor() { }

    showDialogMaximized(dialog: Dialog) {
        setTimeout(() => {
            dialog.maximize();
        }, 0);
    }

    isNumeric(n) {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }

    transformDate(date: string) {
        if (!_.isNull(date) && !_.isEmpty(date)) {
            var dateSplit = date.split("/");
            var dateFormat = `${dateSplit[2]}/${dateSplit[1]}/${dateSplit[0]}`;

            if (dateSplit[0].length == 4)
                return date;
            return dateFormat;
        }
        return null;
    }

    transformDateWithMaskNewDate(date: string) : Date{
        if (!_.isNull(date) && !_.isEmpty(date)) {
            var dateSplit = date.split("/");
            var dateFormat = new Date(parseInt(dateSplit[2]), parseInt(dateSplit[1]) - 1, parseInt(dateSplit[0]));
            return dateFormat;
        }
        return null;
    }

    transformHour(hour: string) {
        if (!_.isNull(hour) && !_.isEmpty(hour)) {
            var hourSplit = hour.split(":");
            var format = new Date().setHours(Number.parseInt(hourSplit[0]), Number.parseInt(hourSplit[1]));
            return new Date(format);
        }
        return null;
    }


    formatDate(date) {
        if (date)
            return new Date(date);
        return null;
    }

    toSelectItem(response: any[]) {
        let tipos = [];

        if (response && response.length > 0) {
            response.forEach((value) => {
                tipos.push({
                    label: value.descricao || value.nome || value.nomeConvenio || value.via || value.razaoSocial,
                    value: value.id || value.convenioId
                });
            });
        }

        return tipos;
    }

    locale() {
        return {
            firstDayOfWeek: 0,
            dayNames: ["Domingo", "Segunda-Feira", "Terça-Feira", "Quarta-Feira", "Quinta-Feira", "Sexta-Feira", "Sábado"],
            dayNamesShort: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
            dayNamesMin: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
            monthNames: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Augusto", "Setembro", "Outubro", "Novembro", "Dezembro"],
            monthNamesShort: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Aug", "Set", "Out", "Nov", "Dez"],
            today: 'Hoje',
            clear: 'Limpar',
            dateFormat: 'dd/mm/yy',
            weekHeader: 'Wk'
        };
    }

    months() {
        return [
            { value: 1, label: "Janeiro" },
            { value: 2, label: "Fevereiro" },
            { value: 3, label: "Março" },
            { value: 4, label: "Abril" },
            { value: 5, label: "Maio" },
            { value: 6, label: "Junho" },
            { value: 7, label: "Julho" },
            { value: 8, label: "Agosto" },
            { value: 9, label: "Setembro" },
            { value: 10, label: "Outubro" },
            { value: 11, label: "Novembro" },
            { value: 12, label: "Dezembro" },
        ] as Dropdown[];
    }

}