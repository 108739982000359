import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { ETipoLancamento, EGrauParticipacao, ETipoAtendimento } from '../../../enum/enums';
import { LancamentoService } from '../../../services/lancamento.service';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { Lancamento } from '../../../models/lancamento.model';
import { ComunsService } from '../../../comuns/comuns.service';
import { TussValorProcedimento } from '../../../models/tuss-valor-procedimento.model';
import { Tuss } from '../../../models/tuss.model';
import { TipoVia } from '../../../models/tipo-via.model';
import { CalculoValorProcedimento } from '../../../models/calcular-valor-procedimento.model';
import { LancamentoTuss } from '../../../models/lancamento-tuss.model';
import { Dropdown } from '../../../models/dropdown.model';
import { TussComponent } from '../../componentes/modulo-componentes/tuss/tuss.component';
import { TipoViaComponent } from '../../componentes/modulo-componentes/tipo-via/tipo-via.component';
import { MessageService, FileUpload, Dialog, ConfirmationService } from 'primeng/primeng';
import { AnexoLancamentoService } from '../../../services/anexoLancamento.service';
import { AnexoLancamento } from '../../../models/anexoLancamento.model';
import { ConvenioComponent } from '../../componentes/modulo-componentes/convenio/convenio.component';
import { TipoLancamentoComponent } from '../../componentes/modulo-componentes/tipo-lancamento/tipo-lancamento.component';
import { GrauParticipacaoComponent } from '../../componentes/modulo-componentes/grau-participacao/grau-participacao.component';

import { NgxSpinnerService } from "ngx-spinner";
import { Contratado } from '../../../models/contratado.model';
import { ContratadoConvenio } from '../../../models/contratado-convenio.model';
import { ProfissionalSaude } from '../../../models/profissionalSaude.model';
import { ContratadoDropComponent } from '../../componentes/modulo-componentes/contratado/contratado-drop.component';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../../services/authentication.service';
import { EPermissao } from '../../../models/permissao.model';
import { ContratadoService } from '../../../services/contratado.service';
import { GuiaConsultaComponent } from '../../guia-consulta/guia-consulta.component';
import { GuiaCirurgiaComponent } from '../../guia-cirurgia/guia-cirurgia.component';
import { SelectLancamentoComponent } from './select-lancamento/select-lancamento.component';
import { GuiaCirurgiaSelectHospitalComponent } from '../../guia-cirurgia/guia-cirurgia-select-hospital/guia-cirurgia-select-hospital.component';
import { Hospital } from '../../../models/hospital.model';
import { Guia } from '../../../models/guia.model';
import { GuiasComponent } from '../../guias/guias.component';
import { FiltroLancamento } from '../../../models/filtro-lancamento.model';
import { RelatorioService } from '../../../services/relatorio.service';
import { PacienteService } from 'src/app/services/paciente.service';
import { Paciente } from 'src/app/models/paciente.model';
import { TabelaConvenioService } from 'src/app/services/tabelaConvenio.servoce';
import { ProcedimentoTabelaConvenio } from 'src/app/models/procedimentoTabelaConvenio.model';

@Component({
  selector: 'app-lancamento-convenio',
  templateUrl: './lancamento-convenio.component.html',
  styleUrls: ['./lancamento-convenio.component.css'],
  providers: [MessageService, ConfirmationService]
})
export class LancamentoConvenioComponent implements OnInit {
  @ViewChild(TussComponent) tussComponent: TussComponent;
  @ViewChild(TipoViaComponent) tipoViaComponent: TipoViaComponent;
  @ViewChild(ConvenioComponent) convenioComponent: ConvenioComponent;
  @ViewChild(ContratadoDropComponent) contratadoDropComponent: ContratadoDropComponent;
  @ViewChild(TipoLancamentoComponent) tipoLancamentoComponent: TipoLancamentoComponent;
  @ViewChild(GrauParticipacaoComponent) grauParticipacaoComponent: GrauParticipacaoComponent;
  @ViewChild(GuiaConsultaComponent) guiaConsultaComponent: GuiaConsultaComponent;
  @ViewChild(GuiaCirurgiaComponent) guiaCirurgiaComponent: GuiaCirurgiaComponent;
  @ViewChild(SelectLancamentoComponent) selectLancamentoComponent: SelectLancamentoComponent;

  @ViewChild('fileInput') fileInput: FileUpload;
  @ViewChild(GuiaCirurgiaSelectHospitalComponent) guiaCirurgiaSelectHospitalComponent: GuiaCirurgiaSelectHospitalComponent;
  @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();

  display: boolean = false;
  public isGrauParticipacao: boolean = false;
  public lancamentos = [];
  convenios: Array<ContratadoConvenio>;
  profissionais: Array<ProfissionalSaude>;
  contratados: Array<Contratado>;
  tiposVia: TipoVia[];
  hospital: Hospital;
  contratadoBase: Contratado;
  faturamentoAutomatico: boolean;
  camposReadonly: boolean = false;
  valueShow: boolean = true;

  pacientes: Array<Paciente>;
  public procedimentos: ProcedimentoTabelaConvenio[];
  public listaTabelas: Dropdown[];
  tabela: Dropdown;

  ptBR: any;
  datePipe = new DatePipe('pt-BR');
  dataLancamento: Date;
  lancamentoForm: FormGroup;
  get lancamentoTuss() { return <FormArray>this.lancamentoForm.get('lancamentoTuss'); }
  submitted = false;
  submittedLancamento = false;
  naoGerarOuAtualizarGuia = false;
  anexos: File[];

  constructor(
    private spinner: NgxSpinnerService,
    private anexoLancamentoService: AnexoLancamentoService,
    private messageService: MessageService,
    private lancamentoService: LancamentoService,
    private routerNavigation: Router,
    private formBuilder: FormBuilder,
    private auth: AuthenticationService,
    private contratadoService: ContratadoService,
    private comunsService: ComunsService,
    private relatorioService: RelatorioService,
    private router: ActivatedRoute,
    private pacienteService: PacienteService,
    private confirmationService: ConfirmationService,
    private tabelaConvenioService: TabelaConvenioService) {
    this.form(null);
  }

  ngOnInit() {
    this.getNomeTabelas();
  }

  form(formValue: Lancamento) {
    this.lancamentoForm = this.formBuilder.group({
      id: [formValue && formValue.id ? formValue.id : 0],
      pacienteId: [formValue && formValue.pacienteId, Validators.required],
      paciente: [formValue && formValue.paciente, Validators.required],
      nomePaciente: [formValue && formValue.nomePaciente, Validators.required],
      dataLancamento: [(formValue && formValue.dataLancamento) ? this.datePipe.transform(formValue.dataLancamento, "dd/MM/yyyy HH:mm", "pt-BR")
        : this.datePipe.transform(this.dataLancamento, "dd/MM/yyyy HH:mm", "pt-BR"), Validators.required],
      dataProcedimento: [formValue && this.datePipe.transform(formValue.dataProcedimento, "dd/MM/yyyy"), Validators.required],
      localAtendimento: [formValue && formValue.localAtendimento, Validators.required],
      convenioId: [formValue && formValue.convenio && formValue.convenio.id],
      contratadoId: [formValue && formValue.contratado && formValue.contratado.id],
      profissionalSaudeId: [formValue && formValue.profissionalSaude && formValue.profissionalSaude.id],
      tipoAtendimentoId: [ETipoAtendimento.convenio],
      anotacoes: [formValue && formValue.anotacoes],
      guiaId: [formValue && formValue.guiaId],
      vlrTotalLancamento: [formValue && formValue.vlrTotalLancamento],
      grauParticipacaoId: [formValue && formValue.grauParticipacao && formValue.grauParticipacao.id, Validators.required],
      tipoLancamentoId: [formValue && formValue.tipoLancamento && formValue.tipoLancamento.id, Validators.required],
      apartamento: [(formValue && formValue.apartamento) == null ? false : formValue.apartamento],
      tussId: ['', Validators.required],
      codigoTuss: ['', Validators.required],
      procedimento: [''],

      contratadoConvenioForm: this.formBuilder.group({
        contratado: [null, Validators.required],
        convenio: [null, Validators.required],
        profissionalSaude: [null, Validators.required]
      }),
      lancamentoTuss: this.formBuilder.array([]),
      tabela: [null, Validators.required],
    })
  }

  // convenience getter for easy access to form fields
  get f() { return this.lancamentoForm.controls; }

  onSubmit() {
    this.removerValidacaoInclusaoTuss();
    this.submitted = true;
    this.submittedLancamento = true;
    var contratadoConvenioForm = (<FormGroup>this.lancamentoForm.controls['contratadoConvenioForm']).value;

    let lancamentoCreate = this.lancamentoForm.value as Lancamento;
    this.setValueLancamento(lancamentoCreate, contratadoConvenioForm);

    if (this.lancamentoForm.invalid || this.lancamentoTuss.length == 0) {
      this.messageService.add({ severity: 'error', summary: '', detail: 'Existem campos obrigatórios não preenchidos.' });
      return;
    }

    if (this.lancamentoTuss.value.filter((f) => f.lancamentoViaId == 0).length > 0) {
      this.messageService.add({ severity: 'error', summary: '', detail: 'Obrigatório selecionar tipo da via.' });
      return;
    }

    if (this.lancamentoTuss.value.filter((f) => f.qtdeProcedimento == 0).length > 0) {
      this.messageService.add({ severity: 'error', summary: '', detail: 'Campo quantidade deve ser maior que 0' });
      return;
    }



    if (this.fileInput && this.fileInput.files.length == 0) {
      if (lancamentoCreate.id) {
        this.atualizarLancamento(lancamentoCreate);
      } else {
        this.criarLancamento(lancamentoCreate);
      }
    }
    else
      this.onUploadGuias(lancamentoCreate);
  }

  incluirTuss() {
    this.aplicarValidacaoInclusaoTuss();
    this.submitted = true;

    var contratadoConvenioForm = (<FormGroup>this.lancamentoForm.controls['contratadoConvenioForm']).value;
    let lancamentoCreate = this.lancamentoForm.value as Lancamento;
    this.setValueLancamento(lancamentoCreate, contratadoConvenioForm);


    // stop here if form is invalid
    if (this.lancamentoForm.invalid) {
      this.messageService.add({ severity: 'error', summary: '', detail: 'Existem campos obrigatórios não preenchidos.' });
      return;
    }

    this.spinner.show();
    let filtroProcedimento = this.lancamentoForm.value as TussValorProcedimento;
    this.ObterValorProcedimento(filtroProcedimento).subscribe((valorProcedimento: TussValorProcedimento) => {

      this.addLancamentoTuss(valorProcedimento).then(() => {
        // this.processarValorProcedimento();
        this.messageService.add({ severity: 'success', summary: '', detail: 'Procedimento incluído com sucesso!' });
        this.submitted = false;
        this.lancamentoForm.patchValue({
          tussId: null,
          codigoTuss: null
        });
        this.tussComponent.reset();
        // this.tipoViaComponent.reset();
      });
      this.spinner.hide();

      //valores carregados
    }, (exception) => {
      this.spinner.hide();
      this.messageService.add({ severity: 'error', summary: '', detail: exception });
    })

  }

  addLancamentoTuss(procedimento: TussValorProcedimento) {
    return new Promise((resolve, reject) => {
      try {
        procedimento.valorProcedimento = this.AplicarCalculoApartamento(procedimento.valorProcedimento);
        (this.lancamentoTuss as FormArray).push(this.formBuilder.group({
          id: 0,
          tussId: this.lancamentoForm.value['tussId'],
          lancamentoId: 0,
          lancamentoViaId: this.lancamentoForm.get('tipoLancamentoId').value == ETipoLancamento.consulta ? 1 : 1,
          codigo: this.lancamentoForm.value['codigoTuss'],
          procedimento: this.lancamentoForm.value['procedimento'],
          valorProcedimento: procedimento,
          vlrTuss: procedimento.valorProcedimento,
          qtdeProcedimento: 1,
          vlrTussPercentAplicado: this.lancamentoForm.get('tipoLancamentoId').value == ETipoLancamento.consulta ?
            procedimento.valorProcedimento : procedimento.valorProcedimento,
          diferencaValorProcedimentoPacote: procedimento.diferencaValorProcedimentoPacote
        }));
        resolve(this.lancamentoTuss);
      } catch (exception) {
        reject(exception);
      }
    });
  }

  addLancamentoTussParaEdicao(tuss: LancamentoTuss) {
    return new Promise((resolve, reject) => {
      try {
        (this.lancamentoTuss as FormArray).push(this.formBuilder.group({
          id: tuss.id ? tuss.id : 0,
          tussId: tuss.tuss.id,
          lancamentoId: tuss.lancamentoId ? tuss.lancamentoId : 0,
          lancamentoViaId: tuss.lancamentoVia.id,
          codigo: tuss.tuss.codigo,
          procedimento: tuss.tuss.procedimento,
          lancamentoVia: tuss.lancamentoVia,
          valorProcedimento: tuss.valorProcedimento,
          vlrTuss: tuss.vlrTuss,
          qtdeProcedimento: tuss.qtdeProcedimento,
          vlrTussPercentAplicado: tuss.vlrTussPercentAplicado,
          diferencaValorProcedimentoPacote: tuss.diferencaValorProcedimentoPacote
        }));

        resolve(this.lancamentoTuss);
      } catch (exception) {
        reject(exception);
      }
    });
  }

  ObterValorProcedimento(filtroProcedimento: TussValorProcedimento) {
    return this.lancamentoService.ObterValorProcedimento(filtroProcedimento);
  }

  processarValorProcedimento() {
    var calculo = new CalculoValorProcedimento();
    var valorProcedimento = calculo.processarValorProcedimento(this.lancamentoTuss.value);

    this.lancamentoForm.patchValue({
      vlrTotalLancamento: valorProcedimento
    });
  }

  AplicarCalculoApartamento(valorProcedimento: number) {
    if (this.lancamentoForm.value['apartamento'] && valorProcedimento > 0) {
      return valorProcedimento *= 2;
    }
    return valorProcedimento;
  }

  validacoes = {
    'campoObrigatorio': [
      { type: 'required', message: 'Este campo é obrigatório.' }
    ]
  };

  excluirTUSS(index) {
    (this.lancamentoTuss as FormArray).removeAt(index);
    this.processarValorProcedimento();
    this.messageService.add({ severity: 'success', summary: '', detail: 'Tuss removido com sucesso!' });
  }

  receiveContratado(contratado: any) {
    var form = (<FormGroup>this.lancamentoForm.controls['contratadoConvenioForm']);
    var listaTuss = this.lancamentoTuss as FormArray;

    if (contratado) {
      this.convenios = contratado.contratadoConvenio;
      this.profissionais = contratado.profissionalSaude;
    }
    else {
      form.reset();
      this.convenios = [];
      this.profissionais = [];
    }

    if (this.contratadoBase == null) {

    }
    else {
      if (contratado.id != this.contratadoBase.id) {
        if (listaTuss.length != 0) {
          this.confirmationService.confirm({
            message: `O contratado foi alterado! Será necessário preencher novamente as informações. Deseja continuar ?`,
            reject: () => {
              let lancamentoData = this.lancamentoForm.value as Lancamento;
              this.getLancamentoById(lancamentoData.id);
            },
            accept: () => {
              var form = (<FormGroup>this.lancamentoForm.controls['contratadoConvenioForm']);
              this.contratadoBase.id = contratado.id;
              form.controls['profissionalSaude'].reset();
              form.controls['convenio'].reset();
              this.limparListaLancamentoTuss();
              this.tipoLancamentoComponent.reset();
            }
          });
        }
      }
    }
  }

  receiveConvenio(convenio) {
    let lancamentoData = this.lancamentoForm.value as Lancamento;
    var listaTuss = this.lancamentoTuss as FormArray;
    this.faturamentoAutomatico = convenio.faturamentoAutomatico;

    if (convenio.id != lancamentoData.convenioId) {
      if (listaTuss.length != 0) {
        this.confirmationService.confirm({
          message: `O lançamento foi alterado! Será necessário selecionar novos procedimentos. Deseja continuar ?`,
          reject: () => {
            this.getLancamentoById(lancamentoData.id);
          },
          accept: () => {
            this.lancamentoForm.patchValue({
              convenioId: convenio.id
            });
            
            this.limparListaLancamentoTuss();
          }
        });
      }
    }
  }

  receiveProfissional(profissional) {
    let lancamentoData = this.lancamentoForm.value as Lancamento;
    var listaTuss = this.lancamentoTuss as FormArray;

    if (profissional.id != lancamentoData.profissionalSaudeId) {
      if (listaTuss.length != 0) {
        this.confirmationService.confirm({
          message: `O lançamento foi alterado! Será necessário selecionar novos procedimentos. Deseja continuar ?`,
          reject: () => {
            this.getLancamentoById(lancamentoData.id);
          },
          accept: () => {
            this.lancamentoForm.patchValue({
              profissionalSaudeId: profissional.id
            });
            this.limparListaLancamentoTuss();
          }
        });
      }
    }
  }

  receiveGrauParticipacao(grauParticipacao) {
    this.lancamentoForm.patchValue({
      grauParticipacaoId: grauParticipacao && grauParticipacao.id
    });
  }

  // receiveTipoVia(tipoVia) {
  //   console.log(tipoVia);
  //   //this.tipoViaSelecionado = tipoVia;
  //   this.lancamentoForm.patchValue({
  //     lancamentoViaId: tipoVia && tipoVia.id
  //   });
  // }

  receiveTuss(tuss) {
    //this.tussSelecionado = tuss;
    this.lancamentoForm.patchValue({
      tussId: tuss && tuss.id,
      codigoTuss: tuss && tuss.codigo,
      procedimento: tuss && tuss.procedimento,
    });
  }

  receiveTipoLancamento(tipoLancamento) {
    let lancamentoData = this.lancamentoForm.value as Lancamento;
    var listaTuss = this.lancamentoTuss as FormArray;
    this.lancamentoForm.patchValue({
      tipoLancamentoId: tipoLancamento && tipoLancamento.id
    });
    this.aplicarHiddenGrauParticipacao(tipoLancamento);

    if (tipoLancamento.id != lancamentoData.tipoLancamentoId) {
      if (listaTuss.length != 0) {
        this.confirmationService.confirm({
          message: `O lançamento foi alterado! Será necessário selecionar novos procedimentos. Deseja continuar ?`,
          reject: () => {
            this.getLancamentoById(lancamentoData.id);
          },
          accept: () => {
            this.aplicarHiddenGrauParticipacao(tipoLancamento);
            this.lancamentoForm.patchValue({
              tipoLancamentoId: tipoLancamento && tipoLancamento.id
            });
            this.limparListaLancamentoTuss();
          }
        });
      }
    }
  }

  onUploadGuias(lancamentoCreate: Lancamento) {

    this.spinner.show();
    const formData = new FormData();
    for (let file of this.fileInput.files) {
      var blob = new Blob([file], { type: "application/pdf" });
      formData.append('anexo' + file.name.replace('.pdf', ''), blob, file.name);
    }

    this.anexoLancamentoService.uploadArquivosLancamento(formData).subscribe((listaAnexosId: Array<AnexoLancamento>) => {
      lancamentoCreate.arquivoLancamentoAnexo = listaAnexosId;
      if (lancamentoCreate.id) {
        this.atualizarLancamento(lancamentoCreate);
      } else {
        this.criarLancamento(lancamentoCreate);
      }
    }, (exception) => {
      this.spinner.hide();
      this.messageService.add({ severity: 'error', summary: '', detail: exception });
    })
  }

  private criarLancamento(lancamento: Lancamento) {
    this.spinner.show();

    this.lancamentoService.criarLancamento(lancamento).subscribe((lancamentoId) => {
      this.limparFormLancamento();
      this.submitted = false;
      this.submittedLancamento = false;
      this.spinner.hide();
      this.messageService.add({ severity: 'success', summary: '', detail: 'Lançamento enviado com sucesso.' });

      if (this.isNotSec) {
        if (this.faturamentoAutomatico == false)
          this.confirmaGerarGuia(lancamentoId, lancamento.tipoLancamentoId);
        else {
          this.voltar();
          this.modalSave.emit(null);
        }
      }
      else {
        this.voltar();
        this.modalSave.emit(null);
      }
      // setTimeout(() => {
      //   this.routerNavigation.navigate(['/lancamentos-faturamento']);
      // }, 1000);
    }, (exception) => {
      this.spinner.hide();
      this.messageService.add({ severity: 'error', summary: '', detail: exception });
    })
  }

  private atualizarLancamento(lancamento: Lancamento) {
    this.spinner.show();

    this.lancamentoService.atualizarLancamento(lancamento).subscribe((success) => {
      this.limparFormLancamento();
      this.submitted = false;
      this.submittedLancamento = false;
      this.spinner.hide();
      this.messageService.add({ severity: 'success', summary: '', detail: 'Lançamento enviado com sucesso.' });

      if (this.isNotSec && !this.naoGerarOuAtualizarGuia) {
        if (lancamento.guiaId > 0 && lancamento.guiaId != null)
          this.confirmaAtualizarGuia(lancamento.id, lancamento.guiaId, lancamento.tipoLancamentoId);
        else {
          this.confirmaGerarGuia(lancamento.id, lancamento.tipoLancamentoId);
        }
      } else {
        this.voltar();
        this.modalSave.emit(null);
      }

      // setTimeout(() => {
      //   this.routerNavigation.navigate(['/lancamentos-faturamento']);
      // }, 1000);
    }, (exception) => {
      this.spinner.hide();
      this.messageService.add({ severity: 'error', summary: '', detail: exception });
    })
  }

  private aplicarValidacaoInclusaoTuss() {
    this.aplicarFormValidation('tussId');
    this.aplicarFormValidation('codigoTuss');
  }

  private removerValidacaoInclusaoTuss() {
    this.removerFormValidation('tussId');
    this.removerFormValidation('codigoTuss');
  }

  private aplicarHiddenGrauParticipacao(tipoLancamento) {
    if (tipoLancamento && tipoLancamento.id == ETipoLancamento.procedimentos) {
      this.lancamentoForm.get('grauParticipacaoId').reset();
      this.aplicarFormValidation('grauParticipacaoId');
      this.isGrauParticipacao = true;
    }
    else {
      this.removerFormValidation('grauParticipacaoId');
      this.lancamentoForm.get('grauParticipacaoId').setValue(EGrauParticipacao.atendimentoClinica);
      this.isGrauParticipacao = false;
    }
  }

  private aplicarFormValidation(control: string) {
    this.lancamentoForm.get(control).setValidators(Validators.required);
    this.lancamentoForm.get(control).updateValueAndValidity();
  }

  private removerFormValidation(control: string) {
    this.lancamentoForm.get(control).clearValidators();
    this.lancamentoForm.get(control).updateValueAndValidity();
  }

  public limparFormLancamento() {
    var form = (<FormGroup>this.lancamentoForm.controls['contratadoConvenioForm']);

    form.reset();
    this.lancamentoForm.reset();
    this.isGrauParticipacao = false;
    this.submittedLancamento = false;
    this.submitted = false;
    this.convenios = [];
    this.profissionais = [];
    this.hospital = null;

    this.limparListaLancamentoTuss();
    this.fileInput.clear();
    this.tipoLancamentoComponent.reset();

    this.dataLancamento = new Date();
    this.lancamentoForm.patchValue({
      vlrTotalLancamento: 0,
      tipoAtendimentoId: ETipoAtendimento.convenio,
      dataLancamento: this.datePipe.transform(this.dataLancamento, "dd/MM/yyyy HH:mm", "pt-BR")
    })
  }

  limparListaLancamentoTuss() {
    while (0 !== this.lancamentoTuss.length) {
      (this.lancamentoTuss as FormArray).removeAt(0);
    }
  }

  setValueLancamento(lancamentoCreate, contratadoConvenioForm) {
    lancamentoCreate.lancamentoTuss = this.lancamentoTuss.value as LancamentoTuss[];
    lancamentoCreate.dataLancamento = this.datePipe.transform(this.dataLancamento, "MM/dd/yyyy HH:mm", "pt-BR");
    lancamentoCreate.dataProcedimento = this.comunsService.transformDate(lancamentoCreate.dataProcedimento);
    lancamentoCreate.contratadoId = contratadoConvenioForm.contratado == null ? null : contratadoConvenioForm.contratado.id;
    lancamentoCreate.convenioId = contratadoConvenioForm.convenio == null ? null : contratadoConvenioForm.convenio.convenioId;
    lancamentoCreate.profissionalSaudeId = contratadoConvenioForm.profissionalSaude == null ? null : contratadoConvenioForm.profissionalSaude.id;
  }

  get isViewTotal() {
    return this.auth.canViewPermissaoUsuario({ roles: [EPermissao.Fat, EPermissao.Admin, EPermissao.Med] });
  }

  getLancamentoById(lancamentoId: number) {
    this.spinner.show();
    this.lancamentoService.getLancamentoById(lancamentoId).subscribe((lancamento: Lancamento) => {
      if (lancamento) {
        lancamento.vlrTotalLancamento = lancamento.vlrTotal;

        this.form(lancamento);
        this.dataLancamento = new Date(lancamento.dataLancamento);

        var form = (<FormGroup>this.lancamentoForm.controls['contratadoConvenioForm']);
        let contratadoSelecionado = this.contratados.find(c => c.id == lancamento.contratado.id);
        this.contratadoBase = this.contratados.find(c => c.id == lancamento.contratado.id);
        this.tipoLancamentoComponent.set(lancamento.tipoLancamento);

        this.aplicarHiddenGrauParticipacao(lancamento.tipoLancamento);
        this.receiveContratado(contratadoSelecionado);
        this.receiveTipoLancamento(lancamento.tipoLancamento);
        this.receiveGrauParticipacao(lancamento.grauParticipacao);
        setTimeout(() => {
          if (lancamento.grauParticipacao && this.isGrauParticipacao) {
            if (this.grauParticipacaoComponent)
              this.grauParticipacaoComponent.set(lancamento.grauParticipacao);
          }
        }, 100);
        form.patchValue({
          contratado: contratadoSelecionado,
          convenio: this.convenios.find(c => c.convenioId == lancamento.convenio.id),
          profissionalSaude: this.profissionais.find(p => p.id == lancamento.profissionalSaude.id)
        });

        lancamento.lancamentoTuss.forEach((tuss, index) => {
          this.receiveTuss(tuss.tuss);
          this.addLancamentoTussParaEdicao(tuss).then(() => {
            this.lancamentoForm.patchValue({
              tussId: null,
              codigoTuss: null
            });
            this.tussComponent.reset();
          });
          this.spinner.hide();
        });
        this.changeLancamentos();
      }
    }, (exception) => {
      this.spinner.hide();
      console.log(exception);
    })
  }

  contratadosByEmpresa(lancamentoId?: number) {
    this.spinner.show();
    this.contratadoService.contratadosByEmpresa().subscribe((response) => {

      this.contratados = response;
      this.setContratadoIsSec();
      this.spinner.hide();

      // this.getParamLancamento();
      if (lancamentoId > 0)
        this.getLancamentoById(lancamentoId);
      else {
        var form = (<FormGroup>this.lancamentoForm);
        this.dataLancamento = new Date();
        form.patchValue({
          dataLancamento: this.datePipe.transform(this.dataLancamento, "dd/MM/yyyy HH:mm", "pt-BR")
        });
      }

    }, (exception) => {
      this.spinner.hide();
      console.log(exception);
    })
  }

  // getParamLancamento() {
  //   this.router
  //     .queryParams
  //     .subscribe(params => {
  //       let id = params['code'];
  //       if (id > 0)
  //         this.getLancamentoById(id);
  //       else {
  //         var form = (<FormGroup>this.lancamentoForm);
  //         this.dataLancamento = new Date();
  //         form.patchValue({
  //           dataLancamento: this.datePipe.transform(this.dataLancamento, "dd/MM/yyyy HH:mm", "pt-BR")
  //         });
  //       }
  //     });
  // }

  // calcularProcedimentos(tuss: LancamentoTuss, index: number, length: number) {
  //   let filtroProcedimento = this.lancamentoForm.value as TussValorProcedimento;
  //   this.ObterValorProcedimento(filtroProcedimento).subscribe((valorProcedimento: number) => {
  //     this.addLancamentoTussParaEdicao(tuss, valorProcedimento);
  //   }, (exception) => {
  //     this.spinner.hide();
  //     this.messageService.add({ severity: 'error', summary: '', detail: exception });
  //   })

  //   setTimeout(() => {
  //     if ((length - 1) == index)
  //       this.spinner.hide();
  //   }, 2000);
  // }

  calculaProcedimento() {
    this.lancamentoTuss.valueChanges.subscribe((s) => {
      this.processarValorProcedimento();
    });
  }

  getTiposViaLancamento() {
    this.lancamentoService.getTiposViaLancamento().subscribe((tipos) => {
      this.tiposVia = tipos;
    });
  }

  trackByFn(index: any, item: any) {
    return index;
  }

  changeLancamentos() {
    this.lancamentoTuss.valueChanges.subscribe((s: LancamentoTuss) => {
      this.processarValorProcedimento();
    });
  }

  setContratadoIsSec() {
    if (this.auth.canViewPermissaoUsuario({ roles: [EPermissao.Sec] })) {
      var form = (<FormGroup>this.lancamentoForm.controls['contratadoConvenioForm']);
      this.receiveContratado(this.contratados[0]);
      form.patchValue({
        contratado: this.contratados[0]
      });
      this.lancamentoForm.patchValue({
        localAtendimento: this.contratados[0].razaoSocial
      });
    }
  }

  voltar() {
    this.display = false;
  }

  showDialogLancamentoConvenio(lancamentoId?: number) {
    this.limparFormLancamento();
    this.form(null);
    this.contratadosByEmpresa(lancamentoId);
    this.getTiposViaLancamento();
    this.changeLancamentos();
    this.display = true;
    this.submitted = false;
  }

  showDialogMaximized(dialog: Dialog) {
    this.comunsService.showDialogMaximized(dialog);
  }

  fecharModalGuia(guia?: Guia) {
    if (guia != null && guia.id > 0)
      this.gerarRelatorioGuias(guia);
  }

  confirmaGerarGuia(lancamentoId: number, tipoLancamento: number) {
    this.confirmationService.confirm({
      message: `Lançamento ${lancamentoId} enviado com sucesso. Deseja gerar guia ?`,
      reject: () => {
        this.voltar();
        this.modalSave.emit(null);
      },
      accept: () => {
        this.display = false;
        if (tipoLancamento == ETipoLancamento.procedimentos) {
          this.guiaCirurgiaComponent.showDialogGuiaCirurgia(null, lancamentoId);
        }
        if (tipoLancamento == ETipoLancamento.consulta || tipoLancamento == ETipoLancamento.exame) {
          this.guiaConsultaComponent.showDialogGuiaConsulta(null, lancamentoId);
        }
      }
    });
  }

  confirmaAtualizarGuia(lancamentoId: number, guiaId: number, tipoLancamento: number) {
    this.confirmationService.confirm({
      message: `Lançamento ${lancamentoId} atualizado com sucesso. Deseja atualizar a guia ?`,
      reject: () => {
        this.voltar();
        this.modalSave.emit(null);
      },
      accept: () => {
        this.display = false;
        if (tipoLancamento == ETipoLancamento.procedimentos) {
          this.guiaCirurgiaComponent.showDialogGuiaCirurgia(guiaId, null);
        }
        if (tipoLancamento == ETipoLancamento.consulta || tipoLancamento == ETipoLancamento.exame) {
          this.guiaConsultaComponent.showDialogGuiaConsulta(guiaId, null);
        }
      }
    });
  }

  showDialogSelectLancamento() {
    this.submitted = false;
    this.selectLancamentoComponent.showDialog(this.lancamentoForm.value);
  }

  getLancamentoSelecionado(lancamento: Lancamento) {
    this.getLancamentoById(lancamento.id);
  }

  showDialogSelectHospital() {
    this.guiaCirurgiaSelectHospitalComponent.showDialog();
  }

  getHospitalSelecionado(hospital: Hospital) {
    this.hospital = hospital;
    this.lancamentoForm.patchValue({
      localAtendimento: hospital.nome
    });
  }

  get isNotSec() {
    return this.auth.canViewPermissaoUsuario({ roles: [EPermissao.Fat, EPermissao.Admin] });
  }

  gerarRelatorioGuias(guia: Guia) {
    let parametros = {
      id: guia.id,
      tipoGuiaId: guia.tipoGuiaId,
      espelho: true,
      guiaSadt: guia.guiaSadt
    } as FiltroLancamento;

    this.spinner.show();
    this.relatorioService.gerarRelatorioGuias(parametros).subscribe((file) => {
      this.spinner.hide();
      this.relatorioService.download(file);
    }, (exception: Response) => {
      this.spinner.hide();
      this.messageService.add({ severity: 'success', summary: '', detail: "Nenhum registro encontrado para o período informado." });
    })
  }

  setValueDisableTipoLancamento() {
    this.tipoLancamentoComponent.setValueDisable();
  }

  desabilitarCamposFormulario() {
    this.camposReadonly = true;
  }

  desabilitarShowClear() {
    this.valueShow = false;
  }

  //AUTOCOMPLETE DO PACIENTE
  getPaciente(event) {
    // Atraso de 300 milissegundos (ou o valor que você preferir)
    const debounce = 300;

    var contratadoConvenioForm = (<FormGroup>this.lancamentoForm.controls['contratadoConvenioForm']).value;

    // Use o RxJS para aplicar o debounceTime e evitar consultas desnecessárias
    const consultaPaciente = this.pacienteService.getPacienteByName(contratadoConvenioForm.profissionalSaude.id, event.query || 'todos');

    consultaPaciente.pipe(
      debounceTime(debounce),
      distinctUntilChanged(),
      switchMap((result) => {
        this.pacientes = result;
        this.spinner.hide();
        return result;
      })
    ).subscribe(
      () => { },
      (exception) => {
        this.spinner.hide();
      }
    );
  }

  setPaciente(paciente) {
    if (paciente) {
      this.lancamentoForm.patchValue({
        paciente: paciente,
        pacienteId: paciente.id,
        nomePaciente: paciente.beneficiario.nome
      });
    }
  }

  getNomeTabelas() {
    this.spinner.show();
    this.tabelaConvenioService.getNomeTabelas()
      .subscribe((tabelas) => {
        this.listaTabelas = tabelas;
        this.spinner.hide();
      }, (exception) => {
        this.spinner.hide();
      })
  }

  getProcedimentos() {
    var id = this.lancamentoForm.value['tabela'].value;
    this.spinner.show();
    this.tabelaConvenioService.getProcedimentos(id)
      .subscribe((procedimentos) => {
        this.procedimentos = procedimentos;
        this.spinner.hide();
      }, (exception) => {
        this.spinner.hide();
      })
  }

}
