import { Component, OnInit, ViewChild } from '@angular/core';
import { GuiaService } from '../../services/guia.service';
import { Guia } from 'src/app/models/guia.model';
import { FiltroGuia } from '../../models/filtro-guia.model';
import { ComunsService } from '../../comuns/comuns.service';
import { SelectItem, ConfirmationService, MessageService, LazyLoadEvent } from 'primeng/primeng';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ETipoGuia, ESituacaoGuia, ETipoLancamento } from 'src/app/enum/enums';
import { Contratado } from '../../models/contratado.model';
import { ContratadoConvenio } from '../../models/contratado-convenio.model';
import { Convenio } from '../../models/convenio.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { Situacao } from '../../models/situacaoGuia.model';
import { Router } from '@angular/router';
import { ProfissionalSaude } from '../../models/profissionalSaude.model';
import { ContratadoService } from '../../services/contratado.service';
import { Paginacao } from '../../models/paginacao.model';
import { Table } from 'primeng/table';
import { GuiaConsultaComponent } from '../guia-consulta/guia-consulta.component';
import { GuiaCirurgiaComponent } from '../guia-cirurgia/guia-cirurgia.component';
import { RelatorioService } from '../../services/relatorio.service';
import { FiltroLancamento } from '../../models/filtro-lancamento.model';

@Component({
  selector: 'app-guias',
  templateUrl: './guias.component.html',
  styleUrls: ['./guias.component.css'],
  providers: [ConfirmationService, MessageService]
})
export class GuiasComponent implements OnInit {

  @ViewChild('dt') dt: Table;
  @ViewChild(GuiaConsultaComponent) guiaConsultaComponent: GuiaConsultaComponent;
  @ViewChild(GuiaCirurgiaComponent) guiaCirurgiaComponent: GuiaCirurgiaComponent;

  profSaude: any;
  lancamentos: any;
  guias: Array<Guia>;
  situacoesGuia: SelectItem[];
  menusBotaoAcoes: any;

  tiposGuia: SelectItem[];
  guia_filter_form: FormGroup;
  convenios: Array<ContratadoConvenio>;
  profissionais: Array<ProfissionalSaude>;
  contratados: Array<Contratado>;

  totalRecords: number;

  constructor(private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private comunsService: ComunsService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private relatorioService: RelatorioService,
    private contratadoService: ContratadoService,
    private router: Router,
    private guiaService: GuiaService) {
    this.form();
    this.getSituacoesGuia();
    this.contratadosByEmpresa();
  }

  ngOnInit() {
    this.menusBotaoAcoes = (guia: Guia) => {
      return this.menusGuiaDigitada(guia);
    };
  }

  form() {
    this.guia_filter_form = this.formBuilder.group({
      tipoGuiaId: [ETipoGuia.consulta],
      situacaoId: [ESituacaoGuia.Digitada],
      numeroGuia: [''],
      numeroLote: [null],
      nomePaciente: [''],
      convenioId: [null],
      contratadoId: [null],
      profissionalSaudeId: [null],

      dtEmissaoInicial: [null],
      dtEmissaoFinal: [null],

      contratado: [null],
      convenio: [null],
      profissionalSaude: [null]
    })
  }

  receiveTipoGuia(tipoGuiaId) {
    this.guia_filter_form.patchValue({
      tipoGuiaId: tipoGuiaId
    });
  }

  getAllGuias(paginacao?: LazyLoadEvent) {
    this.spinner.show();

    this.setValueFiltro(this.guia_filter_form.value);
    var filter = this.guia_filter_form.value as FiltroGuia;
    filter.dtEmissaoInicial = this.comunsService.transformDate(filter.dtEmissaoInicial);
    filter.dtEmissaoFinal = this.comunsService.transformDate(filter.dtEmissaoFinal);

    var pag = new Paginacao();
    if (paginacao == null) this.dt.first = 0;
    pag.setPaginacao(filter, paginacao);    

    this.guiaService.guias(filter).subscribe((response: any) => {

      this.guias = response.guias;
      this.totalRecords = response.totalCount;

      if (filter.situacaoId == ESituacaoGuia.Faturada) {
        this.menusBotaoAcoes = (guia) => {
          return this.menusGuiaFaturada(guia);
        };
      } else {
        this.menusBotaoAcoes = (guia) => {
          return this.menusGuiaDigitada(guia);
        };
      }
      this.spinner.hide();
    }, (exception) => {
      this.spinner.hide();
      console.log(exception);
    })
  }

  getSituacoesGuia() {
    this.guiaService.getSituacoesGuia().subscribe((response) => {
      this.situacoesGuia = this.comunsService.toSelectItem(response);
    }, (exception) => {
      console.log(exception);

    })
  }

  showEditarGuia(guiaId, tipoGuiaId) {
    if (tipoGuiaId == ETipoGuia.honorario) {
      this.guiaCirurgiaComponent.showDialogGuiaCirurgia(guiaId, null);
    }
    if (tipoGuiaId == ETipoGuia.consulta) {
      this.guiaConsultaComponent.showDialogGuiaConsulta(guiaId, null);
    }
  }

  receiveContratado(contratado: any) {
    var form = (<FormGroup>this.guia_filter_form);

    if (contratado) {
      this.convenios = contratado.contratadoConvenio;
      this.profissionais = contratado.profissionalSaude;
    }
    else {
      form.controls["contratado"].reset();
      form.controls["convenio"].reset();
      form.controls["profissionalSaude"].reset();
      this.convenios = [];
      this.profissionais = [];
    }
  }

  setValueFiltro(filtroForm) {
    this.guia_filter_form.patchValue({
      profissionalSaudeId: filtroForm.profissionalSaude == null ? null : filtroForm.profissionalSaude.id,
      contratadoId: filtroForm.contratado == null ? null : filtroForm.contratado.id,
      convenioId: filtroForm.convenio == null ? null : filtroForm.convenio.convenioId,
    });

  }

  contratadosByEmpresa() {
    this.spinner.show();
    this.contratadoService.contratadosByEmpresa().subscribe((response) => {

      this.contratados = response;
      this.spinner.hide();
    }, (exception) => {
      this.spinner.hide();
      console.log(exception);
    })
  }

  confirmDeleteGuia(guiaId: number) {
    this.confirmationService.confirm({
      message: `Confirma a exclusão da guia ${guiaId} ?`,
      accept: () => {
        this.deletarGuia(guiaId);
      }
    });
  }

  deletarGuia(guiaId: number) {
    this.spinner.show();
    this.guiaService.deletarGuia(guiaId).subscribe(() => {
      this.spinner.hide();

      this.messageService.add({ severity: 'success', summary: '', detail: "Guia excluída com sucesso!" });
      this.getAllGuias();
    }, (exception) => {
      this.spinner.hide();
      this.messageService.add({ severity: 'error', summary: '', detail: "Erro ao tentar excluir guia, tente novamente ou contacte o suporte." });
    })

  }

  menusGuiaDigitada(guia: Guia) {
    return [
      {
        label: 'Visualizar Guia', command: () => {
          this.gerarRelatorioGuias(guia);
        }
      },
      {
        label: 'Editar', command: () => {
          this.showEditarGuia(guia.id, guia.tipoGuia.id);
        }
      },
      {
        label: 'Excluir', command: () => {
          this.confirmDeleteGuia(guia.id);
        }
      }
    ];
  }

  menusGuiaFaturada(guia: Guia) {
    return [
      {
        label: 'Visualizar Guia', command: () => {
          this.gerarRelatorioGuias(guia);
        }
      }
    ];
  }

  gerarRelatorioGuias(guia: Guia) {
    let parametros = {
      id: guia.id,
      tipoGuiaId: guia.tipoGuia.id,
      espelho: true,
      guiaSadt: guia.guiaSadt
    } as FiltroLancamento;

    this.spinner.show();
    this.relatorioService.gerarRelatorioGuias(parametros).subscribe((file) => {
      this.spinner.hide();
      this.relatorioService.download(file);
    }, (exception: Response) => {
      this.spinner.hide();
      this.messageService.add({ severity: 'success', summary: '', detail: "Nenhum registro encontrado para o período informado." });
    })
  }

  getUrlParamLotesGuia(guia) {
    return btoa(`${guia.lote.numeroLote}&${guia.lote.situacaoLoteId}&${guia.lote.tipoGuiaId}`);
  }
}
