
export class Permissao {
  descricao: string;
  id: number;
}

export enum EPermissao {
  Admin = 'admin',
  Med = 'med',
  Sec = 'sec',
  Fat = 'fat'
}
