import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { LancamentoService } from '../../../../services/lancamento.service';
import { Lancamento } from '../../../../models/lancamento.model';
import { MessageService } from 'primeng/primeng';
import { NgxSpinnerService } from 'ngx-spinner';
import { StatusLancamentoComponent } from '../status-lancamento/status-lancamento.component';

@Component({
  selector: 'app-atualiza-status',
  templateUrl: './atualiza-status.component.html',
  styleUrls: ['./atualiza-status.component.css'],
  providers: [MessageService]
})
export class AtualizaStatusComponent implements OnInit {
  display: boolean;
  lancamentoId: number;
  statusLancamentoId: number;
  submitted: boolean = false;

  @ViewChild(StatusLancamentoComponent) statusLancamentoComponent: StatusLancamentoComponent;

  @Output() status = new EventEmitter();

  constructor(
    private spinner: NgxSpinnerService,
    private messageService: MessageService,
    private lancamentoService: LancamentoService) { }

  ngOnInit() {
  }

  receiveStatus(status) {
    this.statusLancamentoId = status;
  }

  atualizaStatus() {
    this.submitted = true;
    if (!this.statusLancamentoId)
      return false;

    let lancamento = {
      id: this.lancamentoId,
      statusLancamentoId: this.statusLancamentoId
    } as Lancamento;

    this.spinner.show();
    this.lancamentoService.atualizaStatusLancamento(lancamento).subscribe((sucesso: any) => {
      this.display = false;
      this.submitted = false;
      this.spinner.hide();
      this.statusLancamentoComponent.reset();
      this.emit(true, sucesso);
    }, (exception) => {
      this.submitted = false;
      this.spinner.hide();
      this.emit(false, exception);
    })
  }

  emit(isUpdate, message) {
    this.status.emit({ isUpdate, message });
  }

  showDialog(lancamentoId: number) {
    this.lancamentoId = lancamentoId;
    this.display = true;
  }

  closeDialog() {
    this.display = false;
    this.submitted = false;
    this.statusLancamentoComponent.reset();
  }

  validacoes = {
    'campoObrigatorio': [
      { type: 'required', message: 'Este campo é obrigatório.' }
    ]
  };
}
