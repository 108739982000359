import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-agendar-paciente',
  templateUrl: './agendar-paciente.component.html',
  styleUrls: ['./agendar-paciente.component.css']
})
export class AgendarPacienteComponent implements OnInit {

  @ViewChild('content')
  private content: TemplateRef<any>;
  modalRef: BsModalRef;
  
  display: boolean = false;
  ultimosAtendimentos: any[];
  ultimoAtendimentoSelecionado: any;
  closeResult: string;

  constructor(private modalService: BsModalService) { }

  ngOnInit() {
    this.ultimosAtendimentos = [
      {
        id: 1,
        data: "04/02/2021 às 09:37",
        descricao: "Paciente Carlos atendido em 04/02/2021",
        tipoAtendimento: "Retorno",
      },
      {
        id: 2,
        data: "04/02/2021 às 09:37",
        descricao: "Paciente Carlos atendido em 04/02/2021",
        tipoAtendimento: "Primeira Consulta",
      },
      {
        id: 3,
        data: "04/02/2021 às 09:37",
        descricao: "Paciente Carlos atendido em 04/02/2021",
        tipoAtendimento: "Primeira Consulta",
      },
      {
        id: 3,
        data: "04/02/2021 às 09:37",
        descricao: "Paciente Carlos atendido em 04/02/2021",
        tipoAtendimento: "Primeira Consulta",
      },
      {
        id: 3,
        data: "04/02/2021 às 09:37",
        descricao: "Paciente Carlos atendido em 04/02/2021",
        tipoAtendimento: "Primeira Consulta",
      },
      {
        id: 3,
        data: "04/02/2021 às 09:37",
        descricao: "Paciente Carlos atendido em 04/02/2021",
        tipoAtendimento: "Primeira Consulta",
      }
    ]
  }

  open() {
    this.modalRef = this.modalService.show(this.content);
  }

}
