import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { Chart } from '../../../../models/chart.model';

@Component({
  selector: 'app-chart-card-bar',
  templateUrl: './chart-card-bar.component.html',
  styleUrls: ['./chart-card-bar.component.css']
})
export class ChartCardBarComponent implements OnInit {

  bar: any;
  @Input() header: string = "";
  @Input() data: Chart;

  options = {
    title: {
      display: true,
      fontSize: 16
    }
  };

  constructor() { }

  ngOnInit() { this.setDataChart(this.data) }

  ngOnChanges(changes: SimpleChanges) {

    console.log(changes.data.currentValue);
    this.bar = changes.data.currentValue;
  }

  setDataChart(data) {
    this.bar = data;
  }

}
