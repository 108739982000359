
import { Injectable } from '@angular/core';
import { MEDGESTAO_APP_API } from '../app.api';
import { Http } from '@angular/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ErrorHandler } from '../pages/app.error-handler';
import { Tuss } from '../models/tuss.model';

@Injectable()
export class TussService {

    constructor(public http: Http) {
    }

    getTussNumber(filtro: string): Observable<Tuss[]> {
        return this.http.get(`${MEDGESTAO_APP_API}/tuss/number/${filtro}`)
            .pipe(
                map(response => response.json(),
                    catchError(ErrorHandler.handleError)))
    }

    getTussText(filtro: string): Observable<Tuss[]> {
        return this.http.get(`${MEDGESTAO_APP_API}/tuss/text/${filtro}`)
            .pipe(
                map(response => response.json(),
                    catchError(ErrorHandler.handleError)))
    }


}
