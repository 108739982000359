import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { Dialog, MessageService, SelectItem, DynamicDialogRef, DynamicDialogConfig } from 'primeng/primeng';
import { LancamentoService } from '../../services/lancamento.service';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router, ActivatedRoute, Route } from '@angular/router';
import { Lancamento } from '../../models/lancamento.model';
import { Guia } from '../../models/guia.model';
import { GuiaService } from '../../services/guia.service';
import { ComunsService } from 'src/app/comuns/comuns.service';
import { ETipoGuia } from 'src/app/enum/enums';
import { ProfissionalConselho } from 'src/app/models/profissional-conselho.model';
import { BeneficiarioService } from '../../services/beneficiario.service';
import { LancamentoTuss } from '../../models/lancamento-tuss.model';
import { GuiaCirurgiaSelectHospitalComponent } from './guia-cirurgia-select-hospital/guia-cirurgia-select-hospital.component';
import { Beneficiario } from '../../models/beneficiario.model';
import { Hospital } from '../../models/hospital.model';
import { GuiaTuss } from '../../models/guia-tuss.model';
import { DatePipe } from '@angular/common';
import * as _ from 'lodash';

@Component({
  selector: 'app-guia-cirurgia',
  templateUrl: './guia-cirurgia.component.html',
  styleUrls: ['./guia-cirurgia.component.css'],
  providers: [MessageService]
})
export class GuiaCirurgiaComponent implements OnInit {

  @ViewChild(GuiaCirurgiaSelectHospitalComponent) guiaCirurgiaSelectHospitalComponent: GuiaCirurgiaSelectHospitalComponent;
  @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();
  desabilitaCheckSadt: boolean = false;

  display: boolean = false;
  datePipe = new DatePipe('pt-BR');
  viewProcedimento: LancamentoTuss;
  campoSenhaIsReadonly: boolean = false;
  isDadosProcedimento: boolean = false;
  submitted: boolean = false;
  img_convenio: string = "";
  lancamento: Lancamento;
  conselhos: ProfissionalConselho[];
  menusBotaoAcoes: any;
  tiposTabela: SelectItem[];
  selectAtendimentoRN: SelectItem[] = [
    { label: 'Sim', value: true },
    { label: 'Não', value: false },
  ];
  caraterAtendimento: SelectItem[];
  tipoAtendimentoGuia: SelectItem[];

  navigation = "";
  guia_cirurgia_form: FormGroup;

  constructor(private messageService: MessageService,
    private beneficiarioService: BeneficiarioService,
    private comunsService: ComunsService,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private lancamentoService: LancamentoService,
    private guiaService: GuiaService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig) {
    this.form(null);
  }

  get lancamentoTuss(): FormArray {
    return this.guia_cirurgia_form.get('lancamentoTuss') as FormArray;
  }

  form(formValue: Lancamento) {
    this.guia_cirurgia_form = this.formBuilder.group({
      hospital: this.formBuilder.group({
        id: [(formValue && formValue.hospital.id) ? formValue.hospital.id : 0],
        nome: [formValue && formValue.hospital.nome, Validators.required],
        codigoOperadora: [formValue && formValue.hospital.codigoOperadora, Validators.required],
        cnes: [formValue && formValue.hospital.cnes, Validators.required],
      }),
      beneficiario: this.formBuilder.group({
        id: [(formValue && formValue.beneficiario.id) ? formValue.beneficiario.id : 0],
        numeroCarteira: [formValue && formValue.beneficiario.numeroCarteira, Validators.required],
        validadeCarteira: [formValue && formValue.beneficiario.validadeCarteira ? this.datePipe.transform(formValue.beneficiario.validadeCarteira, "dd/MM/yyyy") : '', Validators.required],
        nome: [formValue && formValue.beneficiario.nome, Validators.required],
        cns: [formValue && formValue.beneficiario.cns],
        convenioId: [formValue && formValue.convenio.id]
      }),
      contratado: this.formBuilder.group({
        id: [formValue && formValue.contratado.id],
        cpfCnpj: [formValue && formValue.contratado.cpfCnpj],
        razaoSocial: [formValue && formValue.contratado.razaoSocial],
        cnes: [formValue && formValue.contratado.cnes]
      }),
      convenio: this.formBuilder.group({
        id: [formValue && formValue.convenio.id],
        registroans: [formValue && formValue.convenio.registroans],
        logoConvenioBASE64: [formValue && formValue.convenio.logoConvenioBASE64],
        guiaPadraoSadt: [formValue && formValue.convenio.guiaPadraoSadt]
      }),
      profissionalSaude: this.formBuilder.group({
        profissionalSaudeNome: [formValue && formValue.profissionalSaude.profissionalSaudeNome],
        especialidade: this.formBuilder.group({
          id: [formValue && formValue.profissionalSaude.especialidade.id],
          cbo: [formValue && formValue.profissionalSaude.especialidade.cbo]
        }),
        conselho: [(this.conselhos && this.conselhos.length == 1) ? this.conselhos[0] : null, Validators.required], //Se tiver apenas um registro, form já é preenchido.
        numeroConselho: [null],
        uf: [null]
      }),
      guia: this.formBuilder.group({
        id: [(formValue && formValue.guiaId) ? formValue.guiaId : 0],
        tipoGuiaId: [ETipoGuia.honorario, Validators.required],
        guiaSadt: [(formValue && formValue.guiaSadt) == null ? false : formValue.guiaSadt],
        numeroOperadora: [formValue && formValue.numeroOperadora],
        numeroSolicitacaoInternacao: [formValue && formValue.numeroSolicitacaoInternacao],
        numeroSenha: [formValue && formValue.numeroSenha],
        lancamento: this.formBuilder.group({
          id: [formValue && formValue.lancamentoId, Validators.required],
          conselhoId: [formValue && formValue.conselhoId, Validators.required]
        }),
        dataInicioFaturamento: [formValue && formValue.dataInicioFaturamento ? this.datePipe.transform(formValue.dataInicioFaturamento, "dd/MM/yyyy") : '', Validators.required],
        dataFimFaturamento: [formValue && formValue.dataFimFaturamento ? this.datePipe.transform(formValue.dataFimFaturamento, "dd/MM/yyyy") : '', Validators.required],
        observacao: [formValue && formValue.observacao],
        dataAtendimento: [formValue && this.datePipe.transform(formValue.dataAtendimento, "dd/MM/yyyy"), Validators.required],
        atendimentoRn: [formValue && formValue.atendimentoRn, Validators.required],

        tipoAtendimentoGuiaId: [(formValue && formValue.convenio && formValue.convenio.guiaPadraoSadt && formValue.tipoAtendimentoGuiaId) ? formValue.tipoAtendimentoGuiaId : null],
        caraterAtendimentoId: [(formValue && formValue.convenio && formValue.convenio.guiaPadraoSadt && formValue.caraterAtendimentoId) ? formValue.caraterAtendimentoId : null],

      }),
      lancamentoTuss: this.formBuilder.array([]),
      vlrTotalLancamento: [formValue && formValue.vlrTotalLancamento]
    })

    if (this.conselhos)
      this.setEstadoENumeroConselho();

    if (formValue != null && (formValue.convenio.guiaPadraoSadt || formValue.guiaSadt)) {
      if (formValue.convenio.guiaPadraoSadt)
        this.desabilitaCheckSadt = true;

      this.setValueCaraterETipoAtendimento(formValue.tipoAtendimentoGuiaId, formValue.caraterAtendimentoId);
    } else {
      this.desabilitaCheckSadt = false;
    }
  }

  ngOnInit() {

  }

  getLancamentoParaGerarGuia(id) {
    this.spinner.show();
    this.lancamentoService.getLancamentoParaGerarGuia(id).subscribe((response) => {
      this.conselhos = response.profissionalSaude.profissionalConselho;
      this.img_convenio = response.convenio.logoConvenioBASE64;
      this.form(response);
      response.lancamentoTuss.forEach((tuss) => {
        (this.lancamentoTuss as FormArray).push(this.formBuilder.group(tuss));
      })
      this.spinner.hide();
    }, (exception) => {
      this.spinner.hide();
      console.log(exception);
    })
  }

  getGuiaPorId(guiaId: number) {
    this.spinner.show();
    this.guiaService.getGuiaPorId(guiaId).subscribe((response) => {

      this.conselhos = response.profissionalSaude.profissionalConselho;
      this.img_convenio = response.convenio.logoConvenioBASE64;
      this.form(response);
      response.lancamentoTuss.forEach((tuss) => {
        (this.lancamentoTuss as FormArray).push(this.formBuilder.group(tuss));
      })

      this.spinner.hide();
    }, (exception) => {
      this.spinner.hide();
      this.messageService.add({ severity: 'error', summary: '', detail: exception });
    });
  }

  showDialog() {
    this.display = true;
  }

  // receiveDataInicial(dtLancamentoInicial) {
  //   this.guia_cirurgia_form.get('guia').patchValue({
  //     dataInicioFaturamento: dtLancamentoInicial
  //   });
  // }

  // receiveDataFinal(dtLancamentoFinal) {
  //   this.guia_cirurgia_form.get('guia').patchValue({
  //     dataFimFaturamento: dtLancamentoFinal
  //   });
  // }

  closeDialog() {
    this.display = false;
  }

  validacoes = {
    'campoObrigatorio': [
      { type: 'required', message: 'Este campo é obrigatório.' }
    ]
  };

  criaOuAtualizaGuia() {

    this.submitted = true;
    if (this.guia_cirurgia_form.invalid) {
      this.messageService.add({ severity: 'error', summary: '', detail: 'Existe campos obrigatórios não preenchidos.' });
      return;
    }

    if (this.lancamentoTuss.value.filter((f) => f.data == null || f.data == "").length > 0) {
      this.messageService.add({ severity: 'error', summary: '', detail: 'Campo 16-Data é obrigatório.' });
      return;
    }

    if (this.lancamentoTuss.value.filter((f) => f.hrInicial == null || f.hrInicial == "").length > 0) {
      this.messageService.add({ severity: 'error', summary: '', detail: 'Campo 17-Hora Inicial é obrigatório.' });
      return;
    }

    if (this.lancamentoTuss.value.filter((f) => f.hrFinal == null || f.hrFinal == "").length > 0) {
      this.messageService.add({ severity: 'error', summary: '', detail: 'Campo 18-Hora Final é obrigatório.' });
      return;
    }

    let guia = this.guia_cirurgia_form.get('guia').value as Guia;
    this.setBeneficiario(guia);
    this.setHospital(guia);
    this.setGuiaTuss(guia);
    guia.dataInicioFaturamento = this.comunsService.transformDate(guia.dataInicioFaturamento);
    guia.dataFimFaturamento = this.comunsService.transformDate(guia.dataFimFaturamento);
    guia.dataAtendimento = this.comunsService.transformDate(guia.dataAtendimento);

    if (guia.id > 0) {
      this.atualizarGuia(guia);
    } else {
      this.criarGuia(guia);
    }
  }

  preencherSenha(checked) {
    if (checked) {
      this.campoSenhaIsReadonly = true;
      this.guia_cirurgia_form.get('guia').patchValue({
        numeroSenha: this.guia_cirurgia_form.get('guia').get("numeroSolicitacaoInternacao").value,
        numeroOperadora: this.guia_cirurgia_form.get('guia').get("numeroSolicitacaoInternacao").value
      })
    } else {
      this.campoSenhaIsReadonly = false;
    }
  }

  setEstadoENumeroConselho() {
    let conselho = this.guia_cirurgia_form.get('profissionalSaude').get('conselho').value as ProfissionalConselho;
    if (conselho) {
      let builderProfissionalSaude = this.guia_cirurgia_form.get('profissionalSaude');
      this.setConselhoNoLancamento(conselho);
      builderProfissionalSaude.patchValue({
        numeroConselho: conselho.numeroConselho,
        uf: conselho.estado.sigla
      });
    }
  }

  setConselhoNoLancamento(profissionalConselho: ProfissionalConselho) {
    let builderLancamento = this.guia_cirurgia_form.get('guia').get('lancamento');
    builderLancamento.patchValue({
      conselhoId: profissionalConselho.conselho.id
    });
  }

  getBeneficiario(numeroCarteira: string) {
    if (numeroCarteira) {
      this.spinner.show();

      this.beneficiarioService.getBeneficiarioByNumeroCarteira(numeroCarteira).subscribe((response) => {
        let beneficiarioForm = this.guia_cirurgia_form.get('beneficiario') as FormGroup;
        beneficiarioForm.patchValue({
          id: response.id ? response.id : 0,
          numeroCarteira: response.numeroCarteira,
          validadeCarteira: response && response.validadeCarteira ? this.datePipe.transform(response.validadeCarteira, "dd/MM/yyyy") : '',
          nome: response.nome,
          cns: response.cns,
          convenioId: response.convenioId
        });
        this.spinner.hide();

      }, (exception) => {
        this.spinner.hide();
        this.messageService.add({ severity: 'info', summary: '', detail: exception });
      })
    }
  }

  getTiposTabela() {
    this.guiaService.getTiposTabela().subscribe((response) => {
      this.tiposTabela = this.comunsService.toSelectItem(response);
    }, (exception) => {
      console.log(exception);
    })
  }

  showDialogSelectHospital() {
    this.guiaCirurgiaSelectHospitalComponent.showDialog();
  }

  setHospital(guia: Guia) {
    guia.hospital = this.guia_cirurgia_form.get('hospital').value as Hospital;
  }

  setBeneficiario(guia: Guia) {
    guia.beneficiario = this.guia_cirurgia_form.get('beneficiario').value as Beneficiario;
    guia.beneficiario.validadeCarteira = this.comunsService.transformDate(guia.beneficiario.validadeCarteira);
  }

  setGuiaTuss(guia: Guia) {
    guia.guiaTuss = [];
    (this.lancamentoTuss.value as LancamentoTuss[]).map((lancamento, index) => {
      var guiaTuss: GuiaTuss = {
        data: this.comunsService.transformDate(lancamento.data),
        hrFinal: this.datePipe.transform(this.comunsService.transformHour(lancamento.hrFinal), "HH:mm"),
        hrInicial: this.datePipe.transform(this.comunsService.transformHour(lancamento.hrInicial), "HH:mm"),
        tipoTabelaId: lancamento.tipoTabelaId,
        lancamentoTussId: lancamento.lancamentoTussId,
        id: lancamento.guiaTussId ? lancamento.guiaTussId : 0,
        guiaId: lancamento.guiaId ? lancamento.guiaId : 0,
      };
      guia.guiaTuss.push(guiaTuss);
    });

  }

  getHospitalSelecionado(hospital: Hospital) {
    this.guia_cirurgia_form.get('hospital').patchValue({
      id: hospital.id,
      nome: hospital.nome,
      codigoOperadora: hospital.codigoOperadora,
      cnes: hospital.cnes
    });
  }

  limparHospital() {
    this.guia_cirurgia_form.get('hospital').reset();
  }

  trackByFn(index: any, item: any) {
    return index;
  }

  preencherDataProcedimento(checked) {
    var dataInicioFaturamento = this.guia_cirurgia_form.get('guia').value.dataInicioFaturamento;
    if (checked && !_.isNull(dataInicioFaturamento) && !_.isEmpty(dataInicioFaturamento)) {
      for (let index = 0; index < this.lancamentoTuss.value.length; index++) {
        const element = this.lancamentoTuss.controls[index];
        element.patchValue({
          data: dataInicioFaturamento
        })
      }
    } else {
    }
  }

  carregaRegistros(navigation: string, code) {
    if (navigation === '/lancamentos-faturamento') {
      this.getLancamentoParaGerarGuia(code);
    }
    if (navigation === '/guias') {
      this.getGuiaPorId(code);
    }
  }

  atualizarGuia(guia: Guia) {
    this.spinner.show();
    this.guiaService.atualizarGuiaCirurgia(guia).subscribe(() => {
      this.submitted = false;
      this.spinner.hide();
      this.messageService.add({ severity: 'success', summary: '', detail: "Guia atualizada com sucesso!" });

      this.voltar(guia);
    }, (exception) => {
      this.submitted = false;
      this.spinner.hide();
      this.messageService.add({ severity: 'error', summary: '', detail: 'Erro ao atualizar guia! Tente novamente ou entre em contato com suporte.' });
    })
  }

  criarGuia(guia: Guia) {
    this.spinner.show();
    this.guiaService.gerarGuiaCirurgia(guia).subscribe((guiaId: number) => {
      this.submitted = false;
      this.spinner.hide();
      this.messageService.add({ severity: 'success', summary: '', detail: "Guia enviada com sucesso!" });

      guia.id = guiaId;
      this.voltar(guia);
    }, (exception) => {
      this.submitted = false;
      this.spinner.hide();
      this.messageService.add({ severity: 'error', summary: '', detail: 'Erro ao salvar nova guia! Tente novamente ou entre em contato com suporte.' });
      console.log(exception);
    })
  }

  voltar(guia?: Guia) {
    this.display = false;
    this.modalSave.emit(guia);
  }

  showDialogGuiaCirurgia(guiaId?: number, lancamentId?: number) {
    this.display = true;
    this.getTiposTabela();
    this.getCaraterAtendimento();
    this.getTipoAtendimentoGuia();

    if (lancamentId != null && lancamentId > 0) {
      this.getLancamentoParaGerarGuia(lancamentId);
    }
    if (guiaId != null && guiaId > 0) {
      this.getGuiaPorId(guiaId);
    }
  }

  showDialogMaximized(dialog: Dialog) {
    this.comunsService.showDialogMaximized(dialog);
  }

  getCaraterAtendimento() {
    this.guiaService.getCaraterAtendimento().subscribe((response) => {
      this.caraterAtendimento = this.comunsService.toSelectItem(response);
    }, (exception) => {
      console.log(exception);
    })
  }

  getTipoAtendimentoGuia() {
    this.guiaService.getTipoAtendimentoGuia().subscribe((response) => {
      this.tipoAtendimentoGuia = this.comunsService.toSelectItem(response);
    }, (exception) => {
      console.log(exception);
    })
  }

  setValueCaraterETipoAtendimento(tipoAtendimentoGuiaId?: any, caraterAtendimentoId?: any) {
    this.guia_cirurgia_form.get('guia').patchValue({
      tipoAtendimentoGuiaId: !tipoAtendimentoGuiaId ? 2 : tipoAtendimentoGuiaId,
      caraterAtendimentoId: !caraterAtendimentoId ? 1 : caraterAtendimentoId,
      guiaSadt: true
    });
  }

  habilitaSadt(checked) {
    if (checked) {
      this.setValueCaraterETipoAtendimento(null, null);
    } else {
      this.guia_cirurgia_form.get('guia').patchValue({
        tipoAtendimentoGuiaId: null,
        caraterAtendimentoId: null
      });
    }
  }
}
