import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Dropdown } from '../../../../models/dropdown.model';
import { Contratado } from '../../../../models/contratado.model';
import { ContratadoService } from '../../../../services/contratado.service';
import { FiltroContratado } from '../../../../models/filtro-contratado.model';
import { ContratadoConvenio } from '../../../../models/contratado-convenio.model';

@Component({
  selector: 'app-contratado-drop',
  templateUrl: './contratado-drop.component.html',
  styleUrls: ['./contratado-drop.component.css']
})
export class ContratadoDropComponent implements OnInit {

  contratadoSelecionado: Contratado;
  contratados: Contratado[] = [];
  convenios: ContratadoConvenio[];

  @Output() contratado = new EventEmitter();
  @Input() contratadoEdit;
  @Input() readonly: boolean = false;
  @Input() isRequest: boolean = true;
  @Input() labelObrigatorio: boolean;
  
  constructor(private contratadoService: ContratadoService) { }

  ngOnInit() {

    if (this.isRequest)
      this.contratadosByEmpresa();
  }

  contratadosByEmpresa() {
    this.contratadoService.contratadosByEmpresa().subscribe((response) => {
      this.contratados = response;
    }, (exception) => {
      console.log(exception);
    })
  }

  ngOnAfterViewInit() {
    this.contratadoSelecionado = this.contratadoEdit;
  }

  emit() {
    this.contratado.emit(this.contratadoSelecionado);
  }

  reset() {
    this.contratadoSelecionado = null;
  }

  setContratado(contratado: Contratado) {
    if (contratado) {
      let list: Contratado[] = [];
      list.push(contratado);
      this.contratados = list;
      this.contratadoSelecionado = contratado;
    }
  }
}
