import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { GrauParticipacaoService } from '../../../../services/grauParticipacao.service';
import { GrauParticipacao } from '../../../../models/grau-participacao.model';

@Component({
  selector: 'app-grau-participacao',
  templateUrl: './grau-participacao.component.html',
  styleUrls: ['./grau-participacao.component.css']
})
export class GrauParticipacaoComponent implements OnInit {

  grauPartSelecionado: GrauParticipacao;
  grausParticipacao: GrauParticipacao[];

  @Output() grauParticipacao = new EventEmitter();
  @Input() grauParticipacaoEdit;

  constructor(private grauParticipacaoService: GrauParticipacaoService) { }

  ngOnInit() {
    this.getGrausParticipacao();
  }

  set(grauParticipacaoEdit) {
    this.grauPartSelecionado = grauParticipacaoEdit;
  }

  getGrausParticipacao() {
    this.grauParticipacaoService.getGrausParticipacao().subscribe((graus) => {
      this.grausParticipacao = graus;
    });
  }

  emit() {
    this.grauParticipacao.emit(this.grauPartSelecionado);
  }

  reset() {
    this.grauPartSelecionado = null;
  }
}
