import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MEDGESTAO_API } from '../app.api';
import { map, catchError } from 'rxjs/operators';
import { ErrorHandler } from '../pages/app.error-handler';
import { RequestOptions, ResponseContentType, Http } from '@angular/http';
import { BehaviorSubject } from 'rxjs';
import { HorariosAgendaProfissionalSaude } from '../models/horariosAgendaProfissionalSaude.model';
import { TipoAgendamento } from '../models/tipo-agendamento.model';
import { SituacaoAgendamento } from '../models/situacao-agendamento.model';
import { Agendamento } from 'src/app/models/agendamento.model';
import { Dropdown } from '../models/dropdown.model';
import { Situacao } from '../models/situacaoGuia.model';
import { FiltroAgenda } from '../models/filtro-agenda.model';

@Injectable({
    providedIn: 'root'
})
export class AgendaService {

    private dataSource = new BehaviorSubject(JSON.parse(localStorage.getItem('diaAgendaCorrente')));
    diaAgendaCorrente = this.dataSource.asObservable();

    constructor(public httpClient: HttpClient, public http: Http) {

    }

    salvarDiaAgendaCorrente(diaAgendaCorrente) {
        localStorage.setItem('diaAgendaCorrente', JSON.stringify(diaAgendaCorrente));
        this.dataSource.next(diaAgendaCorrente);
    }

    buscarHorariosAgendaProfissionalSaude(parametros: HorariosAgendaProfissionalSaude) {
        return this.httpClient.post(`${MEDGESTAO_API}/agenda/GetHorariosAgendaProfissionalSaude`, parametros)
            .pipe(
                map(response => { return response as Array<Agendamento> },
                    catchError(ErrorHandler.handleError)))
    }

    filtrarAgenda(parametros: FiltroAgenda) {
        return this.httpClient.post(`${MEDGESTAO_API}/agenda/FiltrarAgenda`, parametros)
            .pipe(
                map(response => { return response as Array<Agendamento> },
                    catchError(ErrorHandler.handleError)))
    }

    createAgendamento(agendamento: Agendamento) {
        return this.httpClient.post(`${MEDGESTAO_API}/agenda/CreateAgendamento`, agendamento)
            .pipe(
                map(response => { return response as Agendamento },
                    catchError(ErrorHandler.handleError)))
    }

    getAllTipoAgendamento() {
        return this.httpClient.get(`${MEDGESTAO_API}/agenda/GetAllTipoAgendamento`)
            .pipe(
                map(response => { return response as Array<TipoAgendamento> },
                    catchError(ErrorHandler.handleError)))
    }

    getAllSituacaoAgendamento() {
        return this.httpClient.get(`${MEDGESTAO_API}/agenda/GetAllSituacaoAgendamento`)
            .pipe(
                map(response => { return response as Array<SituacaoAgendamento> },
                    catchError(ErrorHandler.handleError)))
    }

    getAgendamentoById(agendamentoId: number) {
        return this.httpClient.get(`${MEDGESTAO_API}/agenda/GetAgendamentoById/${agendamentoId}`)
            .pipe(
                map(response => { return response as Agendamento },
                    catchError(ErrorHandler.handleError)))
    }

    deleteAgendamento(agendamentoId: number) {
        return this.httpClient.get(`${MEDGESTAO_API}/agenda/DeleteAgendamento/${agendamentoId}`)
            .pipe(
                map(response => { return response },
                    catchError(ErrorHandler.handleError)))
    }

    removeAgendamentoRecorrente(agendamentoId: number) {
        return this.httpClient.get(`${MEDGESTAO_API}/agenda/DeleteAgendamentoRecorrente/${agendamentoId}`)
            .pipe(
                map(response => { return response },
                    catchError(ErrorHandler.handleError)))
    }

    updateAgendamento(agendamento: Agendamento) {
        return this.httpClient.post(`${MEDGESTAO_API}/agenda/UpdateAgendamento`, agendamento)
            .pipe(
                map(response => { response as boolean },
                    catchError(ErrorHandler.handleError)))
    }

    updateSituacaoAgendamento(agendamento: Agendamento) {
        return this.httpClient.post(`${MEDGESTAO_API}/agenda/UpdateSituacaoAgendamento`, agendamento)
            .pipe(
                map(response => { response as boolean },
                    catchError(ErrorHandler.handleError)))
    }
}