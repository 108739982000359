import { PacienteComponent } from './paciente/paciente.component';
import { Component, OnInit } from '@angular/core';
import { ConfirmationService, SelectItem, MessageService, MenuItem, LazyLoadEvent, Dialog } from 'primeng/primeng';
import {  ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormGroup, FormBuilder, Validators } from '@angular/forms'; 
import { Paciente } from '../../models/paciente.model';
import { FiltroPaciente } from '../../models/filtro-paciente.model';
import { PacienteService } from '../../services/paciente.service';
import { Dropdown } from '../../models/dropdown.model';
import { ConvenioService } from '../../services/convenio.service';
import { Paginacao } from '../../models/paginacao.model';
import { Table } from 'primeng/table';

@Component({
  selector: 'app-pacientes',
  templateUrl: './pacientes.component.html',
  styleUrls: ['./pacientes.component.css'],
  providers: [ConfirmationService, MessageService]
})
export class PacientesComponent implements OnInit {
  @ViewChild('dt') dt: Table;
  @ViewChild(PacienteComponent) pacienteComponent: PacienteComponent;

  filtro_form: FormGroup;
  pacientes:Array<Paciente>;
  pacientesData:Array<FiltroPaciente>;
  convenios: Dropdown[];
  pacienteData: Paciente;

  totalRecords: number;
  first: number = 0;

  constructor(
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder,
    private pacienteService: PacienteService,
    private convenioService: ConvenioService,
    private router: Router,
    private messageService: MessageService) {
  }

  ngOnInit() {
    this.form();
    this.getTodosConvenios();
  }

  form() {
    this.filtro_form = this.formBuilder.group({
      nome: [null],
      cpf: [null],
      convenioId: [null]
    })
  }

  novoPaciente(){
    this.pacienteComponent.showDialogPaciente(null);
  }

  getAllPacientes(paginacao?: LazyLoadEvent){
    this.spinner.show();
    this.setValueFiltro(this.filtro_form.value);
    let paciente = this.filtro_form.value as FiltroPaciente;

    var pag = new Paginacao();

    if (paginacao == null) this.dt.first = 0;
    pag.setPaginacao(paciente, paginacao);


    this.pacienteService.getAllPacientes(paciente).subscribe((response: any) => {
      this.spinner.hide();
      this.pacientesData = response.pacientes;
      this.totalRecords = response.totalCount;
    }, (exception) => {
      this.spinner.hide();
      this.messageService.add({ severity: 'error', summary: '', detail: exception });
    })
  }
  getTodosConvenios() {
    this.convenioService.getTodosConvenios().subscribe((convenios) => {
      this.convenios = convenios;
    });
  }

  setValueFiltro(filtroForm) {
    this.filtro_form.patchValue({
      nome: filtroForm.nome == null ? null : filtroForm.nome,
      cpf: filtroForm.cpf == null ? null : filtroForm.cpf,
      convenioId: filtroForm.convenioId == null ? null : filtroForm.convenioId,
    });
  }

  editar(pacienteId: number){
    this.pacienteService.getPacienteById(pacienteId).subscribe((response: Paciente) => {
      this.spinner.hide();
      this.pacienteData = response;
      this.pacienteComponent.showDialogPaciente(this.pacienteData);
    }, (exception) => {
      this.spinner.hide();
      this.messageService.add({ severity: 'error', summary: '', detail: exception });
    })
  }
}
