import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ComunsService } from '../../../../comuns/comuns.service';

@Component({
  selector: 'app-intervalo-data',
  templateUrl: './intervalo-data.component.html',
  styleUrls: ['./intervalo-data.component.css']
})
export class IntervaloDataComponent implements OnInit {

  ptBR: any;
  dtInicialSelecionado: any;
  dtFinalSelecionado: any;

  @Input() labelNome: String;
  @Input() labelObrigatorio: boolean;
  @Input() labelAte: String;
  @Output() dtInicialEmit = new EventEmitter();
  @Output() dtFinalEmit = new EventEmitter();

  constructor(private comunsService: ComunsService) { }

  ngOnInit() {
    this.locale();
  }

  setDtInicial(dtInicial) {
    this.dtInicialSelecionado = dtInicial;
  }

  setDtFinal(dtFinal) {
    this.dtFinalSelecionado = dtFinal;
  }

  emitDtInicial() {
    this.dtInicialEmit.emit(this.dtInicialSelecionado);
  }

  emitDtFinal() {
    this.dtFinalEmit.emit(this.dtFinalSelecionado);
  }

  locale() {
    this.ptBR = this.comunsService.locale();
  }
}
