import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { MEDGESTAO_API } from '../app.api';
import { LancamentoPendente } from '../models/lancamentoPendente.model';
import { ErrorHandler } from '../pages/app.error-handler';
import { HttpClient } from '@angular/common/http';
import { Http } from '@angular/http';
import { TussValorProcedimento } from '../models/tuss-valor-procedimento.model';
import { Lancamento } from '../models/lancamento.model';
import { TipoVia } from '../models/tipo-via.model';
import { TipoLancamento } from '../models/tipo-lancamento.model';
import { FiltroLancamento } from '../models/filtro-lancamento.model';
import { Dropdown } from '../models/dropdown.model';
import { Guia } from '../models/guia.model';
import { TipoPagamento } from '../models/tipo-pagamento.model';
import { ProcedimentoParticular } from '../models/procedimentoParticular.model';

@Injectable({
  providedIn: 'root'
})
export class LancamentoService {

  public lancamentos: LancamentoPendente[];

  constructor(public httpClient: HttpClient) {
  }

  getAllLancamentos(parametros: FiltroLancamento) {
    return this.httpClient.post(`${MEDGESTAO_API}/lancamento/lancamentos`, parametros)
      .pipe(
        map(response => response,
          catchError(ErrorHandler.handleError)))
  }

  getLancamentoById(id: number) {
    return this.httpClient.get(`${MEDGESTAO_API}/lancamento/GetLancamentoById/${id}`)
      .pipe(
        map(response => { return response as Lancamento },
          catchError(ErrorHandler.handleError)))
  }

  getLancamentoParticularById(id: number) {
    return this.httpClient.get(`${MEDGESTAO_API}/lancamento/GetLancamentoParticularById/${id}`)
      .pipe(
        map(response => { return response as Lancamento },
          catchError(ErrorHandler.handleError)))
  }

  getAllLancamentosParaFaturamento(parametros: FiltroLancamento) {
    return this.httpClient.post(`${MEDGESTAO_API}/lancamento/GetAllLancamentosParaFaturamento`, parametros)
      .pipe(
        map(response => { return response },
          catchError(ErrorHandler.handleError)))
  }

  atualizaStatusLancamento(lancamento: Lancamento) {
    return this.httpClient.post(`${MEDGESTAO_API}/lancamento/AtualizaStatusLancamento`, lancamento)
      .pipe(
        map(response => { return response },
          catchError(ErrorHandler.handleError)))
  }

  getTiposLancamento() {
    return this.httpClient.get(`${MEDGESTAO_API}/lancamento/TipoLancamento`)
      .pipe(
        map(response => { return response as TipoLancamento[] },
          catchError(ErrorHandler.handleError)))
  }

  getTiposPagamento() {
    return this.httpClient.get(`${MEDGESTAO_API}/lancamento/TipoPagamento`)
      .pipe(
        map(response => { return response as TipoPagamento[] },
          catchError(ErrorHandler.handleError)))
  }

  getTiposViaLancamento() {
    return this.httpClient.get(`${MEDGESTAO_API}/lancamento/LancamentoVia`)
      .pipe(
        map(response => { return response as TipoVia[] },
          catchError(ErrorHandler.handleError)))
  }

  getFaturamentoAtualLancamento() {
    return this.httpClient.get(`${MEDGESTAO_API}/lancamento/GetFaturamentoAtualLancamento`)
      .pipe(
        map(response => { return response },
          catchError(ErrorHandler.handleError)))
  }

  ObterValorProcedimento(procedimento: TussValorProcedimento) {
    return this.httpClient.post(`${MEDGESTAO_API}/lancamento/ObterValorProcedimento`, procedimento)
      .pipe(
        map(response => { return response },
          catchError(ErrorHandler.handleError)))
  }

  criarLancamento(lancamento: Lancamento) {
    return this.httpClient.post(`${MEDGESTAO_API}/lancamento`, lancamento)
      .pipe(
        map(response => { return response as number },
          catchError(ErrorHandler.handleError)))
  }

  deleteLancamento(lancamentoId: number) {
    return this.httpClient.get(`${MEDGESTAO_API}/lancamento/DeleteLancamento/${lancamentoId}`)
      .pipe(
        map(response => { return response },
          catchError(ErrorHandler.handleError)))
  }

  atualizarLancamento(lancamento: Lancamento) {
    return this.httpClient.post(`${MEDGESTAO_API}/lancamento/UpdateLancamento`, lancamento)
      .pipe(
        map(response => { return response },
          catchError(ErrorHandler.handleError)))
  }

  atualizarLancamentoParticular(lancamento: Lancamento) {
    return this.httpClient.post(`${MEDGESTAO_API}/lancamento/UpdateLancamentoParticular`, lancamento)
      .pipe(
        map(response => { return response },
          catchError(ErrorHandler.handleError)))
  }

  lancamentosByEmpresa(parametros: FiltroLancamento) {
    return this.httpClient.post(`${MEDGESTAO_API}/lancamento/LancamentosByEmpresa`, parametros)
      .pipe(
        map(response => { return response as Lancamento[] },
          catchError(ErrorHandler.handleError)))
  }

  getAllStatusLancamento() {
    return this.httpClient.get(`${MEDGESTAO_API}/lancamento/GetAllStatusLancamento`)
      .pipe(
        map(response => { return response as Dropdown[] },
          catchError(ErrorHandler.handleError)))
  }

  getLancamentoParaGerarGuia(id: number) {
    return this.httpClient.get(`${MEDGESTAO_API}/lancamento/LancamentoParaGerarGuia/${id}`)
      .pipe(
        map(response => { return response as Lancamento },
          catchError(ErrorHandler.handleError)))
  }

  getTipoAtendimentoByLancamento(id: number) {
    return this.httpClient.get(`${MEDGESTAO_API}/lancamento/TipoAtendimentoByLancamento/${id}`)
      .pipe(
        map(response => { return response as Lancamento },
          catchError(ErrorHandler.handleError)))
  }


  getProcedimentosParticularByProfissionalId(profissionalId: number, procedimento: string) {
    return this.httpClient.get(`${MEDGESTAO_API}/lancamento/GetProcedimentosParticularByProfissionalId/${profissionalId}/${procedimento}`)
      .pipe(
        map(response => { return response as ProcedimentoParticular[] },
          catchError(ErrorHandler.handleError)))
  }

  novoProcedimentoParticular(parametros: ProcedimentoParticular) {
    return this.httpClient.post(`${MEDGESTAO_API}/lancamento/NovoProcedimentoParticular`, parametros)
      .pipe(
        map(response => { return response as ProcedimentoParticular },
          catchError(ErrorHandler.handleError)))
  }

  faturarAutomatico(parametros: Array<Lancamento>){
    return this.httpClient.post(`${MEDGESTAO_API}/lancamento/FaturarAutomatico`, parametros)
      .pipe(
        map(response => { return response },
          catchError(ErrorHandler.handleError)))
  }
}
