import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { Contratado } from '../../../models/contratado.model';
import { ProfissionalSaude } from '../../../models/profissionalSaude.model';
import { TipoPagamentoComponent } from '../../componentes/modulo-componentes/tipo-pagamento/tipo-pagamento.component';
import { TipoPagamento } from '../../../models/tipo-pagamento.model';
import { Lancamento } from '../../../models/lancamento.model';
import { TipoLancamento } from '../../../models/tipo-lancamento.model';
import { MessageService, FileUpload, Dialog } from 'primeng/primeng';
import { NgxSpinnerService } from 'ngx-spinner';
import { LancamentoService } from '../../../services/lancamento.service';
import { ContratadoDropComponent } from '../../componentes/modulo-componentes/contratado/contratado-drop.component';
import { TipoLancamentoComponent } from '../../componentes/modulo-componentes/tipo-lancamento/tipo-lancamento.component';
import { AnexoLancamento } from '../../../models/anexoLancamento.model';
import { AnexoLancamentoService } from '../../../services/anexoLancamento.service';
import { EGrauParticipacao, ETipoAtendimento, EFormaPagamento } from 'src/app/enum/enums';
import { Router, ActivatedRoute } from '@angular/router';
import { ComunsService } from '../../../comuns/comuns.service';
import { TussComponent } from '../../componentes/modulo-componentes/tuss/tuss.component';
import { AuthenticationService } from '../../../services/authentication.service';
import { EPermissao } from '../../../models/permissao.model';
import { ContratadoService } from '../../../services/contratado.service';
import * as _ from 'lodash';
import { ProcedimentoParticular } from '../../../models/procedimentoParticular.model';
import { NovoProcedimentoParticularComponent } from './novo-procedimento-particular/novo-procedimento-particular.component';
import { PacienteService } from 'src/app/services/paciente.service';
import { Paciente } from 'src/app/models/paciente.model';

@Component({
  selector: 'app-lancamento-particular',
  templateUrl: './lancamento-particular.component.html',
  styleUrls: ['./lancamento-particular.component.css'],
  providers: [MessageService]
})
export class LancamentoParticularComponent implements OnInit {

  @ViewChild(TipoPagamentoComponent) tipoPagamentoComponent: TipoPagamentoComponent;
  @ViewChild(TipoLancamentoComponent) tipoLancamentoComponent: TipoLancamentoComponent;
  @ViewChild(NovoProcedimentoParticularComponent) novoProcedimentoComponent: NovoProcedimentoParticularComponent;
  @ViewChild('fileInput') fileInput: FileUpload;
  @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();

  submitted = false;
  submittedGetProcedimento = false;
  display: boolean = false;
  lancamentoParticularForm: FormGroup;
  datePipe = new DatePipe('pt-BR');
  dataLancamento: Date;

  profissionais: Array<ProfissionalSaude>;
  contratados: Array<Contratado>;
  procedimentos: Array<ProcedimentoParticular>;

  pacientes: Array<Paciente>;


  @ViewChild(TussComponent) tussComponent: TussComponent;

  get pagamentos() { return <FormArray>this.lancamentoParticularForm.get('pagamentos'); }
  get lancamentoTuss() { return <FormArray>this.lancamentoParticularForm.get('lancamentoTuss'); }
  get procedimentosForm() { return <FormArray>this.lancamentoParticularForm.get('lancamentoProcedimentoParticular'); }

  // formaPagamentoCartaoCredito: boolean = false;
  submittedIncluirPag: boolean = false;

  constructor(private formBuilder: FormBuilder,
    private lancamentoService: LancamentoService,
    private anexoLancamentoService: AnexoLancamentoService,
    private messageService: MessageService,
    private comunsService: ComunsService,
    private router: ActivatedRoute,
    private routerNavigation: Router,
    private auth: AuthenticationService,
    private contratadoService: ContratadoService,
    private pacienteService: PacienteService,
    private spinner: NgxSpinnerService) {
    this.dataLancamento = new Date();
    this.form(null);
  }

  ngOnInit() {
  }

  form(formValue: Lancamento) {
    this.lancamentoParticularForm = this.formBuilder.group({
      id: [formValue && formValue.id ? formValue.id : 0],
      pacienteId: [formValue && formValue.pacienteId, Validators.required],
      nomePaciente: [formValue && formValue.nomePaciente, Validators.required],
      dataLancamento: [(formValue && formValue.dataLancamento) ? this.datePipe.transform(formValue.dataLancamento, "dd/MM/yyyy HH:mm", "pt-BR")
        : this.datePipe.transform(this.dataLancamento, "dd/MM/yyyy HH:mm", "pt-BR"), Validators.required],
      dataProcedimento: [formValue && this.datePipe.transform(formValue.dataProcedimento, "dd/MM/yyyy"), Validators.required],
      grauParticipacaoId: [EGrauParticipacao.atendimentoClinica, Validators.required],
      contratadoId: [formValue && formValue.contratado && formValue.contratado.id],
      profissionalSaudeId: [formValue && formValue.profissionalSaude && formValue.profissionalSaude.id],
      tipoAtendimentoId: [ETipoAtendimento.particular],
      anotacoes: [formValue && formValue.anotacoes],
      vlrTotalLancamento: [formValue && formValue.vlrTotalLancamento, Validators.required],
      tipoLancamentoId: [formValue && formValue.tipoLancamento && formValue.tipoLancamento.id, Validators.required],
      contratadoForm: this.formBuilder.group({
        contratado: [null, Validators.required],
        profissionalSaude: [null, Validators.required]
      }),

      pagamentos: this.formBuilder.array([]),
      lancamentoTuss: this.formBuilder.array([]),
      lancamentoProcedimentoParticular: this.formBuilder.array([]),

      pagamento: this.formBuilder.group({
        total: [null],
        tipoPagamentoId: [null],
        formaPagamento: this.formBuilder.group({
          id: [null],
          tipo: [null],
        }),
        qtdParcelasCartao: [null],
        vlrParcelaCartao: [null],
        dtPrevisaoVenc: [null],
      }),

      campoProcedimento: [null],
    })
  }

  validacoes = {
    'campoObrigatorio': [
      { type: 'required', message: 'Este campo é obrigatório.' }
    ]
  };

  receiveTipoPagamento(tipoPagamento: TipoPagamento) {
    // this.showCamposPagamentoCartao(tipoPagamento);
    var form = (<FormGroup>this.lancamentoParticularForm.controls['pagamento']);

    form.patchValue({
      formaPagamento: tipoPagamento,
      tipoPagamentoId: tipoPagamento && tipoPagamento.id
    });
  }

  private showCamposPagamentoCartao(tipoPagamento: TipoPagamento) {
    if (tipoPagamento != null && tipoPagamento.id == EFormaPagamento.cartaoCredito) {
      // this.formaPagamentoCartaoCredito = true;
      this.aplicarFormValidation('qtdParcelasCartao');
      this.aplicarFormValidation('dtPrevisaoVenc');
    }
    else {
      // this.formaPagamentoCartaoCredito = false;
      this.removerFormValidation('qtdParcelasCartao');
      this.removerFormValidation('dtPrevisaoVenc');
    }
  }

  receiveContratado(contratado: Contratado) {
    var form = (<FormGroup>this.lancamentoParticularForm.controls['contratadoForm']);

    if (contratado) {
      this.profissionais = contratado.profissionalSaude;
    }
    else {
      form.reset();
      this.profissionais = [];
    }
  }

  receiveTipoLancamento(tipoLancamento: TipoLancamento) {
    this.lancamentoParticularForm.patchValue({
      tipoLancamentoId: tipoLancamento && tipoLancamento.id
    });
  }

  onSubmit() {
    this.submitted = true;
    var contratadoConvenioForm = (<FormGroup>this.lancamentoParticularForm.controls['contratadoForm']).value;
    let lancamentoCreate = this.lancamentoParticularForm.value as Lancamento;
    this.setValueLancamento(lancamentoCreate, contratadoConvenioForm);

    console.log(lancamentoCreate)
    if (this.lancamentoParticularForm.invalid) {
      this.messageService.add({ severity: 'error', summary: '', detail: 'Existem campos obrigatórios não preenchidos.' });
      return;
    }

    if (lancamentoCreate.vlrTotalLancamento == 0) {
      this.messageService.add({ severity: 'error', summary: '', detail: 'Não é permitido lançamento com valor igual a 0.' });
      return;
    }

    if (this.fileInput && this.fileInput.files.length == 0) {
      // this.spinner.show();
      if (lancamentoCreate.id) {
        this.atualizarLancamento(lancamentoCreate);
      } else {
        this.criarLancamento(lancamentoCreate);
      }
    }
    else
      this.onUploadGuias(lancamentoCreate);
  }

  setValueLancamento(lancamentoCreate, contratadoConvenioForm) {
    lancamentoCreate.dataLancamento = this.datePipe.transform(this.dataLancamento, "MM/dd/yyyy HH:mm", "pt-BR");
    // lancamentoCreate.dataProcedimento = this.datePipe.transform(lancamentoCreate.dataProcedimento, "MM/dd/yyyy HH:mm", "pt-BR");
    lancamentoCreate.dataProcedimento = this.comunsService.transformDate(lancamentoCreate.dataProcedimento);
    lancamentoCreate.contratadoId = contratadoConvenioForm.contratado == null ? null : contratadoConvenioForm.contratado.id;
    lancamentoCreate.profissionalSaudeId = contratadoConvenioForm.profissionalSaude == null ? null : contratadoConvenioForm.profissionalSaude.id;
  }

  private criarLancamento(lancamento: Lancamento) {

    this.lancamentoService.criarLancamento(lancamento).subscribe((success) => {
      this.limparFormLancamento();
      this.submitted = false;
      this.spinner.hide();
      this.messageService.add({ severity: 'success', summary: '', detail: 'Lançamento enviado com sucesso.' });
      this.voltar();
      this.modalSave.emit(null);

      // setTimeout(() => {
      //   this.routerNavigation.navigate(['/lancamentos-faturamento']);
      // }, 1000);
    }, (exception) => {
      this.spinner.hide();
      this.messageService.add({ severity: 'error', summary: '', detail: exception });
    })
  }

  private atualizarLancamento(lancamento: Lancamento) {
    this.spinner.show();

    this.lancamentoService.atualizarLancamentoParticular(lancamento).subscribe((success) => {
      this.limparFormLancamento();
      this.submitted = false;
      this.spinner.hide();
      this.messageService.add({ severity: 'success', summary: '', detail: 'Lançamento enviado com sucesso.' });

      this.voltar();
      this.modalSave.emit(null);

      // setTimeout(() => {
      //   this.routerNavigation.navigate(['/lancamentos-faturamento']);
      // }, 1000);
    }, (exception) => {
      this.spinner.hide();
      this.messageService.add({ severity: 'error', summary: '', detail: exception });
    })
  }

  onUploadGuias(lancamentoCreate: Lancamento) {

    this.spinner.show();
    const formData = new FormData();
    for (let file of this.fileInput.files) {
      var blob = new Blob([file], { type: "application/pdf" });
      formData.append('anexo' + file.name.replace('.pdf', ''), blob, file.name);
    }

    this.anexoLancamentoService.uploadArquivosLancamento(formData).subscribe((listaAnexosId: Array<AnexoLancamento>) => {
      lancamentoCreate.arquivoLancamentoAnexo = listaAnexosId;
      this.criarLancamento(lancamentoCreate);
    }, (exception) => {
      this.spinner.hide();
      this.messageService.add({ severity: 'error', summary: '', detail: exception });
    })
  }

  public limparFormLancamento() {
    var form = (<FormGroup>this.lancamentoParticularForm.controls['contratadoForm']);

    form.reset();
    this.profissionais = [];
    this.fileInput.clear();
    this.lancamentoParticularForm.reset();
    this.tipoLancamentoComponent.reset();
    this.tipoPagamentoComponent.reset();
    this.dataLancamento = new Date();
    this.submittedGetProcedimento = false;
    this.lancamentoParticularForm.patchValue({
      vlrTotalLancamento: null,
      tipoAtendimentoId: ETipoAtendimento.particular,
      grauParticipacaoId: EGrauParticipacao.atendimentoClinica,
      dataLancamento: this.datePipe.transform(this.dataLancamento, "dd/MM/yyyy HH:mm", "pt-BR")
    })
  }

  incluirPagamento() {
    var form = (<FormGroup>this.lancamentoParticularForm.controls['pagamento']);
    this.submittedIncluirPag = true;
    this.aplicarFormValidation('qtdParcelasCartao');
    this.aplicarFormValidation('dtPrevisaoVenc');
    
    if (form.invalid) {
      this.messageService.add({ severity: 'error', summary: '', detail: 'Existem campos obrigatórios não preenchidos.' });
      return;
    }

    if (parseInt(form.value['qtdParcelasCartao']) > 0) {      
      if (form.invalid) {
        this.messageService.add({ severity: 'error', summary: '', detail: 'Existem campos obrigatórios não preenchidos.' });
        return;
      }

      this.ratearParcelaPagamentoCartaoCredito().then(() => {
        this.messageService.add({ severity: 'success', summary: '', detail: 'Pagamento incluido com sucesso!' });
        this.clearFormPagamento();
      });
    } else {
      this.removerFormValidation('qtdParcelasCartao');
      this.removerFormValidation('dtPrevisaoVenc');

      this.pushpagamentos().then(() => {
        this.messageService.add({ severity: 'success', summary: '', detail: 'Pagamento incluido com sucesso!' });
        this.clearFormPagamento();
      });
    }
    this.calculaTotalLancamento();
  }

  private clearFormPagamento() {
    this.clearFormIncluirPagamento();
    this.submittedIncluirPag = false;
    // this.formaPagamentoCartaoCredito = false;
    this.removerFormValidation("tipoPagamentoId");
    this.removerFormValidation('qtdParcelasCartao');
    this.removerFormValidation('dtPrevisaoVenc');
  }

  pushpagamentos() {
    return new Promise((resolve, reject) => {
      try {
        (this.pagamentos as FormArray).push(this.formBuilder.group({
          vlrPagamento: this.lancamentoParticularForm.get("pagamento").value["total"],
          formaPagamento: this.lancamentoParticularForm.get("pagamento").value['formaPagamento'],
          tipoPagamentoId: this.lancamentoParticularForm.get("pagamento").value['tipoPagamentoId'],

          qtdParcelasCartao: (!_.isNull(this.lancamentoParticularForm.get("pagamento").value['qtdParcelasCartao']) &&
            !_.isEmpty(this.lancamentoParticularForm.get("pagamento").value['qtdParcelasCartao'])) ? this.lancamentoParticularForm.get("pagamento").value['qtdParcelasCartao'] : 0,

          numParcelaCartao: 0,
          vlrParcelaCartao: this.lancamentoParticularForm.get("pagamento").value['vlrParcelaCartao'],
          dtPrevisaoVenc: this.lancamentoParticularForm.get("pagamento").value['dtPrevisaoVenc']
        }));
        resolve(this.pagamentos);
      } catch (exception) {
        reject(exception);
      }
    });
  }

  ratearParcelaPagamentoCartaoCredito() {
    return new Promise((resolve, reject) => {
      try {

        var form_pagamento = (<FormGroup>this.lancamentoParticularForm.controls['pagamento']);
        var dtPrevisaoVenc = this.comunsService.transformDateWithMaskNewDate(this.lancamentoParticularForm.get("pagamento").value['dtPrevisaoVenc']);

        for (let index = 1; index <= parseInt(form_pagamento.value['qtdParcelasCartao']); index++) {
          var mes = dtPrevisaoVenc;

          (this.pagamentos as FormArray).push(this.formBuilder.group({
            vlrPagamento: this.lancamentoParticularForm.get("pagamento").value["total"],
            formaPagamento: this.lancamentoParticularForm.get("pagamento").value['formaPagamento'],
            tipoPagamentoId: this.lancamentoParticularForm.get("pagamento").value['tipoPagamentoId'],
            qtdParcelasCartao: this.lancamentoParticularForm.get("pagamento").value['qtdParcelasCartao'],
            numParcelaCartao: index,
            vlrParcelaCartao: this.lancamentoParticularForm.get("pagamento").value['vlrParcelaCartao'],
            dtPrevisaoVenc: JSON.parse(JSON.stringify(mes)) // cria clone da data, sem o clone variavel recebe sempre o ultimo valor.
          }));

          dtPrevisaoVenc.setMonth(mes.getMonth() + 1);
        }

        resolve(this.pagamentos);
      } catch (exception) {
        reject(exception);
      }
    });
  }

  clearFormIncluirPagamento() {
    var formIncluirPagamento = (<FormGroup>this.lancamentoParticularForm.controls['pagamento']);
    formIncluirPagamento.reset();
    this.tipoPagamentoComponent.reset();

    formIncluirPagamento.patchValue({
      total: 0
    })
  }

  private aplicarFormValidation(control: string) {
    this.lancamentoParticularForm.get('pagamento').get(control).setValidators(Validators.required);
    this.lancamentoParticularForm.get('pagamento').get(control).updateValueAndValidity();
  }

  private removerFormValidation(control: string) {
    this.lancamentoParticularForm.get('pagamento').get(control).clearValidators();
    this.lancamentoParticularForm.get('pagamento').get(control).updateValueAndValidity();
  }

  calcularVlParcelaCartao() {

    var form_pagamento = (<FormGroup>this.lancamentoParticularForm.controls['pagamento']);
    var form_vl_lancamento = form_pagamento.value['total'];

    if (form_pagamento.value['qtdParcelasCartao'] > 0 && form_vl_lancamento > 0) {
      form_pagamento.patchValue({
        vlrParcelaCartao: (form_vl_lancamento / form_pagamento.value['qtdParcelasCartao'])
      });
    } else {
      form_pagamento.patchValue({
        vlrParcelaCartao: 0
      });
    }

  }

  calculaTotalLancamento() {
    var sum = (this.pagamentos as FormArray).value.reduce((acc, cur) => {
      var total = 0;

      if (cur.qtdParcelasCartao > 0) {
        total = acc + Number(cur.vlrParcelaCartao);
      } else {
        total = acc + Number(cur.vlrPagamento);
      }

      return total;

    }, 0);

    this.lancamentoParticularForm.patchValue({
      vlrTotalLancamento: sum,
    })
  }


  showDialogLancamentoParticular(lancamentoId?: number) {
    this.limparFormLancamento();
    this.form(null);
    this.display = true;
    this.submitted = false;
    this.contratadosByEmpresa(lancamentoId);
  }

  showDialogMaximized(dialog: Dialog) {
    this.comunsService.showDialogMaximized(dialog);
  }

  excluirPagamento(index) {
    (this.pagamentos as FormArray).removeAt(index);
    this.calculaTotalLancamento();
    this.messageService.add({ severity: 'success', summary: '', detail: 'Pagamento removido com sucesso!' });
  }

  voltar() {
    this.display = false;
  }

  getLancamentoById(lancamentoId: number) {
    this.spinner.show();
    this.lancamentoService.getLancamentoParticularById(lancamentoId).subscribe((lancamento: Lancamento) => {
      if (lancamento) {
        this.form(lancamento);
        this.dataLancamento = new Date(lancamento.dataLancamento);

        var form = (<FormGroup>this.lancamentoParticularForm.controls['contratadoForm']);
        let contratadoSelecionado = this.contratados.find(c => c.id == lancamento.contratado.id);
        this.tipoLancamentoComponent.set(lancamento.tipoLancamento);

        this.receiveContratado(contratadoSelecionado);
        this.receiveTipoLancamento(lancamento.tipoLancamento);
        form.patchValue({
          contratado: contratadoSelecionado,
          profissionalSaude: this.profissionais.find(p => p.id == lancamento.profissionalSaude.id)
        });
        lancamento.lancamentoProcedimentoParticular.forEach((procedimento, index) => {
          this.incluirProcedimento(procedimento);
        });
        lancamento.lancamentoPagamento.forEach((pagamento, index) => {
          (this.pagamentos as FormArray).push(this.formBuilder.group({
            id: pagamento.pagamento.id,
            vlrPagamento: pagamento.pagamento.vlrPagamento,
            formaPagamento: pagamento.pagamento.tipoPagamento,
            tipoPagamentoId: pagamento.pagamento.tipoPagamento.id,
            qtdParcelasCartao: pagamento.pagamento.qtdParcelasCartao,
            numParcelaCartao: pagamento.pagamento.numParcelaCartao,
            vlrParcelaCartao: pagamento.pagamento.vlrParcelaCartao,
            dtPrevisaoVenc: pagamento.pagamento.dtPrevisaoVenc
          }));
        });
        this.spinner.hide();

      }
    }, (exception) => {
      this.spinner.hide();
      console.log(exception);
    })
  }

  contratadosByEmpresa(lancamentoId?: number) {
    this.spinner.show();
    this.contratadoService.contratadosByEmpresa().subscribe((response) => {

      this.contratados = response;
      this.setContratadoDefault();
      this.spinner.hide();

      // this.getParamLancamento();
      if (lancamentoId > 0)
        this.getLancamentoById(lancamentoId);
      else {
        var form = (<FormGroup>this.lancamentoParticularForm);
        this.dataLancamento = new Date();
        form.patchValue({
          dataLancamento: this.datePipe.transform(this.dataLancamento, "dd/MM/yyyy HH:mm", "pt-BR")
        });
      }

    }, (exception) => {
      this.spinner.hide();
      console.log(exception);
    })
  }

  setContratadoDefault() {
    if (this.auth.canViewPermissaoUsuario({ roles: [EPermissao.Sec] })) {
      var form = (<FormGroup>this.lancamentoParticularForm.controls['contratadoForm']);
      this.receiveContratado(this.contratados[0]);
      form.patchValue({
        contratado: this.contratados[0]
      });
    }
  }

  getProcedimento(event) {
    this.submittedGetProcedimento = false;
    let procedimento = event.query;
    var contratadoConvenioForm = (<FormGroup>this.lancamentoParticularForm.controls['contratadoForm']).value;

    if (procedimento && procedimento.length >= 3) {
      this.lancamentoService.getProcedimentosParticularByProfissionalId(contratadoConvenioForm.profissionalSaude.id,
        procedimento).subscribe(procedimentos => {
          this.submittedGetProcedimento = true;
          this.procedimentos = procedimentos
        }, (ex) => {

        });
    }
  }

  incluirProcedimento(novoProcedimento: ProcedimentoParticular) {
    this.submittedGetProcedimento = false;
    this.lancamentoParticularForm.patchValue({
      campoProcedimento: null
    });
    (this.procedimentosForm as FormArray).push(this.formBuilder.group({
      lancamentoId: (!_.isNull(novoProcedimento.lancamentoId) && !_.isEmpty(novoProcedimento.lancamentoId)) ? novoProcedimento.lancamentoId : 0,
      procedimentoParticularId: novoProcedimento.id,
      procedimento: novoProcedimento.procedimento
    }));
  }

  showNovoProcedimento() {
    var contratadoConvenioForm = (<FormGroup>this.lancamentoParticularForm.controls['contratadoForm']).value;

    if (contratadoConvenioForm != null && contratadoConvenioForm.profissionalSaude != null)
      this.novoProcedimentoComponent.showDialogNovoProcedimento(contratadoConvenioForm.profissionalSaude.id);
  }

  setProcedimentoSelecionado(novoProcedimento: ProcedimentoParticular) {
    this.incluirProcedimento(novoProcedimento);
  }

  excluirProcedimento(index) {
    (this.procedimentosForm as FormArray).removeAt(index);
    this.messageService.add({ severity: 'success', summary: '', detail: 'Procedimento removido com sucesso!' });
  }

   //AUTOCOMPLETE DO PACIENTE
   getPaciente(event) {
    // Atraso de 300 milissegundos (ou o valor que você preferir)
    const debounce = 300;
    var contratadoConvenioForm = (<FormGroup>this.lancamentoParticularForm.controls['contratadoForm']).value;

    // Use o RxJS para aplicar o debounceTime e evitar consultas desnecessárias
    const consultaPaciente = this.pacienteService.getPacienteByName(contratadoConvenioForm.profissionalSaude.id, event.query || 'todos');

    consultaPaciente.pipe(
      debounceTime(debounce),
      distinctUntilChanged(),
      switchMap((result) => {
        this.pacientes = result;
        this.spinner.hide();
        return result;
      })
    ).subscribe(
      () => { },
      (exception) => {
        this.spinner.hide();
      }
    );
  }

  setPaciente(paciente) {

    if (paciente) {
      this.lancamentoParticularForm.patchValue({
        pacienteId: paciente.id,
        nomePaciente: paciente.beneficiario.nome
      });
    }
  }

}
