import { Component, OnInit } from '@angular/core';
import { UsuarioService } from '../../../services/usuario.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Usuario } from '../../../models/usuario.model';
import { EmpresaService } from '../../../services/empresa.service';
import { Permissao } from '../../../models/permissao.model';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { SelectItem, MessageService, ConfirmationService } from 'primeng/primeng';
import { ComunsService } from 'src/app/comuns/comuns.service';

@Component({
  selector: 'app-usuario',
  templateUrl: './usuario.component.html',
  styleUrls: ['./usuario.component.css'],
  providers: [MessageService, ConfirmationService]
})
export class UsuarioComponent implements OnInit {

  permissoes: Array<Permissao>;
  usuarioForm: FormGroup;
  usuario: Usuario;
  submitted = false;

  constructor(private formBuilder: FormBuilder,
    private usuarioService: UsuarioService,
    private router: ActivatedRoute,
    private routerNavigate: Router,
    private messageService: MessageService,
    private spinner: NgxSpinnerService,
    private comunsService: ComunsService,
    private empresaService: EmpresaService,
    private confirmationService: ConfirmationService) {
    this.usuario = {} as Usuario;
    this.router
      .queryParams
      .subscribe(params => {
        this.usuario.id = params['code'];

        if (this.usuario.id)
          this.findById(this.usuario.id);
      });
  }

  ngOnInit() {
    this.form(null);
    this.findAllPermissoes();
  }

  form(valueForm: Usuario) {

    if (this.usuario.id == null) {
      this.usuario.id = 0;
    }

    this.usuarioForm = this.formBuilder.group({
      id: [this.usuario && this.usuario.id],
      email: [valueForm && valueForm.email, Validators.required],
      password: [valueForm && valueForm.password, Validators.required],
      nomePessoa: [valueForm && valueForm.nomePessoa, Validators.required],
      permissaoId: [valueForm && valueForm.permissaoId, Validators.required],
    });
  }

  updateUsuario(usuario: Usuario) {
    this.spinner.show();
    this.empresaService.updateUsuarioEmpresa(usuario).subscribe((response) => {
      this.spinner.hide();
      this.messageService.add({ severity: 'success', summary: '', detail: 'Registro alterado com sucesso!' });
      this.routerNavigate.navigate(['/usuarios']);
      this.submitted = false;
    }, (exception) => {
      this.submitted = false;
      this.spinner.hide();
      this.messageService.add({ severity: 'error', summary: '', detail: exception });
    })
  }

  createUsuario(usuario: Usuario) {
    this.spinner.show();
    this.empresaService.createUsuarioEmpresa(usuario).subscribe((response) => {
      this.submitted = false;
      this.messageService.add({ severity: 'success', summary: '', detail: 'Registro criado com sucesso!' });
      this.spinner.hide();

      this.voltar();
    }, (ex) => {
      this.spinner.hide();
      this.submitted = false;
      this.messageService.add({ severity: 'error', summary: '', detail: ex.Message ||  "Erro ao criar registro, tente novamente ou entre em contato com suporte."});
    })
  }

  salvarUsuario() {
    // if (this.usuarioForm.invalid)
    //   return false;
    this.submitted = true;

    if (this.usuarioForm.invalid) {
      this.messageService.add({ severity: 'error', summary: '', detail: 'Existem campos obrigatórios não preenchidos.' });
      return;
    }

    let usuario = this.usuarioForm.value as Usuario;
    this.usuario.permissaoId = usuario.permissaoId.id as number;
    if (this.usuario.id > 0) {
      this.updateUsuario(usuario);
    } else {
      this.createUsuario(usuario);
    }
  }

  voltar() {
    this.routerNavigate.navigate(['/usuarios']);
  }

  findAllPermissoes() {
    this.usuarioService.permissoes().subscribe((response) => {
      this.permissoes = this.comunsService.toSelectItem(response);
    }, (exception) => {
      console.log(exception);
    })
  }

  findById(id: number) {
    this.spinner.show();
    this.usuarioService.usuario(id).subscribe((response) => {
      this.form(response);
      this.removerFormValidation('password');
      this.spinner.hide();
    }, (exception) => {
      this.spinner.hide();
      this.messageService.add({ severity: 'error', summary: '', detail: exception });
    })
  }

  private removerFormValidation(control: string) {
    this.usuarioForm.get(control).clearValidators();
    this.usuarioForm.get(control).updateValueAndValidity();
  }

  validacoes = {
    'campoObrigatorio': [
      { type: 'required', message: 'Este campo é obrigatório.' }
    ]
  };

}
