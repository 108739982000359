import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { MenuItem } from 'primeng/primeng';
import { BreadcrumbService } from '../services/breadcrumb.service';
import { AuthenticationService } from '../services/authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'app';
  overlayMenuActive: boolean;
  staticMenuDesktopInactive: boolean;
  staticMenuMobileActive: boolean;
  topbarMenuActive: boolean;
  topbarItemClick: boolean;
  rotateMenuButton: boolean = true;
  crumbs$: Observable<MenuItem[]>;
  isAuthenticated: boolean = false;

  constructor(private breadcrumb: BreadcrumbService,
    private router: Router,
    private auth: AuthenticationService) {
    this.crumbs$ = this.breadcrumb.crumbs$;
    
    
    this.auth.currentUser.subscribe((user) => { if (user) this.isAuthenticated = true })
  }

  ngOnInit() {
    console.log(this.router.url);
  }

  onMenuButtonClick(event) {

    this.rotateMenuButton = !this.rotateMenuButton;
    this.topbarMenuActive = false;

    if (this.isDesktop()) {
      this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
    } else {
      this.staticMenuMobileActive = !this.staticMenuMobileActive;
    }

    event.preventDefault();
  }

  isDesktop() {
    return window.innerWidth > 1024;
  }
}
