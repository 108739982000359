import { Injectable } from '@angular/core';
import { MEDGESTAO_API } from '../app.api';
import { Http } from '@angular/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Dropdown } from '../models/dropdown.model';
import { ErrorHandler } from '../pages/app.error-handler';
import { CbphmPorte } from '../models/cbhpmPorte.model';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class PorteService {

    constructor(public http: HttpClient) {
    }

    getNomePortes(): Observable<Dropdown[]> {
        return this.http.get(`${MEDGESTAO_API}/porte/getNomePortes`)
            .pipe(
                map(response => { return response as Dropdown[] },
                    catchError(ErrorHandler.handleError)))
    }

    getPortes(porteId: number): Observable<CbphmPorte[]> {
        return this.http.get(`${MEDGESTAO_API}/porte/getCbhpmPorte/${porteId}`)
            .pipe(
                map(response => { return response as CbphmPorte[] },
                    catchError(ErrorHandler.handleError)))
    }

}
