import { Component, Input, OnInit } from '@angular/core';
import { trigger, state, transition, style, animate } from '@angular/animations';
import { MenuItem } from 'primeng/primeng';
import { AppComponent } from '../app.component';
import { AuthenticationService } from '../../services/authentication.service';
import { debugOutputAstAsTypeScript } from '@angular/compiler';
import { MenuMedGestao } from '../../models/menu-medgestao.model';
import { async } from '@angular/core/testing';
import { EPermissao } from '../../models/permissao.model';
import { Usuario } from '../../models/usuario.model';

@Component({
  selector: 'app-menu',
  template: `
        <ul app-submenu [item]="model" root="true" class="layout-menu" visible="true"></ul>
    `
})
export class MenuComponent implements OnInit {

  model: MenuMedGestao[];
  usuario: Usuario;

  constructor(private auth: AuthenticationService) {
    this.usuario = this.auth.currentUserValue.user;
  }

  ngOnInit() {
    this.model = [
      { label: 'Gráficos', icon: 'fa fa-area-chart', routerLink: ['/home'], visible: this.auth.canViewPermissaoUsuario({ roles: [EPermissao.Admin, EPermissao.Fat, EPermissao.Med] }) },
      // { label: 'Lançamentos', icon: 'fa fa-tags', routerLink: ['/lancamentos'], visible: this.auth.canViewPermissaoUsuario({ roles: [EPermissao.Admin, EPermissao.Sec] }) },
      // {
      //   label: 'Recepção', icon: 'fa fa-calendar-check-o', visible: true,
      //   items: [
      //     {  },
      //   ]
      // },
      { label: 'Agenda', icon: 'fa fa-tags', routerLink: ['/agenda'], visible: this.auth.canViewPermissaoUsuario({ roles: [ EPermissao.Med, EPermissao.Sec] }) },
      // {
      //   label: 'Agenda', icon: 'fa fa-calendar-check-o', visible: true, routerLink: ['/agendamentos']
      // },
      {
        label: 'Pacientes', icon: 'fa fa-users', visible: true, routerLink: ['/pacientes']
      },
      {
        label: 'Faturamento', icon: 'fa fa-calendar-check-o', visible: true,
        items: [
          { label: 'Lançamentos', icon: 'fa fa-tags', routerLink: ['/lancamentos-faturamento'], visible: true },
          { label: 'Guias', icon: 'fa fa-file-text-o', routerLink: ['/guias'], visible: this.auth.canViewPermissaoUsuario({ roles: [EPermissao.Admin, EPermissao.Fat] }) },
          { label: 'Lotes', icon: 'fa fa-cubes', routerLink: ['/lotes'], visible: this.auth.canViewPermissaoUsuario({ roles: [EPermissao.Admin, EPermissao.Fat] }) },
        ]
      },

      {
        label: 'Tabelas', icon: 'fa fa-table', visible: this.auth.canViewPermissaoUsuario({ roles: [EPermissao.Admin, EPermissao.Fat, EPermissao.Med] }),
        items: [
          { label: 'Procedimentos', icon: 'fa fa-heartbeat', visible: this.auth.canViewPermissaoUsuario({ roles: [EPermissao.Admin, EPermissao.Fat, EPermissao.Med] }), routerLink: ['tabela-convenio'] },
          { label: 'Portes', icon: 'fa fa-product-hunt', visible: this.auth.canViewPermissaoUsuario({ roles: [EPermissao.Admin, EPermissao.Fat, EPermissao.Med] }), routerLink: ['tabela-porte'] },
        ]
      },
      {
        label: 'Relatórios', icon: 'fa fa-line-chart', visible: this.auth.canViewPermissaoUsuario({ roles: [EPermissao.Admin, EPermissao.Med, EPermissao.Sec, EPermissao.Fat] }),
        items: [
          { label: 'Lançamentos', icon: 'fa fa-list', visible: this.auth.canViewPermissaoUsuario({ roles: [EPermissao.Admin, EPermissao.Med, EPermissao.Sec, EPermissao.Fat] }), routerLink: ['/relatorio-lancamento'] },
          { label: 'Guias', icon: 'fa fa-list-alt', visible: this.auth.canViewPermissaoUsuario({ roles: [EPermissao.Admin, EPermissao.Med, EPermissao.Fat] }), routerLink: ['/relatorio-guia'] },
          { label: 'Faturamento Direto', icon: 'fa fa-bar-chart', visible: this.auth.canViewPermissaoUsuario({ roles: [EPermissao.Admin, EPermissao.Med, EPermissao.Fat] }), routerLink: ['/relatorio-faturamento-direto'] },
        ]
      },
      {
        label: 'Cadastros', icon: 'fa fa-folder-o', visible: this.auth.canViewPermissaoUsuario({ roles: [EPermissao.Admin, EPermissao.Sec] }),
        items: [
          { label: 'Contratados', icon: 'fa fa-hospital-o', visible: this.auth.canViewPermissaoUsuario({ roles: [EPermissao.Admin] }), routerLink: ['/contratados'] },
          // { label: 'Operadoras (Convênios)', icon: 'fa fa-list-alt', routerLink: ['/operadoras'], visible: true },
          { label: 'Profissionais Executantes', icon: 'fa fa-user-md', routerLink: ['/profissionais-executantes'], visible: this.auth.canViewPermissaoUsuario({ roles: [EPermissao.Admin, EPermissao.Sec] }) },
          { label: 'Usuários', icon: 'pi pi-users', visible: this.auth.canViewPermissaoUsuario({ roles: [EPermissao.Admin] }), routerLink: ['/usuarios'] },
          { label: 'Atualizar cadastro da Empresa', icon: '', visible: this.auth.canViewPermissaoUsuario({ roles: [EPermissao.Admin] }), routerLink: ['/empresa'] },
        ]
      },
      // {
      //   label: 'Configurações', icon: 'fa fa-cogs', visible: this.auth.canViewPermissaoUsuario({ roles: [EPermissao.Admin] }),
      //   items: [
      //     { label: 'Tiss', icon: 'fa fa-archive', visible: this.auth.canViewPermissaoUsuario({ roles: [EPermissao.Admin] }), routerLink: ['configuracao-tiss'] },
      //   ]
      // },
    ];
  }



}

@Component({
  /* tslint:disable:component-selector */
  selector: '[app-submenu]',
  /* tslint:enable:component-selector */
  template: `
      <ng-template ngFor let-child let-i="index" [ngForOf]="(root ? item : item.items)">
          <li *ngIf="child.visible == true" [ngClass]="{'active-menuitem': isActive(i)}" [class]="child.badgeStyleClass">
              <a *ngIf="!child.routerLink" [href]="child.url||'#'" (click)="itemClick($event,child,i)"
                  [attr.tabindex]="!visible ? '-1' : null"  [attr.target]="child.target">
                  <i class="fa fa-fw" [ngClass]="child.icon"></i>
                  <span>{{child.label}}</span>
                  <span class="menuitem-badge" *ngIf="child.badge">{{child.badge}}</span>
                  <i class="fa fa-fw fa-angle-down layout-menuitem-toggler" *ngIf="child.items"></i>
              </a>
              <a *ngIf="child.routerLink" (click)="itemClick($event,child,i)" [attr.target]="child.target"
                  [routerLink]="child.routerLink" routerLinkActive="active-menuitem-routerlink"
                  [routerLinkActiveOptions]="{exact: true}">
                  <i class="fa fa-fw" [ngClass]="child.icon"></i>
                  <span>{{child.label}}</span>
                  <span class="menuitem-badge" *ngIf="child.badge">{{child.badge}}</span>
                  <i class="fa fa-fw fa-angle-down layout-menuitem-toggler" *ngIf="child.items"></i>
              </a>
              <ul app-submenu [item]="child" *ngIf="child.items" [@children]="isActive(i) ? 'visible' : 'hidden'" ></ul>
          </li>
      </ng-template>
    `,
  animations: [
    trigger('children', [
      state('hidden', style({
        height: '0px'
      })),
      state('visible', style({
        height: '*'
      })),
      transition('visible => hidden', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
      transition('hidden => visible', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
    ])
  ]
})
export class SubMenuComponent {

  @Input() item: MenuItem;

  @Input() root: boolean;

  @Input() visible: boolean;

  activeIndex: number = 0;

  constructor(public app: AppComponent) { }

  itemClick(event: Event, item: MenuItem, index: number) {
    if (item.disabled) {
      event.preventDefault();
      return true;
    }

    this.activeIndex = (this.activeIndex === index) ? null : index;

    // execute command
    if (item.command) {
      item.command({ originalEvent: event, item: item });
    }

    // prevent hash change
    if (item.items || (!item.url && !item.routerLink)) {
      event.preventDefault();
    }

    if (!item.items) {
      this.app.staticMenuMobileActive = false;
    }
  }

  isActive(index: number): boolean {
    return this.activeIndex === index;
  }
}



// {
//   label: 'Components', icon: 'fa fa-fw fa-sitemap',
//   items: [
//     { label: 'Sample Page', icon: 'fa fa-fw fa-columns', routerLink: ['/sample'] },
//     { label: 'Forms', icon: 'fa fa-fw fa-code', routerLink: ['/forms'] },
//     { label: 'Data', icon: 'fa fa-fw fa-table', routerLink: ['/data'] },
//     { label: 'Panels', icon: 'fa fa-fw fa-list-alt', routerLink: ['/panels'] },
//     { label: 'Overlays', icon: 'fa fa-fw fa-square', routerLink: ['/overlays'] },
//     { label: 'Menus', icon: 'fa fa-fw fa-minus-square-o', routerLink: ['/menus'] },
//     { label: 'Messages', icon: 'fa fa-fw fa-circle-o-notch', routerLink: ['/messages'] },
//     { label: 'Charts', icon: 'fa fa-fw fa-area-chart', routerLink: ['/charts'] },
//     { label: 'File', icon: 'fa fa-fw fa-arrow-circle-o-up', routerLink: ['/file'] },
//     { label: 'Misc', icon: 'fa fa-fw fa-user-secret', routerLink: ['/misc'] }
//   ]
// },
// {
//   label: 'Pages', icon: 'fa fa-fw fa-life-saver',
//   items: [
//     { label: 'Empty Page', icon: 'fa fa-fw fa-square-o', routerLink: ['/empty'] },
//     { label: 'Landing Page', icon: 'fa fa-fw fa-globe', url: 'assets/pages/landing.html', target: '_blank' },
//     { label: 'Login Page', icon: 'fa fa-fw fa-sign-in', url: 'assets/pages/login.html', target: '_blank' },
//     { label: 'Error Page', icon: 'fa fa-fw fa-exclamation-circle', url: 'assets/pages/error.html', target: '_blank' },
//     { label: '404 Page', icon: 'fa fa-fw fa-times', url: 'assets/pages/404.html', target: '_blank' },
//     {
//       label: 'Access Denied Page', icon: 'fa fa-fw fa-exclamation-triangle',
//       url: 'assets/pages/access.html', target: '_blank'
//     }
//   ]
// },

// { label: 'Documentation', icon: 'fa fa-fw fa-book', routerLink: ['/documentation'] }