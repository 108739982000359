import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Dropdown } from '../../../../models/dropdown.model';
import { LocalAtendimentoService } from '../../../../services/localAtendimento.service';

@Component({
  selector: 'app-local-atendimento',
  templateUrl: './local-atendimento-component.html',
  styleUrls: ['./local-atendimento-component.css']
})
export class LocalAtendimentoComponent implements OnInit {

  localAtendimentoSelecionado: Dropdown;
  locaisAtendimento: Dropdown[];

  @Output() localAtendimento = new EventEmitter();
  @Input() localAtendimentoEdit;

  constructor(private localAtendimentoService: LocalAtendimentoService) { }

  ngOnInit() {
    this.getLocalAtendimento();
  }

  ngOnAfterViewInit() {
    this.localAtendimentoSelecionado.value = this.localAtendimentoEdit;
  }

  getLocalAtendimento() {
    this.localAtendimentoService.getLocaisAtendimento().subscribe((locaisAtendimento) => {
      this.locaisAtendimento = locaisAtendimento;
    });
  }

  emit() {
    this.localAtendimento.emit(this.localAtendimentoSelecionado);
  }

  reset() {
    this.localAtendimentoSelecionado = null;
  }

}
