import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { LancamentoService } from '../../services/lancamento.service';
import { Faturamento } from '../../models/Faturamento';
import { ChartService } from '../../services/chart.service';
import { DatePipe } from '@angular/common';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { EPermissao } from '../../models/permissao.model';
import { BoardGeral } from '../../models/boardGeral.model';
import { ComunsService } from '../../comuns/comuns.service';
import { MessageService } from 'primeng/primeng';
import { Dropdown } from '../../models/dropdown.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { Chart } from '../../models/chart.model';
import { ParametrosPesquisaChart } from '../../models/ParametrosPesquisaChart.model';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  providers: [MessageService]
})
export class HomeComponent implements OnInit {

  months: Dropdown[];
  month: Dropdown;
  year: number;

  options = {
    title: {
      display: true,
      fontSize: 16
    }
  };

  chartForm: FormGroup;

  faturamento: Faturamento;
  boardGeralAdmin: BoardGeral[] = [];

  barFaturamentoPorConvenio: any;
  lineProducaoMensal: any;
  barGuiasFaturadas: any;

  currentUser: any;
  line: any;
  pizza: any;

  filtroDtInicial: any;
  filtroDtFinal: any;
  datePipe = new DatePipe('pt-BR');

  constructor(
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private chartService: ChartService,
    private comunsService: ComunsService,
    private lancamentoService: LancamentoService,
    private messageService: MessageService,
    private auth: AuthenticationService) {
    // this.form();

    this.auth.currentUser.subscribe(x => this.currentUser = x);

    var current = new Date().getMonth() + 1;
    this.months = this.comunsService.months();
    this.month = this.comunsService.months().find(f => f.value == current);
    this.year = new Date().getFullYear();

    if (this.isAdmin || this.isFat)
      this.getChartIsAdmin();
    if (this.isMed)
      this.getChartIsMed();
  }

  ngOnInit() {

  }

  form() {
    var date = new Date();
    var primeiroDia = new Date(date.getFullYear(), date.getMonth(), 1);
    var ultimoDia = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    this.chartForm = this.formBuilder.group({
      dataInicio: [this.datePipe.transform(primeiroDia, "dd/MM/yyyy", "pt-BR"), Validators.required],
      dataFim: [this.datePipe.transform(ultimoDia, "dd/MM/yyyy", "pt-BR"), Validators.required],
    })
  }

  getChartIsAdmin() {
    this.getTotalGuias();
  }

  getChartBarGuiasFaturadas() {
    this.barGuiasFaturadas = {
      labels: ['Seg', 'Ter', 'Qua', 'Qui', 'Sex'],
      datasets: [
        {
          label: 'Dia',
          backgroundColor: '#4bc0c0',
          borderColor: '#4bc0c0',
          data: [215.90, 410.83, 930.8, 1299.22, 0]
        }
      ]
    }
  }

  getChartIsMed() {
    this.getTotalProfissionalSaude();
  }

  getFaturamentoPorConvenio(chartsBar: Chart) {
    this.barFaturamentoPorConvenio = {
      total: chartsBar.total,
      media: chartsBar.media,
      labels: chartsBar.labels,
      datasets: [
        {
          label: 'Convênio',
          backgroundColor: '#4bc0c0',
          borderColor: '#4bc0c0',
          data: chartsBar.data
        }
      ]
    }
  }

  getChartLineProducaoMensal(chartsLine: Chart) {
    let data = {      
      label: "Mês",
      data: chartsLine.data,
      backgroundColor: '#4bc0c0',
      borderColor: '#4bc0c0',
      fill: false,
      lineTension: 0.3
    };
    var speedData = {
      total: chartsLine.total,
      media: chartsLine.media,
      labels: chartsLine.labels,
      datasets: [data]
    };

    this.lineProducaoMensal = speedData;
  }

  getChartPizzaTipoLancamento(chartsPizza: Chart) {
    this.pizza = {
      total: chartsPizza.total,
      media: chartsPizza.media,
      labels: chartsPizza.labels,
      datasets: [
        {
          data: chartsPizza.data,
          backgroundColor: [
            "#20aee3", "#4bc0c0"
          ],
          hoverBackgroundColor: [
            "#20aee3", "#4bc0c0"
          ]
        }]
    };
  }

  getChartLine(chartLine: Chart) {
    let data = {
      label: "Mês",
      data: chartLine.data,
      backgroundColor: '#4bc0c0',
      borderColor: '#4bc0c0',
      fill: false,
      lineTension: 0.3
    };
    var speedData = {
      labels: chartLine.labels,
      datasets: [data]
    };

    this.line = speedData;
  }

  async getFaturamentoAtualLancamento() {
    this.lancamentoService.getFaturamentoAtualLancamento().subscribe((faturamento: Faturamento) => {
      this.faturamento = faturamento;
    }, (exception) => {
      console.log(exception);
    })
  }

  getTotalGuias() {
    this.spinner.show();
    this.chartService.getTotalGuias(this.month.value).subscribe((response) => {
      this.pizza = {
        labels: response.labels,
        datasets: [
          {
            data: response.data,
            backgroundColor: [
              "#4bc0c0", "#20aee3", "#6772e5"
            ],
            hoverBackgroundColor: [
              "#4bc0c0", "#20aee3", "#6772e5"
            ]
          }]
      };

      this.boardGeralAdmin = response.chartsTop;
      this.getChartLine(response.chartsLine);

      this.spinner.hide();
    }, (exception) => {
      this.spinner.hide();
      this.messageService.add({ severity: 'error', summary: '', detail: "Erro ao tentar listar informações, tente novamente ou contacte o suporte." });
    })
  }

  getTotalProfissionalSaude() {
    this.spinner.show();

    // if (this.chartForm.invalid)
    //   return;

    // var filter = this.chartForm.value as ParametrosPesquisaChart;
    // filter.dataInicio = this.comunsService.transformDate(filter.dataInicio);
    // filter.dataFim = this.comunsService.transformDate(filter.dataFim);

    this.chartService.getTotalProfissionalSaude(this.month.value).subscribe((response) => {

      this.boardGeralAdmin = response.chartsTop;
      this.getFaturamentoPorConvenio(response.chartsBar);
      this.getChartLineProducaoMensal(response.chartsLine);
      this.getChartPizzaTipoLancamento(response.chartsPizza);

      // this.getFaturamentoAtualLancamento();
      // this.getChartLine();

      this.spinner.hide();

    }, (exception) => {
      this.spinner.hide();

    })

  }

  get isMed() {
    return this.auth.canViewPermissaoUsuario({ roles: [EPermissao.Med] });
  }

  get isAdmin() {
    return this.auth.canViewPermissaoUsuario({ roles: [EPermissao.Admin] });
  }

  get isFat() {
    return this.auth.canViewPermissaoUsuario({ roles: [EPermissao.Fat] });
  }
}
