import { Injectable } from '@angular/core';
import { MEDGESTAO_API } from '../app.api';
import { Observable, Subject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ErrorHandler } from '../pages/app.error-handler';
import { Dropdown } from '../models/dropdown.model';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class LocalAtendimentoService {

  constructor(public http: HttpClient) {
  }

  getLocaisAtendimento(): Observable<Dropdown[]> {
    return this.http.get(`${MEDGESTAO_API}/ProfissionalSaude/locaisAtendimento`)
      .pipe(
        map(response => { return response as Dropdown[] },
          catchError(ErrorHandler.handleError)))
  }
}
