import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EXPORT_API, MEDGESTAO_API } from '../app.api';
import { ErrorHandler } from '../pages/app.error-handler';
import { catchError, map } from 'rxjs/operators';
import { Atendimento } from '../models/atendimento.model';
import { GestaoDocumento } from '../models/atestado.model';
import { TipoAtestadoModel } from '../models/modeloAtestado.model';
import { Prontuario } from '../models/prontuario.model';
import { Paciente } from '../models/paciente.model';
import { CID10 } from '../models/CID10.model';
import { Observable } from 'rxjs';
import { Exame } from '../models/Exame.model';
import { PedidoExame } from '../models/PedidoExame.model';
import { ExameAtendimento } from '../models/ExameAtendimento.model';
import { GeralExame } from '../models/GeralExame.model';
declare var document: any;
declare var MdSinapsePrescricao: any;
declare var MdHub: any;

@Injectable({
    providedIn: 'root'
})

export class ProntuarioService {
    constructor(public httpClient: HttpClient) {

    }

    updateAtendimentoById(atendimento: Atendimento) {
        return this.httpClient.post(`${MEDGESTAO_API}/prontuario/UpdateAtendimento`, atendimento)
            .pipe(
                map(response => { return response },
                    catchError(ErrorHandler.handleError)))
    }

    getProntuarioByPacienteId(pacienteId: number, profissionalSaudeId: number) {
        return this.httpClient.get(`${MEDGESTAO_API}/prontuario/GetProntuarios/${pacienteId}/${profissionalSaudeId}`)
            .pipe(
                map(response => { return response as Paciente },
                    catchError(ErrorHandler.handleError)))
    }


    createOrUpdateAtendimento(prontuario: Atendimento) {
        return this.httpClient.post(`${MEDGESTAO_API}/prontuario/CreateAtendimento`, prontuario)
            .pipe(
                map(response => { return response as Atendimento },
                    catchError(ErrorHandler.handleError)))
    }

    cancelamentoAtendimento(prontuario: Atendimento) {
        return this.httpClient.put(`${MEDGESTAO_API}/prontuario/CancelamentoAtendimento`, prontuario)
            .pipe(
                map(response => { return response },
                    catchError(ErrorHandler.handleError)))
    }

    concluirAtendimento(parametros: Atendimento) {
        return this.httpClient.put(`${MEDGESTAO_API}/prontuario/ConcluirAtendimento`, parametros)
            .pipe(
                map(response => { return response },
                    catchError(ErrorHandler.handleError)))
    }

    createAtestado(parametros: GestaoDocumento) {
        return this.httpClient.post(`${MEDGESTAO_API}/prontuario/CreateAtestado`, parametros)
            .pipe(
                map(response => { return response },
                    catchError(ErrorHandler.handleError)))
    }

    updateAtestado(atestadoId: number, parametros: GestaoDocumento) {
        return this.httpClient.put(`${MEDGESTAO_API}/prontuario/UpdateAtestado/${atestadoId}`, parametros)
            .pipe(
                map(response => { return response },
                    catchError(ErrorHandler.handleError)))
    }

    deleteAtestado(atestadoId: number) {
        return this.httpClient.delete(`${MEDGESTAO_API}/prontuario/DeleteAtestado/${atestadoId}`)
            .pipe(
                map(response => { return response },
                    catchError(ErrorHandler.handleError)))
    }

    getAtendimentoById(atendimentoId: number) {
        return this.httpClient.get(`${MEDGESTAO_API}/prontuario/GetAtendimentoById/${atendimentoId}`)
            .pipe(
                map(response => { return response as Atendimento },
                    catchError(ErrorHandler.handleError)))
    }

    incluirModelo() {
        return this.httpClient.get(`${MEDGESTAO_API}/prontuario/IncluirModeloDocumento`)
            .pipe(
                map(response => { return response },
                    catchError(ErrorHandler.handleError)))
    }

    getAllModelosAtestado() {
        return this.httpClient.get(`${MEDGESTAO_API}/prontuario/GetAllModelos`)
            .pipe(
                map(response => { return response as Array<TipoAtestadoModel> },
                    catchError(ErrorHandler.handleError)))
    }

    getAllAtestadosAtendimento(atendimentoId: number) {
        return this.httpClient.get(`${MEDGESTAO_API}/prontuario/getAllAtestadosAtendimento/${atendimentoId}`)
            .pipe(
                map(response => { return response as Array<GestaoDocumento> },
                    catchError(ErrorHandler.handleError)))
    }

    getCid(name: string) {
        return this.httpClient.get(`${MEDGESTAO_API}/prontuario/GetCid/${name}`)
            .pipe(
                map(response => { return response as CID10[] },
                    catchError(ErrorHandler.handleError)))
    }

    getExames(tipoExame: number) {
        return this.httpClient.get(`${MEDGESTAO_API}/prontuario/GetExames/${tipoExame}`)
            .pipe(
                map(response => { return response as GeralExame },
                    catchError(ErrorHandler.handleError)))
    }

    createExameAtendimento(model: any) {
        return this.httpClient.post(`${MEDGESTAO_API}/prontuario/CreateExameAtendimento`, model)
            .pipe(
                map(response => { return response },
                    catchError(ErrorHandler.handleError)))
    }

    GetExamesAtendimento(atendimentoId: number) {
        return this.httpClient.get(`${MEDGESTAO_API}/prontuario/GetExamesAtendimento/${atendimentoId}`)
            .pipe(
                map(response => { return response as ExameAtendimento[] },
                    catchError(ErrorHandler.handleError)))
    }

    GetExameAtendimentoPorId(exameAtendimentoId: number) {
        return this.httpClient.get(`${MEDGESTAO_API}/prontuario/GetExameAtendimentoPorId/${exameAtendimentoId}`)
            .pipe(
                map(response => { return response as any },
                    catchError(ErrorHandler.handleError)))
    }

    deleteExameAtendimento(exameAtendimentoId: number) {
        return this.httpClient.delete(`${MEDGESTAO_API}/prontuario/DeleteExameAtendimento/${exameAtendimentoId}`)
            .pipe(
                map(response => { return response },
                    catchError(ErrorHandler.handleError)))
    }

    UpdateExameAtendimento(exameAtendimentoId: number, exameAtendimento: ExameAtendimento) {
        return this.httpClient.put(`${MEDGESTAO_API}/prontuario/UpdateExameAtendimento/${exameAtendimentoId}`, exameAtendimento)
            .pipe(
                map(response => { return response },
                    catchError(ErrorHandler.handleError)))
    }

    // MEMED
    loadMemed(token, paciente: Paciente) {
        let script = document.createElement('script');
        let idScript = 'sinapseMemed';

        script.type = 'text/javascript';
        script.id = idScript;
        script.src = 'https://integrations.memed.com.br/modulos/plataforma.sinapse-prescricao/build/sinapse-prescricao.min.js';
        script.setAttribute('data-token', token);
        script.setAttribute('data-container', 'memed-id');

        script.onload = () => {
            this.initEventsMemed(paciente);
        };
        document.body.appendChild(script);
    }

    initEventsMemed(paciente: Paciente) {
        MdSinapsePrescricao.event.add('core:moduleInit', (module) => {
            // O módulo da prescrição foi iniciado.
            if (module.name === 'plataforma.prescricao') {

                // AQUI VOCE PODE REMOVER O LOADER DE ALGUM BOTÃO
                // AQUI VOCE PODE ADICIONAR UM LISTENER NO BOTÃO PARA ABRIR A PRESCRICAO.

                MdHub.command.send(module.name, 'setPaciente', {
                    nome: paciente.beneficiario.nome,
                    endereco: paciente.logradouro ? `${paciente.logradouro}, ${paciente.numeroResidencia}` : null,
                    cidade: paciente.cidade,
                    telefone: paciente.celular,
                    nome_mae: paciente.nomeMae,
                    data_nascimento: paciente.beneficiario.dtNascimento,
                    idExterno: paciente.id,
                    cpf: paciente.cpf
                }).then(() => {
                    MdHub.module.show(module.name)
                });

                MdHub.command.send('plataforma.prescricao', 'setFeatureToggle', {
                    deletePatient: false,
                    removePatient: false,
                    editPatient: false,
                    guidesOnboarding: false,
                    buttonClose: false,
                    dropdownSync: false,
                    showHelpMenu: false,
                    editIdentification: false
                });
            }
        });
    }

    toPdf(id: number): Observable<Blob> {
        return this.httpClient.get(`${EXPORT_API}/exportProntuario/${id}`, { responseType: 'blob' });
    }

    toPdfGuiaSPSADT(id: number, atendimentoId: number, tipoExameId: number): Observable<Blob> {
        return this.httpClient.get(`${EXPORT_API}/geradorGuiaSPSADT/${id}/${atendimentoId}/${tipoExameId}`, { responseType: 'blob' });
    }
}