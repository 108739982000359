import { Component, OnInit } from '@angular/core';
import { ContratadoService } from '../../services/contratado.service';
import { FiltroContratado } from '../../models/filtro-contratado.model';
import { Contratado } from 'src/app/models/contratado.model';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Paginacao } from '../../models/paginacao.model'; 
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ConfirmationService, SelectItem, MessageService, MenuItem, LazyLoadEvent } from 'primeng/primeng';
import { Table } from 'primeng/table';
import { ViewChild } from '@angular/core';
import { ComunsService } from 'src/app/comuns/comuns.service';

@Component({
  selector: 'app-contratados',
  templateUrl: './contratados.component.html',
  styleUrls: ['./contratados.component.css'],
  providers: [MessageService]
})
export class ContratadosComponent implements OnInit {
  @ViewChild('dt') dt: Table;

  contratados: Array<Contratado>;
  contratadosSelect: Array<Contratado>;
  profSaude: any;
  lancamentos: any;
  filtro_form: FormGroup;
  totalRecords: number;
  first: number = 0;

  constructor(private contratadoService: ContratadoService,
    private spinner: NgxSpinnerService,
    private messageService: MessageService,
    private formBuilder: FormBuilder,
    private comunsService: ComunsService,
    private router: Router) { }

  ngOnInit() {
    this.form();
    this.findAllContratados();
  }

  form() {
    this.filtro_form = this.formBuilder.group({
      contratadoId: [null],
      cpfCnpj: [null],
      cnes: [null],
    })
  }

  findContratado(paginacao?: LazyLoadEvent) {
    this.spinner.show();
    let parametros = this.filtro_form.value as FiltroContratado;

    var pag = new Paginacao();

    if (paginacao == null) this.dt.first = 0;
    pag.setPaginacao(parametros, paginacao);

    this.contratadoService.contratadoSearch(parametros).subscribe((response) => {
      this.contratados = response.contratados;
      this.totalRecords = response.totalCount;
      this.spinner.hide();
    }, (exception) => {
      this.spinner.hide();
    })
  }

  findAllContratados() {
    let filtros = {
      contratadoId: 0,
      cpfCnpj: null,
      cnes: null
    } as FiltroContratado;

    this.spinner.show();
    this.contratadoService.contratados(filtros).subscribe((response) => {
      this.contratadosSelect = this.comunsService.toSelectItem(response);
      this.spinner.hide();
    }, (exception) => {
      this.spinner.hide();
      this.messageService.add({ severity: 'error', summary: '', detail: exception });
    })
  }

  receiveContratado(contratadoId: number) {

    this.filtro_form.patchValue({
      contratadoId: contratadoId
    });
  }

  editar(id: number) {
    this.router.navigate(['/contratado'], { queryParams: { code: id } });
  }

}
