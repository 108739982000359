import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { TipoPagamento } from '../../../../models/tipo-pagamento.model';
import { LancamentoService } from '../../../../services/lancamento.service';

@Component({
    selector: 'app-tipo-pagamento',
    templateUrl: './tipo-pagamento.component.html'
})
export class TipoPagamentoComponent implements OnInit {

    tipoPagamentoSelecionado: TipoPagamento;
    tiposPagamento: TipoPagamento[];

    constructor(private lancamentoService: LancamentoService) { }

    @Output() tipoPagamento = new EventEmitter();
    @Input() tipoPagamentoEdit;

    ngOnInit() {
        this.getTiposPagamento();
    }

    ngOnAfterViewInit() {
        this.tipoPagamentoSelecionado.id = this.tipoPagamentoEdit;
    }

    getTiposPagamento() {
        this.lancamentoService.getTiposPagamento().subscribe((tipos) => {
            this.tiposPagamento = tipos;
        });
    }

    emit() {
        this.tipoPagamento.emit(this.tipoPagamentoSelecionado);
    }

    reset() {
        this.tipoPagamentoSelecionado = null;
    }

}