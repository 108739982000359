import { Component, OnInit } from '@angular/core';
import { RelatorioService } from 'src/app/services/relatorio.service';
import { Contratado } from 'src/app/models/contratado.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ContratadoConvenio } from 'src/app/models/contratado-convenio.model';
import { ProfissionalSaude } from 'src/app/models/profissionalSaude.model';
import { FiltroLancamento } from 'src/app/models/filtro-lancamento.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { ComunsService } from '../../../comuns/comuns.service';
import { MessageService } from 'primeng/primeng';
import { AuthenticationService } from '../../../services/authentication.service';

@Component({
  selector: 'app-relatorio-lancamento',
  templateUrl: './relatorio-lancamento.component.html',
  styleUrls: ['./relatorio-lancamento.component.css'],
  providers: [MessageService]
})
export class RelatorioLancamentoComponent implements OnInit {

  convenios: Array<ContratadoConvenio>;
  profissionais: Array<ProfissionalSaude>;
  relatorioLancamentoForm: FormGroup;
  submitted: boolean = false;

  constructor(private comunsService: ComunsService,
    private messageService: MessageService,
    private relatorioService: RelatorioService,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private spinner: NgxSpinnerService) {
    this.form();
  }

  ngOnInit() {
  }

  gerarRelatorioLancamentos() {
    this.submitted = true;
    var contratadoConvenioForm = (<FormGroup>this.relatorioLancamentoForm.controls['contratadoConvenioForm']).value;
    let parametros = this.relatorioLancamentoForm.value as FiltroLancamento;
    this.setValueForm(parametros, contratadoConvenioForm);
    parametros.dtLancamentoInicial = this.comunsService.transformDate(parametros.dtLancamentoInicial);
    parametros.dtLancamentoFinal = this.comunsService.transformDate(parametros.dtLancamentoFinal);

    if (this.relatorioLancamentoForm.invalid) {
      return;
    }

    this.spinner.show();
    this.relatorioService.gerarRelatorioLancamentos(parametros).subscribe((file) => {
      this.spinner.hide();
      this.relatorioService.download(file);
    }, (exception: Response) => {
      this.spinner.hide();
      this.messageService.add({ severity: 'success', summary: '', detail: "Nenhum registro encontrado para o período informado." });

      // if (exception.status == 404) {
      //   this.messageService.add({ severity: 'success', summary: '', detail: "Nenhum registro encontrado para o período informado." });
      // } else {
      //   this.messageService.add({ severity: 'error', summary: '', detail: "Erro ao tentar gerar relatório." });
      // }
    })
  }

  form() {
    this.relatorioLancamentoForm = this.formBuilder.group({
      dtLancamentoInicial: [null, Validators.required],
      dtLancamentoFinal: [null, Validators.required],
      convenioId: [null],
      contratadoId: [null],
      profissionalSaudeId: [null],
      usuario: [this.authenticationService.currentUserValue.user.nomePessoa],
      contratadoConvenioForm: this.formBuilder.group({
        contratado: [null, Validators.required],
        convenio: [null],
        profissionalSaude: [null, Validators.required]
      }),
    })
  }

  receiveContratado(contratado: Contratado) {
    var form = (<FormGroup>this.relatorioLancamentoForm.controls['contratadoConvenioForm']);

    if (contratado) {
      this.convenios = contratado.contratadoConvenio;
      this.profissionais = contratado.profissionalSaude;
      form.patchValue({
        contratado: [contratado]
      });
    }
    else {
      form.reset();
      this.convenios = [];
      this.profissionais = [];
    }
  }

  receiveDataInicial(dtLancamentoInicial) {
    this.relatorioLancamentoForm.patchValue({
      dtLancamentoInicial: dtLancamentoInicial
    });
  }

  receiveDataFinal(dtLancamentoFinal) {
    this.relatorioLancamentoForm.patchValue({
      dtLancamentoFinal: dtLancamentoFinal
    });
  }

  validacoes = {
    'campoObrigatorio': [
      { type: 'required', message: 'Este campo é obrigatório.' }
    ]
  };

  setValueForm(parametros, contratadoConvenioForm) {
    parametros.contratadoId = contratadoConvenioForm.contratado == null ? null : contratadoConvenioForm.contratado[0].id;
    parametros.convenioId = contratadoConvenioForm.convenio == null ? null : contratadoConvenioForm.convenio.convenioId;
    parametros.profissionalSaudeId = contratadoConvenioForm.profissionalSaude == null ? null : contratadoConvenioForm.profissionalSaude.id;
  }


}
