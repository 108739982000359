import { Component, OnInit } from '@angular/core';
import { ContratadoConvenio } from '../../../models/contratado-convenio.model';
import { ProfissionalSaude } from '../../../models/profissionalSaude.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MessageService, SelectItem } from 'primeng/primeng';
import { AuthenticationService } from '../../../services/authentication.service';
import { RelatorioService } from '../../../services/relatorio.service';
import { ComunsService } from '../../../comuns/comuns.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Contratado } from 'src/app/models/contratado.model';
import { FiltroLancamento } from '../../../models/filtro-lancamento.model';
import { Dropdown } from '../../../models/dropdown.model';
import { LoteService } from '../../../services/lote.service';

@Component({
  selector: 'app-relatorio-faturamento-direto',
  templateUrl: './relatorio-faturamento-direto.component.html',
  styleUrls: ['./relatorio-faturamento-direto.component.css'],
  providers: [MessageService]
})
export class RelatorioFaturamentoDiretoComponent implements OnInit {

  convenios: Array<ContratadoConvenio>;
  profissionais: Array<ProfissionalSaude>;
  relatorioFaturamentoForm: FormGroup;
  submitted: boolean = false; 
  months: Dropdown[];
  situacoesLote: SelectItem[];

  constructor(private messageService: MessageService,
    private loteService: LoteService,
    private authenticationService: AuthenticationService,
    private relatorioService: RelatorioService,
    private formBuilder: FormBuilder,
    private comunsService: ComunsService,
    private spinner: NgxSpinnerService) {
      this.getSituacoesLote();
  }

  ngOnInit() {
    this.form();
  }

  form() {
    this.relatorioFaturamentoForm = this.formBuilder.group({
      dtLoteInicial: [null, Validators.required],
      dtLoteFinal: [null, Validators.required],
      situacaoId: [null],
      convenioId: [null],
      contratadoId: [null],
      profissionalSaudeId: [null],
      numeroLote: [null],
      contratadoConvenioForm: this.formBuilder.group({
        contratado: [null, Validators.required],
        convenio: [null],
        profissionalSaude: [null]
      }),
    })
  }

  receiveContratado(contratado: Contratado) {
    var form = (<FormGroup>this.relatorioFaturamentoForm.controls['contratadoConvenioForm']);

    if (contratado) {
      this.convenios = contratado.contratadoConvenio;
      this.profissionais = contratado.profissionalSaude;
      form.patchValue({
        contratado: [contratado]
      });
    }
    else {
      form.reset();
      this.convenios = [];
      this.profissionais = [];
    }
  }

  validacoes = {
    'campoObrigatorio': [
      { type: 'required', message: 'Este campo é obrigatório.' }
    ]
  };

  setValueForm(parametros, contratadoConvenioForm) {
    parametros.contratadoId = contratadoConvenioForm.contratado == null ? null : contratadoConvenioForm.contratado[0].id;
    parametros.convenioId = contratadoConvenioForm.convenio == null ? null : contratadoConvenioForm.convenio.convenioId;
    parametros.profissionalSaudeId = contratadoConvenioForm.profissionalSaude == null ? null : contratadoConvenioForm.profissionalSaude.id;
  }

  gerarRelatorioFaturamentoDireto() {
    this.submitted = true;
    var contratadoConvenioForm = (<FormGroup>this.relatorioFaturamentoForm.controls['contratadoConvenioForm']).value;
    let parametros = this.relatorioFaturamentoForm.value as FiltroLancamento;
    this.setValueForm(parametros, contratadoConvenioForm);
    parametros.dtLoteInicial = this.comunsService.transformDate(parametros.dtLoteInicial);
    parametros.dtLoteFinal = this.comunsService.transformDate(parametros.dtLoteFinal);

    if (this.relatorioFaturamentoForm.invalid) {
      return;
    }

    this.spinner.show();
    this.relatorioService.gerarRelatorioFaturamentoDireto(parametros).subscribe((file) => {
      this.spinner.hide();
      this.submitted = false;
      this.relatorioService.downloadArquivoTemp(file);
    }, (exception) => {
      this.submitted = false;
      this.spinner.hide();
      this.messageService.add({ severity: 'error', summary: '', detail: exception});
    })
  }

  getSituacoesLote() {
    this.loteService.getSituacoesLote().subscribe((response) => {
      this.situacoesLote = this.comunsService.toSelectItem(response);
    }, (exception) => {
      console.log(exception);

    })
  }

}
