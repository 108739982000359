import { Http } from '@angular/http';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CEP } from '../models/CEP.model';
import { ErrorHandler } from '../pages/app.error-handler';
import { catchError, map } from 'rxjs/operators';



@Injectable({
    providedIn: 'root'
})
export class CepService {

    constructor(public httpClient: HttpClient, http: Http) {

    }
    buscarCep(cepDigitado: string){
        return this.httpClient.get(`https://brasilapi.com.br/api/cep/v1/${cepDigitado}`)
                .pipe(
                    map(response => { return response as CEP},
                        catchError(ErrorHandler.handleError)))
      }
}