import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { EmpresaService } from '../../services/empresa.service';
import { Empresa } from 'src/app/models/empresa.model';
import { MessageService } from 'primeng/primeng';
import { Message } from 'primeng/components/common/api';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-cadastro',
  templateUrl: './cadastro.component.html',
  styleUrls: ['./cadastro.component.css'],
  styles: [`
        :host ::ng-deep button {
            margin-right: .25em;
        }

        :host ::ng-deep .ui-message,
        :host ::ng-deep .ui-inputtext {
            margin-right: .25em;
        }
    `],
  providers: [MessageService]
})
export class CadastroComponent implements OnInit {

  msgs: Message[] = [];
  isMessageReturn: Boolean = false;
  cadastroForm: FormGroup;

  constructor(private formBuilder: FormBuilder,
    private empresaService: EmpresaService,
    private spinner: NgxSpinnerService,
    private messageService: MessageService) { }

  ngOnInit() {
    this.form();
    this.msgs = [];
  }

  form() {
    this.cadastroForm = this.formBuilder.group({
      cnpj: ['', Validators.required],
      razaoSocial: ['', Validators.required],
      nomeEmpresa: ['', Validators.required],
      nomePessoaResponsavel: ['', Validators.required],
      telefone: ['', Validators.required],
      cidadeId: [2419, Validators.required],
      email: ['', Validators.required],
      senha: ['', Validators.required]
    });
  }

  createEmpresa() {

    if (this.cadastroForm.invalid)
      return false;

    this.spinner.show();
    let empresa = this.cadastroForm.value as Empresa;

    this.empresaService.createEmpresa(empresa).subscribe((response) => {
      this.msgs.push({ severity: 'success', summary: response });
      this.spinner.hide();
      this.isMessageReturn = true;
    }, (exception) => {
      this.msgs.push({ severity: 'info', summary: 'Info Message', detail: 'PrimeNG rocks' });
      this.spinner.hide();
      this.isMessageReturn = false;
    })
  }

}
