import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

import { ProntuarioService } from '../../services/prontuario.service';
import { ConfirmationService, SelectItem, MessageService, MenuItem, LazyLoadEvent, Dialog, PickList } from 'primeng/primeng';
import { NgxSpinnerService } from 'ngx-spinner';
import { Atendimento } from 'src/app/models/atendimento.model';
import { Prontuario } from 'src/app/models/prontuario.model';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { Paciente } from 'src/app/models/paciente.model';
import { AuthenticationService } from '../../services/authentication.service';
import { Beneficiario } from 'src/app/models/beneficiario.model';
import { TipoAtestadoModel } from 'src/app/models/modeloAtestado.model';
import { BeneficiarioService } from 'src/app/services/beneficiario.service';
import { GestaoDocumento } from 'src/app/models/atestado.model';
import { CID10 } from 'src/app/models/CID10.model';
import { AuthUser } from 'src/app/models/auth.model';
import tiposAtendimento from 'src/app/models/tipoAtendimento.model';
import * as jsPDF from 'jspdf';
import { PacienteService } from 'src/app/services/paciente.service';
import { PacienteComponent } from '../pacientes/paciente/paciente.component';
import { pipe } from 'rxjs';
import { TabDirective, TabsetComponent } from 'ngx-bootstrap/tabs';
import { Exame } from 'src/app/models/Exame.model';
import { PedidoExame } from 'src/app/models/PedidoExame.model';
import { ExameAtendimento } from 'src/app/models/ExameAtendimento.model';
import { GeralExame } from 'src/app/models/GeralExame.model';
import * as moment from 'moment';
import html2pdf from 'html2pdf.js';

@Component({
  selector: 'app-prontuario',
  templateUrl: './prontuario.component.html',
  styleUrls: ['./prontuario.component.css'],
  providers: [ConfirmationService, MessageService]
})
export class ProntuarioComponent implements OnInit {

  @ViewChild('pdfTable') pdfTable: ElementRef;
  @ViewChild('meuTabset') meuTabset: TabsetComponent;

  itemsMenuBar: MenuItem[];
  itemsTieredMenu: MenuItem[];
  items: MenuItem[];

  atendimentoForm: FormGroup;
  prontuarioForm: FormGroup;
  atestado_form: FormGroup;
  showVisualizarAtestado: boolean;

  atestadosAtendimento: Array<GestaoDocumento>;

  modelosAtestado: Array<TipoAtestadoModel>;
  text: string;
  aux: Date;
  prontuario: Atendimento;
  atendimentosPaciente: Array<Atendimento>;
  paciente: Paciente;
  beneficiario: Beneficiario;
  dataProntuario: Date;
  profissionalSaudeId: number;
  isNewProntuario: boolean;
  pacienteId: number;
  agendamentoId: number;
  atendimentoId: number;
  datePipe = new DatePipe('pt-BR');
  sugesstionsCids: CID10[];
  sugesstionsExames: Exame[];
  examesMaisPedidos: Exame[];
  caraterSolicitacao: Array<any> = [
    {
      label: "Eletiva",
      value: "E",
    },
    {
      label: "Urgência/Emergência",
      value: "U",
    }
  ];


  currentUser: AuthUser;
  atendimentoEdit: boolean = false;
  pedidoExameShow: string = "listar"
  sourceHeader: string = "Todos os Exames";
  targetPedidoExames: PedidoExame[] = [];
  examesAtendimento: ExameAtendimento[] = [];

  @ViewChild(PacienteComponent) pacienteComponent: PacienteComponent;


  constructor(
    private formBuilder: FormBuilder,
    private pacienteService: PacienteService,
    private prontuarioService: ProntuarioService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private spinner: NgxSpinnerService,
    private router: ActivatedRoute,
    private authService: AuthenticationService,
    private routerNavigate: Router
  ) {
    this.currentUser = this.authService.currentUserValue;
    this.showVisualizarAtestado = false;
  }

  ngOnInit() {
    this.router
      .queryParams
      .subscribe(params => {
        this.profissionalSaudeId = params['profissionalId'];
        this.pacienteId = params['pacienteId'];
        this.agendamentoId = params['agendamentoId'];

        this.isNewProntuario = params['prontuario'] === "true" ? true : false;

        this.prontuario = {
          pacienteId: this.pacienteId,
          profissionalSaudeId: this.profissionalSaudeId,
          agendamentoId: this.agendamentoId
        } as Atendimento;

        this.findByPacienteId(this.pacienteId, this.profissionalSaudeId);
        this.getAllModelosAtestado();

      });

    this.form(null);
  }

  form(valueForm: Atendimento) {
    this.prontuarioForm = this.formBuilder.group({
      atendimentoForm: this.formBuilder.group({
        id: [valueForm && valueForm.id],
        queixaPrincipal: [valueForm && valueForm.queixaPrincipal],
        historicoDeDoencaAtual: [valueForm && valueForm.historicoDeDoencaAtual],
        antecedentesPessoais: [valueForm && valueForm.antecedentesPessoais],
        resultadosExames: [valueForm && valueForm.resultadosExames],
        exameFisico: [valueForm && valueForm.exameFisico],
        conduta: [valueForm && valueForm.conduta],
        atendimentoCid: [null],
        cids: [null]
      }),
      atestado_form: this.formBuilder.group({
        id: [null],
        atestadoText: [null],
        modeloAtestado: [null],
        tipoDocumento: [null]
      }),
      exameAtendimento_form: this.formBuilder.group({
        id: [null],
        atendimentoId: [null, Validators.required],
        caraterSolicitacao: [null],
        indicacaoClinica: [null],
        CID10: [null],
        pedidoExame: [[], Validators.required]
      })
    });

    this.AtendimentoValueChange();
  }

  private AtendimentoValueChange() {
    this.prontuarioForm.controls["atendimentoForm"].valueChanges
      .pipe(debounceTime(500)).subscribe(value => {
        this.salvarAtendimento();
      });
  }

  getCidsByFilter(event) {
    let query = event.query;
    this.prontuarioService.getCid(query).pipe(debounceTime(500)).subscribe((response) => {
      this.sugesstionsCids = response;
    }, (ex) => {

    })
  }

  salvarAtendimento() {
    var form = (<FormGroup>this.prontuarioForm.controls['atendimentoForm']);

    // this.atendimento.id = this.prontuario.id;
    // this.atendimento.pacienteId = this.paciente.id;
    // this.atendimento.profissionalSaudeId = this.profissionalSaudeId;

    form.value["atendimentoCid"] = this.setAtendimentoCid(form.value);
    this.prontuarioService.updateAtendimentoById(form.value).subscribe((response) => {
      this.messageService.add({ severity: 'success', summary: '', detail: 'Prontuário salvo!' });
    }, (exception) => {
      this.messageService.add({ severity: 'error', summary: '', detail: exception });
    })
  }

  salvarEdicaoAtendimento() {
    this.spinner.show();

    var form = (<FormGroup>this.prontuarioForm.controls['atendimentoForm']);
    form.value["atendimentoCid"] = this.setAtendimentoCid(form.value);
    this.prontuarioService.updateAtendimentoById(form.value).subscribe((response) => {
      this.spinner.hide();

      this.messageService.add({ severity: 'success', summary: '', detail: 'Prontuário editado com sucesso!' });

      setTimeout(() => {
        location.reload();
      }, 1500);
    }, (exception) => {
      this.spinner.hide();

      this.messageService.add({ severity: 'error', summary: '', detail: exception });
    })
  }

  cancelEditAtendimento() {
    location.reload();
  }

  getAtendimentoById(atendimentoId: number) {
    this.spinner.show();

    this.prontuarioService.getAtendimentoById(atendimentoId).subscribe((atendimento) => {
      this.spinner.hide();
      this.atendimentoEdit = true;
      this.atendimentoId = atendimentoId;
      this.form(atendimento);
      this.atestadosAtendimento = atendimento.documentos;
      this.getExamesAtendimento(this.atendimentoId);

    }, (exception) => {
      this.spinner.hide();
      this.messageService.add({ severity: 'error', summary: '', detail: exception });
    })
  }

  setAtendimentoCid(form: any) {
    let atendimentoCid = [];
    if (form.cids != null) {
      for (let i = 0; i < form.cids.length; i++) {
        atendimentoCid.push({ atendimentoId: form.id, CID10Id: form.cids[i].id })
      }
    }

    return atendimentoCid;
  }

  salvarAtestado() {
    var form = (<FormGroup>this.prontuarioForm.controls['atestado_form']);
    var value = form.value;

    var atestado = {
      nome: value.modeloAtestado,
      texto: value.atestadoText,
      atendimentoId: this.atendimentoId
    } as GestaoDocumento;

    if (value.id > 0) {
      this.prontuarioService.updateAtestado(value.id, atestado).subscribe((response) => {
        this.spinner.hide();
        this.getAllAtestados(this.atendimentoId);
        this.messageService.add({ severity: 'success', summary: '', detail: 'Atestado atualizado com sucesso' });
        this.prontuarioForm.controls['atestado_form'].reset();

      }, (exception) => {
        this.spinner.hide();
        this.messageService.add({ severity: 'error', summary: '', detail: exception });
      })
    } else {
      this.prontuarioService.createAtestado(atestado).subscribe((response) => {
        this.spinner.hide();
        this.getAllAtestados(this.atendimentoId);
        this.prontuarioForm.controls['atestado_form'].reset();
        this.messageService.add({ severity: 'success', summary: '', detail: 'Atestado criado com sucesso' });
      }, (exception) => {
        this.spinner.hide();
        this.messageService.add({ severity: 'error', summary: '', detail: exception });
      })
    }
  }

  deleteAtestado(atestadoId: number) {
    this.spinner.show();

    this.prontuarioService.deleteAtestado(atestadoId).subscribe((response) => {
      this.spinner.hide();
      this.getAllAtestados(this.atendimentoId);
    }, (exception) => {
      this.spinner.hide();
      this.messageService.add({ severity: 'error', summary: '', detail: exception });
    })
  }

  editarAtestado(atestado: GestaoDocumento) {
    this.showVisualizarAtestado = false;
    this.prontuarioForm.get("atestado_form")
      .patchValue(
        {
          id: atestado.id,
          atestadoText: atestado.texto,
          modeloAtestado: atestado.nome,
          tipoDocumento: atestado.nome
        }
      )
  }

  visualizarAtestado(atestado: GestaoDocumento) {
    this.showVisualizarAtestado = true;
    this.prontuarioForm.get("atestado_form")
      .patchValue(
        {
          id: atestado.id,
          atestadoText: atestado.texto,
          modeloAtestado: atestado.nome,
          tipoDocumento: atestado.nome
        }
      )
  }

  cancelarVisualizacaoAtestado() {
    this.showVisualizarAtestado = false;
    this.prontuarioForm.controls['atestado_form'].reset();
  }

  findByPacienteId(pacienteId: number, profissionalSaudeId: number) {
    this.spinner.show();

    this.prontuarioService.getProntuarioByPacienteId(pacienteId, profissionalSaudeId).subscribe((response) => {
      this.spinner.hide();
      this.paciente = response;
      this.atendimentosPaciente = response.atendimentos;

      if (this.isNewProntuario || this.paciente.existeAtendimentoAndamento) {
        this.createOrUpdateAtendimento(this.prontuario);
        this.prontuarioService.loadMemed(this.currentUser.memed, this.paciente);
      }
    }, (exception) => {
      this.spinner.hide();
      this.messageService.add({ severity: 'error', summary: '', detail: exception });
    })
  }

  novoProntuario() {
    this.spinner.show();

    this.prontuarioService.createOrUpdateAtendimento(this.prontuario).subscribe((response) => {
      this.spinner.hide();
      this.atendimentoId = response.id;
      this.setAttendanceInStorage(this.pacienteId, this.profissionalSaudeId, this.atendimentoId);

      location.reload();
      this.routerNavigate.navigate(['/prontuario'], { queryParams: { pacienteId: this.pacienteId, profissionalId: this.profissionalSaudeId, prontuario: true } });
    }, (exception) => {
      this.spinner.hide();
    })
  }

  createOrUpdateAtendimento(atendimento: Atendimento) {
    this.spinner.show();

    this.prontuarioService.createOrUpdateAtendimento(atendimento).subscribe((response) => {
      this.spinner.hide();
      this.atendimentoId = response.id;
      this.prontuarioForm.get("atendimentoForm").patchValue(response);
      this.getAllAtestados(this.atendimentoId);
      this.getExamesAtendimento(this.atendimentoId);
      this.paciente.existeAtendimentoAndamento = true;
      this.setAttendanceInStorage(this.pacienteId, this.profissionalSaudeId, this.atendimentoId);

    }, (exception) => {
      this.spinner.hide();
    })
  }

  getAllModelosAtestado() {
    this.modelosAtestado = tiposAtendimento;
    // this.spinner.show();

    // this.prontuarioService.getAllModelosAtestado().subscribe((response) => {
    //   this.spinner.hide();
    //   this.modelosAtestado = response;
    // }, (exception) => {
    //   this.spinner.hide();
    //   this.messageService.add({ severity: 'error', summary: '', detail: exception });
    // })
  }

  getAllAtestados(atendimentoId: number) {
    this.prontuarioService.getAllAtestadosAtendimento(atendimentoId).subscribe((response) => {
      this.spinner.hide();
      this.atestadosAtendimento = response;
    }, (exception) => {
      this.spinner.hide();
      this.messageService.add({ severity: 'error', summary: '', detail: exception });
    })
  }

  concluirAtendimento() {
    this.spinner.show();

    this.prontuarioService.concluirAtendimento(this.prontuario).subscribe((response) => {
      this.spinner.hide();
      this.messageService.add({ severity: 'success', summary: '', detail: 'Atendimento concluído com sucesso' });
      this.routerNavigate.navigate(['/prontuario'], { queryParams: { pacienteId: this.pacienteId, profissionalId: this.profissionalSaudeId } }).then(() => {
        location.reload();

      });
    }, (exception) => {
      this.spinner.hide();
      this.messageService.add({ severity: 'error', summary: '', detail: exception });
    })
  }

  receiveTipoDocumento(modelo) {

    let modeloAtestado = modelo.value.texto;

    if (this.paciente.beneficiario.nome) {
      modeloAtestado = modelo.value.texto.replace("{{nome}}", this.paciente.beneficiario.nome.toUpperCase());
    }

    if (this.paciente.beneficiario.numeroCarteira) {
      modeloAtestado = modeloAtestado.replace("{{numeroCarteira}}", this.paciente.beneficiario.numeroCarteira);
    }

    if (this.paciente.nomeMae) {
      modeloAtestado = modeloAtestado.replace("{{mae}}", this.paciente.nomeMae);
    }

    if (this.paciente.beneficiario.dtNascimento) {
      modeloAtestado = modeloAtestado.replace("{{dataNascimento}}", this.paciente.beneficiario.dtNascimento);
    }

    modeloAtestado = modeloAtestado.replace("{{data}}", moment(new Date()).format("DD/MM/yyyy"));
    modeloAtestado = modeloAtestado.replace("{{dia}}", moment(new Date()).format("DD"));
    modeloAtestado = modeloAtestado.replace("{{mes}}", moment(new Date()).format("MM"));
    modeloAtestado = modeloAtestado.replace("{{ano}}", moment(new Date()).format("yyyy"));

    this.prontuarioForm.get("atestado_form")
      .patchValue(
        {
          atestadoText: modeloAtestado,
          modeloAtestado: modelo.value.nome
        }
      )
  }

  selecionarAtestado(modelo) {

    let description = "<p><b>ATESTADO</b></p>\n<p> <br> </p> \n<p>O(a) Sr.(a) %NOMEPACIENTE% compareceu e permaneceu nesta clí­nica, das %CAIXADETEXTO% às %CAIXADETEXTO% hs, do dia %DATAHOJE%.</p> \n<p> <br> </p> \n<p>&#9744; Acompanhando familiar.</p> \n<p>&#9744; Para exames subsidiários(%CAIXADETEXTO%) resultado à partir das %CAIXADETEXTO% hs no dia %CAIXADETEXTO%.</p> \n<p>&#9744; Para tratamento.</p> \n<p>&#9744; Para consulta, podendo trabalhar normalmente.</p> \n<p>&#9744; Para consulta, devendo permanecer afastado do serviço durante %CAIXADETEXTO% dias contados à partir de hoje.</p> \n<p>&#9744; %CAIXADETEXTO%.<br></p> \n<p> <br> </p> \n<p>%CAIXADETEXTO%, %DATAHOJE%.</p>\n";
    description = description.replace("%NOMEPACIENTE%", this.paciente.beneficiario.nome);
    this.prontuarioForm.get("atestado_form")
      .patchValue(
        {
          atestadoText: description
        }
      )
  }

  imprimirAtestado(): void {
    const content = this.pdfTable.nativeElement;

    // Gere um nome de arquivo com base na data atual
    const dataAtual = new Date();
    const nomeArquivo = `Documento_${dataAtual.getFullYear()}${dataAtual.getMonth() + 1}${dataAtual.getDate()}_${dataAtual.getHours()}${dataAtual.getMinutes()}${dataAtual.getSeconds()}.pdf`;

    const options = {
      margin: 10,
      filename: nomeArquivo,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
    };

    html2pdf().from(content.innerHTML).set(options).save();
  }

  editarPaciente() {
    this.spinner.show();
    this.pacienteService.getPacienteById(this.paciente.id).subscribe((paciente: Paciente) => {
      this.spinner.hide();
      this.pacienteComponent.showDialogPaciente(paciente);
    }, (exception) => {
      this.spinner.hide();
      this.messageService.add({ severity: 'error', summary: '', detail: exception });
    })
  }

  confirmarCancelamentoProntuario() {
    this.confirmationService.confirm({
      message: `Confirma o cancelamento do atendimento e descarte dos dados inseridos?`,
      accept: () => {
        this.cancelamentoProntuario();
      }
    });
  }

  cancelamentoProntuario() {

    this.spinner.show();
    this.prontuarioService.cancelamentoAtendimento(this.prontuario).subscribe((response) => {
      this.routerNavigate.navigate(['/prontuario'], { queryParams: { pacienteId: this.pacienteId, profissionalId: this.profissionalSaudeId } }).then(() => {
        this.removeAttendanceInStorage();
        location.reload();
      });

    }, (exception) => {
      this.spinner.hide();
      this.messageService.add({ severity: 'error', summary: '', detail: exception });
    })
  }

  private setAttendanceInStorage(pacienteId, atendimentoId, profissionalSaudeId) {
    localStorage.setItem('attendance', JSON.stringify({
      pacienteId: pacienteId,
      atendimentoId: atendimentoId,
      profissionalSaudeId: profissionalSaudeId,
      time: '00:00:00'
    }));
  }

  private removeAttendanceInStorage() {
    localStorage.removeItem('attendance');
  }

  toPdf(atendimentoId: number) {
    this.spinner.show();
    this.prontuarioService.toPdf(atendimentoId).subscribe((response: any) => {
      this.spinner.hide();

      // Cria uma nova janela para exibir o PDF
      const novaJanela = window.open('', '_blank');

      // Converte o resultado da requisição em um blob
      const blob = new Blob([response], { type: 'application/pdf' });

      // Cria uma URL temporária para o blob
      const url = URL.createObjectURL(blob);

      // Define a URL da nova janela como a URL do PDF
      novaJanela.location.href = url;
    }, (exception) => {
      this.spinner.hide();
      this.messageService.add({ severity: 'error', summary: '', detail: exception });
    })
  }

  changePedidoExameShow(option: string) {

    switch (option) {
      case "listar":
        this.pedidoExameShow = option;
        this.targetPedidoExames = [];

        this.getExamesAtendimento(this.atendimentoId);
        break;
      case "novo":
        this.pedidoExameShow = option;
        this.getExames(0);
        break;
      default:
        break;
    }
  }

  getExames(tipoExame) {
    this.spinner.show();
    this.prontuarioService.getExames(tipoExame).subscribe((geralExames: GeralExame) => {
      this.spinner.hide();
      this.sugesstionsExames = geralExames.exames;
      this.examesMaisPedidos = geralExames.examesMaisPedidos;

      switch (tipoExame) {
        case 0:
          this.sourceHeader = "Todos os Exames"
          break;
        case 1:
          this.sourceHeader = "Exames de Imagem"
          break;
        case 2:
          this.sourceHeader = "Exames de Laboratório"
          break;
        default:
          break;
      }
    }, (ex) => {
      this.spinner.hide();
    })
  }

  createOrUpdateExameAtendimento() {
    if (!this.prontuarioForm.get("exameAtendimento_form").value["id"]) {
      this.createExameAtendimento();
    } else {
      this.UpdateExameAtendimento(this.prontuarioForm.get("exameAtendimento_form").value["id"]);
    }
  }

  createExameAtendimento() {
    this.prontuarioForm.get("exameAtendimento_form").patchValue({ atendimentoId: this.atendimentoId, pedidoExame: this.targetPedidoExames });

    if (this.prontuarioForm.get("exameAtendimento_form").invalid) {
      this.messageService.add({ severity: 'error', summary: '', detail: 'Obrigatório selecionar o exame.' });
      return;
    }

    this.spinner.show();
    this.prontuarioService.createExameAtendimento(this.prontuarioForm.get("exameAtendimento_form").value).subscribe(() => {
      this.spinner.hide();
      this.messageService.add({ severity: 'success', summary: '', detail: 'Pedido de exame salvo!' });
      this.changePedidoExameShow('listar');
      this.prontuarioForm.get("exameAtendimento_form").reset();

    }, (exception) => {
      this.spinner.hide();
      this.messageService.add({ severity: 'error', summary: '', detail: exception });

    })
  }

  getExamesAtendimento(atendimentoId: number) {
    this.spinner.show();

    this.prontuarioService.GetExamesAtendimento(atendimentoId).subscribe((examesAtendimento: ExameAtendimento[]) => {
      this.spinner.hide();
      this.examesAtendimento = examesAtendimento;
    }, exception => {
      this.spinner.hide();
      this.messageService.add({ severity: 'error', summary: '', detail: exception });

    })
  }

  confirmarDeleteExameAtendimento(exameAtendimentoId: number) {
    this.confirmationService.confirm({
      message: `Confirma a exclusão do exame?`,
      accept: () => {
        this.deleteExameAtendimento(exameAtendimentoId);
      },
      reject: () => {

      }
    });
  }

  GetExameAtendimentoPorId(exameAtendimentoId: number) {
    this.spinner.show();

    this.prontuarioService.GetExameAtendimentoPorId(exameAtendimentoId).subscribe((exameAtendimento: any) => {
      this.spinner.hide();
      exameAtendimento.pedidoExame.forEach((pe) => this.targetPedidoExames.push(pe.exame));
      this.prontuarioForm.get("exameAtendimento_form").patchValue(
        {
          id: exameAtendimento.id,
          atendimentoId: exameAtendimento.atendimentoId,
          caraterSolicitacao: exameAtendimento.caraterSolicitacao,
          ciD10: exameAtendimento.ciD10,
          indicacaoClinica: exameAtendimento.indicacaoClinica,
          pedidoExame: this.targetPedidoExames
        });

      this.changePedidoExameShow('novo');

    }, exception => {
      this.spinner.hide();
      this.messageService.add({ severity: 'error', summary: '', detail: exception });

    })
  }

  UpdateExameAtendimento(exameAtendimentoId: number) {

    this.prontuarioForm.get("exameAtendimento_form").patchValue({ atendimentoId: this.atendimentoId, pedidoExame: this.targetPedidoExames });

    if (this.prontuarioForm.get("exameAtendimento_form").invalid) {
      this.messageService.add({ severity: 'error', summary: '', detail: 'Obrigatório selecionar o exame.' });
      return;
    }

    this.spinner.show();
    this.prontuarioService.UpdateExameAtendimento(exameAtendimentoId, this.prontuarioForm.get("exameAtendimento_form").value).subscribe(() => {
      this.spinner.hide();
      this.messageService.add({ severity: 'success', summary: '', detail: 'Pedido de exame atualizado com sucesso!' });
      this.changePedidoExameShow('listar');
      this.prontuarioForm.get("exameAtendimento_form").reset();

    }, exception => {
      this.spinner.hide();
      this.messageService.add({ severity: 'error', summary: '', detail: exception });
    })
  }

  deleteExameAtendimento(exameAtendimentoId: number) {
    this.spinner.show();

    this.prontuarioService.deleteExameAtendimento(exameAtendimentoId).subscribe(() => {
      this.spinner.hide();
      this.messageService.add({ severity: 'success', summary: '', detail: 'Pedido de exame excluído com sucesso!' });
      this.changePedidoExameShow('listar');

    }, exception => {
      this.spinner.hide();
      this.messageService.add({ severity: 'error', summary: '', detail: exception });
    })
  }

  onRowExameMaisPedidoSelect(examesMaisPedidoSelected: Exame) {
    const exameExistente = this.targetPedidoExames.find(e => e.exameId === examesMaisPedidoSelected.exameId);
    if (!exameExistente) {
      this.messageService.add({ severity: 'success', summary: '', detail: `Exame ${examesMaisPedidoSelected.nome} selecionado!` });
      this.targetPedidoExames.push(examesMaisPedidoSelected);
    }
  }

  toPdfGuiaSPSADT(atendimentoId: number, exameAtendimentoId: number, tipoExameId: number) {
    this.spinner.show();
    this.prontuarioService.toPdfGuiaSPSADT(exameAtendimentoId, atendimentoId != null ? atendimentoId : this.atendimentoId, tipoExameId).subscribe((response: any) => {
      this.spinner.hide();

      // Cria uma nova janela para exibir o PDF
      const novaJanela = window.open('', '_blank');

      // Converte o resultado da requisição em um blob
      const blob = new Blob([response], { type: 'application/pdf' });

      // Cria uma URL temporária para o blob
      const url = URL.createObjectURL(blob);

      // Define a URL da nova janela como a URL do PDF
      novaJanela.location.href = url;
    }, (exception) => {
      this.spinner.hide();
      this.messageService.add({ severity: 'error', summary: '', detail: exception });
    })
  }

  temTipoExame(exameAtendimento: any, tipo: string): boolean {
    return exameAtendimento.pedidoExame.some((pedidoExame: any) => pedidoExame.exame.tipoExame == tipo);
  }

  setCid10PedidoExame(cid10: any) {
    debugger
    if (cid10 != null) {
      this.prontuarioForm.get("exameAtendimento_form").patchValue({ CID10: cid10.cid });
    }
  }
}