import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { TipoGuia } from '../../../../models/tipo-guia.model';
import { GuiaService } from '../../../../services/guia.service';
import { ComunsService } from '../../../../comuns/comuns.service';
import { ETipoGuia } from 'src/app/enum/enums';
import { Dropdown } from '../../../../models/dropdown.model';

@Component({
  selector: 'app-tipo-guia-drop',
  templateUrl: './tipo-guia-drop.component.html',
  styleUrls: ['./tipo-guia-drop.component.css']
})
export class TipoGuiaDropComponent implements OnInit {

  tipoGuiaSelecionado: number = ETipoGuia.consulta;
  tiposGuia: Dropdown[];

  @Output() tipoGuia = new EventEmitter();
  @Input() tipoGuiaEdit;
  @Input() readonly: boolean = false;
  
  constructor(private guiaService: GuiaService, private comunsService: ComunsService) { }

  ngOnInit() {
    this.getTiposGuia();
  }

  getTiposGuia() {
    this.guiaService.getTiposGuia().subscribe((response) => {
      this.tiposGuia = this.comunsService.toSelectItem(response);
    }, (exception) => {
      console.log(exception);
    })
  }

  emit() {
    this.tipoGuia.emit(this.tipoGuiaSelecionado);
  }

  reset() {
    this.tipoGuiaSelecionado = null;
  }

  setTipoGuia(tipoGuiaId: number){
    this.tipoGuiaSelecionado = tipoGuiaId;
  }

}
