import { Especialidade } from './especialidade.model';
import { ProfissionalConselho } from './profissional-conselho.model';
import { Contratado } from './contratado.model';
import { Usuario } from './usuario.model';
import { Cidade } from './cidade.model';
import { Estado } from './estado.model';
import { ProfissionalContratado } from './profissional-contratado.model';
import { HorariosAgenda } from './horariosAgenda.model';
export class ProfissionalSaude {
    profissionalSaudeNome: string;
    telefone: string;
    dataNascimento: string;
    cpf: string;
    id: number;
    token: string; 
    usuario: Usuario;
    email: string;
    senha: string;
    cidade: Cidade;
    cidadeId: number;
    estado: Estado;
    estadoId: number;
    especialidade: Especialidade;
    especialidadeId: number;
    profissionalConselho: Array<ProfissionalConselho>;
    profissionalContratado: Array<Contratado>;
    contratados: Array<Contratado>;
    horariosAgenda: HorariosAgenda;
    horariosAgendaId: number;
    memedCadastrado: boolean;
    memed_token: string;

    constructor() {
        
    }
}