import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Empresa } from '../models/empresa.model';
import { MEDGESTAO_API } from '../app.api';
import { ErrorHandler } from '../pages/app.error-handler';
import { catchError, map } from 'rxjs/operators';
import { Usuario } from '../models/usuario.model';
import { FiltroUsuario } from 'src/app/models/filtroUsuario';
import { Estado } from 'src/app/models/estado.model';
import { Cidade } from 'src/app/models/cidade.model';

@Injectable({
    providedIn: 'root'
})
export class EmpresaService {

    constructor(public httpClient: HttpClient) {

    }

    createEmpresa(empresa: Empresa) {
        return this.httpClient.post(`${MEDGESTAO_API}/empresa/CreateEmpresa`, empresa)
            .pipe(
                map(response => { return response as string },
                    catchError(ErrorHandler.handleError)))
    }

    createUsuarioEmpresa(usuario: Usuario) {
        return this.httpClient.post(`${MEDGESTAO_API}/empresa/CreateUsuarioEmpresa`, usuario)
            .pipe(
                map(response => { return response as boolean },
                    catchError(ErrorHandler.handleError)))
    }

    usuariosEmpresa(parametros: FiltroUsuario) {
        return this.httpClient.post(`${MEDGESTAO_API}/empresa/UsuariosEmpresa`, parametros)
          .pipe(
            map(response => { return response as any},
              catchError(ErrorHandler.handleError)))
      }   


    updateUsuarioEmpresa(usuario: Usuario) {
        return this.httpClient.post(`${MEDGESTAO_API}/empresa/UpdateUsuarioEmpresa`, usuario)
            .pipe(
                map(response => { return response as Usuario },
                    catchError(ErrorHandler.handleError)))
        }
    
    getEmpresa(){
        return this.httpClient.get(`${MEDGESTAO_API}/empresa/GetEmpresa`)
            .pipe(
                map(response => {return response as Empresa},
                    catchError(ErrorHandler.handleError)))
        }

    updateEmpresa(empresaLogada: Empresa){
        return this.httpClient.post(`${MEDGESTAO_API}/empresa/UpdateEmpresa`, empresaLogada)
            .pipe(
                map(response => {return response as Empresa},
                    catchError(ErrorHandler.handleError)))
    }

    findEstados(){
        return this.httpClient.get(`${MEDGESTAO_API}/empresa/FindEstados`)
        .pipe(
            map(response => { return response as Array<Estado> },
                catchError(ErrorHandler.handleError)))
    }

    findCidades(estadoId: number) {
        return this.httpClient.get(`${MEDGESTAO_API}/empresa/FindCidades/${estadoId}`)
          .pipe(
            map(response => { return response as Array<Cidade> },
              catchError(ErrorHandler.handleError)))
    }
}
