import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { HospitalService } from '../../../services/hospital.service';
import { Hospital } from '../../../models/hospital.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { Dialog, LazyLoadEvent } from 'primeng/primeng';
import { ComunsService } from '../../../comuns/comuns.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { FiltroHospital } from '../../../models/filtro-hospital';
import { Paginacao } from '../../../models/paginacao.model';
import { Table } from 'primeng/table';

@Component({
  selector: 'app-guia-cirurgia-select-hospital',
  templateUrl: './guia-cirurgia-select-hospital.component.html',
  styleUrls: ['./guia-cirurgia-select-hospital.component.css']
})
export class GuiaCirurgiaSelectHospitalComponent implements OnInit {

  display: boolean = false;
  hospitais: Hospital[];
  filtro_form: FormGroup;

  totalRecords: number;
  first: number = 0;

  @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('dt') dt: Table;

  constructor(private hospitalService: HospitalService, private spinnerService: NgxSpinnerService,
    private comunsService: ComunsService, private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.form();
  }

  form() {
    this.filtro_form = this.formBuilder.group({
      nome: [null],
      codigoOperadora: [null],
    })
  }

  getAllHospitais(paginacao?: LazyLoadEvent) {
    this.spinnerService.show();
    let parametros = this.filtro_form.value as FiltroHospital;

    var pag = new Paginacao();
    if (paginacao == null) this.dt.first = 0;
    pag.setPaginacao(parametros, paginacao);

    this.hospitalService.getAllHospitais(parametros).subscribe((response) => {

      this.hospitais = response.hospitais;
      this.totalRecords = response.totalCount;

      this.spinnerService.hide();
    }, (exception) => {
      this.spinnerService.hide();
    })
  }

  showDialog() {
    this.filtro_form.reset();
    this.getAllHospitais();
    this.display = true;
  }

  setHospitalSelecionado(hospital: Hospital) {
    this.display = false;
    this.modalSave.emit(hospital);
  }

  showDialogMaximized(dialog: Dialog) {
    this.comunsService.showDialogMaximized(dialog);
  }
}
