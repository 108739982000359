import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { ContratadoService } from '../../../services/contratado.service';
import { SelectItem, MessageService } from 'primeng/primeng';
import { ActivatedRoute, Router } from '@angular/router';
import { Contratado } from 'src/app/models/contratado.model';
import { Convenio } from '../../../models/convenio.model';
import { ConvenioService } from '../../../services/convenio.service';
import { Dropdown } from '../../../models/dropdown.model';
import { ContratadoConvenio } from '../../../models/contratado-convenio.model';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-contratado',
  templateUrl: './contratado.component.html',
  styleUrls: ['./contratado.component.css'],
  providers: [MessageService]
})
export class ContratadoComponent implements OnInit {

  contratadoForm: FormGroup;
  tipoPessoa: SelectItem[];
  contratado: Contratado;
  convenios: Dropdown[];

  displayDialog: boolean;
  copyConvenio: ContratadoConvenio = {};
  selectedConvenio: ContratadoConvenio;

  get convenios_list() { return <FormArray>this.contratadoForm.get('contratadoConvenio'); }

  constructor(private formBuilder: FormBuilder,
    private contratadoService: ContratadoService,
    private router: ActivatedRoute,
    private routerNavigate: Router,
    private convenioService: ConvenioService,
    private spinner: NgxSpinnerService,
    private messageService: MessageService) {
    this.contratado = {} as Contratado;
    this.router
      .queryParams
      .subscribe(params => {
        this.contratado.id = params['code'];

        if (this.contratado.id)
          this.findById(this.contratado.id);

      });
  }

  ngOnInit() {
    this.form(null);
    this.getTodosConvenios();
    this.tipoPessoa = [
      { label: 'Física ', value: 1 },
      { label: 'Jurídica', value: 2 },
    ]
  }

  form(valueForm: Contratado) {
    this.contratadoForm = this.formBuilder.group({
      id: [this.contratado && this.contratado.id],
      razaoSocial: [valueForm && valueForm.razaoSocial, Validators.required],
      cpfCnpj: [valueForm && valueForm.cpfCnpj, Validators.required],
      cnes: [valueForm && valueForm.cnes, Validators.required],
      telefone: [valueForm && valueForm.telefone],
      celular: [valueForm && valueForm.celular],
      email: [valueForm && valueForm.email, Validators.required],
      tipoPessoaId: [null, Validators.required],
      pessoaResponsavel: ['', Validators.required],
      contratadoConvenio: this.formBuilder.array([]),
      contratadoConvenioForm: this.formBuilder.group({
        codigoOperadora: [null],
        convenioId: [null, Validators.required],
        xmlArqZip: [false],
        guiaPadraoSadt: [false],
        cpfProfExecutante: [false],
        faturamentoAutomatico: [false],
        custoOperacional: [''],
        loginPrestador: [''],
        senhaPrestador: [''],
        utfHash: ['']
      })
    });
  }

  salvar() {
    this.contratado = this.contratadoForm.value as Contratado;
    this.spinner.show();

    if (this.contratado.id > 0) {
      this.contratadoService.updateContratado(this.contratado).subscribe((response) => {
        this.spinner.hide();
        this.messageService.add({ severity: 'success', summary: '', detail: 'Registro alterado com sucesso!' });
        this.routerNavigate.navigate(['/contratados']);

      }, (exception) => {
        this.spinner.hide();
        this.messageService.add({ severity: 'error', summary: '', detail: exception });
      })
    } else {
      this.contratadoService.createContratado(this.contratado).subscribe((response) => {
        this.spinner.hide();
        this.messageService.add({ severity: 'success', summary: '', detail: 'Registro criado com sucesso!' });
        this.routerNavigate.navigate(['/contratados']);

      }, (exception) => {
        this.spinner.hide();
        this.messageService.add({ severity: 'error', summary: '', detail: exception });
      })
    }
  }

  findById(id: number) {
    this.spinner.show();
    this.contratadoService.contratado(id).subscribe((response) => {
      this.form(response);
      this.addListContratadoConvenio(response.contratadoConvenio);
      this.spinner.hide();

    }, (exception) => {
      this.spinner.hide();
      this.messageService.add({ severity: 'error', summary: '', detail: exception });
    })
  }

  incluirConvenio() {
    try {
      if ((<FormGroup>this.contratadoForm.controls['contratadoConvenioForm']).invalid)
        return false;

      var convenioSelecionado = (<FormGroup>this.contratadoForm.controls['contratadoConvenioForm']).value;
      if (this.convenios_list != null && this.convenios_list.value.find(c => c.convenioId == convenioSelecionado.convenioId)) {
        this.messageService.add({ severity: 'error', summary: '', detail: "Convênio já cadastrado!" });
        return false;
      }

      this.convenios_list.push(this.formBuilder.group({
        convenioId: convenioSelecionado.convenioId,
        nomeConvenio: this.convenios.find(c => c.value == convenioSelecionado.convenioId).label,
        codigoOperadora: convenioSelecionado.codigoOperadora,
        custoOperacional: !convenioSelecionado.custoOperacional ? 0 : convenioSelecionado.custoOperacional,
        xmlArqZip: convenioSelecionado.xmlArqZip == null ? false : convenioSelecionado.xmlArqZip,
        guiaPadraoSadt: convenioSelecionado.guiaPadraoSadt == null ? false : convenioSelecionado.guiaPadraoSadt,
        cpfProfExecutante: convenioSelecionado.cpfProfExecutante == null ? false : convenioSelecionado.cpfProfExecutante,
        faturamentoAutomatico: convenioSelecionado.faturamentoAutomatico == null ? false : convenioSelecionado.faturamentoAutomatico,
        loginPrestador: convenioSelecionado.loginPrestador,
        senhaPrestador: convenioSelecionado.senhaPrestador,
        utfHash: convenioSelecionado.utfHash
      }));

      (<FormGroup>this.contratadoForm.controls['contratadoConvenioForm']).reset();
      this.messageService.add({ severity: 'success', summary: '', detail: "Convênio Incluído com sucesso!" });
    } catch (exception) {
      console.log(exception);
    }
  }

  getTodosConvenios() {
    this.convenioService.getTodosConvenios().subscribe((convenios) => {
      this.convenios = convenios;
    });
  }

  addListContratadoConvenio(contratadoConvenio: Array<ContratadoConvenio>) {
    contratadoConvenio.forEach((contraConvenio, index) => {
      (this.convenios_list as FormArray).push(this.formBuilder.group({
        id: contraConvenio.id,
        contratadoId: contraConvenio.contratadoId,
        convenioId: contraConvenio.convenioId,
        nomeConvenio: contraConvenio.nomeConvenio,
        codigoOperadora: (contraConvenio.codigoOperadora != null && contraConvenio.codigoOperadora.trim() == "") ? null : contraConvenio.codigoOperadora,
        xmlArqZip: contraConvenio.xmlArqZip,
        guiaPadraoSadt: contraConvenio.guiaPadraoSadt,
        cpfProfExecutante: contraConvenio.cpfProfExecutante,
        faturamentoAutomatico: contraConvenio.faturamentoAutomatico,
        custoOperacional: contraConvenio.custoOperacional,
        loginPrestador: contraConvenio.loginPrestador,
        senhaPrestador: contraConvenio.senhaPrestador,
        utfHash: contraConvenio.utfHash
      }));
    })
  }

  showDialog(convenio) {
    this.copyConvenio = this.cloneConvenio(convenio);
    this.selectedConvenio = convenio;
    this.displayDialog = true;
  }

  cloneConvenio(c: ContratadoConvenio): ContratadoConvenio {
    let convenio = {};
    for (let prop in c) {
      convenio[prop] = c[prop];
    }
    return convenio;
  }

  alterarConvenio() {
    this.copyConvenio.codigoOperadora = (this.copyConvenio.codigoOperadora != null && this.copyConvenio.codigoOperadora.trim() == "") ? null : this.copyConvenio.codigoOperadora,
    this.copyConvenio.custoOperacional = !this.copyConvenio.custoOperacional ? 0 : this.copyConvenio.custoOperacional;
    this.convenios_list.value[this.convenios_list.value.indexOf(this.selectedConvenio)] = this.copyConvenio;
    this.fecharAlteracao();
    this.messageService.add({ severity: 'success', summary: '', detail: 'Registro alterado com sucesso!' });
  }

  fecharAlteracao() {
    this.selectedConvenio = null;
    this.copyConvenio = null;
    this.displayDialog = false;
  }

  clearConveniosList(arr: FormArray) {
    while (arr.length) {
      arr.removeAt(0);
    }
  }


}
