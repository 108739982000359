import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'obrigatorio',
  templateUrl: './caracter-campo-obrigatorio.component.html',
  styleUrls: ['./caracter-campo-obrigatorio.component.css']
})
export class CaracterCampoObrigatorioComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
