import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MEDGESTAO_API } from '../app.api';
import { ErrorHandler } from '../pages/app.error-handler';
import { catchError, map } from 'rxjs/operators';
import { Guia } from '../models/guia.model';
import { TipoTabela } from '../models/tipo-tabela.model';
import { TipoConsulta } from '../models/tipo-consulta.model';
import { IndicacaoAcidente } from '../models/indicacao-acidente.model';
import { FiltroGuia } from '../models/filtro-guia.model';
import { TipoGuia } from '../models/tipo-guia.model';
import { Situacao } from '../models/situacaoGuia.model';
import { GuiaGeracaoLote } from '../models/guia-geracao-lote.model';
import { CaraterAtendimento } from '../models/caraterAtendimento';
import { TipoAtendimentoGuia } from '../models/tipoAtendimentoGuia';


@Injectable({
  providedIn: 'root'
})
export class GuiaService {


  constructor(public httpClient: HttpClient) {
  }


  getTiposTabela() {
    return this.httpClient.get(`${MEDGESTAO_API}/guia/TipoTabela`)
      .pipe(
        map(response => { return response as TipoTabela[] },
          catchError(ErrorHandler.handleError)))
  }

  getTiposConsulta() {
    return this.httpClient.get(`${MEDGESTAO_API}/guia/TipoConsulta`)
      .pipe(
        map(response => { return response as TipoConsulta[] },
          catchError(ErrorHandler.handleError)))
  }

  getIndicacoesAcidente() {
    return this.httpClient.get(`${MEDGESTAO_API}/guia/IndicacaoAcidente`)
      .pipe(
        map(response => { return response as IndicacaoAcidente[] },
          catchError(ErrorHandler.handleError)))
  }

  getTiposGuia() {
    return this.httpClient.get(`${MEDGESTAO_API}/guia/TipoGuia`)
      .pipe(
        map(response => { return response as TipoGuia[] },
          catchError(ErrorHandler.handleError)))
  }

  getSituacoesGuia() {
    return this.httpClient.get(`${MEDGESTAO_API}/guia/SituacaoGuia`)
      .pipe(
        map(response => { return response as Situacao[] },
          catchError(ErrorHandler.handleError)))
  }

  getCaraterAtendimento() {
    return this.httpClient.get(`${MEDGESTAO_API}/guia/CaraterAtendimento`)
      .pipe(
        map(response => { return response as CaraterAtendimento[] },
          catchError(ErrorHandler.handleError)))
  }

  getTipoAtendimentoGuia() {
    return this.httpClient.get(`${MEDGESTAO_API}/guia/TipoAtendimentoGuia`)
      .pipe(
        map(response => { return response as TipoAtendimentoGuia[] },
          catchError(ErrorHandler.handleError)))
  }

  downloadGuia(nomeArquivo: string) {
    return this.httpClient.get(`${MEDGESTAO_API}/lancamento/DownloadArquivoLancamento/${nomeArquivo}`)
      .pipe(
        map(response => { return response as string },
          catchError(ErrorHandler.handleError)))
  }

  gerarGuiaConsulta(guia: Guia) {
    return this.httpClient.post(`${MEDGESTAO_API}/guia/GerarGuiaConsulta`, guia)
      .pipe(
        map(response => { return response },
          catchError(ErrorHandler.handleError)))
  }

  gerarGuiaCirurgia(parametros: Guia) {
    return this.httpClient.post(`${MEDGESTAO_API}/guia/GerarGuiaCirurgia`, parametros)
      .pipe(
        map(response => { return response },
          catchError(ErrorHandler.handleError)))
  }

  guias(filter: FiltroGuia) {
    return this.httpClient.post(`${MEDGESTAO_API}/guia/Guias`, filter)
      .pipe(
        map(response => { return response },
          catchError(ErrorHandler.handleError)))
  }

  getGuiasGeracaoLote(filter: FiltroGuia) {
    return this.httpClient.post(`${MEDGESTAO_API}/guia/GuiasGeracaoLote`, filter)
      .pipe(
        map(response => { return response as GuiaGeracaoLote },
          catchError(ErrorHandler.handleError)))
  }

  getGuiaPorId(guiaId: number) {
    return this.httpClient.get(`${MEDGESTAO_API}/guia/GetGuiaPorId/${guiaId}`)
      .pipe(
        map(response => { return response as Guia },
          catchError(ErrorHandler.handleError)))
  }

  atualizarGuiaCirurgia(guia: Guia) {
    return this.httpClient.post(`${MEDGESTAO_API}/guia/UpdateGuiaCirurgia`, guia)
      .pipe(
        map(response => { return response },
          catchError(ErrorHandler.handleError)))
  }

  atualizarGuiaConsulta(guia: Guia) {
    return this.httpClient.post(`${MEDGESTAO_API}/guia/UpdateGuiaConsulta`, guia)
      .pipe(
        map(response => { return response },
          catchError(ErrorHandler.handleError)))
  }

  deletarGuia(guiaId: number) {
    return this.httpClient.get(`${MEDGESTAO_API}/guia/DeleteGuia/${guiaId}`)
      .pipe(
        map(response => { return response },
          catchError(ErrorHandler.handleError)))
  }

}
