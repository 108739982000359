import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { NgModule, LOCALE_ID } from '@angular/core';

// import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ModalModule } from 'ngx-bootstrap/modal';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { PickListModule } from 'primeng/picklist';

import { ptBrLocale } from 'ngx-bootstrap/locale';
defineLocale('pt-br', ptBrLocale);

//locale
import localePT from '@angular/common/locales/pt';
import localeExtraPT from '@angular/common/locales/extra/pt';
import { registerLocaleData, CommonModule } from '@angular/common';
registerLocaleData(localePT, 'pt', localeExtraPT)

import { HttpModule } from '@angular/http';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

//Currency
import { CurrencyMaskModule } from "ng2-currency-mask";
import { CurrencyMaskConfig, CURRENCY_MASK_CONFIG } from "ng2-currency-mask/src/currency-mask.config";

//Mask
import { NgxMaskModule, IConfig } from 'ngx-mask'
export const options: Partial<IConfig> | (() => Partial<IConfig>) = {
  dropSpecialCharacters: false,
  validation: false
};

export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
  align: "right",
  allowNegative: true,
  decimal: ",",
  precision: 2,
  prefix: "R$ ",
  suffix: "",
  thousands: "."
};

//Modules PrimeNg
import { InputSwitchModule } from 'primeng/inputswitch';
import { ProgressBarModule } from 'primeng/progressbar';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { PanelModule } from 'primeng/panel';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { ChartModule } from 'primeng/chart';
import { TableModule } from 'primeng/table';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TabViewModule } from 'primeng/tabview';
import { AccordionModule } from 'primeng/accordion';
import { TooltipModule } from 'primeng/tooltip';
import { CalendarModule } from 'primeng/calendar';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { DialogModule, DynamicDialogRef, DynamicDialogConfig } from 'primeng/primeng';
import { InputTextModule } from 'primeng/inputtext';
import { FileUploadModule } from 'primeng/fileupload';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { CardModule } from 'primeng/card';
import { ToastModule } from 'primeng/toast';
import { InputMaskModule } from 'primeng/inputmask';
import { FieldsetModule } from 'primeng/fieldset';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { TabMenuModule } from 'primeng/tabmenu';
import { RadioButtonModule } from 'primeng/radiobutton';

//Modulo spinner
import { NgxSpinnerModule } from "ngx-spinner";
import { MenubarModule } from 'primeng/menubar';
import { MenuModule } from 'primeng/menu';
import { EditorModule } from 'primeng/editor';
import { AppComponent } from './app.component';
import { TopbarComponent } from './topbar/topbar.component';
import { MenuComponent, SubMenuComponent } from './menu/menu.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { AppRoutingModule } from './app.routing';
import { TabelaProcedimentoComponent } from './tabela-procedimento/tabela-procedimento.component';
import { TabelaPorteComponent } from './tabela-porte/tabela-porte.component';
import { LancamentosComponent } from './lancamentos/lancamentos.component';
import { BreadcrumbService } from '../services/breadcrumb.service';
import { ProfissionalSaudeService } from '../services/profissionalSaude.service';
import { ConvenioService } from '../services/convenio.service';
import { PacienteService } from '../services/paciente.service';
import { LancamentoService } from '../services/lancamento.service';
import { TabelaConvenioService } from '../services/tabelaConvenio.servoce';
import { PorteService } from '../services/porte.service';
import { GrauParticipacaoService } from '../services/grauParticipacao.service';
import { ComunsService } from '../comuns/comuns.service';
import { TussService } from '../services/tuss.service';
import { LoginComponent } from './login/login.component';
import { JwtInterceptor } from './_helpers/jwt.interceptor';
import { ErrorInterceptor } from './_helpers/error.interceptor';
import { LocalAtendimentoService } from '../services/localAtendimento.service';
import { ModuloComponentesModule } from './componentes/modulo-componentes/modulo-componentes.module';
import { UsuariosComponent } from './usuarios/usuarios.component';
import { AnexoLancamentoService } from '../services/anexoLancamento.service';
import { EmpresaComponent } from './empresa/empresa.component';
import { ChartService } from '../services/chart.service';
import { GuiaCirurgiaComponent } from './guia-cirurgia/guia-cirurgia.component';
import { GuiaConsultaComponent } from './guia-consulta/guia-consulta.component';
import { LotesComponent } from './lotes/lotes.component';
import { GerarLoteComponent } from './gerar-lote/gerar-lote.component';
import { GuiasComponent } from './guias/guias.component';
import { ConfiguracoesComponent } from './configuracoes/configuracoes.component';
import { ConfiguracaoTissComponent } from './configuracao-tiss/configuracao-tiss.component';
import { ProfissionaisExecutantesComponent } from './profissionais-executantes/profissionais-executantes.component';
import { LoteComponent } from './lotes/lote/lote.component';
import { LancamentosFaturamentoComponent } from './lancamentos-faturamento/lancamentos-faturamento.component';
import { OperadorasComponent } from './operadoras/operadoras.component';
import { UsuarioComponent } from './usuarios/usuario/usuario.component';
import { ContratadosComponent } from './contratados/contratados.component';
import { ContratadoComponent } from './contratados/contratado/contratado.component';
import { OperadoraComponent } from './operadoras/operadora/operadora.component';
import { ProfissionalExecutanteComponent } from './profissionais-executantes/profissional-executante/profissional-executante.component';
import { ContratadoService } from '../services/contratado.service';
import { IdentificarProfissionalComponent } from './profissionais-executantes/identificar-profissional/identificar-profissional.component';
import { CadastroComponent } from './cadastro/cadastro.component';
import { TopbarCadastroComponent } from './topbar-cadastro/topbar-cadastro.component';
import { EmpresaService } from '../services/empresa.service';
import { UsuarioService } from '../services/usuario.service';
import { LancamentoParticularComponent } from './lancamentos/lancamento-particular/lancamento-particular.component';
import { LancamentoConvenioComponent } from './lancamentos/lancamento-convenio/lancamento-convenio.component';
import { LancamentoComponent } from './lancamentos/lancamento/lancamento.component';
import { GuiaService } from '../services/guia.service';
import { BeneficiarioService } from '../services/beneficiario.service';
import { LoteService } from '../services/lote.service';
import { CepService } from '../services/cep.service';
import { RelatorioService } from '../services/relatorio.service';
import { RelatorioLancamentoComponent } from './relatorios/relatorio-lancamento/relatorio-lancamento.component';
import { ConverteBool } from '../pipes/converteBool';
import { GuiaCirurgiaSelectHospitalComponent } from './guia-cirurgia/guia-cirurgia-select-hospital/guia-cirurgia-select-hospital.component';
import { HospitalService } from '../services/hospital.service';
import { RelatorioGuiaComponent } from './relatorios/relatorio-guia/relatorio-guia.component';
import { RedefinirSenhaComponent } from './redefinir-senha/redefinir-senha.component';
import { CriarOuEditarProcedimentoComponent } from './tabela-procedimento/criar-ou-editar-procedimento/criar-ou-editar-procedimento.component';
import { SelectLancamentoComponent } from './lancamentos/lancamento-convenio/select-lancamento/select-lancamento.component';
import { RelatorioFaturamentoDiretoComponent } from './relatorios/relatorio-faturamento-direto/relatorio-faturamento-direto.component';
import { NovoProcedimentoParticularComponent } from './lancamentos/lancamento-particular/novo-procedimento-particular/novo-procedimento-particular.component';
import { AgendaComponent } from './agenda/agenda.component';
import { HorariosComponent } from './horarios/horarios.component';
import { AgendaService } from '../services/agenda.service';
import { AgendarPacienteComponent } from './agendar-paciente/agendar-paciente.component';
import { AtendimentoComponent } from './atendimento/atendimento.component';
import { AgendamentosComponent } from './agendamentos/agendamentos.component';
import { ProntuarioComponent } from './prontuario/prontuario.component';
import { PacientesComponent } from './pacientes/pacientes.component';
import { PacienteComponent } from './pacientes/paciente/paciente.component';
import { DetalheAgendamentoComponent } from './detalhe-agendamento/detalhe-agendamento.component';
import { HistoricoAtendimentoComponent } from './historico-atendimento/historico-atendimento.component';
import { SafeHtmlPipe } from './prontuario/safe-html.pipe';

@NgModule({
  declarations: [
    ConverteBool,
    AppComponent,
    TopbarComponent,
    MenuComponent,
    SubMenuComponent,
    FooterComponent,
    HomeComponent,
    TabelaProcedimentoComponent,
    TabelaPorteComponent,
    LancamentosComponent,
    LancamentoComponent,
    LoginComponent,
    UsuariosComponent,
    EmpresaComponent,
    GuiaCirurgiaComponent,
    GuiaConsultaComponent,
    LotesComponent,
    GerarLoteComponent,
    GuiasComponent,
    ConfiguracoesComponent,
    ConfiguracaoTissComponent,
    ProfissionaisExecutantesComponent,
    LoteComponent,
    LancamentosFaturamentoComponent,
    OperadorasComponent,
    UsuarioComponent,
    ContratadosComponent,
    ContratadoComponent,
    OperadoraComponent,
    ProfissionalExecutanteComponent,
    IdentificarProfissionalComponent,
    CadastroComponent,
    TopbarCadastroComponent,
    LancamentoParticularComponent,
    LancamentoConvenioComponent,
    RelatorioLancamentoComponent,
    GuiaCirurgiaSelectHospitalComponent,
    RelatorioGuiaComponent,
    RedefinirSenhaComponent,
    CriarOuEditarProcedimentoComponent,
    SelectLancamentoComponent,
    RelatorioFaturamentoDiretoComponent,
    NovoProcedimentoParticularComponent,
    AgendaComponent,
    HorariosComponent,
    AgendarPacienteComponent,
    AtendimentoComponent,
    AgendamentosComponent,
    ProntuarioComponent,
    PacientesComponent,
    PacienteComponent,
    DetalheAgendamentoComponent,
    HistoricoAtendimentoComponent,
    SafeHtmlPipe,
  ],
  // entryComponents: [LancamentoConvenioComponent, GuiaCirurgiaComponent, GuiaConsultaComponent],
  imports: [

    BrowserAnimationsModule,
    EditorModule,
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserModule,
    InputSwitchModule,
    ProgressBarModule,
    ScrollPanelModule,
    PanelModule,
    CheckboxModule,
    DropdownModule,
    ChartModule,
    TableModule,
    SplitButtonModule,
    TabViewModule,
    AccordionModule,
    TooltipModule,
    CalendarModule,
    BreadcrumbModule,
    InputTextModule,
    FileUploadModule,
    AutoCompleteModule,
    HttpClientModule,
    HttpModule,
    DialogModule,
    MessagesModule,
    MessageModule,
    CardModule,
    ToastModule,
    // InputMaskModule,
    FieldsetModule,
    NgxSpinnerModule,
    ModuloComponentesModule,
    CurrencyMaskModule,
    NgxMaskModule.forRoot(options),
    InputTextareaModule,
    ConfirmDialogModule,
    DynamicDialogModule,
    TieredMenuModule,
    TabMenuModule,
    RadioButtonModule,
    MenubarModule,
    MenuModule,
    BsDatepickerModule.forRoot(),
    ModalModule.forRoot(),
    TabsModule.forRoot(),
    PickListModule
  ],
  providers: [
    BreadcrumbService,
    ProfissionalSaudeService,
    ConvenioService,
    LancamentoService,
    TabelaConvenioService,
    PorteService,
    LocalAtendimentoService,
    GrauParticipacaoService,
    ComunsService,
    TussService,
    ChartService,
    AnexoLancamentoService,
    ContratadoService,
    EmpresaService,
    UsuarioService,
    GuiaService,
    PacienteService,
    CepService,
    BeneficiarioService,
    LoteService,
    RelatorioService,
    HospitalService,
    DynamicDialogRef,
    DynamicDialogConfig,
    AgendaService,
    { provide: LOCALE_ID, useValue: "pt-BR" },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
