import { Component, OnInit } from '@angular/core';
import {  ViewChild } from '@angular/core';
import { ProfissionalSaudeService } from '../../services/profissionalSaude.service';
import { ProfisisonalEmpresa } from '../../models/profissional-empresa.model';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormGroup, FormBuilder, Validators } from '@angular/forms'; 
import { ContratadoConvenio } from '../../models/contratado-convenio.model'; 
import { ProfissionalSaude } from '../../models/profissionalSaude.model'; 
import { Contratado } from '../../models/contratado.model'; 
import { ContratadoService } from '../../services/contratado.service'; 
import { Paginacao } from '../../models/paginacao.model'; //
import { FiltroLancamento } from '../../models/filtro-lancamento.model'; //
import { ConfirmationService, SelectItem, MessageService, MenuItem, LazyLoadEvent } from 'primeng/primeng';
import { Table } from 'primeng/table';
import { Lancamento } from 'src/app/models/lancamento.model';
import { ProfissionalExecutanteComponent } from './profissional-executante/profissional-executante.component';


@Component({
  selector: 'app-profissionais-executantes',
  templateUrl: './profissionais-executantes.component.html',
  styleUrls: ['./profissionais-executantes.component.css']
})
export class ProfissionaisExecutantesComponent implements OnInit {
  @ViewChild('dt') dt: Table;
  @ViewChild(ProfissionalExecutanteComponent) profissionalExecutanteComponent: ProfissionalExecutanteComponent;

  profissionais2: Array<ProfisisonalEmpresa>;
  profSaude: any;
  filtro_form: FormGroup; 
  convenios: Array<ContratadoConvenio>; 
  profissionais: Array<ProfissionalSaude>; 
  contratados: Array<Contratado>; 
  totalRecords: number;
  first: number = 0;

  constructor(private profissionalService: ProfissionalSaudeService, 
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder, 
    private contratadoService: ContratadoService, 
    private router: Router) {
  }

  ngOnInit() {
    this.form(); 
    this.contratadosByEmpresa();
  }

  form() { 
    this.filtro_form = this.formBuilder.group({ 
      profissionalSaudeId: [''], 
      contratadoId: [''], 
      convenioId: [''], 
      contratado: [null], 
      convenio: [null], 
      profissionalSaude: [null], 
    })
  }

  profissionalSaudeByEmpresa(paginacao?: LazyLoadEvent) {
    this.spinner.show();
    this.setValueFiltro(this.filtro_form.value);
    let parametros = this.filtro_form.value as FiltroLancamento;
    
    var pag = new Paginacao();

    if (paginacao == null) this.dt.first = 0;
    pag.setPaginacao(parametros, paginacao);
   
    this.profissionalService.profissionalSaudeByEmpresa(parametros).subscribe((response: any) => {

      this.profissionais2 = response.profissionais;
      this.totalRecords = response.totalCount;
      this.spinner.hide();
    }, (exception) => {

      console.log(exception)
      this.spinner.hide();
    })
  }
  
  editar(id: number) {
    this.profissionalExecutanteComponent.showDialogProfissionalExecutante(id);
  }

  novoProfissional() {
    // this.spinner.show();
    // var id = 0;
    // this.router.navigate(['/profissional-executante'], { queryParams: { code: id } });
    this.profissionalExecutanteComponent.showDialogProfissionalExecutante(0);
  }




  //ATUALIZAÇÕES
  receiveContratado(contratado: any) {
    var form = (<FormGroup>this.filtro_form);

    if (contratado) {
      this.convenios = contratado.contratadoConvenio;
      this.profissionais = contratado.profissionalSaude;
    }
    else {
      form.controls["contratado"].reset();
      form.controls["convenio"].reset();
      form.controls["profissionalSaude"].reset();
      this.convenios = [];
      this.profissionais = [];
    }
  }

  contratadosByEmpresa() {
    this.spinner.show();
    this.contratadoService.contratadosByEmpresa().subscribe((response) => {

      this.contratados = response;
      this.spinner.hide();
    }, (exception) => {
      this.spinner.hide();
      console.log(exception);
    })
  }

  setValueFiltro(filtroForm) {
    this.filtro_form.patchValue({
      profissionalSaudeId: filtroForm.profissionalSaude == null ? null : filtroForm.profissionalSaude.id,
      contratadoId: filtroForm.contratado == null ? null : filtroForm.contratado.id,
      convenioId: filtroForm.convenio == null ? null : filtroForm.convenio.convenioId,
    });

  }
}


