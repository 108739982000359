import { Component, OnInit, ViewChild } from '@angular/core';
import { AppComponent } from '../app.component';
import { AuthenticationService } from '../../services/authentication.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { EPermissao } from '../../models/permissao.model';
import * as moment from 'moment';
import { ProfissionalExecutanteComponent } from '../profissionais-executantes/profissional-executante/profissional-executante.component';
import { Usuario } from 'src/app/models/usuario.model';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.css']
})
export class TopbarComponent implements OnInit {

  username: string = "";
  time: string = '00:00:00 Atendimento em Andamento';
  intervalTime: NodeJS.Timer;
  attendanceCurrent: any;
  usuario: Usuario;

  @ViewChild(ProfissionalExecutanteComponent) profissionalExecutanteComponent: ProfissionalExecutanteComponent;


  constructor(public app: AppComponent, private authenticationService: AuthenticationService, private spinner: NgxSpinnerService,
    private router: Router) {
      this.usuario = this.authenticationService.currentUserValue.user;
  }

  AttendanceNowStart() {
    clearInterval(this.intervalTime);
    if (this.attendanceCurrent) {

      var constTime = this.attendanceCurrent && this.attendanceCurrent.time || '00:00:00';
      var now = moment(constTime, "HH:mm:ss");

      this.intervalTime = setInterval(() => {
        now.add(1, 's');
        var attendenceNow = moment(now).format("HH:mm:ss");
        this.time = `${attendenceNow} Atendimento em Andamento`;

        this.attendanceCurrent.time = attendenceNow;
        localStorage.setItem('attendance', JSON.stringify(this.attendanceCurrent));

      }, 1000);
    }

  }

  getAttendanceInStorage() {
    this.attendanceCurrent = JSON.parse(localStorage.getItem('attendance'));
  }


  fadeInDown = {
    content: 'topbar-menu fadeInDown',
    status: false
  };

  ngOnInit() {
    this.getAttendanceInStorage();
    // this.AttendanceNowStart();
    this.username = this.authenticationService.currentUserValue.user.nomePessoa;
  }

  toogleMenu() {
    if (!this.fadeInDown.status) {
      this.fadeInDown.content = 'topbar-menu fadeInDown topbar-menu-visible';
      this.fadeInDown.status = true;
    } else {
      this.fadeInDown.content = 'topbar-menu fadeInDown';
      this.fadeInDown.status = false;
    }
  }

  logout() {
    this.spinner.show();
    this.authenticationService.logout();
    setTimeout(() => {
      this.spinner.hide();
      // this.router.navigate(['/login']);
    }, 100);
  }

  home() {
    if (this.authenticationService.canViewPermissaoUsuario({ roles: [EPermissao.Sec] })) {
      this.router.navigate(['/lancamentos-faturamento']);
    } else {
      this.router.navigate(['/home']);
    }
  }

  editar() {
    this.profissionalExecutanteComponent.showDialogProfissionalExecutante(this.usuario.profissionalSaudeId);
  }

  onClose() {
    
  }

}
