import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { AgendaService } from '../../services/agenda.service';
import { AgendarPacienteComponent } from '../agendar-paciente/agendar-paciente.component';
import { ConfirmationService } from 'primeng/primeng';

@Component({
  selector: 'app-horarios',
  templateUrl: './horarios.component.html',
  styleUrls: ['./horarios.component.css'],
  providers: [ConfirmationService]
})
export class HorariosComponent implements OnInit {

  @ViewChild(AgendarPacienteComponent) agendarPacienteComponent: AgendarPacienteComponent;
  @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();

  horarios: any[];
  diaAgendaCorrente: any;


  constructor(private router: Router, private agendaService: AgendaService,
    private confirmationService: ConfirmationService) { }
  
 

  ngOnInit() {
    this.horarios = [
      {
        hora: "08:00",
        paciente: "Alessandra Gonçalves Domingues",
        convenio: "Amil"
      },
      {
        hora: "09:00",
        paciente: "Uilian de Jesus Silva",
        convenio: "Premium Saúde"
      },
      {
        hora: "10:00",
        paciente: "João Cleber",
        convenio: "Vitallis"
      },
      {
        hora: "11:00",
        paciente: "Gilberto Rodrigues",
        convenio: "Promed"
      },
      {
        hora: "12:00",
        paciente: "José Roberto",
        convenio: "Amil"
      },
      {
        hora: "13:00",
        paciente: "Afonso Ribeiro",
        convenio: "Amil"
      },
      {
        hora: "08:00"
      },
      {
        hora: "09:00"
      },
      {
        hora: "10:00"
      },
      {
        hora: "11:00"
      },
      {
        hora: "12:00"
      },
      {
        hora: "13:00"
      },
      {
        hora: "08:00"
      },
      {
        hora: "09:00"
      },
      {
        hora: "10:00"
      },
      {
        hora: "11:00"
      },
      {
        hora: "12:00"
      },
      {
        hora: "13:00"
      },
      {
        hora: "08:00"
      },
      {
        hora: "09:00"
      },
      {
        hora: "10:00"
      },
      {
        hora: "11:00"
      },
      {
        hora: "12:00"
      },
      {
        hora: "13:00"
      },
    ];

    this.agendaService.diaAgendaCorrente.subscribe(data => this.diaAgendaCorrente = data);
    console.log(this.diaAgendaCorrente);
  }

  navigateToAgenda() {
    this.router.navigateByUrl('/agenda');
  }

  showAgendarPaciente() {
    this.agendarPacienteComponent.open();
  }

  confirm() {
    this.confirmationService.confirm({
      message: 'Confirma bloqueio  de horário para às 10:00?',
      accept: () => {
        //Actual logic to perform a confirmation
      }
    });
  }

}
