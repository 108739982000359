import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/primeng';

@Component({
  selector: 'app-redefinir-senha',
  templateUrl: './redefinir-senha.component.html',
  styleUrls: ['./redefinir-senha.component.css'],
  providers: [MessageService]
})
export class RedefinirSenhaComponent implements OnInit {

  novaSenhaForm: FormGroup;
  submitted = false;
  senhaNaoConfere = false;
  model: RedefinirSenha = {} as RedefinirSenha;
  iconSenha: string = "fa fa-eye";
  typeSenha: string = "password";

  iconRepetirSenha: string = "fa fa-eye";
  typeRepetirSenha: string = "password";

  constructor(private formBuilder: FormBuilder,
    private _router: Router,
    private messageService: MessageService,
    private _spinner: NgxSpinnerService,
    private auth: AuthenticationService,
    private _activatedRoute: ActivatedRoute) {
    this.model.userId = this._activatedRoute.snapshot.queryParams['userId'];
    this.model.code = this._activatedRoute.snapshot.queryParams['code'];
  }

  ngOnInit() {
    this.novaSenhaForm = this.formBuilder.group({
      novaSenha: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]],
      confirmaNovaSenha: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]]
    });
  }

  trocarIconSenha() {
    if (this.iconSenha == "fa fa-eye-slash") {
      this.iconSenha = "fa fa-eye";
      this.typeSenha = "password";
    }
    else {
      this.iconSenha = "fa fa-eye-slash";
      this.typeSenha = "text";
    }
  }

  trocarIconRepetirSenha() {
    if (this.iconRepetirSenha == "fa fa-eye-slash") {
      this.iconRepetirSenha = "fa fa-eye";
      this.typeRepetirSenha = "password";
    }
    else {
      this.iconRepetirSenha = "fa fa-eye-slash";
      this.typeRepetirSenha = "text";
    }
  }

  validaSenha() {
    if (this.novaSenhaForm.value["novaSenha"] != this.novaSenhaForm.value["confirmaNovaSenha"]) {
      this.senhaNaoConfere = true;
      return false;
    } else {
      this.senhaNaoConfere = false;
      return true;
    }
  }

  onSubmit() {
    this.submitted = true;

    if (this.novaSenhaForm.invalid) {
      return;
    }
    if (!this.validaSenha()) { return; }
    this.model.novaSenha = this.novaSenhaForm.value["novaSenha"];

    this._spinner.show();
    this.auth.redefinirSenha(this.model).subscribe((sucesso) => {

      if (sucesso) {
        this._spinner.hide();
        this.messageService.add({ severity: 'success', summary: '', detail: "Senha alterada com sucesso!" });
        setTimeout(() => {
          this._router.navigate(['/login']);
        }, 2000);
      }
    }, (exception) => {
      this.senhaNaoConfere = false;
      this.submitted = false;
      this._spinner.hide();
      this.messageService.add({ severity: 'error', summary: '', detail: exception });
    });

  }

  validacoes = {
    'valida': [
      { type: 'required', message: 'Este campo é obrigatório.' },
      { type: 'minlength', message: 'Senha deve conter 6 dígitos.' },
      { type: 'maxlength', message: 'Senha deve conter 6 dígitos.' },
    ]
  };

}
