import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { LancamentoService } from '../../services/lancamento.service';
import { Lancamento } from '../../models/lancamento.model';
import { AnexoLancamento } from '../../models/anexoLancamento.model';
import { FormGroup, FormBuilder } from '@angular/forms';
import { FiltroLancamento } from '../../models/filtro-lancamento.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/primeng';
import { DownloadGuiaComponent } from '../componentes/modulo-componentes/download-guia/download-guia.component';

@Component({
  selector: 'app-lancamentos',
  templateUrl: './lancamentos.component.html',
  styleUrls: ['./lancamentos.component.css'],
  providers: [MessageService]
})
export class LancamentosComponent implements OnInit {
  @ViewChild(DownloadGuiaComponent) guias: DownloadGuiaComponent;

  public lancamentos: Lancamento[];
  public profSaude = [];
  display: boolean = false;

  lancamento_form: FormGroup;

  constructor(
    private spinner: NgxSpinnerService,
    private lancamentoService: LancamentoService,
    private messageService: MessageService,
    private auth: AuthenticationService,
    private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.form();
    this.lancamentosByEmpresa();
  }

  form() {
    this.lancamento_form = this.formBuilder.group({
      convenioId: [''],
      hospitalId: [''],
      dtLancamentoInicial: [''],
      dtLancamentoFinal: [''],
    })
  }

  showGuias(anexos: AnexoLancamento[]) {
    this.guias.showDialog(anexos);
  }

  lancamentosByEmpresa() {
    this.spinner.show();
    let parametros = this.lancamento_form.value as FiltroLancamento;
    this.lancamentoService.lancamentosByEmpresa(parametros).subscribe((lancamentos) => {
      this.lancamentos = lancamentos;
      this.spinner.hide();
    }, (exception) => {
      this.spinner.hide();
      this.messageService.add({ severity: 'info', summary: '', detail: exception });
    })
  }

  receiveLocalAtendimento(localAtendimentoId) {
    console.log(localAtendimentoId);
    this.lancamento_form.patchValue({
      hospitalId: localAtendimentoId
    });
  }

  receiveConvenio(convenioId) {
    console.log(convenioId);
    this.lancamento_form.patchValue({
      convenioId: convenioId
    });
  }

  receiveDataInicial(dtLancamentoInicial) {
    console.log(dtLancamentoInicial);
    this.lancamento_form.patchValue({
      dtLancamentoInicial: dtLancamentoInicial
    });
  }

  receiveDataFinal(dtLancamentoFinal) {
    console.log(dtLancamentoFinal);
    this.lancamento_form.patchValue({
      dtLancamentoFinal: dtLancamentoFinal
    });
  }

}
