import { Component, OnInit } from '@angular/core';
import { Usuario } from '../../models/usuario.model';
import { EmpresaService } from '../../services/empresa.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Permissao } from '../../models/permissao.model';
import { UsuarioService } from '../../services/usuario.service'; 
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {  ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { ConfirmationService, SelectItem, MessageService, MenuItem, LazyLoadEvent } from 'primeng/primeng';//
import { Paginacao } from '../../models/paginacao.model'; 
import { FiltroUsuario } from 'src/app/models/filtroUsuario';
import { ComunsService } from 'src/app/comuns/comuns.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.css']
})
export class UsuariosComponent implements OnInit {
  @ViewChild('dt') dt: Table;

  permissoes: Array<Permissao>;;
  usuariosEmpresa: Array<Usuario>;
  usuarioForm: FormGroup; 
  totalRecords: number;
  first: number = 0;

    constructor(private empresaService: EmpresaService, 
    private spinner: NgxSpinnerService,
    private usuarioService: UsuarioService,
    private comunsService: ComunsService,
    private router: Router,
    private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.form(); 
    this.findAllPermissoes(); 
  }

  form() { 
    this.usuarioForm = this.formBuilder.group({
      email: [''],
      nomePessoa: [''],
      permissaoId: [null],
    });
  }

  findAllUsuariosEmpresa(paginacao?: LazyLoadEvent) { 
    this.spinner.show();
    
    let parametros = this.usuarioForm.value as FiltroUsuario;

    var pag = new Paginacao(); 

    if (paginacao == null) this.dt.first = 0;
    pag.setPaginacao(parametros, paginacao);

    this.empresaService.usuariosEmpresa(parametros).subscribe((response) => {
      this.usuariosEmpresa = response.usuarios;
      this.totalRecords = response.totalCount;
      this.spinner.hide();
    }, (exception) => {
      this.spinner.hide();
    })
  }
  findAllPermissoes() {
    this.usuarioService.permissoes().subscribe((response) => {
      this.permissoes = this.comunsService.toSelectItem(response);;
    }, (exception) => {
      console.log(exception);
    })
  }

  editar(id: number) {
    this.router.navigate(['/usuario'], { queryParams: { code: id } });
  }

}
