import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ComunsService } from '../../../../comuns/comuns.service';
import { Dialog, MessageService } from 'primeng/primeng';
import { ProcedimentoParticular } from '../../../../models/procedimentoParticular.model';
import { LancamentoService } from '../../../../services/lancamento.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-novo-procedimento-particular',
  templateUrl: './novo-procedimento-particular.component.html',
  styleUrls: ['./novo-procedimento-particular.component.css'],
  providers: [MessageService]
})
export class NovoProcedimentoParticularComponent implements OnInit {

  display: boolean = false;
  submitted = false;
  @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();
  procedimento_form: FormGroup;
  profissionalId: number;

  constructor(private comunsService: ComunsService,
    private lancamentoService: LancamentoService,
    private spinner: NgxSpinnerService,
    private messageService: MessageService,
    private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.form();
  }

  form() {
    this.procedimento_form = this.formBuilder.group({
      procedimento: [null, Validators.required]
    })
  }

  salvarProcedimento() {
    this.submitted = true;

    if (this.procedimento_form.invalid) {
      return;
    }

    var procedimento = {
      profissionalSaudeId: this.profissionalId,
      procedimento: this.procedimento_form.value["procedimento"]
    } as ProcedimentoParticular;

    this.spinner.show();
    this.lancamentoService.novoProcedimentoParticular(procedimento).subscribe((procedimento) => {
      this.submitted = false;
      this.spinner.hide();
      this.messageService.add({ severity: 'success', summary: '', detail: 'Procedimento salvo com sucesso.' });
      this.setNovoProcedimentoSelecionado(procedimento);
    }, (ex) => {
      this.spinner.hide();
    })
  }

  setNovoProcedimentoSelecionado(procedimento: ProcedimentoParticular) {
    this.display = false;
    this.modalSave.emit(procedimento);
  }

  showDialogNovoProcedimento(profissionalId: number) {
    this.form();
    this.display = true;
    this.profissionalId = profissionalId;
  }

  voltar() {
    this.display = false;
    this.modalSave.emit(null);
  }


}
