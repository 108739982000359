import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MEDGESTAO_API } from '../app.api';
import { map, catchError } from 'rxjs/operators';
import { ErrorHandler } from '../pages/app.error-handler';
import { Permissao } from '../models/permissao.model';
import { Usuario } from '../models/usuario.model';

@Injectable({
    providedIn: 'root'
})
export class UsuarioService {

    constructor(public httpClient: HttpClient) { }

    permissoes() {
        return this.httpClient.get(`${MEDGESTAO_API}/users/Permissoes`)
            .pipe(
                map(response => { return response as Array<Permissao> },
                    catchError(ErrorHandler.handleError)))
    }

    usuario(usuarioId: number) {
        return this.httpClient.get(`${MEDGESTAO_API}/users/BuscarUsuarioPorId/${usuarioId}`)
            .pipe(
                map(response => { return response as Usuario },
                    catchError(ErrorHandler.handleError)))
    }
}