import { Component, OnInit, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { ComunsService } from '../../../../comuns/comuns.service';
import { TussService } from '../../../../services/tuss.service';
import { Tuss } from '../../../../models/tuss.model';


@Component({
  selector: 'app-tuss',
  templateUrl: './tuss.component.html',
  styleUrls: ['./tuss.component.css']
})
export class TussComponent implements OnInit {

  tussSelecionado: Tuss;
  listaTuss: Tuss[];
  constructor(private comunsService: ComunsService, private tussService: TussService) { }

  @Output() tuss = new EventEmitter();
  @Input() tussEdit;

  ngOnInit() {
  }

  ngOnAfterViewInit() {
    this.tussSelecionado.id = this.tussEdit;
  }

  getTuss(event) {
    let query = event.query;
    if (query && query.length >= 3) {
      if (this.comunsService.isNumeric(query)) {
        this.tussService.getTussNumber(query).subscribe((tuss) => {
          if (tuss.length == 0) { this.tuss.emit(null); }
          this.listaTuss = tuss;
        });
      } else {
        this.tussService.getTussText(query).subscribe((tuss) => {
          if (tuss.length == 0) { this.tuss.emit(null); }
          this.listaTuss = tuss;
        })
      }
    } else {
      this.tuss.emit(null);
    }
  }

  emit() {
    this.tuss.emit(this.tussSelecionado);
  }

  reset() {
    this.tussSelecionado = null;
  }

}
