import { Injectable } from '@angular/core';
import { MEDGESTAO_API } from '../app.api';
import { Http } from '@angular/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Dropdown } from '../models/dropdown.model';
import { ErrorHandler } from '../pages/app.error-handler';
import { ProcedimentoTabelaConvenio } from '../models/procedimentoTabelaConvenio.model';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class TabelaConvenioService {

    constructor(public http: HttpClient) {
    }

    getNomeTabelas(): Observable<Dropdown[]> {
        return this.http.get(`${MEDGESTAO_API}/tabelaConvenio/getNomeTabelas`)
            .pipe(
                map(response => { return response as Dropdown[] },
                    catchError(ErrorHandler.handleError)))
    }

    getProcedimentos(tabelaId: number): Observable<ProcedimentoTabelaConvenio[]> {
        return this.http.get(`${MEDGESTAO_API}/tabelaConvenio/getProcedimentos/${tabelaId}`)
            .pipe(
                map(response => { return response as ProcedimentoTabelaConvenio[] },
                    catchError(ErrorHandler.handleError)))
    }

    getProcedimentoPorId(tabelaId: number, procedimentoId: number): Observable<ProcedimentoTabelaConvenio> {
        return this.http.get(`${MEDGESTAO_API}/tabelaConvenio/GetProcedimentoPorId/${tabelaId}/${procedimentoId}`)
            .pipe(
                map(response => { return response as ProcedimentoTabelaConvenio },
                    catchError(ErrorHandler.handleError)))
    }

    getProcedimentosPorCodigoTuss(codigo: number, tabelaId: number): Observable<ProcedimentoTabelaConvenio[]> {
        return this.http.get(`${MEDGESTAO_API}/tabelaConvenio/GetProcedimentosPorCodigoTuss/${codigo}/${tabelaId}`)
            .pipe(
                map(response => { return response as ProcedimentoTabelaConvenio[] },
                    catchError(ErrorHandler.handleError)))
    }

    getProcedimentosPorNomeTuss(nome: string, tabelaId: number): Observable<ProcedimentoTabelaConvenio[]> {
        return this.http.get(`${MEDGESTAO_API}/tabelaConvenio/GetProcedimentosPorNomeTuss/${nome}/${tabelaId}`)
            .pipe(
                map(response => { return response as ProcedimentoTabelaConvenio[] },
                    catchError(ErrorHandler.handleError)))
    }

    criarProcedimentoTabelaConvenio(procedimento: ProcedimentoTabelaConvenio) {
        return this.http.post(`${MEDGESTAO_API}/tabelaConvenio`, procedimento)
            .pipe(
                map(response => { return response },
                    catchError(ErrorHandler.handleError)))
    }

    atualizarProcedimentoTabelaConvenio(procedimento: ProcedimentoTabelaConvenio) {
        return this.http.post(`${MEDGESTAO_API}/tabelaConvenio/UpdateProcedimentoTabelaConvenio`, procedimento)
            .pipe(
                map(response => { return response },
                    catchError(ErrorHandler.handleError)))
    }

}
