import { Component, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { Dialog, LazyLoadEvent, MessageService } from 'primeng/primeng';
import { ComunsService } from '../../../../comuns/comuns.service';
import { Table } from 'primeng/table';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Lancamento } from '../../../../models/lancamento.model';
import { Paginacao } from '../../../../models/paginacao.model';
import { FiltroLancamento } from '../../../../models/filtro-lancamento.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { LancamentoService } from '../../../../services/lancamento.service';
import { EStatusLancamento, ETipoAtendimento } from 'src/app/enum/enums';
import { ContratadoService } from '../../../../services/contratado.service';
import { ContratadoConvenio } from '../../../../models/contratado-convenio.model';
import { ProfissionalSaude } from '../../../../models/profissionalSaude.model';
import { Contratado } from 'src/app/models/contratado.model';

@Component({
  selector: 'app-select-lancamento',
  templateUrl: './select-lancamento.component.html',
  styleUrls: ['./select-lancamento.component.css'],
  providers: [MessageService]
})

export class SelectLancamentoComponent implements OnInit {

  display: boolean = false;
  filtro_form: FormGroup;
  totalRecords: number;
  first: number = 0;
  lancamentos: Array<Lancamento>;

  convenios: Array<ContratadoConvenio>;
  convenio: ContratadoConvenio;

  profissionais: Array<ProfissionalSaude>;
  profissional: ProfissionalSaude;

  contratados: Array<Contratado>;
  contratado: Contratado;

  tipoLancamento: number;
  grauParticipacao: number;

  @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('dt') dt: Table;

  constructor(private comunsService: ComunsService,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private messageService: MessageService,
    private contratadoService: ContratadoService,
    private lancamentoService: LancamentoService) { }

  ngOnInit() {
    this.form();
  }

  form() {
    this.filtro_form = this.formBuilder.group({
      profissionalSaudeId: [''],
      contratadoId: [''],
      convenioId: [''],
      dtLancamentoInicial: [''],
      dtLancamentoFinal: [''],
      nomePaciente: [''],
      statusLancamentoId: [EStatusLancamento.emLancamento],
      numeroGuia: [''],
      contratado: [null],
      convenio: [null],
      profissionalSaude: [null],
      tipoAtendimentoId: [ETipoAtendimento.convenio],
      tipoLancamentoId: [null],
      grauParticipacaoId: [null]
    })
  }

  receiveContratado(contratado: any) {
    var form = (<FormGroup>this.filtro_form);

    if (contratado) {
      this.convenios = contratado.contratadoConvenio;
      this.profissionais = contratado.profissionalSaude;
    }
    else {
      form.reset();
      this.convenios = [];
      this.profissionais = [];
    }
  }

  showDialog(filtros) {
    this.contratado = null;
    this.convenio = null;
    this.profissional = null;
    this.lancamentos = [];
    this.filtro_form.reset();
    this.form();
    this.filtro_form.patchValue({
      nomePaciente: filtros.nomePaciente
    });
    this.contratadosByEmpresa(filtros["contratadoConvenioForm"].contratado, filtros["contratadoConvenioForm"].convenio,
      filtros["contratadoConvenioForm"].profissionalSaude, filtros.tipoLancamentoId, filtros.grauParticipacaoId);
    this.display = true;
  }

  contratadosByEmpresa(contratado?: any, convenio?: any, profissional?: any, tipoLancamento?: any, grauParticipacao?: any) {
    this.spinner.show();
    this.contratadoService.contratadosByEmpresa().subscribe((response) => {
      this.contratados = response;

      if (contratado) {
        this.contratado = this.contratados.find(c => c.id == contratado.id);
        this.receiveContratado(this.contratado);
        this.filtro_form.patchValue({
          contratado: this.contratado
        });
      }
      if (convenio) {
        this.convenio = this.convenios.find(c => c.convenioId == convenio.id);
        this.filtro_form.patchValue({
          convenio: this.convenio
        });
      }
      if (profissional) {
        this.profissional = this.profissionais.find(p => p.id == profissional.id);
        this.filtro_form.patchValue({
          profissionalSaude: this.profissional
        });
      }

      if (tipoLancamento) {
        this.tipoLancamento = tipoLancamento;
        this.receiveTipoLancamento(tipoLancamento);
      }

      if (grauParticipacao) {
        this.grauParticipacao = grauParticipacao;
        this.receiveGrauParticipacao(grauParticipacao);
      }

      this.getAllLancamentos();
      this.spinner.hide();
    }, (exception) => {
      this.spinner.hide();
    })
  }

  getAllLancamentos(paginacao?: LazyLoadEvent) {
    this.spinner.show();
    this.setValueFiltro(this.filtro_form.value);
    let parametros = this.filtro_form.value as FiltroLancamento;
    parametros.dtLancamentoInicial = this.comunsService.transformDate(parametros.dtLancamentoInicial);
    parametros.dtLancamentoFinal = this.comunsService.transformDate(parametros.dtLancamentoFinal);

    var pag = new Paginacao();
    if (paginacao == null) this.dt.first = 0;
    pag.setPaginacao(parametros, paginacao);

    this.lancamentoService.getAllLancamentos(parametros).subscribe((response: any) => {

      this.lancamentos = response.lancamentos;
      this.totalRecords = response.totalCount;

      this.spinner.hide();
    }, (exception) => {

      console.log(exception)
      this.spinner.hide();
      this.messageService.add({ severity: 'info', summary: '', detail: exception });
    })
  }

  setValueFiltro(filtroForm) {
    this.filtro_form.patchValue({
      profissionalSaudeId: filtroForm.profissionalSaude == null ? null : filtroForm.profissionalSaude.id,
      contratadoId: filtroForm.contratado == null ? null : filtroForm.contratado.id,
      convenioId: filtroForm.convenio == null ? null : filtroForm.convenio.convenioId,
    });
  }

  setLancamentoSelecionado(lancamento: Lancamento) {
    this.display = false;
    this.modalSave.emit(lancamento);
  }

  showDialogMaximized(dialog: Dialog) {
    this.comunsService.showDialogMaximized(dialog);
  }

  receiveTipoLancamento(tipoLancamento) {
    this.filtro_form.patchValue({
      tipoLancamentoId: tipoLancamento && tipoLancamento.id
    });
  }

  receiveGrauParticipacao(grauParticipacao) {
    this.filtro_form.patchValue({
      grauParticipacaoId: grauParticipacao && grauParticipacao.id
    });
  }
}
