import { Component, OnInit, ViewChild } from '@angular/core';
import { DetalheAgendamentoComponent } from '../detalhe-agendamento/detalhe-agendamento.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-agendamentos',
  templateUrl: './agendamentos.component.html'
})
export class AgendamentosComponent implements OnInit {

  @ViewChild(DetalheAgendamentoComponent) detalheAgendamentoComponent: DetalheAgendamentoComponent;

  agendaDia: any;
  options: any;
  events: any[];
  bsInlineValue = new Date();
  
  constructor(private router: Router) { }

  ngOnInit() {}

  navigateToProntuario() {
    this.router.navigateByUrl('/prontuario');
  }

}
