import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { TipoVia } from '../../../../models/tipo-via.model';
import { LancamentoService } from '../../../../services/lancamento.service';

@Component({
  selector: 'app-tipo-via',
  templateUrl: './tipo-via.component.html',
  styleUrls: ['./tipo-via.component.css']
})
export class TipoViaComponent implements OnInit {

  tipoViaSelecionado: TipoVia;
  tiposVia: TipoVia[];

  constructor(private lancamentoService: LancamentoService) { }

  @Output() tipoVia = new EventEmitter();
  @Input() tipoViaEdit;

  ngOnInit() {
    this.getTiposViaLancamento();
  }

  ngOnAfterViewInit() {
    this.tipoViaSelecionado.id = this.tipoViaEdit;
  }

  getTiposViaLancamento() {
    this.lancamentoService.getTiposViaLancamento().subscribe((tipos) => {
      this.tiposVia = tipos;
    });
  }

  emit() {
    this.tipoVia.emit(this.tipoViaSelecionado);
  }

  reset() {
    this.tipoViaSelecionado = null;
  }
}
