import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-operadoras',
  templateUrl: './operadoras.component.html',
  styleUrls: ['./operadoras.component.css']
})
export class OperadorasComponent implements OnInit {
  profSaude: any;
  lancamentos: any;
  constructor() { }

  ngOnInit() {
  }

  receiveConvenio(event) {

  }
  
}
