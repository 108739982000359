import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { TabelaProcedimentoComponent } from './tabela-procedimento/tabela-procedimento.component';
import { TabelaPorteComponent } from './tabela-porte/tabela-porte.component';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './_guards/auth.guard';
import { UsuariosComponent } from './usuarios/usuarios.component';
import { GuiaCirurgiaComponent } from './guia-cirurgia/guia-cirurgia.component';
import { GuiaConsultaComponent } from './guia-consulta/guia-consulta.component';
import { LotesComponent } from './lotes/lotes.component';
import { GerarLoteComponent } from './gerar-lote/gerar-lote.component';
import { GuiasComponent } from './guias/guias.component';
import { ConfiguracaoTissComponent } from './configuracao-tiss/configuracao-tiss.component';
import { LoteComponent } from './lotes/lote/lote.component';
import { LancamentosFaturamentoComponent } from './lancamentos-faturamento/lancamentos-faturamento.component';
import { ProfissionaisExecutantesComponent } from './profissionais-executantes/profissionais-executantes.component';
import { UsuarioComponent } from './usuarios/usuario/usuario.component';
import { ContratadosComponent } from './contratados/contratados.component';
import { ContratadoComponent } from './contratados/contratado/contratado.component';
import { OperadoraComponent } from './operadoras/operadora/operadora.component';
import { ProfissionalExecutanteComponent } from './profissionais-executantes/profissional-executante/profissional-executante.component';
import { IdentificarProfissionalComponent } from './profissionais-executantes/identificar-profissional/identificar-profissional.component';
import { CadastroComponent } from './cadastro/cadastro.component';
import { EPermissao } from '../models/permissao.model';
import { LancamentoComponent } from './lancamentos/lancamento/lancamento.component';
import { RelatorioLancamentoComponent } from './relatorios/relatorio-lancamento/relatorio-lancamento.component';
import { RelatorioGuiaComponent } from './relatorios/relatorio-guia/relatorio-guia.component';
import { RedefinirSenhaComponent } from './redefinir-senha/redefinir-senha.component';
import { CriarOuEditarProcedimentoComponent } from './tabela-procedimento/criar-ou-editar-procedimento/criar-ou-editar-procedimento.component';
import { RelatorioFaturamentoDiretoComponent } from './relatorios/relatorio-faturamento-direto/relatorio-faturamento-direto.component';
import { EmpresaComponent } from './empresa/empresa.component';
import { AgendaComponent } from './agenda/agenda.component';
import { HorariosComponent } from './horarios/horarios.component';
import { AtendimentoComponent } from './atendimento/atendimento.component';
import { AgendamentosComponent } from './agendamentos/agendamentos.component';
import { PacientesComponent } from './pacientes/pacientes.component';
import { PacienteComponent } from './pacientes/paciente/paciente.component';
import { ProntuarioComponent } from './prontuario/prontuario.component';

const routes: Routes = [
    { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
    // { path: 'faturamento', component: FaturamentoComponent, canActivate: [AuthGuard], data: { roles: [{ name: 'fat' }] } },
    // { path: 'lancamentos', component: LancamentosComponent, canActivate: [AuthGuard], data: { roles: [EPermissao.Admin, EPermissao.Sec] } },
    { path: 'lancamento', component: LancamentoComponent, canActivate: [AuthGuard], data: { roles: [EPermissao.Admin, EPermissao.Sec, EPermissao.Fat] } },
    { path: 'lancamentos-faturamento', component: LancamentosFaturamentoComponent, canActivate: [AuthGuard], data: { roles: [EPermissao.Admin, EPermissao.Fat, EPermissao.Sec, EPermissao.Med] } },
    { path: 'guias', component: GuiasComponent, canActivate: [AuthGuard], data: { roles: [EPermissao.Admin, EPermissao.Fat] } },
    { path: 'lotes', component: LotesComponent, canActivate: [AuthGuard], data: { roles: [EPermissao.Admin, EPermissao.Fat] } },
    { path: 'lotes/:code', component: LotesComponent, canActivate: [AuthGuard], data: { roles: [EPermissao.Admin, EPermissao.Fat] } },
    // { path: 'lote', component: LoteComponent, canActivate: [AuthGuard], data: { roles: [EPermissao.Admin, EPermissao.Fat] } },
    { path: 'gerar-lote', component: GerarLoteComponent, canActivate: [AuthGuard], data: { roles: [EPermissao.Admin, EPermissao.Fat] } },
    { path: 'tabela-convenio', component: TabelaProcedimentoComponent, canActivate: [AuthGuard], data: { roles: [EPermissao.Admin, EPermissao.Fat, EPermissao.Med] } },
    { path: 'procedimento', component: CriarOuEditarProcedimentoComponent, canActivate: [AuthGuard], data: { roles: [EPermissao.Admin, EPermissao.Fat , EPermissao.Med] } },
    { path: 'tabela-porte', component: TabelaPorteComponent, canActivate: [AuthGuard], data: { roles: [EPermissao.Admin, EPermissao.Fat, EPermissao.Med] } },
    { path: 'relatorio-lancamento', component: RelatorioLancamentoComponent, canActivate: [AuthGuard], data: { roles: [EPermissao.Admin, EPermissao.Med, EPermissao.Sec, EPermissao.Fat] } },
    { path: 'relatorio-guia', component: RelatorioGuiaComponent, canActivate: [AuthGuard], data: { roles: [EPermissao.Admin, EPermissao.Med, EPermissao.Fat] } },
    { path: 'relatorio-faturamento-direto', component: RelatorioFaturamentoDiretoComponent, canActivate: [AuthGuard], data: { roles: [EPermissao.Admin, EPermissao.Med, EPermissao.Fat] } },
    { path: 'guia-cirurgia', component: GuiaCirurgiaComponent, canActivate: [AuthGuard], data: { roles: [EPermissao.Admin, EPermissao.Fat] } },
    { path: 'guia-consulta', component: GuiaConsultaComponent, canActivate: [AuthGuard], data: { roles: [EPermissao.Admin, EPermissao.Fat] } },
    { path: 'contratados', component: ContratadosComponent, canActivate: [AuthGuard], data: { roles: [EPermissao.Admin] } },
    { path: 'contratado', component: ContratadoComponent, canActivate: [AuthGuard], data: { roles: [EPermissao.Admin] } },
    { path: 'usuarios', component: UsuariosComponent, canActivate: [AuthGuard], data: { roles: [EPermissao.Admin] } },
    { path: 'usuario', component: UsuarioComponent, canActivate: [AuthGuard], data: { roles: [EPermissao.Admin] } },
    // { path: 'operadoras', component: OperadorasComponent, canActivate: [AuthGuard], data: { roles: [{ name: 'med' }] } },
    { path: 'operadora', component: OperadoraComponent, canActivate: [AuthGuard], data: { roles: [EPermissao.Admin] } },
    { path: 'profissionais-executantes', component: ProfissionaisExecutantesComponent, canActivate: [AuthGuard], data: { roles: [EPermissao.Admin, EPermissao.Sec] } },
    { path: 'profissional-executante', component: ProfissionalExecutanteComponent, canActivate: [AuthGuard], data: { roles: [EPermissao.Admin, EPermissao.Sec] } },
    { path: 'identificar-profissional', component: IdentificarProfissionalComponent, canActivate: [AuthGuard], data: { roles: [EPermissao.Admin] } },
    { path: 'configuracao-tiss', component: ConfiguracaoTissComponent, canActivate: [AuthGuard], data: { roles: [EPermissao.Admin] } },
    { path: 'cadastro', component: CadastroComponent },
    { path: 'login', component: LoginComponent },
    { path: 'redefinir-senha', component: RedefinirSenhaComponent },
    { path: 'empresa', component: EmpresaComponent, canActivate: [AuthGuard], data: { roles: [EPermissao.Admin] } },
    { path: 'agenda', component: AgendaComponent, canActivate: [AuthGuard] },
    { path: 'horarios', component: HorariosComponent, canActivate: [AuthGuard] },
    { path: 'atendimentos', component: AtendimentoComponent, canActivate: [AuthGuard] },
    { path: 'agendamentos', component: AgendamentosComponent, canActivate: [AuthGuard] },
    { path: 'pacientes', component: PacientesComponent, canActivate: [AuthGuard] },
    { path: 'paciente', component: PacienteComponent, canActivate: [AuthGuard] },
    { path: 'prontuario', component: ProntuarioComponent, canActivate: [AuthGuard] },

    // otherwise redirect to home
    { path: '**', redirectTo: 'home' }
];

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        RouterModule.forRoot(routes)
    ],
    exports: [
    ],
})
export class AppRoutingModule { }
