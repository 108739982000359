import { Injectable } from '@angular/core';
import { MEDGESTAO_API } from '../app.api';
import { Http } from '@angular/http';
import { ErrorHandler } from '../pages/app.error-handler';
import { catchError, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Dropdown } from '../models/dropdown.model';
import { ProfissionalSaude } from '../models/profissionalSaude.model';
import { ProfisisonalEmpresa } from '../models/profissional-empresa.model';
import { FiltroLancamento } from '../models/filtro-lancamento.model';
import { Memed } from '../models/memed.model'

@Injectable()
export class ProfissionalSaudeService {

  constructor(public httpClient: HttpClient) {
  }

  profissional: ProfissionalSaude;

  getProfissionaisSaude() {
    return this.httpClient.get(`${MEDGESTAO_API}/profissionalSaude/NomeProfissionaisSaude`)
      .pipe(
        map(response => { return response as Dropdown[] },
          catchError(ErrorHandler.handleError)))
  }

  findProfissionalByIdentificator(id: number) {
    return this.httpClient.get(`${MEDGESTAO_API}/profissionalSaude/ProfissionalSaudeByIdentificator/${id}`)
      .pipe(
        map(response => { return response as ProfissionalSaude },
          catchError(ErrorHandler.handleError)))
  }

  profissionalSaudeByEmpresa(parametros: FiltroLancamento) {
    return this.httpClient.post(`${MEDGESTAO_API}/profissionalSaude/ProfissionalSaudeByEmpresa`, parametros)
      .pipe(
        map(response => response,
          catchError(ErrorHandler.handleError)))
  }

  createProfissionalSaude(parametros: ProfissionalSaude) {
    return this.httpClient.post(`${MEDGESTAO_API}/users/CreateProssionalSaude`, parametros)
      .pipe(
        map(response => { return response as boolean },
          catchError(ErrorHandler.handleError)))
  }
  updateProfissionalSaudeWeb(parametros: ProfissionalSaude) {
    return this.httpClient.post(`${MEDGESTAO_API}/profissionalSaude/UpdateProfissionalSaudeWeb`, parametros)
      .pipe(
        map(response => { return response as boolean },
          catchError(ErrorHandler.handleError)))
  }

  cadastroMemed(medicoId: number) {
    return this.httpClient.post(`${MEDGESTAO_API}/memed/CadastrarMemed`, medicoId)
      .pipe(
        map(response => { return response as boolean },
          catchError(ErrorHandler.handleError)))
  }

}
