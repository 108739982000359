
enum ETipoLancamento {
    consulta = 1,
    exame = 2,
    procedimentos = 3
}

enum EGrauParticipacao {
    atendimentoClinica = 5
}

enum EStatusLancamento {
    emLancamento = 1,
    guiaGerada = 2,
    guiaFaturada = 3
}

enum ETipoAtendimento {
    particular = 1,
    convenio = 2
}

enum ETipoGuia {
    consulta = 1,
    honorario = 2
}

enum ESituacaoGuia {
    Digitada = 1,
    Faturada = 2
}

enum ESituacaoLote {
    Faturado = 1,
    EmAndamento = 2
}

enum EFormaPagamento {
    dinheiro = 1,
    cheque = 2,
    cartaoCredito = 3,
    cartaoDebito = 4
}

enum ESituacaoAgendamento {
    Atendido = 2,
    EmAtendimento = 8
}


export { ETipoLancamento, EGrauParticipacao, EStatusLancamento, ETipoAtendimento, ETipoGuia, ESituacaoGuia, EFormaPagamento, ESituacaoLote, ESituacaoAgendamento };