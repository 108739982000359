import { DropdownModule } from 'primeng/dropdown';
import { AutoCompleteModule } from 'primeng/autocomplete';

import { LottieAnimationViewModule } from 'ng-lottie';
import {InputMaskModule} from 'primeng/inputmask';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LocalAtendimentoComponent } from './local-atendimento/local-atendimento-component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConvenioComponent } from './convenio/convenio.component';
import { GrauParticipacaoComponent } from './grau-participacao/grau-participacao.component';
import { TipoViaComponent } from './tipo-via/tipo-via.component';
import { TussComponent } from './tuss/tuss.component';
import { TipoLancamentoComponent } from './tipo-lancamento/tipo-lancamento.component';
import { IntervaloDataComponent } from './intervalo-data/intervalo-data.component';
import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from 'primeng/primeng';
import { TableModule } from 'primeng/table';
import { StatusLancamentoComponent } from './status-lancamento/status-lancamento.component';
import { ProfissionalSaudeComponent } from './profissional-saude/profissional-saude.component';
import { TussViaComponent } from './tuss-via/tuss-via.component';
import { AtualizaStatusComponent } from './atualiza-status/atualiza-status.component';
import { ChartCardComponent } from './chart-card/chart-card.component';
import { ChartCardBarComponent } from './chart-card-bar/chart-card-bar.component';
import { ChartModule } from 'primeng/chart';
import { CardModule } from 'primeng/card';
import { CaracterCampoObrigatorioComponent } from './caracter-campo-obrigatorio/caracter-campo-obrigatorio.component';
import { DownloadGuiaComponent } from './download-guia/download-guia.component';
import { ContratadoDropComponent } from './contratado/contratado-drop.component';
import { TipoPagamentoComponent } from './tipo-pagamento/tipo-pagamento.component';
import { TipoGuiaDropComponent } from './tipo-guia-drop/tipo-guia-drop.component';

@NgModule({
  declarations: [
    LocalAtendimentoComponent, 
    ConvenioComponent, 
    GrauParticipacaoComponent, 
    TipoViaComponent, 
    TussComponent, 
    TipoLancamentoComponent,
    IntervaloDataComponent,
    DownloadGuiaComponent,
    StatusLancamentoComponent,
    ProfissionalSaudeComponent,
    TussViaComponent,
    AtualizaStatusComponent,
    ChartCardComponent,
    ChartCardBarComponent,
    CaracterCampoObrigatorioComponent,
    ContratadoDropComponent,
    TipoPagamentoComponent,
    TipoGuiaDropComponent],
  imports: [
    DropdownModule,
    AutoCompleteModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CalendarModule,
    DialogModule,
    TableModule,
    ChartModule,
    CardModule,
    InputMaskModule,
    LottieAnimationViewModule.forRoot()
  ],
  exports: [
    LocalAtendimentoComponent,
    ConvenioComponent,
    GrauParticipacaoComponent,
    TipoViaComponent,
    TussComponent,
    TipoLancamentoComponent,
    IntervaloDataComponent,
    DownloadGuiaComponent,
    StatusLancamentoComponent,
    ProfissionalSaudeComponent,
    TussViaComponent,
    AtualizaStatusComponent,
    ChartCardComponent,
    ChartCardBarComponent,
    CaracterCampoObrigatorioComponent,
    ContratadoDropComponent,
    TipoPagamentoComponent,
    TipoGuiaDropComponent
  ]
})
export class ModuloComponentesModule { }
