import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-detalhe-agendamento',
  templateUrl: './detalhe-agendamento.component.html',
  styleUrls: ['./detalhe-agendamento.component.css']
})
export class DetalheAgendamentoComponent implements OnInit {

  display: boolean = false;
  constructor() { }

  ngOnInit() {
  }

  showDialogDetalheAgendamento() {
    this.display = true;
  }  

  
  
}
