import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MEDGESTAO_API } from '../app.api';
import { map, catchError } from 'rxjs/operators';
import { ErrorHandler } from '../pages/app.error-handler';
import { VersaoTiss } from '../models/verstao-tiss.model';
import { Lote } from '../models/lote.model';
import { FiltroGuia } from '../models/filtro-guia.model';
import { RequestOptions, ResponseContentType, Http } from '@angular/http';
import { FiltroLote } from '../models/filtro-lote';
import { Situacao } from '../models/situacaoGuia.model';

@Injectable({
    providedIn: 'root'
})
export class LoteService {

    constructor(public httpClient: HttpClient, public http: Http) {

    }

    lotes(parametros: FiltroLote) {
        return this.httpClient.post(`${MEDGESTAO_API}/lote/GetLotes`, parametros)
            .pipe(
                map(response => { return response as Lote[] },
                    catchError(ErrorHandler.handleError)))
    }

    getVersoesTiss() {
        return this.httpClient.get(`${MEDGESTAO_API}/lote/VersaoTiss`)
            .pipe(
                map(response => { return response as VersaoTiss[] },
                    catchError(ErrorHandler.handleError)))
    }

    abrirLote(loteId: number) {
        return this.httpClient.get(`${MEDGESTAO_API}/lote/AbrirLote/${loteId}`)
            .pipe(
                map(response => { return response as Lote },
                    catchError(ErrorHandler.handleError)))
    }

    finalizarLote(lote: Lote) {
        return this.httpClient.post(`${MEDGESTAO_API}/lote/FinalizarLote`, lote)
            .pipe(
                map(response => { return response },
                    catchError(ErrorHandler.handleError)))
    }

    atualizarLote(lote: Lote) {
        return this.httpClient.post(`${MEDGESTAO_API}/lote/AtualizarLote`, lote)
            .pipe(
                map(response => { return response },
                    catchError(ErrorHandler.handleError)))
    }

    criarLote(lote: Lote) {
        return this.httpClient.post(`${MEDGESTAO_API}/lote/CriarLote`, lote)
            .pipe(
                map(response => { return response },
                    catchError(ErrorHandler.handleError)))
    }

    baixarLoteXml(loteId: number) {
        let options = new RequestOptions({ responseType: ResponseContentType.Blob });

        return this.http.get(`${MEDGESTAO_API}/lote/BaixarLoteXml/${loteId}`, options)
            .pipe(
                map(response => { return response },
                    catchError(ErrorHandler.handleError)))
    }

    download(file: Blob, numeroLote: number, extension: string) {
        let blob = new Blob([file], { type: 'application/pdf' });

        var downloadURL = window.URL.createObjectURL(blob);
        var link = document.createElement('a');
        link.href = downloadURL;
        link.download = "lote_" + numeroLote + extension;
        link.click();
    }

    deleteLote(loteId: number) {
        return this.httpClient.get(`${MEDGESTAO_API}/lote/DeleteLote/${loteId}`)
            .pipe(
                map(response => { return response },
                    catchError(ErrorHandler.handleError)))
    }

    visualizarLote(loteId: number) {
        return this.httpClient.get(`${MEDGESTAO_API}/relatorio/VisualizarLote/${loteId}`,
            {
                responseType: 'blob'
            })
            .pipe(
                map(blob => { return new Blob([blob], { type: "application/pdf" }) },
                    catchError(ErrorHandler.handleError)))
    }

    getSituacoesLote() {
        return this.httpClient.get(`${MEDGESTAO_API}/lote/SituacaoLote`)
            .pipe(
                map(response => { return response as Situacao[] },
                    catchError(ErrorHandler.handleError)))
    }

    atualizarValorLote(loteUpdate: Lote) {
        return this.httpClient.post(`${MEDGESTAO_API}/lote/UpdateValorTotalLote`, loteUpdate)
            .pipe(
                map(response => { return response },
                    catchError(ErrorHandler.handleError)))
    }
}