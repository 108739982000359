import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { EmpresaService } from '../../services/empresa.service';
import { Empresa } from 'src/app/models/empresa.model';
import { MessageService, ConfirmationService } from 'primeng/primeng';
import { Message } from 'primeng/components/common/api';
import { NgxSpinnerService } from 'ngx-spinner';
import { Estado } from 'src/app/models/estado.model';
import { Cidade } from 'src/app/models/cidade.model';
import { ComunsService } from 'src/app/comuns/comuns.service';

@Component({
  selector: 'app-empresa',
  templateUrl: './empresa.component.html',
  styleUrls: ['./empresa.component.css'],
  styles: [`
        :host ::ng-deep button {
            margin-right: .25em;
        }

        :host ::ng-deep .ui-message,
        :host ::ng-deep .ui-inputtext {
            margin-right: .25em;
        }
    `],
    providers: [ConfirmationService, MessageService]
})
export class EmpresaComponent implements OnInit {

  msgs: Message[] = [];
  isMessageReturn: Boolean = false;
  cadastroForm: FormGroup;
  empresaLog: Empresa;
  estados: Array<Estado>;
  cidades: Array<Cidade>;

  constructor(private formBuilder: FormBuilder,
    private empresaService: EmpresaService,
    private spinner: NgxSpinnerService,
    private comunsService: ComunsService,
    private messageService: MessageService) { }

  ngOnInit() {
    this.form(null);
    this.findAllEstados();
    this.getEmpresa();
  }

  form(empresaLogada: Empresa) {
    this.cadastroForm = this.formBuilder.group({
      id: [empresaLogada && empresaLogada.id],
      cnpj: [empresaLogada && empresaLogada.cnpj, Validators.required],
      razaoSocial: [empresaLogada && empresaLogada.razaoSocial, Validators.required],
      nomeEmpresa: [empresaLogada && empresaLogada.nomeEmpresa, Validators.required],
      nomePessoaResponsavel: [empresaLogada && empresaLogada.nomePessoaResponsavel, Validators.required],
      telefone: [empresaLogada && empresaLogada.telefone, Validators.required],
      cnes: [empresaLogada && empresaLogada.cnes, Validators.required],
      estado: [empresaLogada && empresaLogada.cidade.estado, Validators.required],
      estadoId: [''],
      cidadeId: [''],
      cidade: [null],
      email: [empresaLogada && empresaLogada.email, Validators.required],
      senha: [empresaLogada && empresaLogada.senha, Validators.required]
    });
  }

  getEmpresa(){
      this.spinner.show();
      this.empresaService.getEmpresa().subscribe((empresaLogada: Empresa) =>{
        var result = empresaLogada;
        this.findAllCidades(result.cidade.estado.id, result.cidadeId);
        this.form(result);

        this.empresaLog = result;
        this.spinner.hide();
      },(exception) => {
        this.spinner.hide();
        console.log(exception);
      })

  }


  updateEmpresa() {
    this.setValueFiltro(this.cadastroForm.value);
    let form = this.cadastroForm;
    var formValue = form.value as Empresa;

    this.spinner.show();
    this.empresaService.updateEmpresa(formValue).subscribe((response) => {
      this.spinner.hide();
      this.messageService.add({ severity: 'success', summary: '', detail: 'Registro alterado com sucesso!' });
    }, (exception) => {
      this.spinner.hide();
      this.messageService.add({ severity: 'error', summary: '', detail: exception });
    })
  }

  findAllEstados() {
    this.spinner.show();
    this.empresaService.findEstados()
      .subscribe((result) => {
        this.estados = result;
        this.spinner.hide();
      }, (exception) => {
        this.spinner.hide();
      })
  }

  receiveCidades(estado: any){
    var form = (<FormGroup>this.cadastroForm);

    if (estado) {
      this.findAllCidades(estado.id, 0);
    }
    else {
      form.controls["estado"].reset();
      form.controls["cidadeId"].reset();
      this.cidades = [];
    }
  }

  findAllCidades(id: number, cidadeId: number) {
    this.spinner.show();
    this.empresaService.findCidades(id)
      .subscribe((result) => {
        this.cidades = result;
        if(cidadeId > 0){
          var cidadeView = this.cidades.find(c => c.id == cidadeId);
          this.cadastroForm.patchValue({cidade: cidadeView});
        }
        this.spinner.hide();
      }, (exception) => {
        this.spinner.hide();
      })
  }

  setValueFiltro(cadastro_Form) {
    this.cadastroForm.patchValue({
      estadoId: cadastro_Form.estado == null ? null : cadastro_Form.estado.id,
      cidadeId: cadastro_Form.cidade == null ? null : cadastro_Form.cidade.id
    });

  }
}
