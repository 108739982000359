import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-historico-atendimento',
  templateUrl: './historico-atendimento.component.html',
  styleUrls: ['./historico-atendimento.component.css']
})
export class HistoricoAtendimentoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
