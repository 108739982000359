import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { TipoLancamento } from '../../../../models/tipo-lancamento.model';
import { LancamentoService } from '../../../../services/lancamento.service';

@Component({
  selector: 'app-tipo-lancamento',
  templateUrl: './tipo-lancamento.component.html',
  styleUrls: ['./tipo-lancamento.component.css']
})
export class TipoLancamentoComponent implements OnInit {

  tipoLancamentoSelecionado: TipoLancamento;
  tiposLancamento: TipoLancamento[];
  readonly: boolean = false;
  showClear: boolean = true;

  constructor(private lancamentoService: LancamentoService) { }

  @Output() tipoLancamento = new EventEmitter();
  @Input() tipoLancamentoEdit;

  ngOnInit() {
    this.getTiposLancamento();
  }

  set(tipoLancamentoEdit) {
    this.tipoLancamentoSelecionado = tipoLancamentoEdit;
  }

  getTiposLancamento() {
    this.lancamentoService.getTiposLancamento().subscribe((tipos) => {
      this.tiposLancamento = tipos;
    });
  }

  emit() {
    this.tipoLancamento.emit(this.tipoLancamentoSelecionado);
  }

  reset() {
    this.tipoLancamentoSelecionado = null;
  }

  setValueDisable() {
    this.readonly = true;
    this.showClear = false;
  }

}
