import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ConfirmationService, SelectItem, MessageService, MenuItem, LazyLoadEvent, Dialog } from 'primeng/primeng';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { ComunsService } from '../../../comuns/comuns.service';
import { Paciente } from '../../../models/paciente.model';
import { DatePipe } from '@angular/common';
import { Convenio } from 'src/app/models/convenio.model';
import { Beneficiario } from 'src/app/models/beneficiario.model';
import { EmpresaService } from '../../../services/empresa.service';
import { ConvenioService } from '../../../services/convenio.service';
import { PacienteService } from '../../../services/paciente.service';
import { CepService } from '../../../services/cep.service';
import { CEP } from 'src/app/models/CEP.model';
import { Dropdown } from '../../../models/dropdown.model';
import { ContratadoService } from 'src/app/services/contratado.service';
import { Contratado } from 'src/app/models/contratado.model';
import { ProfissionalSaude } from 'src/app/models/profissionalSaude.model';

@Component({
  selector: 'app-paciente',
  templateUrl: './paciente.component.html',
  styleUrls: ['./paciente.component.css'],
  providers: [ConfirmationService, MessageService]
})
export class PacienteComponent implements OnInit {

  @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();

  paciente: Paciente;
  pacienteForm: FormGroup;
  display: boolean = false;
  submitted: boolean = false;
  datePipe = new DatePipe('pt-BR');
  convenios: Dropdown[];
  tiposEstadoCivil: SelectItem[];
  tiposGeneros: SelectItem[];
  consultaCep: CEP;
  contratados: Array<Contratado>;
  profissionais: Array<ProfissionalSaude>;

  constructor(
    private formBuilder: FormBuilder,
    private comunsService: ComunsService,
    private spinner: NgxSpinnerService,
    private empresaService: EmpresaService,
    private convenioService: ConvenioService,
    private pacienteService: PacienteService,
    private messageService: MessageService,
    private cepService: CepService,
    private contratadoService: ContratadoService
  ) {
    this.form(null);
    this.contratadosByEmpresa();
  }

  ngOnInit() {
  }

  form(valueForm: Paciente) {
    this.pacienteForm = this.formBuilder.group({
      id: [valueForm && valueForm.id],
      dataNascimento: [valueForm && this.datePipe.transform(valueForm.dataNascimento, "dd/MM/yyyy")],
      logradouro: [valueForm && valueForm.logradouro],
      bairro: [valueForm && valueForm.bairro],
      cidade: [valueForm && valueForm.cidade],
      numeroResidencia: [valueForm && valueForm.numeroResidencia],
      telefone: [valueForm && valueForm.telefone],
      email: [valueForm && valueForm.email],
      celular: [valueForm && valueForm.celular],
      cep: [valueForm && valueForm.cep],
      complemento: [valueForm && valueForm.complemento],
      estado: [valueForm && valueForm.estado],
      estadoCivilId: [valueForm && valueForm.estadoCivilId],
      generoId: [valueForm && valueForm.generoId],
      conjuge: [valueForm && valueForm.conjuge],
      naturalidade: [valueForm && valueForm.naturalidade],
      nomeMae: [valueForm && valueForm.nomeMae],
      nomePai: [valueForm && valueForm.nomePai],
      profissao: [valueForm && valueForm.profissao],
      cpf: [valueForm && valueForm.cpf],
      beneficiarioId: [valueForm && valueForm.beneficiario.id],
      beneficiario: this.formBuilder.group({
        id: [(valueForm && valueForm.beneficiario.id) ? valueForm.beneficiario.id : 0],
        numeroCarteira: [valueForm && valueForm.beneficiario.numeroCarteira],
        validadeCarteira: [valueForm && this.datePipe.transform(valueForm.beneficiario.validadeCarteira, "dd/MM/yyyy")],
        nome: [valueForm && valueForm.beneficiario.nome, Validators.required],
        cns: [valueForm && valueForm.beneficiario.cns],
        convenioId: [valueForm && valueForm.beneficiario.convenioId],
        profissionalSaudeId: [valueForm && valueForm.beneficiario.profissionalSaudeId, Validators.required]
      }),
    });
  }

  showDialogPaciente(paciente: Paciente) {
    this.display = true;
    this.form(paciente);
    this.submitted = false;
    this.getTodosConvenios();
    this.tiposEstadoCivil =
      [
        { label: 'Casado(a)', value: 1 },
        { label: 'Solteiro(a)', value: 2 },
        { label: 'Divorciado(a)', value: 3 },
        { label: 'Viúvo(a)', value: 4 }
      ]

    this.tiposGeneros =
      [
        { label: 'Feminino', value: 3 },
        { label: 'Masculino', value: 2 },
        { label: 'Outro ', value: 1 },
      ]
  }

  getAllPacientes() {

  }

  showDialogMaximized(dialog: Dialog) {
    this.comunsService.showDialogMaximized(dialog);
  }

  validacoes = {
    'campoObrigatorio': [
      { type: 'required', message: 'Este campo é obrigatório.' }
    ]
  };

  salvarPaciente() {
    this.paciente = this.pacienteForm.value as Paciente;

    if (this.paciente.id != null && this.paciente.id > 0) {
      this.updatePaciente();
    }

    else {
      this.createPaciente();
    }
  }

  createPaciente() {
    this.submitted = true;
    this.paciente = this.pacienteForm.value as Paciente;

    if (this.pacienteForm.invalid) {
      this.messageService.add({ severity: 'error', summary: '', detail: 'Existem campos obrigatórios não preenchidos.' });
      return;
    }

    if (this.paciente.beneficiario.validadeCarteira != null)
      this.paciente.beneficiario.validadeCarteira = this.comunsService.transformDate(this.paciente.beneficiario.validadeCarteira);

    if (this.paciente.dataNascimento != null)
      this.paciente.dataNascimento = this.comunsService.transformDate(this.paciente.dataNascimento);

    this.pacienteService.createPaciente(this.paciente).subscribe((response) => {
      var resul = response;
      this.spinner.hide();
      this.messageService.add({ severity: 'success', summary: '', detail: 'Paciente Registrado com sucesso' });

      this.voltar();
      this.pacienteForm.reset();
    }, (exception) => {
      this.spinner.hide();
      this.messageService.add({ severity: 'error', summary: '', detail: exception });
    })
  }

  updatePaciente() {
    this.paciente = this.pacienteForm.value as Paciente;

    if (this.paciente.beneficiario.validadeCarteira != null)
      this.paciente.beneficiario.validadeCarteira = this.comunsService.transformDate(this.paciente.beneficiario.validadeCarteira);

    if (this.paciente.dataNascimento != null)
      this.paciente.dataNascimento = this.comunsService.transformDate(this.paciente.dataNascimento);

    this.pacienteService.updatePaciente(this.paciente).subscribe((response) => {
      this.spinner.hide();
      this.messageService.add({ severity: 'success', summary: '', detail: 'Registro Alterado com sucesso' });

      this.voltar();
      this.pacienteForm.reset();
    }, (exception) => {
      this.spinner.hide();
      this.messageService.add({ severity: 'error', summary: '', detail: exception });
    })
  }

  getTodosConvenios() {
    this.convenioService.getTodosConvenios().subscribe((convenios) => {
      this.convenios = convenios;
    });
  }

  setConvenioId(convenio: Convenio) {
    this.pacienteForm.get('beneficiario').patchValue({
      convenioId: convenio.convenioId
    });
  }

  voltar() {
    this.display = false;
    this.modalSave.emit(null);
  }

  buscarCep(cepDigitado: string) {
    if (cepDigitado.length == 10) {
      this.spinner.show();
      this.cepService.buscarCep(cepDigitado).subscribe((response) => {
        this.spinner.hide();
        this.pacienteForm.patchValue({
          cidade: response.city,
          bairro: response.neighborhood,
          logradouro: response.street,
          estado: response.state
        });
        console.log(response);
      }, (exception) => {
        this.spinner.hide();
        this.messageService.add({ severity: 'error', summary: '', detail: 'Cep não encontrado!' });
      })
    }
  }

  contratadosByEmpresa() {
    this.spinner.show();
    this.contratadoService.contratadosByEmpresa().subscribe((response) => {

      this.contratados = response;
      this.spinner.hide();

    }, (exception) => {
      this.spinner.hide();
      console.log(exception);
    })
  }

  receiveContratado(contratado: any) {
    if (contratado) {
      this.profissionais = contratado.profissionalSaude;
    }
  }

  receiveProfissional(profissional) {
    this.pacienteForm.get('beneficiario').patchValue({
      profissionalSaudeId: profissional.id
    });
  }
}
