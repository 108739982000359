import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { LancamentoService } from '../../../../services/lancamento.service';
import { Dropdown } from '../../../../models/dropdown.model';

@Component({
  selector: 'app-status-lancamento',
  templateUrl: './status-lancamento.component.html',
  styleUrls: ['./status-lancamento.component.css']
})
export class StatusLancamentoComponent implements OnInit {

  statusSelecionado: any;
  listaStatus: Dropdown[];

  @Output() status = new EventEmitter();
  @Input() StatusInit = null;
  @Input() StatusRemove = [];
  @Input() StatusReadonly: boolean = false;
  @Input() showClear: boolean = true;

  constructor(private lancamentoService: LancamentoService) { }

  ngOnInit() {
    this.getAllStatus();
  }

  getAllStatus() {
    this.lancamentoService.getAllStatusLancamento().subscribe((statusLancamento) => {
      this.listaStatus = statusLancamento;
      this.statusSelecionado = this.StatusInit;
    })
  }

  emit() {
    this.status.emit(this.statusSelecionado);
  }

  reset() {
    this.statusSelecionado = null;
  }
}
