import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { Dropdown } from '../../../../models/dropdown.model';
import { ProfissionalSaudeService } from '../../../../services/profissionalSaude.service';

@Component({
  selector: 'app-profissional-saude',
  templateUrl: './profissional-saude.component.html',
  styleUrls: ['./profissional-saude.component.css']
})
export class ProfissionalSaudeComponent implements OnInit {

  profissionalSaudeSelecionado: Dropdown;
  profissionaisSaude: Dropdown[];

  @Output() profissionalSaude = new EventEmitter();
  @Input() ProfissionalSaudeInit;
  @Input() todos: boolean = false;
  @Input() readonly: boolean = false;

  constructor(private profSaudeService: ProfissionalSaudeService) { }

  ngOnInit() {
    this.getProfissionaisSaude();
  }

  getProfissionaisSaude() {
    this.profSaudeService.getProfissionaisSaude().subscribe((profissionais) => {
      this.profissionaisSaude = profissionais;
    }, (exception) => {
      console.log(exception);
    })
  }

  emit() {
    this.profissionalSaude.emit(this.profissionalSaudeSelecionado);
  }

  setProfissionalSaude(profissionalSaudeId: number) {

    if (this.profissionaisSaude) {
      var profissional = this.profissionaisSaude.find((p) => p.value == profissionalSaudeId);
      this.profissionalSaudeSelecionado = profissional;
    }
  }

}
