import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { ComunsService } from '../../../comuns/comuns.service';
import { MessageService, ConfirmationService } from 'primeng/primeng';
import { ProcedimentoTabelaConvenio } from '../../../models/procedimentoTabelaConvenio.model';
import { TabelaConvenioService } from '../../../services/tabelaConvenio.servoce';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { TussComponent } from '../../componentes/modulo-componentes/tuss/tuss.component';
import * as _ from 'lodash';

@Component({
  selector: 'app-criar-ou-editar-procedimento',
  templateUrl: './criar-ou-editar-procedimento.component.html',
  styleUrls: ['./criar-ou-editar-procedimento.component.css'],
  providers: [ConfirmationService, MessageService]
})
export class CriarOuEditarProcedimentoComponent implements OnInit {
  @ViewChild(TussComponent) tussComponent: TussComponent;

  listaProcedimentos: ProcedimentoTabelaConvenio[];
  procedimento_form: FormGroup;
  get procedimentosPacote() { return <FormArray>this.procedimento_form.get('procedimentosPacote'); }
  public procedimentos: ProcedimentoTabelaConvenio[];
  tabelaId: number;
  procedimentoId: number;

  submittedNovoProcedimento: boolean = false;

  constructor(private formBuilder: FormBuilder,
    private tabelaConvenioService: TabelaConvenioService,
    private comunsService: ComunsService,
    private messageService: MessageService,
    private activatedRoute: ActivatedRoute,
    private confirmationService: ConfirmationService,
    private router: Router,
    private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.tabelaId = params['code'];
      this.procedimentoId = params['procedimento'];
      this.form();

      if (this.procedimentoId)
        this.getProcedimentoPorId();
    });
  }

  form(formValue?: ProcedimentoTabelaConvenio) {
    this.procedimento_form = this.formBuilder.group({
      id: [formValue && formValue.id ? formValue.id : 0],
      codigo: [formValue && formValue.codigo, Validators.required],
      procedimento: [formValue && formValue.procedimento, Validators.required],
      porte: [formValue && formValue.porte],
      habilitaPacote: [formValue && formValue.habilitaPacote != null ? formValue.habilitaPacote : false],
      valorPorte: [formValue && formValue.valorPorte],
      auxiliares: [formValue && formValue.auxiliares],
      valorUco: [formValue && formValue.valorUco],
      porteAnestesico: [formValue && formValue.porteAnestesico],
      qtdeFilme: [formValue && formValue.qtdeFilme],
      incidencia: [formValue && formValue.incidencia],
      valor: [formValue && formValue.valor],
      procedimentosPacote: this.formBuilder.array([]),

      procedimentoTabelaConvenioId: [''],
      codigoTuss: [''],
      nomeTuss: [''],
      procedimentoSelecionado: [null],
    });
  }

  criaOuAtualizaProcedimento() {
    this.submittedNovoProcedimento = true;
    let procedimentoCreate = this.procedimento_form.value as ProcedimentoTabelaConvenio;
    procedimentoCreate.tabelaConvenioId = this.tabelaId;

    if (this.procedimento_form.invalid || (procedimentoCreate.habilitaPacote && this.procedimentosPacote.length == 0)) {
      this.messageService.add({ severity: 'error', summary: '', detail: 'Existem campos obrigatórios não preenchidos.' });
      return;
    }

    if (!this.procedimentoId) {
      this.create(procedimentoCreate);
    } else {
      this.update(procedimentoCreate);
    }
  }

  create(procedimentoCreate: ProcedimentoTabelaConvenio) {
    this.spinner.show();
    this.tabelaConvenioService.criarProcedimentoTabelaConvenio(procedimentoCreate).subscribe(() => {

      this.messageService.add({ severity: 'success', summary: '', detail: 'Procedimento enviado com sucesso.' });
      this.submittedNovoProcedimento = false;
      this.limparListaProcedimentosTuss();
      setTimeout(() => {
        this.spinner.hide();
        this.voltar();

      }, 1000);
    }, (exception) => {
      this.spinner.hide();
      this.messageService.add({ severity: 'error', summary: '', detail: 'Erro ao salvar novo procedimento! Tente novamente ou entre em contato com suporte.' });
    })
  }

  update(procedimentoCreate: ProcedimentoTabelaConvenio) {
    this.spinner.show();
    this.tabelaConvenioService.atualizarProcedimentoTabelaConvenio(procedimentoCreate).subscribe(() => {

      this.messageService.add({ severity: 'success', summary: '', detail: 'Procedimento atualizado com sucesso.' });
      this.submittedNovoProcedimento = false;
      this.limparListaProcedimentosTuss();
      setTimeout(() => {
        this.spinner.hide();
        this.voltar();

      }, 1000);
    }, (exception) => {
      this.spinner.hide();
      this.messageService.add({ severity: 'error', summary: '', detail: 'Erro ao salvar procedimento! Tente novamente ou entre em contato com suporte.' });
    })
  }

  receiveProcedimentoTuss(procedimento) {
    this.procedimento_form.patchValue({
      codigo: procedimento.codigo,
      procedimento: procedimento.procedimento,
    });
    this.tussComponent.reset();
  }

  receiveTuss(procedimento) {
    this.procedimento_form.patchValue({
      procedimentoTabelaConvenioId: procedimento && procedimento.id,
      codigoTuss: procedimento && procedimento.codigo,
      nomeTuss: procedimento && procedimento.procedimento
    });
  }

  excluirTUSS(index) {
    (this.procedimentosPacote as FormArray).removeAt(index);
    this.messageService.add({ severity: 'success', summary: '', detail: 'Tuss removido com sucesso!' });
  }

  incluirTuss() {
    try {

      let procedimentoCreate = this.procedimento_form.value as ProcedimentoTabelaConvenio;

      if (!this.procedimento_form.value['procedimentoTabelaConvenioId']) {
        this.messageService.add({ severity: 'error', summary: '', detail: 'Obrigatório selecionar código TUSS' });
        return;
      }

      (this.procedimentosPacote as FormArray).push(this.formBuilder.group({
        procedimentoTabelaId: this.procedimento_form.value['procedimentoTabelaConvenioId'],
        procedimentoTabelaCovenioCodigo: this.procedimento_form.value['codigoTuss'],
        procedimentoTabelaCovenioId: this.tabelaId,
        nomeTuss: this.procedimento_form.value['nomeTuss'],

        pacoteId: 0,
        pacoteCodigoId: procedimentoCreate.codigo,
        pacoteTabelaConvenioId: this.tabelaId
      }));

      this.procedimento_form.get('procedimentoSelecionado').reset();
      this.messageService.add({ severity: 'success', summary: '', detail: 'Tuss incluído com sucesso!' });
    } catch (error) {

    }
  }

  limparListaProcedimentosTuss() {
    while (0 !== this.procedimentosPacote.length) {
      (this.procedimentosPacote as FormArray).removeAt(0);
    }
  }

  getTuss(event) {
    let query = event.query;
    if (query && query.length >= 3) {
      if (this.comunsService.isNumeric(query)) {
        this.tabelaConvenioService.getProcedimentosPorCodigoTuss(query, this.tabelaId).subscribe((procedimentos) => {
          if (procedimentos.length == 0) { this.receiveTuss(null); }
          this.listaProcedimentos = procedimentos;
        });
      } else {
        this.tabelaConvenioService.getProcedimentosPorNomeTuss(query, this.tabelaId).subscribe((procedimentos) => {
          if (procedimentos.length == 0) { this.receiveTuss(null); }
          this.listaProcedimentos = procedimentos;
        })
      }
    } else {
      this.receiveTuss(null);
    }
  }

  validacoes = {
    'campoObrigatorio': [
      { type: 'required', message: 'Este campo é obrigatório.' }
    ]
  };

  voltar() {
    this.router.navigate(['/tabela-convenio']);
  }

  getProcedimentoPorId() {

    this.spinner.show();
    this.tabelaConvenioService.getProcedimentoPorId(this.tabelaId, this.procedimentoId)
      .subscribe((procedimento: ProcedimentoTabelaConvenio) => {
        this.form(procedimento);

        procedimento.procedimentosPacote.forEach((procedimento, index) => {
          (this.procedimentosPacote as FormArray).push(this.formBuilder.group({
            id: procedimento.id,
            procedimentoTabelaId: procedimento.procedimentoTabelaId,
            procedimentoTabelaCovenioCodigo: procedimento.codigoTuss,
            procedimentoTabelaCovenioId: procedimento.procedimentoTabelaCovenioId,
            nomeTuss: procedimento.nomeTuss,
            pacoteId: procedimento.pacoteId,
            pacoteCodigoId: procedimento.pacoteCodigoId,
            pacoteTabelaConvenioId: procedimento.pacoteTabelaConvenioId
          }));
        });



        this.spinner.hide();

      }, (exception) => {
        this.spinner.hide();
      })
  }

  confirmLimparProcedimentos() {
    if (!this.procedimento_form.get('habilitaPacote').value &&
      this.procedimentosPacote.length > 0) {
      this.confirmationService.confirm({
        message: `Confirma a remoção dos procedimentos incluídos neste pacote ?`,
        accept: () => {
          this.limparListaProcedimentosTuss();
        },
        reject: () => {
          this.procedimento_form.patchValue({
            habilitaPacote: true
          });
        }
      });
    }
  }

}
