import { Injectable } from '@angular/core';
import { MEDGESTAO_API } from '../app.api';
import { ErrorHandler } from '../pages/app.error-handler';
import { catchError, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ProfisisonalEmpresa } from '../models/profissional-empresa.model';
import { Http, RequestOptions, ResponseContentType } from '@angular/http';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class RelatorioService {

  constructor(public http: HttpClient, private authenticationService: AuthenticationService) {
  }


  gerarRelatorioLancamentos(parametros) {
    // let options = new RequestOptions({ responseType: ResponseContentType.Blob });

    return this.http.post(`${MEDGESTAO_API}/relatorio/gerarRelatorioLancamentos`, parametros,
      {
        responseType: 'blob'
      })
      .pipe(
        map(blob => { return new Blob([blob], { type: "application/pdf" }) },
          catchError(ErrorHandler.handleError)))
  }

  gerarRelatorioGuias(parametros) {
    // let options = new RequestOptions({ responseType: ResponseContentType.Blob });

    return this.http.post(`${MEDGESTAO_API}/relatorio/gerarRelatorioGuias`, parametros,
      {
        responseType: 'blob'
      })
      .pipe(
        map(blob => { return new Blob([blob], { type: "application/pdf" }) },
          catchError(ErrorHandler.handleError)))
  }

  gerarRelatorioFaturamentoDireto(parametros) {
    return this.http.post(`${MEDGESTAO_API}/relatorio/FaturamentoDireto`, parametros)
      .pipe(
        map(response => { return response as any },
          catchError(ErrorHandler.handleError)))
  }

  download(file: Blob) {
    let blob = new Blob([file], { type: 'application/pdf' });

    var downloadURL = window.URL.createObjectURL(blob);
    var link = document.createElement('a');
    link.href = downloadURL;
    link.download = "_documento_" + Math.random() + ".pdf";
    link.click();
  }

  downloadArquivoTemp(file) {
    const url = MEDGESTAO_API + '/Arquivo/BaixarArquivoTemp?tipoArquivo=' + file.tipoArquivo + '&tokenArquivo=' + file.tokenArquivo + '&nomeArquivo=' + file.nomeArquivo;
    location.href = url; //TODO: This causes reloading of same page in Firefox
  }

}
