import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MEDGESTAO_API } from '../app.api';
import { Beneficiario } from '../models/beneficiario.model';
import { ErrorHandler } from '../pages/app.error-handler';
import { catchError, map } from 'rxjs/operators';
import { Hospital } from '../models/hospital.model';
import { FiltroHospital } from '../models/filtro-hospital';


@Injectable()
export class HospitalService {

    constructor(public httpClient: HttpClient) {
    }

    getAllHospitais(filtros: FiltroHospital) {
        return this.httpClient.post(`${MEDGESTAO_API}/hospital/hospitais`, filtros)
            .pipe(
                map(response => { return response as any },
                    catchError(ErrorHandler.handleError)))
    }

}