import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-configuracao-tiss',
  templateUrl: './configuracao-tiss.component.html',
  styleUrls: ['./configuracao-tiss.component.css']
})
export class ConfiguracaoTissComponent implements OnInit {
  lancamentos: any;
  constructor() { }

  ngOnInit() {
  }

}
