import { Component, OnInit } from '@angular/core';
import { Dropdown } from '../../models/dropdown.model';
import { PorteService } from '../../services/porte.service';
import { CbphmPorte } from '../../models/cbhpmPorte.model';

@Component({
  selector: 'app-tabela-porte',
  templateUrl: './tabela-porte.component.html',
  styleUrls: ['./tabela-porte.component.css']
})
export class TabelaPorteComponent implements OnInit {

  public listaPortes: Dropdown[];
  public portes: CbphmPorte[];

  constructor(private porteService: PorteService) { }

  ngOnInit() {
    this.getNomePortes();
  }

  getNomePortes() {
    this.porteService.getNomePortes()
      .subscribe(listaPortes => this.listaPortes = listaPortes)
  }

  getPortes(porte) {
    var id = porte.value.value;

    this.porteService.getPortes(id)
      .subscribe(portes => this.portes = portes)
  }
}
