import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-chart-card',
  templateUrl: './chart-card.component.html',
  styleUrls: ['./chart-card.component.css']
})
export class ChartCardComponent implements OnInit {

  @Input() class_card;
  @Input() titulo;
  @Input() valor: any = null;

  lottieConfig: any;

  constructor() {
    this.lottieConfig = {
      path: 'assets/animations/loading.json',
      autoplay: true,
      loop: true
    }
   }

  ngOnInit() {
  }

}
