import { Injectable } from '@angular/core';
import { MEDGESTAO_API, MEDGESTAO_APP_API } from '../app.api';
import { Http } from '@angular/http';
import { Observable, Subject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ErrorHandler } from '../pages/app.error-handler';
import { HttpClient } from '@angular/common/http';
import { Dropdown } from '../models/dropdown.model';
import { TabelaConvenio } from '../models/tabela-convenio.model';
import { Porte } from '../models/porte-model';
import { ProfissionalConvenio } from '../models/profissional-convenio.model';

@Injectable()
export class ConvenioService {

  constructor(public httpClient: HttpClient) {
  }

  getTodosConvenios() {
    return this.httpClient.get(`${MEDGESTAO_API}/convenio/convenios`)
      .pipe(
        map(response => { return response as Dropdown[] },
          catchError(ErrorHandler.handleError)))
  }

  getConvenios() {
    return this.httpClient.get(`${MEDGESTAO_API}/ProfissionalSaude/convenios`)
      .pipe(
        map(response => { return response as Dropdown[] },
          catchError(ErrorHandler.handleError)))
  }

  tabelas() {
    return this.httpClient.get(`${MEDGESTAO_API}/convenio/Tabelas`)
      .pipe(
        map(response => { return response as Array<TabelaConvenio> },
          catchError(ErrorHandler.handleError)))
  }

  portes() {
    return this.httpClient.get(`${MEDGESTAO_API}/convenio/Portes`)
      .pipe(
        map(response => { return response as Array<Porte> },
          catchError(ErrorHandler.handleError)))
  }

  //---------Profissional Convenio
  createProfissionalConvenio(profissionalConvenio: ProfissionalConvenio) {
    return this.httpClient.post(`${MEDGESTAO_API}/convenio/CreateProfissionalConvenio`, profissionalConvenio)
      .pipe(
        map(response => { return response as boolean },
          catchError(ErrorHandler.handleError)))
  }

  profissionalConvenioByIdProfissional(profissionalId: number) {
    return this.httpClient.get(`${MEDGESTAO_API}/convenio/ProfissionalConvenioByProfissional/${profissionalId}`)
      .pipe(
        map(response => { return response as Array<ProfissionalConvenio> },
          catchError(ErrorHandler.handleError)))
  }

  findProfissionalConvenioById(profissionalConveniolId: number){
    return this.httpClient.get(`${MEDGESTAO_API}/convenio/FindProfissionalConvenioById/${profissionalConveniolId}`)
    .pipe(
      map(response => {return response as ProfissionalConvenio},
        catchError(ErrorHandler.handleError)))
  }

  updateProfissionalConvenio(profissionalConvenio: ProfissionalConvenio) {
    return this.httpClient.post(`${MEDGESTAO_API}/convenio/UpdateProfissionalConvenio`, profissionalConvenio)
        .pipe(
            map(response => { return response as ProfissionalConvenio },
                catchError(ErrorHandler.handleError)))
    }
}
