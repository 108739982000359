import { Component, OnInit, ViewChild } from '@angular/core';
import { MenuItem, MessageService, Dialog } from 'primeng/primeng';
import { TabelaConvenioService } from '../../services/tabelaConvenio.servoce';
import { Dropdown } from '../../models/dropdown.model';
import { ProcedimentoTabelaConvenio } from '../../models/procedimentoTabelaConvenio.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { TussComponent } from '../componentes/modulo-componentes/tuss/tuss.component';
import { ComunsService } from 'src/app/comuns/comuns.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-tabela-procedimento',
  templateUrl: './tabela-procedimento.component.html',
  styleUrls: ['./tabela-procedimento.component.css'],
  providers: [MessageService]
})
export class TabelaProcedimentoComponent implements OnInit {

  tabelaSelecionda: string = "";
  tabela: Dropdown;
  menusBotaoAcoes: any;
  submittedAddProcedimento: boolean = false;
  public listaTabelas: Dropdown[];
  public procedimentos: ProcedimentoTabelaConvenio[];

  constructor(private tabelaConvenioService: TabelaConvenioService,
    private router: Router,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit() {
    this.getNomeTabelas();
    this.menusBotaoAcoes = (procedimento) => {
      return this.menus(procedimento);
    };
  }

  getNomeTabelas() {
    this.spinner.show();
    this.tabelaConvenioService.getNomeTabelas()
      .subscribe((tabelas) => {
        this.listaTabelas = tabelas;
        this.spinner.hide();
      }, (exception) => {
        this.spinner.hide();
      })
  }

  getProcedimentos() {
    this.submittedAddProcedimento = false;
    var id = this.tabela.value;
    this.spinner.show();
    this.tabelaConvenioService.getProcedimentos(id)
      .subscribe((procedimentos) => {
        this.procedimentos = procedimentos;
        this.spinner.hide();
      }, (exception) => {
        this.spinner.hide();
      })
  }

  menus(procedimento: ProcedimentoTabelaConvenio) {
    return [
      {
        label: 'Editar', command: () => {
          this.criaOuAtualizaProcedimento(procedimento.id);
        }
      }
    ];
  }

  criaOuAtualizaProcedimento(procedimentoId?: Number) {
    this.submittedAddProcedimento = true;
    if (!this.tabela)
      return;

    this.router.navigate(['/procedimento'], { queryParams: { code: this.tabela.value, procedimento: procedimentoId } });
  }

}
