import { Component, OnInit } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { ProfissionalSaudeService } from 'src/app/services/profissionalSaude.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'identificar-profissional',
  templateUrl: './identificar-profissional.component.html',
  styleUrls: ['./identificar-profissional.component.css']
})
export class IdentificarProfissionalComponent implements OnInit {

  identificadorForm: FormGroup;
  constructor(private formBuilder: FormBuilder, private router: Router) { }

  ngOnInit() {
    this.form();
  }

  form() {
    this.identificadorForm = this.formBuilder.group({
      token: ['', Validators.required],
    });
  }

  identificacao() {

    if (this.identificadorForm.invalid)
      return false;

    let token = this.identificadorForm.value.token;
    this.router.navigate(['/profissional-executante'], { queryParams: { token: token } });
  }
}