import { LancamentoTuss } from './lancamento-tuss.model';
import * as _ from 'lodash';

export class CalculoValorProcedimento {

    processarValorProcedimento(lancamentoTuss: any): number {

        let valorProcedimento = 0;
        lancamentoTuss
            .map((tuss) => tuss)
            .reduce((prev, tuss: LancamentoTuss, i) => {
                const procedimento = tuss.valorProcedimento;

                if (!_.isNull(procedimento)) {
                    procedimento.lancamentoViaPercentual = this.getLancamentoViaPercentual(tuss.lancamentoViaId);

                    if (!_.isNull(procedimento.lancamentoViaPercentual) && procedimento.lancamentoViaPercentual > 0) {
                        const isProcedimentoMaiorValor = this.procedimentoMaiorValor(lancamentoTuss, procedimento.valorProcedimento);
                        tuss.vlrTuss = procedimento.valorProcedimento;

                        if (isProcedimentoMaiorValor)
                            tuss.vlrTussPercentAplicado = this.calcularHonorarioMedico(procedimento.valorProcedimento, procedimento.grauPartPercentual, 100);
                        else
                            tuss.vlrTussPercentAplicado = this.calcularHonorarioMedico(procedimento.valorProcedimento, procedimento.grauPartPercentual, procedimento.lancamentoViaPercentual);

                        if (tuss.qtdeProcedimento > 0) {
                            tuss.vlrTussPercentAplicado *= tuss.qtdeProcedimento;
                        }
                        valorProcedimento += tuss.vlrTussPercentAplicado;
                    }
                }
            }, 0);

        return valorProcedimento;
    }

    private calcularHonorarioMedico(valorProc: number, percGrauPart: number, percLancamentoVia: number) {
        let valorProcedimento = valorProc;
        if (percLancamentoVia > 0)
            valorProcedimento = (percLancamentoVia / 100) * valorProc;
        if (percGrauPart > 0)
            valorProcedimento = (percGrauPart / 100) * valorProcedimento;

        return valorProcedimento;
    }

    private procedimentoMaiorValor(lancamentoTuss: any, valor: number) {
        // if (lancamentoTuss.length == 1)
        //     return true;

        var isMaiorValor = lancamentoTuss.filter((procedimento) => {
            return (procedimento.valorProcedimento.valorProcedimento >= valor);
        });
        if (!isMaiorValor.length)
            return true;
        return false;
    }

    private getLancamentoViaPercentual(tipoViaId: number) {
        var id = 0;
        if (tipoViaId == null)
            return id;
        else
            id = Number.parseInt(tipoViaId.toString());

        switch (id) {
            case 1: // Única
                return 100;
            case 2: // Mesma via
                return 50;
            case 3: // Diferente
                return 70;
            default:
                return 0;
        }
    }
}