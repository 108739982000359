import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MEDGESTAO_API } from '../app.api';
import { Beneficiario } from '../models/beneficiario.model';
import { ErrorHandler } from '../pages/app.error-handler';
import { catchError, map } from 'rxjs/operators';


@Injectable()
export class BeneficiarioService {

    constructor(public httpClient: HttpClient) {
    }

    getBeneficiarioByNumeroCarteira(numeroCarteira: string) {
        return this.httpClient.get(`${MEDGESTAO_API}/beneficiario/BeneficiarioByNumeroCarteira/${numeroCarteira}`)
            .pipe(
                map(response => { return response as Beneficiario },
                    catchError(ErrorHandler.handleError)))
    }

}