import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LancamentoService } from '../../services/lancamento.service';
import { Lancamento } from '../../models/lancamento.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MessageService, SelectItem, DynamicDialogRef, DynamicDialogConfig, Dialog } from 'primeng/primeng';
import { GuiaService } from 'src/app/services/guia.service';
import { ProfissionalConselho } from '../../models/profissional-conselho.model';
import { ETipoGuia } from 'src/app/enum/enums';
import { BeneficiarioService } from '../../services/beneficiario.service';
import { Beneficiario } from 'src/app/models/beneficiario.model';
import { ComunsService } from '../../comuns/comuns.service';
import { DatePipe } from '@angular/common';
import { Guia } from '../../models/guia.model';
import { Hospital } from '../../models/hospital.model';
import { GuiaCirurgiaSelectHospitalComponent } from '../guia-cirurgia/guia-cirurgia-select-hospital/guia-cirurgia-select-hospital.component';
import { Convenio } from 'src/app/models/convenio.model';

@Component({
  selector: 'app-guia-consulta',
  templateUrl: './guia-consulta.component.html',
  styleUrls: ['./guia-consulta.component.css'],
  providers: [MessageService]
})
export class GuiaConsultaComponent implements OnInit {

  @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild(GuiaCirurgiaSelectHospitalComponent) guiaCirurgiaSelectHospitalComponent: GuiaCirurgiaSelectHospitalComponent;

  desabilitaCheckSadt: boolean = false;
  display: boolean = false;
  guia_consulta_form: FormGroup;
  img_convenio: string = "";
  datePipe = new DatePipe('pt-BR');

  tiposTabela: SelectItem[];
  tiposConsulta: SelectItem[];
  indicacoesAcidente: SelectItem[];
  caraterAtendimento: SelectItem[];
  tipoAtendimentoGuia: SelectItem[];

  navigation = "";
  conselhos: ProfissionalConselho[];
  submitted = false;
  campoSenhaIsReadonly: boolean = false;

  selectAtendimentoRN: SelectItem[] = [
    { label: 'Sim', value: true },
    { label: 'Não', value: false },
  ];

  constructor(
    private comunsService: ComunsService,
    private messageService: MessageService,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private lancamentoService: LancamentoService,
    private guiaService: GuiaService,
    private beneficiarioService: BeneficiarioService) {
    this.form(null);
    // this.activatedRoute.queryParams.subscribe((params) => {

    //   if (this.hasDynamicDialog()) {
    //     this.getGuiaPorId(this.config.data.guiaId);
    //   } else {
    //     var code = params['code']
    //     this.navigation = params['navigation']
    //     this.carregaRegistros(this.navigation, code);
    //   }
    // });

  }

  ngOnInit() {

  }

  form(formValue: Lancamento) {

    this.guia_consulta_form = this.formBuilder.group({
      vlrTotalLancamento: [formValue && formValue.vlrTotalLancamento],
      codigoTuss: [formValue && formValue.codigoTuss],
      beneficiario: this.formBuilder.group({
        id: [(formValue && formValue.beneficiario.id) ? formValue.beneficiario.id : 0],
        numeroCarteira: [formValue && formValue.beneficiario.numeroCarteira, Validators.required],
        validadeCarteira: [formValue && formValue.beneficiario.validadeCarteira ? this.datePipe.transform(formValue.beneficiario.validadeCarteira, "dd/MM/yyyy") : '', Validators.required],
        nome: [formValue && formValue.beneficiario.nome, Validators.required],
        cns: [formValue && formValue.beneficiario.cns],
        convenioId: [formValue && formValue.convenio.id]
      }),
      contratado: this.formBuilder.group({
        id: [formValue && formValue.contratado.id],
        cpfCnpj: [formValue && formValue.contratado.cpfCnpj],
        razaoSocial: [formValue && formValue.contratado.razaoSocial],
        cnes: [formValue && formValue.contratado.cnes]
      }),
      convenio: this.formBuilder.group({
        id: [formValue && formValue.convenio.id],
        registroans: [formValue && formValue.convenio.registroans],
        logoConvenioBASE64: [formValue && formValue.convenio.logoConvenioBASE64],
        guiaPadraoSadt: [formValue && formValue.convenio.guiaPadraoSadt],
      }),
      profissionalSaude: this.formBuilder.group({
        profissionalSaudeNome: [formValue && formValue.profissionalSaude.profissionalSaudeNome],
        especialidade: this.formBuilder.group({
          id: [formValue && formValue.profissionalSaude.especialidade.id],
          cbo: [formValue && formValue.profissionalSaude.especialidade.cbo]
        }),
        conselho: [(this.conselhos && this.conselhos.length == 1) ? this.conselhos[0] : null, Validators.required], //Se tiver apenas um registro, form já é preenchido.
        numeroConselho: [null],
        uf: [null]
      }),
      guia: this.formBuilder.group({
        id: [(formValue && formValue.guiaId) ? formValue.guiaId : 0],
        tipoGuiaId: [ETipoGuia.consulta, Validators.required],
        guiaSadt: [(formValue && formValue.guiaSadt) == null ? false : formValue.guiaSadt],
        numeroOperadora: [formValue && formValue.numeroOperadora],
        numeroSolicitacaoInternacao: [formValue && formValue.numeroSolicitacaoInternacao],
        numeroSenha: [formValue && formValue.numeroSenha],

        indicacaoAcidenteId: [(formValue && formValue.indicacaoAcidenteId) ? formValue.indicacaoAcidenteId : 4, Validators.required],
        tipoConsultaId: [(formValue && formValue.tipoConsultaId) ? formValue.tipoConsultaId : 1, Validators.required],
        tipoTabelaId: [(formValue && formValue.tipoTabelaId) ? formValue.tipoTabelaId : 5, Validators.required],

        tipoAtendimentoGuiaId: [(formValue && formValue.convenio && formValue.convenio.guiaPadraoSadt && formValue.tipoAtendimentoGuiaId) ? formValue.tipoAtendimentoGuiaId : null],
        caraterAtendimentoId: [(formValue && formValue.convenio && formValue.convenio.guiaPadraoSadt && formValue.caraterAtendimentoId) ? formValue.caraterAtendimentoId : null],

        lancamento: this.formBuilder.group({
          id: [formValue && formValue.lancamentoId, Validators.required],
          conselhoId: [formValue && formValue.conselhoId, Validators.required]
        }),

        hospital: this.formBuilder.group({
          id: [formValue && formValue.hospital && formValue.hospital.id],
          nome: [formValue && formValue.hospital && formValue.hospital.nome],
          codigoOperadora: [formValue && formValue.hospital && formValue.hospital.codigoOperadora],
          cnes: [formValue && formValue.hospital && formValue.hospital.cnes],
        }),

        observacao: [formValue && formValue.observacao],
        dataAtendimento: [formValue && this.datePipe.transform(formValue.dataAtendimento, "dd/MM/yyyy"), Validators.required],
        atendimentoRn: [formValue && formValue.atendimentoRn, Validators.required],
      })
    })

    if (this.conselhos)
      this.setEstadoENumeroConselho();

    if (formValue != null && (formValue.convenio.guiaPadraoSadt || formValue.guiaSadt)) {

      if (formValue.convenio.guiaPadraoSadt)
        this.desabilitaCheckSadt = true;
      this.aplicarFormValidationGuiaPadraoSpSadt();
      this.setValueCaraterETipoAtendimento(formValue.tipoAtendimentoGuiaId, formValue.caraterAtendimentoId);
    } 
    
    //else {
      //this.desabilitaCheckSadt = false;
    //}
  }

  getLancamentoParaGerarGuia(id) {
    this.spinner.show();
    this.lancamentoService.getLancamentoParaGerarGuia(id).subscribe((response) => {
      this.conselhos = response.profissionalSaude.profissionalConselho;
      this.img_convenio = response.convenio.logoConvenioBASE64;
      this.form(response);

      this.spinner.hide();
    }, (exception) => {
      this.spinner.hide();
      console.log(exception);
    })
  }

  getGuiaPorId(guiaId: number) {
    this.spinner.show();
    this.guiaService.getGuiaPorId(guiaId).subscribe((response) => {

      this.conselhos = response.profissionalSaude.profissionalConselho;
      this.img_convenio = response.convenio.logoConvenioBASE64;
      this.form(response);
      this.spinner.hide();
    }, (exception) => {
      this.spinner.hide();
      this.messageService.add({ severity: 'error', summary: '', detail: exception });
    });
  }

  criaOuAtualizaGuia() {
    this.submitted = true;
    if (this.guia_consulta_form.invalid) {
      this.messageService.add({ severity: 'error', summary: '', detail: 'Existem campos obrigatórios não preenchidos.' });
      return;
    }

    let guia = this.guia_consulta_form.get('guia').value as Guia;
    this.setBeneficiario(guia);

    guia.dataAtendimento = this.comunsService.transformDate(guia.dataAtendimento);

    if (guia.id > 0) {
      this.atualizarGuia(guia);
    } else {
      this.criarGuia(guia);
    }
  }

  atualizarGuia(guia: Guia) {
    this.spinner.show();
    this.guiaService.atualizarGuiaConsulta(guia).subscribe(() => {
      this.submitted = false;
      this.spinner.hide();
      this.messageService.add({ severity: 'success', summary: '', detail: "Guia atualizada com sucesso!" });

      this.voltar();
    }, (exception) => {
      this.submitted = false;
      this.spinner.hide();
      this.messageService.add({ severity: 'error', summary: '', detail: 'Erro ao atualizar guia! Tente novamente ou entre em contato com suporte.' });
    })
  }

  criarGuia(guia: Guia) {
    this.spinner.show();
    this.guiaService.gerarGuiaConsulta(guia).subscribe((guiaId: number) => {
      this.submitted = false;
      this.spinner.hide();
      this.messageService.add({ severity: 'success', summary: '', detail: "Guia enviada com sucesso!" });

      guia.id = guiaId;
      this.voltar(guia);
    }, (exception) => {
      this.submitted = false;
      this.spinner.hide();
      this.messageService.add({ severity: 'error', summary: '', detail: 'Erro ao salvar nova guia! Tente novamente ou entre em contato com suporte.' });
    })
  }

  setBeneficiario(guia: Guia) {

    guia.beneficiario = this.guia_consulta_form.get('beneficiario').value as Beneficiario;
    guia.beneficiario.validadeCarteira = this.comunsService.transformDate(guia.beneficiario.validadeCarteira);
  }

  getTiposTabela() {
    this.guiaService.getTiposTabela().subscribe((response) => {
      this.tiposTabela = this.comunsService.toSelectItem(response);
    }, (exception) => {
      console.log(exception);
    })
  }

  getTiposConsulta() {
    this.guiaService.getTiposConsulta().subscribe((response) => {
      this.tiposConsulta = this.comunsService.toSelectItem(response);
    }, (exception) => {
      console.log(exception);
    })
  }

  getIndicacoesAcidente() {
    this.guiaService.getIndicacoesAcidente().subscribe((response) => {
      this.indicacoesAcidente = this.comunsService.toSelectItem(response);
    }, (exception) => {
      console.log(exception);
    })
  }

  getCaraterAtendimento() {
    this.guiaService.getCaraterAtendimento().subscribe((response) => {
      this.caraterAtendimento = this.comunsService.toSelectItem(response);
    }, (exception) => {
      console.log(exception);
    })
  }

  getTipoAtendimentoGuia() {
    this.guiaService.getTipoAtendimentoGuia().subscribe((response) => {
      this.tipoAtendimentoGuia = this.comunsService.toSelectItem(response);
    }, (exception) => {
      console.log(exception);
    })
  }

  setEstadoENumeroConselho() {
    let conselho = this.guia_consulta_form.get('profissionalSaude').get('conselho').value as ProfissionalConselho;
    if (conselho) {
      let builderProfissionalSaude = this.guia_consulta_form.get('profissionalSaude');
      this.setConselhoNoLancamento(conselho);
      builderProfissionalSaude.patchValue({
        numeroConselho: conselho.numeroConselho,
        uf: conselho.estado.sigla
      });
    }
  }

  setConselhoNoLancamento(profissionalConselho: ProfissionalConselho) {
    let builderLancamento = this.guia_consulta_form.get('guia').get('lancamento');
    builderLancamento.patchValue({
      conselhoId: profissionalConselho.conselho.id
    });
  }

  validacoes = {
    'campoObrigatorio': [
      { type: 'required', message: 'Este campo é obrigatório.' }
    ]
  };

  getBeneficiario(numeroCarteira: string) {

    if (numeroCarteira) {
      this.spinner.show();
      this.beneficiarioService.getBeneficiarioByNumeroCarteira(numeroCarteira).subscribe((response) => {
        let beneficiarioForm = this.guia_consulta_form.get('beneficiario') as FormGroup;
        beneficiarioForm.patchValue({
          id: response.id,
          numeroCarteira: response.numeroCarteira,
          validadeCarteira: response && response.validadeCarteira ? this.datePipe.transform(response.validadeCarteira, "dd/MM/yyyy") : '',
          nome: response.nome,
          cns: response.cns,
          convenioId: response.convenioId
        });
        this.spinner.hide();

      }, (exception) => {
        this.spinner.hide();
        this.messageService.add({ severity: 'info', summary: '', detail: exception });
      })
    }
  }

  carregaRegistros(navigation: string, code) {
    if (navigation === '/lancamentos-faturamento') {
      this.getLancamentoParaGerarGuia(code);
    }
    if (navigation === '/guias') {
      this.getGuiaPorId(code);
    }
  }

  preencherSenha(checked) {
    if (checked) {
      this.campoSenhaIsReadonly = true;
      this.guia_consulta_form.get('guia').patchValue({
        numeroSenha: this.guia_consulta_form.get('guia').get("numeroSolicitacaoInternacao").value,
        numeroOperadora: this.guia_consulta_form.get('guia').get("numeroSolicitacaoInternacao").value
      })
    } else {
      this.campoSenhaIsReadonly = false;
    }
  }

  voltar(guia?: Guia) {
    this.display = false;
    this.modalSave.emit(guia);
  }

  showDialogGuiaConsulta(guiaId?: number, lancamentId?: number) {
    this.display = true;

    this.getTiposTabela();
    this.getTiposConsulta();
    this.getIndicacoesAcidente();
    this.getCaraterAtendimento();
    this.getTipoAtendimentoGuia();

    if (lancamentId != null && lancamentId > 0) {
      this.getLancamentoParaGerarGuia(lancamentId);
    }
    if (guiaId != null && guiaId > 0) {
      this.getGuiaPorId(guiaId);
    }
  }

  showDialogMaximized(dialog: Dialog) {
    this.comunsService.showDialogMaximized(dialog);
  }

  showDialogSelectHospital() {
    this.guiaCirurgiaSelectHospitalComponent.showDialog();
  }

  getHospitalSelecionado(hospital: Hospital) {
    this.guia_consulta_form.get('guia').get('hospital').patchValue({
      id: hospital.id,
      nome: hospital.nome,
      codigoOperadora: hospital.codigoOperadora,
      cnes: hospital.cnes
    });
  }

  limparHospital() {
    this.guia_consulta_form.get('guia').get('hospital').reset();
  }

  private aplicarFormValidationGuiaPadraoSpSadt() {

    this.guia_consulta_form.get('guia').get('tipoAtendimentoGuiaId').setValidators(Validators.required);
    this.guia_consulta_form.get('guia').get('caraterAtendimentoId').setValidators(Validators.required);

    this.guia_consulta_form.get('guia').get('hospital').get('nome').setValidators(Validators.required);
    this.guia_consulta_form.get('guia').get('hospital').get('codigoOperadora').setValidators(Validators.required);
    this.guia_consulta_form.get('guia').get('hospital').get('cnes').setValidators(Validators.required);

    this.guia_consulta_form.get('guia').updateValueAndValidity();
    this.guia_consulta_form.get('guia').get('hospital').updateValueAndValidity();
  }

  private removerFormValidationGuiaPadraoSpSadt() {

    this.guia_consulta_form.get('guia').get('tipoAtendimentoGuiaId').clearValidators();
    this.guia_consulta_form.get('guia').get('tipoAtendimentoGuiaId').setErrors(null);

    this.guia_consulta_form.get('guia').get('caraterAtendimentoId').clearValidators();
    this.guia_consulta_form.get('guia').get('caraterAtendimentoId').setErrors(null);

    this.guia_consulta_form.get('guia').get('hospital').get('nome').clearValidators();
    this.guia_consulta_form.get('guia').get('hospital').get('nome').setErrors(null);

    this.guia_consulta_form.get('guia').get('hospital').get('codigoOperadora').clearValidators();
    this.guia_consulta_form.get('guia').get('hospital').get('codigoOperadora').setErrors(null);

    this.guia_consulta_form.get('guia').get('hospital').get('cnes').clearValidators();
    this.guia_consulta_form.get('guia').get('hospital').get('cnes').setErrors(null);

    this.guia_consulta_form.get('guia').updateValueAndValidity();
    this.guia_consulta_form.get('guia').get('hospital').updateValueAndValidity();
  }

  setValueCaraterETipoAtendimento(tipoAtendimentoGuiaId?: any, caraterAtendimentoId?: any) {
    this.guia_consulta_form.get('guia').patchValue({
      tipoAtendimentoGuiaId: !tipoAtendimentoGuiaId ? 4 : tipoAtendimentoGuiaId,
      caraterAtendimentoId: !caraterAtendimentoId ? 1 : caraterAtendimentoId,
      guiaSadt: true
    });
  }

  habilitaSadt(checked) {
    if (checked) {
      this.aplicarFormValidationGuiaPadraoSpSadt();
      this.setValueCaraterETipoAtendimento(null, null);
    } else {
      this.removerFormValidationGuiaPadraoSpSadt();
      this.guia_consulta_form.get('guia').patchValue({
        tipoAtendimentoGuiaId: null,
        caraterAtendimentoId: null
      });
    }
  }
}
