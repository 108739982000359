import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { MEDGESTAO_API } from '../app.api';
import { AuthUser } from '../models/auth.model';
import { ErrorHandler } from '../pages/app.error-handler';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<AuthUser>;
    public currentUser: Observable<AuthUser>;

    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<AuthUser>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): AuthUser {
        return this.currentUserSubject.value;
    }

    login(user: {}) {
        return this.http.post<any>(`${MEDGESTAO_API}/users/autenticar`, user)
            .pipe(map(user => {
                // login successful if there's a jwt token in the response
                if (user && user.token) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('currentUser', JSON.stringify(user));
                    this.currentUserSubject.next(user);
                }

                return user;
            }));
    }

    redefinirSenha(model: RedefinirSenha) {
        return this.http.post(`${MEDGESTAO_API}/Users/ConfirmarAlterarSenha`, model)
            .pipe(
                map(response => { return response },
                    catchError(ErrorHandler.handleError)))
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
    }

    canViewPermissaoUsuario(permissoes: any) {
        return !(permissoes.roles.indexOf(this.currentUserValue.user.permissao) === -1);
    }
}