import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Contratado } from '../models/contratado.model';
import { MEDGESTAO_API } from '../app.api';
import { ErrorHandler } from '../pages/app.error-handler';
import { Especialidade } from '../models/especialidade.model';
import { catchError, map } from 'rxjs/operators';
import { FiltroContratado } from '../models/filtro-contratado.model';
import { ProfissionalContratado } from '../models/profissional-contratado.model';
import { Conselho} from '../models/conselho.model';

@Injectable({
    providedIn: 'root'
})
export class ContratadoService {

    constructor(public httpClient: HttpClient) {

    }

    createContratado(contratado: Contratado) {
        return this.httpClient.post(`${MEDGESTAO_API}/contratado/CreateContratado`, contratado)
            .pipe(
                map(response => { return response as Contratado },
                    catchError(ErrorHandler.handleError)))
    }

    updateContratado(contratado: Contratado) {
        return this.httpClient.post(`${MEDGESTAO_API}/contratado/UpdateContratado`, contratado)
            .pipe(
                map(response => { return response as Contratado },
                    catchError(ErrorHandler.handleError)))
    }

    contratados(filtros: FiltroContratado) {
        return this.httpClient.get(`${MEDGESTAO_API}/contratado/Contratados/${filtros.contratadoId}/${filtros.cpfCnpj}/${filtros.cnes}`)
            .pipe(
                map(response => { return response as Array<Contratado> },
                    catchError(ErrorHandler.handleError)))
    }

    contratadoSearch(parametros: FiltroContratado) {
        return this.httpClient.post(`${MEDGESTAO_API}/contratado/ContratadoSearch`, parametros)
            .pipe(
                map(response => { return response as any },
                    catchError(ErrorHandler.handleError)))
    }

    contratadosByEmpresa() {
        return this.httpClient.get(`${MEDGESTAO_API}/contratado/ContratadosByEmpresa`)
            .pipe(
                map(response => { return response as Array<Contratado> },
                    catchError(ErrorHandler.handleError)))
    }

    getProfissionalContratadosPorEmpresa(prossionalSaudeId: number) {
        return this.httpClient.get(`${MEDGESTAO_API}/contratado/ProfissionalContratadosPorEmpresa/${prossionalSaudeId}`)
            .pipe(
                map(response => { return response as Array<Contratado> },
                    catchError(ErrorHandler.handleError)))
    }

    contratado(contratadoId: number) {
        return this.httpClient.get(`${MEDGESTAO_API}/contratado/Contratado/${contratadoId}`)
            .pipe(
                map(response => { return response as Contratado },
                    catchError(ErrorHandler.handleError)))
    }

    // ----Profissional Contratado
    createProfissionalContratado(profissionalContratado: ProfissionalContratado) {
        return this.httpClient.post(`${MEDGESTAO_API}/contratado/CreateProfissionalContratado`, profissionalContratado)
            .pipe(
                map(response => { return response as boolean },
                    catchError(ErrorHandler.handleError)))
    }

    getEspecialidades(nomeEspecialidade: string){
        return this.httpClient.get(`${MEDGESTAO_API}/ProfissionalSaude/Especialidades/${nomeEspecialidade}`)
            .pipe(
                map(response => { return response as Array<Especialidade> },
                    catchError(ErrorHandler.handleError)))
    }

    findConselhos(){
        return this.httpClient.get(`${MEDGESTAO_API}/ProfissionalSaude/Conselhos`)
        .pipe(
            map(response => { return response as Array<Conselho> },
                catchError(ErrorHandler.handleError)))
    }
}