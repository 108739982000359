import { Component, OnInit, ViewChild, OnDestroy, Output, EventEmitter } from '@angular/core';
import { SelectItem, MessageService, DialogService, DynamicDialogRef, Dialog } from 'primeng/primeng';
import { LoteService } from '../../../services/lote.service';
import { ComunsService } from '../../../comuns/comuns.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ETipoGuia } from 'src/app/enum/enums';
import { ContratadoConvenio } from '../../../models/contratado-convenio.model';
import { Contratado } from '../../../models/contratado.model';
import { GuiaService } from '../../../services/guia.service';
import { FiltroGuia } from '../../../models/filtro-guia.model';
import { Guia } from 'src/app/models/guia.model';
import { Lote } from '../../../models/lote.model';
import { Router, ActivatedRoute } from '@angular/router';
import { ContratadoDropComponent } from '../../componentes/modulo-componentes/contratado/contratado-drop.component';
import { GuiaConsultaComponent } from '../../guia-consulta/guia-consulta.component';
import { ProfissionalSaude } from 'src/app/models/profissionalSaude.model';
import { DatePipe } from '@angular/common';
import { TipoGuiaDropComponent } from '../../componentes/modulo-componentes/tipo-guia-drop/tipo-guia-drop.component';
import { GuiaCirurgiaComponent } from '../../guia-cirurgia/guia-cirurgia.component';
import { ContratadoService } from '../../../services/contratado.service';
import { LancamentoConvenioComponent } from '../../lancamentos/lancamento-convenio/lancamento-convenio.component';
import { error } from 'protractor';

@Component({
  selector: 'app-lote',
  templateUrl: './lote.component.html',
  styleUrls: ['./lote.component.css'],
  providers: [MessageService, DialogService]
})
export class LoteComponent implements OnInit {

  desabilitaCheckSadt: boolean = false;
  datePipe = new DatePipe('pt-BR');
  lote_filter_form: FormGroup;
  versoesTiss: SelectItem[];
  convenios: Array<ContratadoConvenio>;
  profissionais: Array<ProfissionalSaude>;
  // guiaGeracaoLote: GuiaGeracaoLote;
  guias: Array<Guia>;
  selectedGuias: Array<Guia> = [];
  cols: any[];
  submitted = false;
  textFilterGlobal: String = '';
  contratados: Array<Contratado>;
  display: boolean = false;
  loteId: number;
  menuOpcoesAcoes: any;

  @ViewChild('dt') table;
  @ViewChild(TipoGuiaDropComponent) tipoGuiaDropComponent: TipoGuiaDropComponent;
  @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild(GuiaConsultaComponent) guiaConsultaComponent: GuiaConsultaComponent;
  @ViewChild(GuiaCirurgiaComponent) guiaCirurgiaComponent: GuiaCirurgiaComponent;
  @ViewChild(LancamentoConvenioComponent) lancamentoConvenioComponent: LancamentoConvenioComponent;

  constructor(
    private route: Router,
    private formBuilder: FormBuilder,
    private router: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private messageService: MessageService,
    private loteService: LoteService,
    private guiaService: GuiaService,
    private contratadoService: ContratadoService,
    private comunsService: ComunsService,
    public dialogService: DialogService) {
    this.form(null);
  }

  ngOnInit() {
    this.menuOpcoesAcoes = (guia: Guia) => {
      return this.menusOpcoes(guia);
    };
  }

  menusOpcoes(guia) {
    return [
      {
        label: 'Editar Guia', command: () => {
          this.editarGuia(guia.id);
        }
      },
      {
        label: 'Editar lançamento', command: () => {
          this.showEditarLancamento(guia.lancamentoId);
        }
      }
    ];
  }
  showDialogLote(loteId: number) {
    this.limparFormLote();
    this.form(null);
    this.getVersoesTiss();
    this.contratadosByEmpresa(loteId);
    this.display = true;
  }

  form(formValue: Lote) {
    this.lote_filter_form = this.formBuilder.group({
      id: [formValue && formValue.id],
      tipoGuiaId: [(formValue && formValue.tipoGuiaId) ? formValue.tipoGuiaId : ETipoGuia.consulta],
      dtEmissaoInicial: [formValue && formValue.dtEmissaoInicial ? this.datePipe.transform(formValue && formValue.dtEmissaoInicial, "dd/MM/yyyy") : '', Validators.required],
      dtEmissaoFinal: [formValue && formValue.dtEmissaoFinal ? this.datePipe.transform(formValue && formValue.dtEmissaoFinal, "dd/MM/yyyy") : '', Validators.required],
      versaoTissId: [formValue && formValue.versaoTissId, Validators.required],
      convenioId: [formValue && formValue.convenioId],
      contratadoId: [formValue && formValue.contratadoId],
      profissionalSaudeId: [formValue && formValue.profissionalSaudeId],
      guiaSadt: [(formValue && formValue.guiaSadt) == null ? false : formValue.guiaSadt],

      contratadoConvenioForm: this.formBuilder.group({
        contratado: [null, Validators.required],
        convenio: [null, Validators.required],
        profissionalSaude: [null]
      })
    })
  }

  receiveContratado(contratado: Contratado) {

    var form = (<FormGroup>this.lote_filter_form.controls['contratadoConvenioForm']);

    if (contratado) {
      this.convenios = contratado.contratadoConvenio;
      this.profissionais = contratado.profissionalSaude;
    }
    else {
      form.controls["contratado"].reset();
      form.controls["convenio"].reset();
      form.controls["profissionalSaude"].reset();
      this.convenios = [];
      this.profissionais = [];
      this.habilitaSadt({ guiaPadraoSadt: false })
    }
  }

  receiveTipoGuia(tipoGuiaId) {
    this.lote_filter_form.patchValue({
      tipoGuiaId: tipoGuiaId
    });
  }

  getVersoesTiss() {
    this.loteService.getVersoesTiss().subscribe((response) => {
      this.versoesTiss = this.comunsService.toSelectItem(response);
    }, (exception) => {
      console.log(exception);
    })
  }

  getGuiasGeracaoLote(isUpdateValorLote: boolean) {
    this.submitted = true;
    if (this.lote_filter_form.invalid) {
      this.messageService.add({ severity: 'error', summary: '', detail: 'Existe campos obrigatórios não preenchidos.' });
      return;
    }

    this.setIds();
    let filter = this.lote_filter_form.value as FiltroGuia;
    filter.dtEmissaoInicial = this.comunsService.transformDate(filter.dtEmissaoInicial);
    filter.dtEmissaoFinal = this.comunsService.transformDate(filter.dtEmissaoFinal);
    filter.loteId = this.loteId;

    this.spinner.show();
    this.guiaService.getGuiasGeracaoLote(filter).subscribe((response) => {
      this.submitted = false;

      this.guias = response.guias;
      this.selectedGuias = response.guiasSelecionadas;

      if (isUpdateValorLote) {
        let valorTotalLote = this.getVlTotalGuias();
        var loteId = this.loteId;

        let loteUpdate = { id: loteId, valorTotalLote: valorTotalLote } as Lote;
        this.loteService.atualizarValorLote(loteUpdate).subscribe((response) => {
          this.spinner.hide();
        }, error => {
          this.spinner.hide();
        });
      } else {
        this.spinner.hide();
      }
    }, (exception) => {
      this.submitted = false;
      this.spinner.hide();
      this.messageService.add({ severity: 'error', summary: '', detail: exception });
    })
  }

  validacoes = {
    'campoObrigatorio': [
      { type: 'required', message: 'Este campo é obrigatório.' }
    ]
  };

  salvarLote() {
    this.submitted = true;
    let lote = this.lote_filter_form.value as Lote;

    if (this.lote_filter_form.invalid) {
      this.messageService.add({ severity: 'error', summary: '', detail: 'Existe campos obrigatórios não preenchidos.' });
      return;
    }
    if (this.selectedGuias.length == 0) {
      this.messageService.add({ severity: 'error', summary: '', detail: "Não é permitido salvar lote com valor zerado." });
      return;
    }

    this.setIds();
    this.setValuesLote(lote);
    lote.dtEmissaoInicial = this.comunsService.transformDate(lote.dtEmissaoInicial);
    lote.dtEmissaoFinal = this.comunsService.transformDate(lote.dtEmissaoFinal);

    this.spinner.show();

    if (this.loteId > 0) {
      this.loteService.atualizarLote(lote).subscribe((response) => {
        this.spinner.hide();
        this.messageService.add({ severity: 'success', summary: '', detail: 'Lote atualizado com sucesso!' });

        this.voltar();

      }, (exception) => {
        this.spinner.hide();
        this.messageService.add({ severity: 'error', summary: '', detail: exception });
      })
    } else {
      this.loteService.criarLote(lote).subscribe((response) => {
        this.spinner.hide();
        this.messageService.add({ severity: 'success', summary: '', detail: 'Lote enviado com sucesso!' });

        this.voltar();

      }, (exception) => {
        this.spinner.hide();
        this.messageService.add({ severity: 'error', summary: '', detail: exception });
      })
    }
  }

  private setIds() {

    var contratadoConvenioForm = (<FormGroup>this.lote_filter_form.controls['contratadoConvenioForm']).value;
    this.lote_filter_form.patchValue({
      contratadoId: contratadoConvenioForm.contratado[0] == null ? contratadoConvenioForm.contratado.id : contratadoConvenioForm.contratado[0].id,
      convenioId: contratadoConvenioForm.convenio == null ? null : contratadoConvenioForm.convenio.convenioId,
      profissionalSaudeId: contratadoConvenioForm.profissionalSaude == null ? null : contratadoConvenioForm.profissionalSaude.id
    });
  }

  private setValuesLote(lote: Lote) {
    lote.loteGuia = [];
    lote.qtdGuias = this.selectedGuias.length;
    lote.valorTotalLote = this.getVlTotalGuias();

    this.selectedGuias.forEach((guia) => {
      lote.loteGuia.push({
        guiaId: guia.id,
        loteId: lote.id ? lote.id : 0
      });
    });
  }

  clearText() {
    this.table.reset();
    this.textFilterGlobal = '';
  }

  getVlTotalGuias(): number {
    return this.selectedGuias
      .map((item) => item)
      .reduce((prev, item, i) => {
        if (item.vlrTotalLancamento) {
          prev += item.vlrTotalLancamento;
        }
        return prev;
      }, 0)
  }

  abrirLote(loteId: number) {

    this.spinner.show();
    this.loteService.abrirLote(loteId).subscribe((response) => {

      this.form(response);
      this.selectedGuias = response.guiaGeracaoLote.guias;
      this.guias = response.guiaGeracaoLote.guias;
      // this.contratadoDropComponent.setContratado(response.contratado[0] || response.contratado);
      this.tipoGuiaDropComponent.setTipoGuia(response.tipoGuiaId);

      let contratadoSelecionado = this.contratados.find(c => c.id == response.contratadoId);
      this.convenios = response.contratado.contratadoConvenio;
      this.profissionais = response.contratado.profissionalSaude;

      var form = (<FormGroup>this.lote_filter_form.controls['contratadoConvenioForm']);
      form.patchValue({
        contratado: contratadoSelecionado,
        convenio: response.contratado.contratadoConvenio[0],
        profissionalSaude: response.contratado.profissionalSaude[0]
      });
      // if (!response.guiaSadt)
      //   this.habilitaSadt(response.contratado.contratadoConvenio[0]);
      // else
      //   this.desabilitaCheckSadt = true;

      this.spinner.hide();
    }, (exception) => {
      this.spinner.hide();
      this.messageService.add({ severity: 'error', summary: '', detail: exception });
    })
  }

  editarGuia(guiaId: number) {

    if (this.lote_filter_form.value['guiaSadt'] == true)
      this.guiaConsultaComponent.desabilitaCheckSadt = false;
    else {
      this.guiaConsultaComponent.desabilitaCheckSadt = true;
    }

    if (this.lote_filter_form.value['tipoGuiaId'] == ETipoGuia.honorario) {
      this.guiaCirurgiaComponent.showDialogGuiaCirurgia(guiaId, null);
    }

    if (this.lote_filter_form.value['tipoGuiaId'] == ETipoGuia.consulta) {
      this.guiaConsultaComponent.showDialogGuiaConsulta(guiaId, null);
    }
  }

  fecharModalGuia() {

  }

  fecharModalLancamento() {
    this.getGuiasGeracaoLote(true);
  }

  contratadosByEmpresa(loteId: number) {
    this.spinner.show();
    this.contratadoService.contratadosByEmpresa().subscribe((response) => {
      this.contratados = response;

      if (loteId != null && loteId > 0) {
        this.loteId = loteId;
        this.abrirLote(loteId);
      }

      this.spinner.hide();
    }, (exception) => {
      this.spinner.hide();
    })
  }

  voltar() {
    this.display = false;
    this.modalSave.emit(null);
  }

  limparFormLote() {
    var contratadoConvenioForm = (<FormGroup>this.lote_filter_form.controls['contratadoConvenioForm']);
    contratadoConvenioForm.reset();
    this.lote_filter_form.reset();
    this.guias = [];
    this.selectedGuias = [];
    this.submitted = false;
    this.loteId = 0;
  }

  showDialogMaximized(dialog: Dialog) {
    this.comunsService.showDialogMaximized(dialog);
  }

  habilitaSadt(convenio: ContratadoConvenio) {
    if (convenio.guiaPadraoSadt) {
      this.lote_filter_form.patchValue({
        guiaSadt: true
      });
      this.desabilitaCheckSadt = true;
    } else {
      this.lote_filter_form.patchValue({
        guiaSadt: false
      });
      this.desabilitaCheckSadt = false;
    }
  }
  showEditarLancamento(lancamentoId: number) {
    this.lancamentoConvenioComponent.naoGerarOuAtualizarGuia = true;
    this.lancamentoConvenioComponent.desabilitarCamposFormulario();
    this.lancamentoConvenioComponent.setValueDisableTipoLancamento();
    this.lancamentoConvenioComponent.desabilitarShowClear();
    this.lancamentoConvenioComponent.showDialogLancamentoConvenio(lancamentoId);
  }
}
