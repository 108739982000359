import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, SelectItem, MessageService, MenuItem, LazyLoadEvent } from 'primeng/primeng';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { DownloadGuiaComponent } from '../componentes/modulo-componentes/download-guia/download-guia.component';
import { TussViaComponent } from '../componentes/modulo-componentes/tuss-via/tuss-via.component';
import { AtualizaStatusComponent } from '../componentes/modulo-componentes/atualiza-status/atualiza-status.component';
import { LancamentoPendente } from '../../models/lancamentoPendente.model';
import { LancamentoService } from '../../services/lancamento.service';
import { EStatusLancamento, ETipoLancamento } from '../../enum/enums';
import { AnexoLancamento } from '../../models/anexoLancamento.model';
import { FiltroLancamento } from '../../models/filtro-lancamento.model';
import { Lancamento } from '../../models/lancamento.model';
import { ContratadoConvenio } from '../../models/contratado-convenio.model';
import { ProfissionalSaude } from '../../models/profissionalSaude.model';
import { Contratado } from '../../models/contratado.model';
import { ContratadoService } from '../../services/contratado.service';
import { ComunsService } from '../../comuns/comuns.service';
import { ETipoAtendimento } from 'src/app/enum/enums';
import { AuthenticationService } from '../../services/authentication.service';
import { EPermissao } from '../../models/permissao.model';
import { Paginacao } from '../../models/paginacao.model';
import { Table } from 'primeng/table';
import { GuiaConsultaComponent } from '../guia-consulta/guia-consulta.component';
import { LancamentoConvenioComponent } from '../lancamentos/lancamento-convenio/lancamento-convenio.component';
import { GuiaCirurgiaComponent } from '../guia-cirurgia/guia-cirurgia.component';
import { LancamentoParticularComponent } from '../lancamentos/lancamento-particular/lancamento-particular.component';
import { Guia } from '../../models/guia.model';
import { RelatorioService } from '../../services/relatorio.service';

@Component({
  selector: 'app-lancamentos-faturamento',
  templateUrl: './lancamentos-faturamento.component.html',
  styleUrls: ['./lancamentos-faturamento.component.css'],
  providers: [ConfirmationService, MessageService]
})
export class LancamentosFaturamentoComponent implements OnInit {
  @ViewChild(DownloadGuiaComponent) guias: DownloadGuiaComponent;
  @ViewChild(TussViaComponent) tussViaComponent: TussViaComponent;
  @ViewChild(AtualizaStatusComponent) atualizaStatusComponent: AtualizaStatusComponent;
  @ViewChild(LancamentoConvenioComponent) lancamentoConvenioComponent: LancamentoConvenioComponent;
  @ViewChild(LancamentoParticularComponent) lancamentoParticularComponent: LancamentoParticularComponent;
  @ViewChild(GuiaConsultaComponent) guiaConsultaComponent: GuiaConsultaComponent;
  @ViewChild(GuiaCirurgiaComponent) guiaCirurgiaComponent: GuiaCirurgiaComponent;

  @ViewChild('dt') dt: Table;

  interval: any;
  dpwProfSaude: SelectItem[];
  dpwConvenios: SelectItem[];
  dpwTipoAtendimento: SelectItem[];

  lancamentos: Array<Lancamento>;
  tussLancamentoVia: Array<string>;
  display: boolean = false;
  filtro_form: FormGroup;
  convenios: Array<ContratadoConvenio>;
  profissionais: Array<ProfissionalSaude>;
  contratados: Array<Contratado>;
  menusBotaoAcoes: any;
  menusBotaoNovoLancamento: any;
  selectedLancamentos: Array<Lancamento> = [];

  totalRecords: number;
  first: number = 0;

  constructor(
    private router: Router,
    private confirmationService: ConfirmationService,
    private spinner: NgxSpinnerService,
    private messageService: MessageService,
    private formBuilder: FormBuilder,
    private comunsService: ComunsService,
    private auth: AuthenticationService,
    private relatorioService: RelatorioService,
    private contratadoService: ContratadoService,
    private lancamentoService: LancamentoService) { }

  ngOnInit() {
    this.form();
    this.contratadosByEmpresa();

    this.menusBotaoAcoes = (lancamento) => {
      return this.menusEmLancamento(lancamento);
    };

    this.menusBotaoNovoLancamento = () => {
      return [
        {
          label: 'Particular', command: () => {
            this.novoLancamentoParticular();
          }
        },
        {
          label: 'Convênio', command: () => {
            this.novoLancamentoConvenio();
          }
        },
      ];
    };

    this.dpwTipoAtendimento = [
      { label: 'Convênio', value: 2 },
      { label: 'Particular ', value: 1 },
    ]
  }

  form() {
    this.filtro_form = this.formBuilder.group({
      profissionalSaudeId: [''],
      contratadoId: [''],
      convenioId: [''],
      dtLancamentoInicial: [''],
      dtLancamentoFinal: [''],
      nomePaciente: [''],
      statusLancamentoId: [EStatusLancamento.emLancamento],
      numeroGuia: [''],
      contratado: [null],
      convenio: [null],
      profissionalSaude: [null],
      tipoAtendimentoId: [ETipoAtendimento.convenio],
      tipoLancamentoId: [null],
      grauParticipacaoId: [null]
    })
  }

  showGuias(anexos: AnexoLancamento[]) {
    this.guias.showDialog(anexos);
  }

  showTussVia(tussLancamentoVia) {
    this.tussViaComponent.showDialog(tussLancamentoVia);
  }

  getAllLancamentos(paginacao?: LazyLoadEvent) {
    this.spinner.show();
    this.setValueFiltro(this.filtro_form.value);
    let parametros = this.filtro_form.value as FiltroLancamento;
    parametros.dtLancamentoInicial = this.comunsService.transformDate(parametros.dtLancamentoInicial);
    parametros.dtLancamentoFinal = this.comunsService.transformDate(parametros.dtLancamentoFinal);

    var pag = new Paginacao();
    if (paginacao == null) this.dt.first = 0;
    pag.setPaginacao(parametros, paginacao);

    this.lancamentoService.getAllLancamentos(parametros).subscribe((response: any) => {

      this.lancamentos = response.lancamentos;
      this.totalRecords = response.totalCount;

      if (this.filtro_form.value.statusLancamentoId == EStatusLancamento.guiaGerada) {
        this.menusBotaoAcoes = (lancamento) => {
          return this.menusGuiaGerada(lancamento);
        };
      }
      else if (this.filtro_form.value.statusLancamentoId == EStatusLancamento.guiaFaturada) {
        this.menusBotaoAcoes = (lancamento) => {
          return this.menusGuiaFaturada(lancamento);
        };
      } else {
        this.menusBotaoAcoes = (lancamento) => {
          return this.menusEmLancamento(lancamento);
        };
      }
      this.spinner.hide();
    }, (exception) => {

      console.log(exception)
      this.spinner.hide();
      this.messageService.add({ severity: 'info', summary: '', detail: exception });
    })
  }

  get isViewFaturamentoAutomatico() {
    var form = (<FormGroup>this.filtro_form);

    var valor = form.controls["tipoAtendimentoId"].value;
    if (valor == 2) {
      return true;
    }
    else {
      return false;
    }
  }

  setValueTipoAtendimento(id: any) {
    this.filtro_form.patchValue({
      tipoAtendimentoID: id
    });
  }

  receiveContratado(contratado: any) {
    var form = (<FormGroup>this.filtro_form);

    if (contratado) {
      this.convenios = contratado.contratadoConvenio;
      this.profissionais = contratado.profissionalSaude;
    }
    else {
      form.controls["contratado"].reset();
      form.controls["convenio"].reset();
      form.controls["profissionalSaude"].reset();
      this.convenios = [];
      this.profissionais = [];
    }
  }

  // receiveDataInicial(dtLancamentoInicial) {
  //   console.log(dtLancamentoInicial);
  //   this.filtro_form.patchValue({
  //     dtLancamentoInicial: dtLancamentoInicial
  //   });
  // }

  // receiveDataFinal(dtLancamentoFinal) {
  //   console.log(dtLancamentoFinal);
  //   this.filtro_form.patchValue({
  //     dtLancamentoFinal: dtLancamentoFinal
  //   });
  // }

  receiveStatusLancamento(status) {
    this.filtro_form.patchValue({
      statusLancamentoId: status
    });
  }

  status() {
    return EStatusLancamento.emLancamento;
  }

  showGerarGuia(lancamentoId, tipoLancamento) {

    if (tipoLancamento == ETipoLancamento.procedimentos) {
      this.guiaCirurgiaComponent.showDialogGuiaCirurgia(null, lancamentoId);
    }
    if (tipoLancamento == ETipoLancamento.consulta || tipoLancamento == ETipoLancamento.exame) {
      this.guiaConsultaComponent.showDialogGuiaConsulta(null, lancamentoId);
    }
  }

  showEditarLancamento(lancamentoId: number, tipoAtendimentoId: number) {

    console.log(tipoAtendimentoId);
    if (tipoAtendimentoId == ETipoAtendimento.convenio)
      this.lancamentoConvenioComponent.showDialogLancamentoConvenio(lancamentoId);
    if (tipoAtendimentoId == ETipoAtendimento.particular)
      this.lancamentoParticularComponent.showDialogLancamentoParticular(lancamentoId);
  }

  confirmDeleteLancamento(lancamentoId: number) {
    this.confirmationService.confirm({
      message: `Confirma a exclusão do lançamento ${lancamentoId} ?`,
      accept: () => {
        this.deletarLancamento(lancamentoId);
      }
    });
  }

  deletarLancamento(lancamentoId: number) {
    this.spinner.show();
    this.lancamentoService.deleteLancamento(lancamentoId).subscribe(() => {
      this.spinner.hide();

      this.messageService.add({ severity: 'success', summary: '', detail: "Lançamento excluído com sucesso!" });
      this.getAllLancamentos();
    }, (exception) => {
      this.spinner.hide();
      this.messageService.add({ severity: 'error', summary: '', detail: "Erro ao tentar excluir lançamento, tente novamente ou contacte o suporte." });
    })

  }

  setValueFiltro(filtroForm) {
    this.filtro_form.patchValue({
      profissionalSaudeId: filtroForm.profissionalSaude == null ? null : filtroForm.profissionalSaude.id,
      contratadoId: filtroForm.contratado == null ? null : filtroForm.contratado.id,
      convenioId: filtroForm.convenio == null ? null : filtroForm.convenio.convenioId,
    });
  }

  confirmaFaturamentoAutomatico() {
    if (this.selectedLancamentos.length == 0) {
      this.messageService.add({ severity: 'warn', summary: '', detail: "Nenhum lançamento selecionado!" });
      return;
    }

    this.confirmationService.confirm({
      message: `Confirma o faturamento automático dos lançamentos selecionados ?`,
      accept: () => {
        this.faturamentoAutomatico();
      }
    });
  }

  faturamentoAutomatico() {
    this.spinner.show();
    this.lancamentoService.faturarAutomatico(this.selectedLancamentos).subscribe((response) => {
      this.spinner.hide();
      this.messageService.add({ severity: 'success', summary: '', detail: "Lançamentos faturados com sucesso!" });
      this.selectedLancamentos = [];
      this.getAllLancamentos();
    }, (exception) => {
      this.spinner.hide();
      this.messageService.add({ severity: 'error', summary: '', detail: "Faturamento Automático liberado apenas para convenios habilitados" });
    })
  }

  contratadosByEmpresa() {
    this.spinner.show();
    this.contratadoService.contratadosByEmpresa().subscribe((response) => {

      this.contratados = response;
      this.setContratadoDefault();

      // this.getAllLancamentos();

      this.spinner.hide();
    }, (exception) => {
      this.spinner.hide();
      console.log(exception);
    })
  }

  validacoes = {
    'campoObrigatorio': [
      { type: 'required', message: 'Este campo é obrigatório.' },
      { type: 'pattern', message: 'Data inválida.' }
    ]
  };

  showNovoLancamento() {
    if (this.auth.canViewPermissaoUsuario({ roles: [EPermissao.Fat] })) {
      this.router.navigate(['/lancamento'], {
        queryParams: {
          code: 0,
          type: btoa(ETipoAtendimento.convenio.toString()),
        },
        replaceUrl: false
      });
    } else {
      this.router.navigate(['/lancamento']);
    }
  }

  novoLancamentoConvenio() {
    this.lancamentoConvenioComponent.showDialogLancamentoConvenio();
  }

  novoLancamentoParticular() {
    this.lancamentoParticularComponent.showDialogLancamentoParticular();
  }

  menusEmLancamento(lancamento) {
    var menus = [
      // {
      //   label: 'Visualizar Lançamento', command: () => {

      //   }
      // },
      {
        label: 'Editar', command: () => {
          this.showEditarLancamento(lancamento.id, lancamento.tipoAtendimentoId);
        }
      },
      {
        label: 'Excluir', command: () => {
          this.confirmDeleteLancamento(lancamento.id);
        }
      },
      {
        label: 'Download Documento', command: () => {
          this.showGuias(lancamento.anexoLancamento);
        }
      }
    ];

    if (this.isNotSec) {
      menus.push({
        label: 'Gerar Guia', command: () => {
          this.showGerarGuia(lancamento.id, lancamento.tipoLancamento.id);
        }
      });
    }

    return menus;
  }

  menusGuiaGerada(lancamento) {
    var menus = [
      {
        label: 'Visualizar Lançamento', command: () => {

        }
      },
      {
        label: 'Editar', command: () => {
          this.showEditarLancamento(lancamento.id, lancamento.tipoAtendimentoId);
        }
      },
      {
        label: 'Download Documento', command: () => {
          this.showGuias(lancamento.anexoLancamento);
        }
      }
    ];

    if (this.isNotSec) {
      menus.push({
        label: 'Editar Guia', command: () => {
          if (lancamento.tipoLancamento.id == ETipoLancamento.procedimentos) {
            this.guiaCirurgiaComponent.showDialogGuiaCirurgia(lancamento.guiaId, null);
          }
          if (lancamento.tipoLancamento.id == ETipoLancamento.consulta || lancamento.tipoLancamento.id == ETipoLancamento.exame) {
            this.guiaConsultaComponent.showDialogGuiaConsulta(lancamento.guiaId, null);
          }
        }
      });
    }

    return menus;
  }

  menusGuiaFaturada(lancamento) {
    var menus = [
      {
        label: 'Visualizar Lançamento', command: () => {

        }
      },
      {
        label: 'Download Documento', command: () => {
          this.showGuias(lancamento.anexoLancamento);
        }
      }
    ];

    return menus;
  }

  setContratadoDefault() {
    if (this.auth.canViewPermissaoUsuario({ roles: [EPermissao.Sec, EPermissao.Med] })) {
      this.receiveContratado(this.contratados[0]);
      this.filtro_form.patchValue({
        contratado: this.contratados[0],
        profissionalSaude: this.contratados[0].profissionalSaude.length == 1 ? this.contratados[0].profissionalSaude[0] : null
      });
    }
  }

  get isNotSec() {
    return this.auth.canViewPermissaoUsuario({ roles: [EPermissao.Fat, EPermissao.Admin, EPermissao.Med] });
  }

  receiveTipoLancamento(tipoLancamento) {
    this.filtro_form.patchValue({
      tipoLancamentoId: tipoLancamento && tipoLancamento.id
    });
  }

  receiveGrauParticipacao(grauParticipacao) {
    this.filtro_form.patchValue({
      grauParticipacaoId: grauParticipacao && grauParticipacao.id
    });
  }

  gerarRelatorioGuias(guia: Guia) {
    let parametros = {
      id: guia.id,
      tipoGuiaId: guia.tipoGuiaId,
      espelho: true,
      guiaSadt: guia.guiaSadt
    } as FiltroLancamento;

    this.spinner.show();
    this.relatorioService.gerarRelatorioGuias(parametros).subscribe((file) => {
      this.spinner.hide();
      this.getAllLancamentos();
      this.relatorioService.download(file);
    }, (exception: Response) => {
      this.spinner.hide();
      this.messageService.add({ severity: 'success', summary: '', detail: "Nenhum registro encontrado para o período informado." });
    })
  }
}
