import { Component, OnInit } from '@angular/core';
import { MessageService, SelectItem } from 'primeng/primeng';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-lancamento',
  templateUrl: './lancamento.component.html',
  providers: [MessageService]
})

export class LancamentoComponent {

  layoutLancamento: SelectItem[];
  layout: SelectItem;
  readonly: boolean = false;

  constructor(private router: ActivatedRoute, private messageService: MessageService) {
    this.layoutLancamento = [
      { label: 'Particular', value: 1 },
      { label: 'Convênio', value: 2 },
    ];

    this.router
      .queryParams
      .subscribe(params => {
        this.readonly = true;
        let id = params['code'];
        let type = this.getTipoAtendimentoId(params['type']);
        if (id > 0 && type > 0) {
          this.layout = this.layoutLancamento[type - 1];
        } else if (id == 0 && type > 0) {
          this.layout = this.layoutLancamento[type - 1];
        } else if (!id && !type) {
          this.readonly = false;
          this.layout = null;
        }
      });
  }

  ngOnInit() {
  }

  getTipoAtendimentoId(param): number {
    try {
      return parseInt(atob(param));
    } catch (exception) {
      this.messageService.add({ severity: 'error', summary: '', detail: "Tipo informado inválido!" });
    }
  }
}