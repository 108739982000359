import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ErrorHandler } from '../pages/app.error-handler';
import { GrauParticipacao } from '../models/grau-participacao.model';
import { MEDGESTAO_API } from '../app.api';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class GrauParticipacaoService {

  constructor(public http: HttpClient) {
  }

  getGrausParticipacao(): Observable<GrauParticipacao[]> {
    return this.http.get(`${MEDGESTAO_API}/lancamento/GrauParticipacao`)
      .pipe(
        map(response => { return response as GrauParticipacao[]},
          catchError(ErrorHandler.handleError)))
  }


}
